import React, { ReactElement } from "react";

import { useRecoilValueLoadable } from 'recoil';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { useTranslation } from 'react-i18next';

import { builtISODate } from '../../../helpers'

import {
  ICoachSalary,
  IDrillSalaryAttendance,
  ISailsSalary,
  ICoachDrillDateAttendanceSalary,
  ICoachDrillTypeSalary,
} from "../../../interfaces/SalariesStat";

import { drillTypesAtom } from "../../../atoms/DrillTypes";
import { salaryStatSelector } from "../../../atoms/SalariesCountStat";

interface ITableCalendar {
  dateStart: string
  dateStop: string
  clubUnit: number
}

interface IAttendanceCardProps {
  data: IDrillSalaryAttendance
}

interface ISailCardProps {
  data: ISailsSalary
}

function AttendanceCard(props: IAttendanceCardProps) {
  const { t, i18n } = useTranslation(['calendar', 'club']);
  const drillTypes = useRecoilValueLoadable(drillTypesAtom)

  return (
    <Paper elevation={3} sx={{ marginTop: '2px', marginBottom: '2px' }}>
      <Typography sx={{ fontSize: '0.75rem' }}>
        {t('drill_time')}: {props.data.drill_time.split('T')[1].split('+')[0]}
      </Typography>
      {drillTypes.state === 'hasValue' && drillTypes.contents.items.filter(dt => dt.id === props.data.drill_type_id).length
        ? <Typography sx={{ fontSize: '0.75rem' }}>
            {drillTypes.contents.items.filter(dt => dt.id === props.data.drill_type_id)[0].name}
          </Typography>
        : ''
      }
      <Typography sx={{ fontSize: '0.75rem' }}>
        {t('clients_amount')}: {props.data.attendance.length}
      </Typography>
      <Typography sx={{ fontSize: '0.75rem' }}>
        {t('salary', { ns: 'club' })}: {props.data.salary}
      </Typography>
    </Paper>
  )
}

function SailCard(props: ISailCardProps) {
  const { t, i18n } = useTranslation(['statistic', 'club']);

  return (
    <Paper elevation={3} sx={{ marginTop: '2px', marginBottom: '2px' }}>
      <Typography sx={{ fontSize: '0.75rem' }}>
        {t('salary_stat_data.sail_time')}: {builtISODate(new Date(props.data.sail_time))}
      </Typography>
      <Typography sx={{ fontSize: '0.75rem' }}>
        {t('ticket')}: {props.data.ticket}
      </Typography>
      <Typography sx={{ fontSize: '0.75rem' }}>
        {t('salary', { ns: 'club' })}: {props.data.salary}
      </Typography>
    </Paper>
  )
}

export function SalaryTableCalendar(props: ITableCalendar) {
  const [datesArr, setDatesArr] = React.useState<string[]>([])

  const { t, i18n } = useTranslation(['calendar', 'statistic']);

  const salaryStat = useRecoilValueLoadable(
    salaryStatSelector(
      {
        club_unit: props.clubUnit,
        date_start: props.dateStart,
        date_stop: props.dateStop,
      }
    )
  )
  const drillTypes = useRecoilValueLoadable(drillTypesAtom)

  const collectDatesArr = () => {
    let dt1: any = new Date(props.dateStart)
    let dt2: any = new Date(props.dateStop)
    let daysAmount = Math.floor((dt2 - dt1) / (1000 * 60 * 60 * 24))
    
    let currentDate: any = new Date(props.dateStart)
    let days: string[] = [builtISODate(currentDate)]

    for (let i = 0; i < daysAmount + 1; i++) {
      currentDate.setDate(currentDate.getDate() + 1)
      days.push(builtISODate(currentDate))
    }

    setDatesArr(days)
  }

  React.useEffect(() => {
    collectDatesArr()
  }, [props.dateStart])

  React.useEffect(() => {
    collectDatesArr()
  }, [props.dateStop])

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', height: '70vh' }}>
      <TableContainer sx={{ height: '100%' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                align={'center'}
                style={{ minWidth: 200 }}
              >
                {t('salary_stat_data.coach', { ns: 'statistic' })}
              </TableCell>
              <TableCell
                align={'center'}
                style={{ minWidth: 300 }}
              >
                {t('salary_stat_data.sum', { ns: 'statistic' })}
              </TableCell>
              {datesArr.map((dt, index) => (
                <TableCell
                  key={`head-dt-${index}`}
                  align={'center'}
                  style={{ minWidth: 200 }}
                >
                  {dt}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {salaryStat.state === 'hasValue'
                ? <>
                  {salaryStat.contents.items.map((coach: ICoachSalary) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={`time-row-${coach.pk}`}
                      >
                              <TableCell
                                key={`name-cell-date-coach-${coach.pk}`}
                                align={'center'}
                              >
                                {coach.first_name} {coach.last_name}
                              </TableCell>
                              <TableCell
                                key={`salary-cell-date-coach-${coach.pk}`}
                                align={'left'}
                              >
                                <Typography>
                                  {t('salary_stat_data.sum', { ns: 'statistic' })}: {coach.result_salary.toFixed(2)}
                                </Typography>
                                {coach.drill_type_salary.map((dts: ICoachDrillTypeSalary) => {
                                  return (
                                    <Typography>
                                      {drillTypes.contents.items.filter((dt: any) => dt.id === dts.drill_type_id)[0].name}: {dts.salary.toFixed(2)}
                                    </Typography>
                                  )
                                })}
                              </TableCell>
                        {datesArr.map((dt, index) => {
                          let salaries: ICoachDrillDateAttendanceSalary[] = coach.salaries.filter(sl => sl.drill_date === dt)
                          if (salaries.length) {
                            return (
                              <TableCell
                                key={`date-cell-date-coach-${coach.pk}-${index}`}
                                align={'center'}
                              >
                                <Box>
                                  {salaries[0].attendance.map(
                                    (at, j) => {
                                      return (
                                        <AttendanceCard
                                          key={`coach-attendance-card-${coach.pk}-${j}`}
                                          data={at}
                                        />
                                      )
                                    }
                                  )}
                                </Box>
                                <Box>
                                  {salaries[0].sails_salary.map(
                                    (ss, j) => {
                                      return (
                                        <SailCard
                                          key={`coach-sails-salary-card-${coach.pk}-${j}`}
                                          data={ss}
                                        />
                                      )
                                    }
                                  )}
                                </Box>
                              </TableCell>
                            )
                          } else {
                            return (
                              <TableCell
                                key={`date-cell-date-coach-${coach.pk}-${index}`}
                                align={'center'}
                              >
                              </TableCell>
                            )
                          }
                        })}
                      </TableRow>
                    );
                  })}
                  </>
                : <></>
              }
            </>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}
