import React, { ReactElement } from "react";

import { useRecoilValueLoadable } from 'recoil';

import { CSVLink } from "react-csv";

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { useTranslation } from 'react-i18next';

import { ITicketsSalesStat } from '../../../interfaces/Statistic'
import { UserInfoCommon } from '../../../interfaces/AccountInfo'
import { ClubUnit } from "../../../interfaces/Club";

interface ISalesStatProps {
  data: ITicketsSalesStat[]
}

function TicketsSalesTable(props: ISalesStatProps) {
  const { t, i18n } = useTranslation(['statistic']);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', height: '90%' }}>
      <TableContainer sx={{ height: '100%' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                align={'left'}
                style={{ minWidth: 100 }}
              >
                {t('tickets_stat.table.date')}
              </TableCell>
              <TableCell
                align={'left'}
                style={{ minWidth: 200 }}
              >
                {t('tickets_stat.table.client')}
              </TableCell>
              <TableCell
                align={'left'}
                style={{ minWidth: 200 }}
              >
                {t('tickets_stat.table.ticket')}
              </TableCell>
              <TableCell
                align={'left'}
                style={{ minWidth: 150 }}
              >
                {t('tickets_stat.table.start_date')}
              </TableCell>
              <TableCell
                align={'left'}
                style={{ minWidth: 150 }}
              >
                {t('tickets_stat.table.expire_date')}
              </TableCell>
              <TableCell
                align={'left'}
                style={{ minWidth: 150 }}
              >
                {t('tickets_stat.table.closing_date')}
              </TableCell>
              <TableCell
                align={'left'}
                style={{ minWidth: 300 }}
              >
                {t('tickets_stat.table.coaches')}
              </TableCell>
              <TableCell
                align={'left'}
                style={{ minWidth: 200 }}
              >
                {t('tickets_stat.table.club_unit')}
              </TableCell>
              <TableCell
                align={'left'}
                style={{ minWidth: 50 }}
              >
                {t('tickets_stat.table.price')}
              </TableCell>
              <TableCell
                align={'left'}
                style={{ minWidth: 50 }}
              >
                {t('tickets_stat.table.discount')}
              </TableCell>
              <TableCell
                align={'left'}
                style={{ minWidth: 50 }}
              >
                {t('tickets_stat.table.payed_amount')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {props.data.map((d: ITicketsSalesStat) => {
                console.log('Ticket: ', d.id, d.start_date)
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={`sales-stat-row-${d.id}`}
                  >
                    <TableCell
                      key={`sales-stat-date-cell-${d.id}`}
                      align={'left'}
                    >
                      {d.purchase_date.split('T')[0]}
                    </TableCell>
                    <TableCell
                      key={`sales-stat-client-cell-${d.id}`}
                      align={'left'}
                    >
                      {d.client.first_name} {d.client.last_name}
                    </TableCell>
                    <TableCell
                      key={`sales-stat-ticket-cell-${d.id}`}
                      align={'left'}
                    >
                      {d.ticket_name}
                    </TableCell>
                    <TableCell
                      key={`sales-stat-start_date-cell-${d.id}`}
                      align={'left'}
                    >
                      {d.start_date}
                    </TableCell>
                    <TableCell
                      key={`sales-stat-expire_date-cell-${d.id}`}
                      align={'left'}
                    >
                      {d.expire_date}
                    </TableCell>
                    <TableCell
                      key={`sales-stat-closing_date-cell-${d.id}`}
                      align={'left'}
                    >
                      {d.closing_date}
                    </TableCell>
                    <TableCell
                      key={`sales-stat-coaches-cell-${d.id}`}
                      align={'left'}
                    >
                      {d.coaches.map(c => `${c.first_name} ${c.last_name}`).join(', ')}
                    </TableCell>
                    <TableCell
                      key={`sales-stat-club_unit-cell-${d.id}`}
                      align={'left'}
                    >
                      {d.club_units.map(c => `${c.name}`).join(', ')}
                    </TableCell>
                    <TableCell
                      key={`sales-stat-price-cell-${d.id}`}
                      align={'left'}
                    >
                      {d.price}
                    </TableCell>
                    <TableCell
                      key={`sales-stat-discount-cell-${d.id}`}
                      align={'left'}
                    >
                      {(d.discount) ? (d.discount * 100) | 0 : d.discount}
                    </TableCell>
                    <TableCell
                      key={`sales-stat-payed_amount-cell-${d.id}`}
                      align={'left'}
                    >
                      {d.payed_amount}
                    </TableCell>
                  </TableRow>
                );
              })}
            </>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export function TicketsSalesStatCard(props: ISalesStatProps) {
  const { t, i18n } = useTranslation(['common']);

  return (
    <Box style={{ height: '100%' }}>
      <Box style={{ marginBottom: '5px' }}>
        {props.data.length
          ? <Box sx={{ width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', marginTop: '10px' }}>
              <CSVLink
                data={
                  props.data.map(
                    (d: ITicketsSalesStat) => {
                      return {
                        id: d.id,
                        ticket_name: d.ticket_name,
                        start_date: d.start_date,
                        expire_date: d.expire_date,
                        closing_date: d.closing_date,
                        coaches: d.coaches.map((c: UserInfoCommon) => {return `${c.first_name} ${c.last_name}`}).join(', '),
                        client: `${d.client.first_name} ${d.client.last_name}`,
                        purchase_date: d.purchase_date,
                        club_units: d.club_units.map((c: ClubUnit) => {return c.name}).join(', '),
                        price: d.price,
                        discount: d.discount,
                        payed_amount: d.payed_amount,
                      }
                    }
                  )
                }
              >
                <Button
                  variant="contained"
                  color="info"
                >
                  {t("download")}
                </Button>
              </CSVLink>
            </Box>
          : <></>
        }
      </Box>
      <TicketsSalesTable data={props.data} />
    </Box>
  )
}
