import React, { useEffect } from "react";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { useTranslation } from 'react-i18next';

import {
  BidFullProps,
  AutocompleteProps,
  NewBidProps,
} from "../../interfaces/Props";
import { BodyObj } from "../../interfaces/Types";
import { ClubUnit } from "../../interfaces/Club";
import { BidSource } from "../../interfaces/Common";

import { builtISODate } from "../../helpers";

export function BidManage(props: BidFullProps) {
  const { t } = useTranslation(['bids', 'common']);
  const [formValid, setFormValid] = React.useState<boolean>(false);
  const regex = /^[A-Za-z0-9\-\.]+@[A-Za-z0-9\-\.]+\.[a-z]{2,3}$/;
  const phoneRegex = /^\+{0,1}[0-9\-\.]{8,}$/;
  const [editBody, setEditBody] = React.useState<BodyObj>(
    {
      bid: props.bid.id,
      contact_phone: (props.bid.contact_phone) ? props.bid.contact_phone : '',
      contact_email: (props.bid.contact_email) ? props.bid.contact_email : '',
    }
  );
  const [phone, setPhone] = React.useState<string>(
    (props.bid.contact_phone) ? props.bid.contact_phone : ''
  );
  const [phoneValid, setPhoneValid]= React.useState<boolean>(true);
  const [email, setEmail] = React.useState<string>(
    (props.bid.contact_email) ? props.bid.contact_email : ''
  );
  const [emailValid, setEmailValid]= React.useState<boolean>(true);

  React.useEffect(() => {
    if (phone !== '' && !phoneRegex.test(phone)) {
      setPhoneValid(false);
    } else {
      setPhoneValid(true);
      setEditBody({...editBody, contact_phone: phone});
    }
  }, [phone]);

  React.useEffect(() => {
    if (email !== '' && !regex.test(email)) {
      setEmailValid(false);
    } else {
      setEmailValid(true);
      setEditBody({...editBody, contact_email: email});
    }
  }, [email]);

  const checkFormValid = () => {
    if (phone === '' && email === '' && !props.bid.child) {
      setFormValid(false);
    } else if (!phoneValid || !emailValid) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }

  React.useEffect(() => {
    checkFormValid()
  }, [phoneValid]);
  React.useEffect(() => {
    checkFormValid()
  }, [emailValid]);
  
  return (
    <Box>
      <Autocomplete
        id="bid-create-club-unit-id"
        options={
          props.clubUnits.map(
             (unit: ClubUnit) => {return {id: unit.id, label: unit.name}}
          )
        }
        sx={{ width: "100%", marginBottom: "15px" }}
        renderInput={(params) => <TextField {...params} label={t("club_unit")} />}
        onChange={(
          e: React.SyntheticEvent,
          value: AutocompleteProps | null,
          reason: string,
        ) => {
          if (value && value.id) {
            setEditBody({...editBody, club_unit: value.id});
          }
        }}
        defaultValue={
          props.clubUnits.map(
             (unit: ClubUnit) => {return {id: unit.id, label: unit.name}}
          ).find(cu => cu.id === props.bid.club_unit_id)
        }
      />
      <Divider />
      <Autocomplete
        id="bid-create-source-id"
        options={
          props.sources.map(
            (bid: BidSource) => {return {id: bid.id, label: bid.source}}
          )
        }
        sx={{ width: "100%", marginBottom: "15px" }}
        renderInput={(params) => <TextField {...params} label={t("bid_source")} />}
        onChange={(
          e: React.SyntheticEvent,
          value: AutocompleteProps | null,
          reason: string,
        ) => {
          if (value && value.id) {
            setEditBody({...editBody, source: {id: Number(value.id), source: value.label}});
          }
        }}
        defaultValue={
          props.sources.map(
            (bid: BidSource) => {return {id: bid.id, label: bid.source}}
          ).find(s => s.id === props.bid.source.id)
        }
      />
      <Divider />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "15px",
        }}
      >
        <Typography sx={{ marginRight: "10px" }}>{t("contact_name")}:</Typography>
        <Input
          sx={{ width: "100%" }}
          defaultValue={props.bid.contact_name}
          onChange={(e) => {
            setEditBody({...editBody, contact_name: e.target.value});
          }}
        />
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "15px",
        }}
      >
        <Typography sx={{ marginRight: "10px" }}>{t("phone")}:</Typography>
        <Input
          sx={{ width: "100%" }}
          value={phone}
          onChange={
            (e) => {
              setPhoneValid(false);
              setPhone(e.target.value)
            }
          }
          error={!phoneValid}
        />
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "15px",
        }}
      >
        <Typography sx={{ marginRight: "10px" }}>{t("email")}:</Typography>
        <Input
          sx={{ width: "100%" }}
          value={email}
          onChange={
            (e) => {
              setEmailValid(false);
              setEmail(e.target.value)
            }
          }
          error={!emailValid}
        />
      </Box>
      <Divider />
      <Box>
        {props.departments.map((department, index) => {
          return (
            <Box key={`department-arr-key-${index}`}>
              <Typography sx={{marginBottom: "10px"}}>{department.name}</Typography>
              <Autocomplete
                disablePortal
                id="bid-employees-managing"
                options={
                  department.employees.map((emp) => {
                    return {id: emp.id, label: `${emp.employee.first_name} ${emp.employee.last_name}`}
                  })
                }
                sx={{ width: "100%", marginBottom: "15px" }}
                defaultValue={
                  (props.bid.employees && props.bid.employees.length)
                    ? department.employees.filter(
                        emp => props.bid.employees.includes(emp.id)
                      ).map((emp, index) => {
                        return {id: emp.id, label: `${emp.employee.first_name} ${emp.employee.last_name}`}
                      })
                    : []
                }
                renderInput={(params) => (<TextField {...params} label={t("employees")} />)}
                multiple={true}
                onChange={(
                  e: React.SyntheticEvent,
                  value: Array<AutocompleteProps> | null,
                  reason: string,
                ) => {
                  if (value && value.length) {
                    let choosen_emp_ids = value.map((emp) => {return emp.id})
                    props.bid.employees = department.employees.filter(
                      emp => choosen_emp_ids.includes(emp.id)
                    ).map((emp) => {return emp.id})
                    setEditBody(
                      {
                        ...editBody,
                        employees: department.employees.filter(
                          emp => choosen_emp_ids.includes(emp.id)
                        ).map((emp) => {return emp.id}),
                      }
                    );
                  } else {
                    setEditBody(
                      {
                        ...editBody,
                        employees: [],
                      }
                    );
                  }
                  if (props.bid.contact_phone === '' && props.bid.contact_email === '') {
                    setFormValid(false);
                  } else {
                    setFormValid(true);
                  }
                }}
              />
            </Box>
          )
        })}
      </Box>
      <Divider />
      <Box>
        <TextField
          id="standard-multiline-static"
          label="Multiline"
          multiline
          rows={5}
          defaultValue={props.bid.comment}
          variant="standard"
          sx={{width: "100%"}}
          onChange={(e: any) => {
            setEditBody({...editBody, comment: e.target.value});
          }}
        />
      </Box>
      <Divider />
      <DialogActions
        sx={{ marginBottom: "15px" }}
      >
        <Button
          variant="outlined"
          color="error"
          onClick={(e) => {props.closeEdit()}}
        >
          {t("cancel", { ns: 'common' })}
        </Button>
        <Button
          variant="contained"
          color="info"
          disabled={!formValid}
          onClick={(e) => {
            if (props.bid.contact_email && !regex.test(props.bid.contact_email)) {
              setFormValid(false);
              return
            }
            props.applyEdit(editBody);
          }}
        >
          {t("save", { ns: 'common' })}
        </Button>
      </DialogActions>
    </Box>
  )
}

export function BidCreate(props: NewBidProps) {
  let today = new Date();
  
  const { t } = useTranslation(['bids', 'common']);
  const [formValid, setFormValid] = React.useState<boolean>(false);
  const regex = /^[A-Za-z0-9\-\.]+@[A-Za-z0-9\-\.]+\.[a-z]{2,3}$/;
  const phoneRegex = /^\+{0,1}[0-9\-\.]{8,}$/;
  const [body, setBody] = React.useState<BodyObj>(
    {
      child: false,
      bid_date: builtISODate(today),
    }
  );
  const [phone, setPhone] = React.useState<string>('');
  const [phoneValid, setPhoneValid]= React.useState<boolean>(true);
  const [email, setEmail] = React.useState<string>('');
  const [emailValid, setEmailValid]= React.useState<boolean>(true);

  React.useEffect(() => {
    checkFormValid()
    if (phone !== '' && !phoneRegex.test(phone)) {
      setPhoneValid(false);
    } else {
      setPhoneValid(true);
      setBody({...body, contact_phone: phone});
    }
  }, [phone]);

  React.useEffect(() => {
    checkFormValid()
    if (email !== '' && !regex.test(email)) {
      setEmailValid(false);
    } else {
      setEmailValid(true);
      setBody({...body, contact_email: email});
    }
  }, [email]);

  const checkFormValid = () => {
    if (phone === '' && email === '') {
      setFormValid(false);
    } else if (!phoneValid || !emailValid) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }

  React.useEffect(() => {
    checkFormValid()
  }, [phoneValid]);
  React.useEffect(() => {
    checkFormValid()
  }, [emailValid]);
  
  return (
    <Box>
      <Autocomplete
        id="bid-create-club-unit-id"
        options={
          props.clubUnits.map(
             (unit: ClubUnit) => {return {id: unit.id, label: unit.name}}
          )
        }
        sx={{ width: "100%", marginBottom: "15px" }}
        renderInput={(params) => <TextField {...params} label={t("club_unit")} />}
        onChange={(
          e: React.SyntheticEvent,
          value: AutocompleteProps | null,
          reason: string,
        ) => {
          if (value && value.id) {
            setBody({...body, club_unit: value.id});
          }
        }}
      />
      <Divider />
      <Autocomplete
        id="bid-create-source-id"
        options={
          props.sources.map(
            (bid: BidSource) => {return {id: bid.id, label: bid.source}}
          )
        }
        sx={{ width: "100%", marginBottom: "15px" }}
        renderInput={(params) => <TextField {...params} label={t("bid_source")} />}
        onChange={(
          e: React.SyntheticEvent,
          value: AutocompleteProps | null,
          reason: string,
        ) => {
          if (value && value.id) {
            setBody({...body, source: {id: Number(value.id), source: value.label}});
          }
        }}
      />
      <Divider />
      <TextField
        id="bid-create-date-from-id"
        label={t('bid_date')}
        variant="standard"
        type="date"
        onChange={(e) => {
          setBody({...body, bid_date: e.target.value});
        }}
        defaultValue={builtISODate(today)}
      />
      <Divider />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "15px",
        }}
      >
        <Typography sx={{ marginRight: "10px" }}>{t("contact_name")}:</Typography>
        <Input
          sx={{ width: "100%" }}
          onChange={(e) => {
            setBody({...body, contact_name: e.target.value});
          }}
        />
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "15px",
        }}
      >
        <Typography sx={{ marginRight: "10px" }}>{t("phone")}:</Typography>
        <Input
          sx={{ width: "100%" }}
          onChange={
            (e) => {
              setPhoneValid(false);
              setPhone(e.target.value)
            }
          }
          error={!phoneValid}
        />
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "15px",
        }}
      >
        <Typography sx={{ marginRight: "10px" }}>{t("email")}:</Typography>
        <Input
          sx={{ width: "100%" }}
          onChange={
            (e: any) => {
              setEmailValid(false);
              setEmail(e.target.value)
            }
          }
          error={!emailValid}
        />
      </Box>
      <Divider />
      <FormControlLabel
        control={
          <Switch
            onChange={
              (e: any) => {
                setBody(
                  {
                    ...body,
                    child: e.target.checked,
                    child_name: (e.target.checked) ? '' : null,
                  }
                );
              }
            }
          />
        }
        label={t('child')}
      />
      {body.child
        ? <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "15px",
            }}
          >
            <Typography sx={{ marginRight: "10px" }}>{t("child_name")}:</Typography>
            <Input
              sx={{ width: "100%" }}
              onChange={(e: any) => {
                setBody({...body, child_name: e.target.value});
              }}
            />
          </Box>
        : ''
      }
      <Divider />
      <DialogActions
        sx={{ marginBottom: "15px" }}
      >
        <Button
          variant="outlined"
          color="error"
          onClick={(e) => {props.closeEdit()}}
        >
          {t("cancel", { ns: 'common' })}
        </Button>
        <Button
          variant="contained"
          color="info"
          disabled={!formValid}
          onClick={(e: any) => {
            if (body.contact_email && !regex.test(String(body.contact_email))) {
              setFormValid(false);
              return
            }
            props.applyEdit(body);
          }}
        >
          {t("save", { ns: 'common' })}
        </Button>
      </DialogActions>
    </Box>
  )
}
