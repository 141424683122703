import { atom, selector } from 'recoil'

import { BaseApi } from '../api/base';

import { ClubUnit } from '../interfaces/Club';

export interface IClubUnitsState {
  items: ClubUnit[]
}

export const clubUnitsSelector = selector({
    key: 'clubUnitsSelector',
    get: async (): Promise<IClubUnitsState> => {
      const api = new BaseApi(1, 'management/club_units/?short=1');
      let res = await api.get(
        {},
        () => {},
        {},
      );
      if (res.status === 200) {
        return { items: res.body };
      };
      return { items: [] };
    },
    set: ({ set, get }, newData) => {
      const currentState = get(clubUnitsAtom);
      let newState = [ ...currentState.items ];
      if (newData.items) {
        newState = [...currentState.items, ...newData.items as ClubUnit[]];
      }
      set(clubUnitsAtom, {items: newState});
    },
});

export const clubUnitsAtom = atom<IClubUnitsState>({
  key: 'clubUnits',
  default: clubUnitsSelector,
})
