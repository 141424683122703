import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { useTranslation } from 'react-i18next';

import { BaseApi } from "../../api/base";

import { ChatMessage } from './Comments';
import { TargetObjComments } from './TargetChat';

import {
  IComment,
  ICommentsProps,
  IGroupedComments,
  IObjGroupedCommentsProps,
} from '../../interfaces/Comments';
import { CommentTargetType, PKStatusObj } from '../../interfaces/Types';

import { builtISODate } from '../../helpers';

export function UnreadComments(props: ICommentsProps) {
  const { t } = useTranslation('chat');
  
  const [unreadComments, setUnreadComments] = useState<IGroupedComments[]>([]);

  const [choosenChat, setChoosenChat] = useState<IObjGroupedCommentsProps | null>(null);
  const [spinner, setSpinner] = React.useState<boolean>(false);

  const getClientInfo = async (messagesBranch: IGroupedComments) => {
    const api = new BaseApi(2, 'management/get_clients/');
    setSpinner(true);
    let res = await api.get(
      {
        limit: 1,
        offset: 0,
        pks: encodeURIComponent(String(messagesBranch.messages[0].target_id)),
      },
      props.appSetState,
      props.appState,
    );
    setChoosenChat(
      {
        messages: messagesBranch,
        targetObject: res.body.results[0],
      } as IObjGroupedCommentsProps
    );
    setSpinner(false);
  };

  const getBidInfo = async (messagesBranch: IGroupedComments) => {
    const api = new BaseApi(1, 'management/bids/');
    setSpinner(true);
    let res = await api.get(
      {
        limit: 1,
        offset: 0,
        pks: encodeURIComponent(String(messagesBranch.messages[0].target_id)),
      },
      props.appSetState,
      props.appState,
    );
    setChoosenChat(
      {
        messages: messagesBranch,
        targetObject: res.body.results[0],
      } as IObjGroupedCommentsProps
    );
    setSpinner(false);
  };

  const getObjInfo = async (messagesBranch: IGroupedComments) => {
    switch (messagesBranch.messages[0]?.target_type) {
      case CommentTargetType.UserTarget:
        return await getClientInfo(messagesBranch)
      case CommentTargetType.BidTarget:
        return await getBidInfo(messagesBranch)
    }
  }

  useEffect(() => {
    setSpinner(true);
    let commentsTargetMap: PKStatusObj = {};
    let usersTargetMap: PKStatusObj = {};
    let groupedComments: IGroupedComments[] = [];
    for (let mes of props.messages) {
      if (!commentsTargetMap[mes.target_id]) {
        commentsTargetMap[mes.target_id] = [];
      }
      commentsTargetMap[mes.target_id].push(mes);
    }
    for (let mes of Object.values(commentsTargetMap)) {
      mes.sort(
        (a: IComment, b: IComment) => {
          return new Date(b.created_at) - new Date(a.created_at)
        }
      )
      usersTargetMap[mes[0].target_id] = [];
      for (let com of mes) {
        usersTargetMap[mes[0].target_id].push(com.author_id);
        usersTargetMap[mes[0].target_id].push(com.dm_user_id);
        usersTargetMap[mes[0].target_id].push(...com.mentioned_users);
      }
      usersTargetMap[mes[0].target_id] = props.users.filter(u => usersTargetMap[mes[0].target_id].includes(u.pk))
    }
    for (let k in commentsTargetMap) {
      groupedComments.push(
        {
          messages: commentsTargetMap[k],
          users: usersTargetMap[k],
          target_id: Number(k),
        } as IGroupedComments
      )
    }
    setUnreadComments(groupedComments);
    if (groupedComments.length) {
      getObjInfo(groupedComments[0]);
    }
    setSpinner(false);
  }, []);

  useEffect(() => {
    if (!choosenChat) { return }
    if (choosenChat.targetObject) {
      for (let mes of choosenChat.messages.messages) {
        let data = {
          ...mes,
          read: true,
          author_id: props.appState.account_info.id,
          message_type: "mark_comment_read",
        };
        props.sendSocketMessage(JSON.stringify(data));
      }
      props.substractUnreadChats();
      return
    }
    getObjInfo(choosenChat.messages);
  }, [choosenChat]);

  return (
    <Box>
      <Box
        sx={{ display: 'flex' }}
      >
        <Box
          sx={{
            borderRight: '1px solid var(--orange)',
            height: '90vh',
            width: (props.device === 'desktop') ? '40%' : '25%',
          }}
          className='scrollable'
        >
          {unreadComments.map(
            (mes: IGroupedComments) => {
              return (
                <Box
                  key={`comments-group-${mes.target_id}-key`}
                  onClick={
                    () => {
                      getObjInfo(mes);
                    }
                  }
                >
                  {mes.messages.length
                    ? <>
                      {props.device === 'desktop'
                        ? <ChatMessage
                            message={mes.messages[0]}
                            users={mes.users}
                            appState={props.appState}
                            appSetState={props.appSetState}
                            device={props.device}
                          />
                        : <Box
                            sx={{
                              fontSize: '12px',
                              border: '1px solid var(--orange)',
                              padding: '2px',
                              margin: '2px',
                            }}
                          >
                            {builtISODate(new Date(mes.messages[0].created_at))}
                          </Box>
                      }
                      </>
                    : ''
                  }
                </Box>
              )
            }
          )}
        </Box>
        <Box
          sx={{
            height: '90vh',
            width: (props.device === 'desktop') ? '60%' : '75%',
          }}
        >
          {spinner
            ? <Box>
                <CircularProgress color="inherit" />
              </Box>
            : <Box>
                {choosenChat
                  ? <TargetObjComments
                      messages={choosenChat.messages}
                      targetObject={choosenChat?.targetObject}
                      device={props.device}
                      appState={props.appState}
                      appSetState={props.appSetState}
                      sendSocketMessage={props.sendSocketMessage}
                      departments={props.departments}
                    />
                  : ''
                }
              </Box>
          }
        </Box>
      </Box>
    </Box>
  )
}
