import React from "react";
import { useEffect } from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import allLocales from '@fullcalendar/core/locales-all'

import { useTranslation } from 'react-i18next';

import { builtISODate } from "../../helpers";

import { BaseApi } from "../../api/base";

import { StatisticTabProps } from "../../interfaces/Props";
import { QueryObj } from "../../interfaces/Types";
import { UserInfoCommon } from "../../interfaces/AccountInfo";
import { ClubUnit } from "../../interfaces/Club";
import { PaymentType } from "../../interfaces/Common";

import { BootstrapDialog } from "../common/Dialogs";

interface ICallendarEvent {
  id: string;
  title: string;
  start: string;
  end: string;
  textColor: string;
  manager: string;
  amount: string;
}

interface IDaySailsStatisticProps extends StatisticTabProps {
  dayStatQuery: QueryObj;
}

interface IDayDataReportBuying {
  coaches: UserInfoCommon[];
  item: string;
  pk: number;
}

interface IDayDataReportItem {
  categories: string[];
  client: UserInfoCommon;
  payed_amount: string;
  payment_type: number;
  price: string;
  ticket_price: string;
  discount: number;
  items: IDayDataReportBuying[];
}

interface IDayUnitPaymentTypeData {
  payment_type: number;
  income: string;
}

interface IAvgBillData {
  payed_amount: number;
  count: number;
}

type TAvgBillData = { [key: string]: IAvgBillData }

interface IAvgBillCat {
  category: string;
  profit: number;
  amount: number;
}

interface IDayDataUnitStatArr {
  data: IDayDataReportItem[];
  club_unit: ClubUnit;
  payment_types_income: IDayUnitPaymentTypeData[];
  attendance: number | null;
  new_clients: number | null;
  predicted_profit: number | string;
  real_income: number | string;
  average_bill: IAvgBillCat[];
}

export function BoxOfficeReport(props: StatisticTabProps) {
  let today = new Date();
  let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  const boxOfficeStatRef = React.useRef<any>(null);
  
  const { t, i18n } = useTranslation(['statistic', 'filters']);

  const [statDates, setStatDates] = React.useState<QueryObj>(
    { date_start: builtISODate(firstDayOfMonth), date_stop: builtISODate(lastDayOfMonth) }
  );
  
  const [boxOfficeStat, setBoxOfficeStat] = React.useState<ICallendarEvent[]>([]);

  const [firstRender, setFirstRender] = React.useState<boolean>(true);

  const [showDaySailsStat, setShowDaySailsStat] = React.useState<boolean>(false);
  const [daySailsStatDates, setDaySailsStatDates] = React.useState<QueryObj>({});

  const getBoxOfficeStat = async () => {
    props.handleOpenSpinner(true);
    const api = new BaseApi(1, 'management/box_office/');
    let res = await api.get(
      statDates,
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      let _statItems: ICallendarEvent[] = [];
      let _statItem: ICallendarEvent;
      let _startDate;
      let _stopDate;
      for (let item of res.body) {
        _stopDate = new Date(item.created_at);
        _startDate = new Date(item.created_at);
        _startDate.setHours(_startDate.getHours() - 1);
        _statItem = {
          id: item.id.toString(),
          title: `${item.manager.first_name} ${item.manager.last_name}: ${item.amount}`,
          start: builtISODate(_startDate),
          end: builtISODate(_stopDate),
          textColor: 'black',
          manager: `${item.manager.first_name} ${item.manager.last_name}`,
          amount: `${item.amount}`,
        };
        _statItems.push(_statItem);
      }
      setBoxOfficeStat(_statItems);
    }
    setFirstRender(false);
    props.handleCloseSpinner(false);
  }

  useEffect(() => {
    if (props.statType !== 'box_office_stat') { return }
    getBoxOfficeStat();
  }, [props.statType]);

  useEffect(() => {
    if (firstRender) { return }
    getBoxOfficeStat();
  }, [statDates]);

  const CalendarBoxOfficeItem = (event: any) => {
    return (
        <CardContent
          sx={{
            overflow: 'hidden',
          }}
        >
          <Typography
            component="div"
          >
            {event.event.extendedProps.manager}
          </Typography>
          <Typography
            variant="h6"
            component="div"
            sx={{ whiteSpace: 'pre-wrap' }}
          >
            {event.event.extendedProps.amount}
          </Typography>
        </CardContent>
    )
  };

  return (
    <Box>
      <FullCalendar
        ref={boxOfficeStatRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          start: 'prev',
          center: 'title',
          end: 'next',
        }}
        buttonText={{
          today: "current",
          month: "month",
          week: "week",
          day: "day",
          list: "list"
        }}
        locales={allLocales}
        locale={props.appState.account_info.language || 'en'}
        initialView={(props.device === 'desktop') ? 'dayGridMonth' : 'dayGridDay'}
        dayHeaderFormat={
          (props.device === 'desktop')
            ? {weekday: 'short'}
            : { weekday: 'short', month: 'short', day: 'numeric' }
        }
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={true}
        events={boxOfficeStat}
        select={(e) => {console.log("event")}}
        eventContent={(e) => CalendarBoxOfficeItem(e)}
        eventDisplay="block"
        eventClick={(e) => {
          setDaySailsStatDates(
            {
              date_start: builtISODate(e.event._instance.range.start),
              date_stop: builtISODate(e.event._instance.range.start),
            }
          );
          setShowDaySailsStat(true);
        }}
        eventsSet={() => console.log("eventsSet")}
        firstDay={0}
        datesSet={
          (e) => {
            let _date_start = e.start;
            let _date_stop = e.end;
            _date_start.setDate(_date_start.getDate() + 1);
            if (
              statDates.date_start !== _date_start.toISOString().split('T')[0] ||
              statDates.date_stop !== _date_stop.toISOString().split('T')[0]
            ) {
              setStatDates({
                date_start: _date_start.toISOString().split('T')[0],
                date_stop: _date_stop.toISOString().split('T')[0],
              });
            }
          }
        }
        eventDrop={(e) => {console.log("eventDrop")}}
        eventResize={(e) => {console.log("eventResize")}}
        dateClick={(e) => {
          setDaySailsStatDates(
            {
              date_start: builtISODate(e.date),
              date_stop: builtISODate(e.date),
            }
          );
          setShowDaySailsStat(true);
        }}
        eventAdd={(e) => {
          console.log("eventAdd", e);
        }}
        eventChange={(e) => {
          console.log("eventChange", e);
        }}
        eventRemove={(e) => {
          console.log("eventRemove", e);
        }}
        eventMinHeight={ 200 }
      />
      <BootstrapDialog
        onClose={() => setShowDaySailsStat(false)}
        open={showDaySailsStat}
        fullScreen
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar sx={{ backgroundColor: 'var(--black)' }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => {setShowDaySailsStat(false)}}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {daySailsStatDates.date_start}
            </Typography>
          </Toolbar>
        </AppBar>
        <DaySailsStat
          handleCloseSpinner={props.handleCloseSpinner}
          handleOpenSpinner={props.handleOpenSpinner}
          openPopUp={props.openPopUp}
          departments={props.departments}
          device={props.device}
          appState={props.appState}
          appSetState={props.appSetState}
          statType={props.statType}
          dayStatQuery={daySailsStatDates}
          sendSocketMessage={props.sendSocketMessage}
        />
      </BootstrapDialog>
    </Box>
  )
}

function DaySailsStat(props: IDaySailsStatisticProps) {
  const { t, i18n } = useTranslation(['statistic', 'tables']);
  const [dayReport, setDayReport] = React.useState<IDayDataUnitStatArr[]>([]);
  const [paymentTypes, setPaymentTypes] = React.useState<PaymentType[]>([]);
  const [spinner, setSpinner] = React.useState<boolean>(false);

  const countCategoryAvgBill = (data: IDayDataReportItem[]) => {
    let avgBill: TAvgBillData = {};
    for (let item of data) {
      for (let cat of item.categories) {
        if (!avgBill[cat]) {
          avgBill[cat] = { payed_amount: 0, count: 0 };
        }
        avgBill[cat].payed_amount += Number(item.payed_amount) / item.categories.length
        avgBill[cat].count += 1
      }
    }
    return avgBill
  };

  const getBoxOfficeStat = async () => {
    const api = new BaseApi(1, 'management/daily/sails/statistic/');
    let res = await api.get(
      props.dayStatQuery,
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      let _day_units_arr: IDayDataUnitStatArr[] = [];
      let _predicted_profit: number = 0;
      let _real_income: number = 0;
      let _profit: number = 0;
      let _average_bill: number = 0;
      // let _avg_bill: TAvgBillData = countCategoryAvgBill(res.body.market.data);
      if (process.env.REACT_APP_ENABLED_MODULES && process.env.REACT_APP_ENABLED_MODULES.includes('market')) {
        res.body.market.data.forEach(it => { _predicted_profit += Number(it.price) });
        res.body.market.data.forEach(it => { _real_income += Number(it.payed_amount) });
        _day_units_arr.push(
          {
            data: res.body.market.data,
            payment_types_income: res.body.market.payment_types_income,
            club_unit: { id: 0, name: (props.appState.account_info.language === 'ru') ? 'Магазин' : 'Market' },
            attendance: null,
            new_clients: null,
            predicted_profit: _predicted_profit.toFixed(2).toString(),
            real_income: _real_income.toFixed(2).toString(),
            average_bill: res.body.market.avg_bill,
          }
        );
      }
      for (let t of Object.values(res.body.tickets)) {
        _predicted_profit = 0;
        _real_income = 0;
        // _avg_bill = countCategoryAvgBill(t.data);
        t.data.forEach((it: any) => { _predicted_profit += Number(it.price) });
        t.data.forEach((it: any) => { _real_income += Number(it.payed_amount) });
        _day_units_arr.push(
          {
            data: t.data,
            payment_types_income: t.payment_types_income,
            club_unit: t.club_unit,
            attendance: t.attendance,
            new_clients: t.new_clients,
            predicted_profit: _predicted_profit.toFixed(2).toString(),
            real_income: _real_income.toFixed(2).toString(),
            average_bill: t.avg_bill,
          }
        );
      }
      setDayReport(_day_units_arr);
    }
  };

  const getPaymentTypes = async () => {
    const api = new BaseApi(1, 'management/payment_type/');
    let res = await api.get(
      props.dayStatQuery,
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      setPaymentTypes(res.body.results);
    }
  };

  const getData = async () => {
    setSpinner(true);
    getPaymentTypes();
    getBoxOfficeStat();
    setSpinner(false);
  }

  useEffect(() => {
    if (!Object.keys(props.dayStatQuery).length) { return }
    getData();
  }, []);

  return (
    <Box sx={{ overflowY: 'auto' }}>
      {spinner
        ? <Box
            sx={{
              width: 'fit-content',
              marginLeft: 'auto',
              marginRight: 'auto',
              paddingTop: '5px',
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        : <Box>
            <Typography
              variant="h4"
              sx={{ width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', marginBottom: '10px' }}
            >
              {t('report')}
            </Typography>
          {dayReport.filter(item => item.data.length).map((item, index) => {
            return (
              <Box key={`club-unit-key-${index}`}>
                <Typography
                  variant="h5"
                  sx={{ width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', marginBottom: '10px' }}
                >
                  {item.club_unit.name}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', marginTop: '10px' }}
                >
                  {t('day_report.attendance')}: {item.attendance}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', marginTop: '10px' }}
                >
                  {t('day_report.new_clients')}: {item.new_clients}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ width: 'fit-content', marginLeft: 'auto', marginRight: 'auto' }}
                >
                  {t('day_report.payment_type_income')}:
                </Typography>
                {item.payment_types_income.map((pti, ind) => {
                  return (
                    <Box
                      key={`payment-types-arr-key-${index}-${ind}-${pti.payment_type}`}
                      sx={{ width: 'fit-content', marginLeft: 'auto', marginRight: 'auto' }}
                    >
                      <Typography>
                        {paymentTypes.filter(pt => pt.id === pti.payment_type)[0]?.shortcut}: {pti.income}
                      </Typography>
                    </Box>
                  )
                })}
                {item.attendance !== null
                  ? <Typography
                      variant="h6"
                      sx={{ width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', marginTop: '10px' }}
                    >
                      {t('day_report.attendance')}: {item.attendance}
                    </Typography>
                  : ''
                }
                {item.new_clients !== null
                  ? <Typography
                      variant="h6"
                      sx={{ width: 'fit-content', marginLeft: 'auto', marginRight: 'auto' }}
                    >
                      {t('day_report.new_clients')}: {item.new_clients}
                    </Typography>
                  : ''
                }
                <Typography
                  variant="h6"
                  sx={{ width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', marginTop: '10px' }}
                >
                  {t('day_report.predicted_profit')}: {item.predicted_profit}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ width: 'fit-content', marginLeft: 'auto', marginRight: 'auto' }}
                >
                  {t('day_report.real_income')}: {item.real_income}
                </Typography>
                {item.average_bill.map((avg_bill, ind) => {
                  return (
                    <Box
                      key={`avg-amount-key-${index}-${ind}-${avg_bill.category}`}
                      sx={{ width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', marginTop: '10px' }}
                    >
                      <Typography variant="h6">
                        {t('day_report.income')} {avg_bill.category}: {avg_bill.profit}
                      </Typography>
                      <Typography variant="h6">
                        {t('day_report.average_bill')} {avg_bill.category}: {avg_bill.amount}
                      </Typography>
                    </Box>
                  )
                })}
                <TableContainer component={Paper} sx={{ width: '95%', marginLeft: 'auto', marginRight: 'auto', marginTop: '10px' }}>
                  <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('heads.full_name', { ns: 'tables' })}</TableCell>
                        <TableCell align="right">{t('heads.price', { ns: 'tables' })}</TableCell>
                        <TableCell align="right">{t('heads.discount', { ns: 'tables' })}</TableCell>
                        <TableCell align="right">{t('heads.client_price', { ns: 'tables' })}</TableCell>
                        <TableCell align="right">{t('heads.payment', { ns: 'tables' })}</TableCell>
                        <TableCell align="right">{t('heads.name', { ns: 'tables' })}</TableCell>
                        <TableCell align="right">{t('heads.category', { ns: 'tables' })}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {item.data.map((row, index) => (
                        <TableRow
                          key={`report-key-${item.club_unit.id}-${index}`}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            backgroundColor: (Number(row.payed_amount) < Number(row.price)) ? 'var(--red)' : 'transparent',
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {`${row.client?.first_name} ${row.client?.last_name}`}
                          </TableCell>
                          <TableCell align="right">
                            {`${row.ticket_price}`}
                          </TableCell>
                          <TableCell align="right">
                            {`${(row.discount * 100).toFixed(0)}`}
                          </TableCell>
                          <TableCell align="right">
                            {`${row.price}(${row.payed_amount})`}
                          </TableCell>
                          <TableCell align="right">
                            {paymentTypes.filter(pt => pt.id === row.payment_type)[0]?.shortcut}
                          </TableCell>
                          <TableCell align="right">
                            {row.items.map((it) => {
                              return (
                                <Box key={`bought-item-key-${it.pk}`}>
                                  <Typography>
                                    {it.item}
                                  </Typography>
                                  {it.coaches.length
                                    ? <>
                                      (
                                      {it.coaches.map((ch) => {
                                        return (`${ch.first_name} ${ch.last_name}`)
                                      }).join(',')}
                                      )
                                      </>
                                    : ''
                                  }
                                </Box>
                              )
                            })}
                          </TableCell>
                          <TableCell align="right">{row.categories.toString()}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )
          })}
          </Box>
      }
    </Box>
  )
}
