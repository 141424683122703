import React, { useEffect } from "react";

import { useRecoilState, useRecoilStateLoadable, useSetRecoilState } from "recoil";

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import { SxProps } from '@mui/system';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import ListItemIcon from '@mui/material/ListItemIcon';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SportsGymnasticsIcon from '@mui/icons-material/SportsGymnastics';
import SportsIcon from '@mui/icons-material/Sports';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import { theme as root_theme } from "../../consts";

import { useTranslation } from 'react-i18next';

import { BaseApi } from "../../api/base";

import { Coaches } from "../users/Coaches";
import { BootstrapDialog } from "../common/Dialogs";
import { ApproveAction } from "../common/ActionApprove";
import { ClubSalary, SalaryManage } from "./Salary";

import { ClubUnitCommon } from "../../interfaces/Club";
import {
  ClubPageProps,
  ClubUnitInfoProps,
} from "../../interfaces/Props";
import { AccountInfo } from '../../interfaces/AccountInfo';
import { BodyObj } from "../../interfaces/Types";
import {
  DrillType,
  Discipline,
  PaymentType,
} from "../../interfaces/Common";
import { DepartmentInfo } from "./Department";

import { departmentsAtom } from "../../atoms/Departments";
import { paymentTypesAtom } from "../../atoms/PaymentTypes";
import { spinnerAtom } from "../../atoms/Spinner";

export function ClubPage(props: ClubPageProps) {
  const { t } = useTranslation(['club', 'common']);

  const [clubUnits, setClubUnits] = React.useState<ClubUnitCommon[]>([]);
  const [value, setValue] = React.useState<string>("0");
  const [clubSettingValue, setClubSettingValue] = React.useState<string>("payment_type");
  const [newClubUnit, setNewClubUnit] = React.useState<boolean>(false);
  const [newClubUnitBody, setNewClubUnitBody] = React.useState<BodyObj>({club: props.appState.account_info.clubs[0]?.id});

  const getClubUnits = async () => {
    const api = new BaseApi(1, 'management/club_units/');
    props.handleOpenSpinner();
    let res = await api.get(
      {},
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      setClubUnits(res.body);
    };
    props.handleCloseSpinner();
  };

  const updateClubUnit = async (data: BodyObj) => {
    props.handleOpenSpinner();
    let res = { status: 500 };
    if (data.id) {
      const api = new BaseApi(1, `management/club_units/${value}/`);
      res = await api.post(
        data,
        "application/json",
        props.appSetState,
        props.appState,
      );
    } else {
      const api = new BaseApi(1, 'management/club_units/');
      res = await api.put(
        data,
        "application/json",
        props.appSetState,
        props.appState,
      );
    }
    if ([200, 201].includes(res.status)) {
      if (data.id) {
        setClubUnits(
          clubUnits.map(
            (cu) => {
              if (cu.id === res.body.id) {
                return res.body
              } else {
                return cu
              }
            }
          )
        );
      } else {
        clubUnits.push(res.body);
        setClubUnits(clubUnits);
      }
    };
    setNewClubUnitBody({club: props.appState.account_info.clubs[0]?.id});
    setNewClubUnit(false);
    props.handleCloseSpinner();
  };

  useEffect(() => {
    getClubUnits();
  }, []);

  useEffect(() => {
    if (!clubUnits.length) { return }
    setValue(String(clubUnits[0].id));
  }, [clubUnits]);
  
  return (
    <Box>
      <Box>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={(event: React.SyntheticEvent, value: string) => {
                setValue(value);
              }}
              aria-label="Club units"
            >
              {clubUnits.map((cu) => {
                return (
                  <Tab label={cu.name} value={String(cu.id)} key={`tab-key-${cu.id}`} />
                )
              })}
            </TabList>
          </Box>
          {clubUnits.map((cu) => {
            return (
              <TabPanel value={String(cu.id)} key={`tabpanel-key-${cu.id}`}>
                <ClubUnitInfo
                  appState={props.appState}
                  appSetState={props.appSetState}
                  device={props.device}
                  clubUnit={cu}
                  handleCloseSpinner={props.handleCloseSpinner}
                  handleOpenSpinner={props.handleOpenSpinner}
                  openPopUp={props.openPopUp}
                  updateClubUnit={updateClubUnit}
                  sendSocketMessage={props.sendSocketMessage}
                />
              </TabPanel>
            )
          })}
        </TabContext>
      </Box>
      <Zoom
        key="new-client-key"
        in={true}
      >
        <Fab sx={
            {
              position: 'absolute',
              bottom: 36,
              left: 36,
              borderColor: root_theme.palette.info.main,
              borderWidth: '2px',
              borderStyle: 'solid',
              '&:hover': {
                bgcolor: root_theme.palette.background.default,
                borderColor: root_theme.palette.info.main,
              },
            } as SxProps
          }
          aria-label="Add client"
          color='info'
          onClick={(e) => {setNewClubUnit(true)}}
        >
          <AddIcon />
        </Fab>
      </Zoom>
      <BootstrapDialog
        onClose={() => { setNewClubUnit(false) }}
        open={newClubUnit}
      >
        <DialogTitle sx={{ paddingLeft: '5px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontWeight: '600', fontSize: '1.25em' }}>
              {t('new_club_unit')}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent
          dividers
          className="box-content-center"
        >
          <Box sx={{ marginBottom: '10px' }}>
            <TextField
              id='new-club-unit-name-id'
              label={t('name')}
              variant="standard"
              onChange={(e: any) => {
                setNewClubUnitBody({ ...newClubUnitBody, name: e.target.value });
              }}
            />
          </Box>
          <Box sx={{ marginBottom: '10px' }}>
            <TextField
              id='new-club-unit-location-id'
              label={t('address')}
              variant="standard"
              onChange={(e: any) => {
                setNewClubUnitBody({ ...newClubUnitBody, location: e.target.value });
              }}
            />
          </Box>
          <DialogActions>
            <Button
              variant="contained"
              color="info"
              disabled={!Boolean(newClubUnitBody.name)}
              onClick={() => {updateClubUnit(newClubUnitBody)}}
            >
              <CheckIcon />
            </Button>
          </DialogActions>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  )
}

function ClubUnitInfo(props: ClubUnitInfoProps) {
  const { t } = useTranslation(['club', 'common', 'tables']);

  const setOpenSpinner = useSetRecoilState(spinnerAtom);
  const [value, setValue] = React.useState<string>("coaches");
  const [searchString, setSearchString] = React.useState<string>('');
  const [addManager, setAddManager] = React.useState<boolean>(false);
  const [users, setUsers] = React.useState<AccountInfo[]>([]);
  const [anchorUsersList, setAnchorUsersList] = React.useState<null | HTMLElement>(null);
  const showUsers: boolean = Boolean(anchorUsersList);
  const [selectedManagers, setSelectedManagers] = React.useState<AccountInfo[]>([]);
  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null);
  const [openMenu, setOpenMenu] = React.useState<number>();
  const [removeManager, setRemoveManager] = React.useState<number>(0);
  const [drillTypes, setDrillTypes] = React.useState<DrillType[]>([]);
  const [newDrillType, setNewDrillType] = React.useState<string>('');
  const [showNewDrillType, setShowNewDrillType] = React.useState<boolean>(false);
  const [disciplines, setDisciplines] = React.useState<Discipline[]>([]);
  const [newDiscipline, setNewDiscipline] = React.useState<string>('');
  const [showNewDiscipline, setShowNewDiscipline] = React.useState<boolean>(false);
  const [clubUnitSalaries, setClubUnitSalaries] = React.useState<any>([]);
  const [clubUnitSalariesSource, setClubUnitSalariesSource] = React.useState<any>([]);
  const [showSalaryManage, setShowSalaryManage] = React.useState<boolean>(false);
  const [editSalary, setEditSalary] = React.useState<any | null>(null);
  const [departments, setDepartments] = useRecoilState(departmentsAtom);
  const [choosenDepartment, setChoosenDepartment] = React.useState<string>('');
  const [showNewDepartment, setShowNewDepartment] = React.useState<boolean>(false);
  const [newDepartment, setNewDepartment] = React.useState<string>('');
  const [paymentTypes, setPaymentTypes] = useRecoilStateLoadable(paymentTypesAtom);
  const [showNewPaymentType, setShowNewPaymentType] = React.useState<boolean>(false);
  const [paymentTypeBody, setPaymentTypeBody] = React.useState<BodyObj>({});
  const [removePaymentType, setRemovePaymentType] = React.useState<number>(0);

  useEffect(() => {
    if (departments?.items?.length) {
      setChoosenDepartment(String(departments?.items[0]?.id))
    }
  }, [departments]);

  const searchUsers = async () => {
    props.handleOpenSpinner();
    const api = new BaseApi(2, 'management/get_clients/');
    let res = await api.get(
      {limit: 5, offset: 0, search: encodeURIComponent(searchString)},
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      setUsers(res.body.results);
    };
    props.handleCloseSpinner();
  };

  const getDrillTypes = async () => {
    props.handleOpenSpinner();
    const api = new BaseApi(1, 'management/drills_types/');
    let res = await api.get(
      {},
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      setDrillTypes(res.body.results);
    };
    props.handleCloseSpinner();
  };

  const createDrillType = async () => {
    if (!newDrillType) { return }
    props.handleOpenSpinner();
    const api = new BaseApi(1, 'management/drills_types/');
    let res = await api.post(
      { name: newDrillType },
      "application/json",
      props.appSetState,
      props.appState,
    );
    if ([200, 201].includes(res.status)) {
      drillTypes.push(res.body);
      setDrillTypes(drillTypes);
    };
    setShowNewDrillType(false);
    props.handleCloseSpinner();
  };

  const getDisciplines = async () => {
    props.handleOpenSpinner();
    const api = new BaseApi(1, 'management/disciplines/');
    let res = await api.get(
      {},
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      setDisciplines(res.body.results);
    };
    props.handleCloseSpinner();
  };

  const createDiscipline = async () => {
    if (!newDiscipline) { return }
    props.handleOpenSpinner();
    const api = new BaseApi(1, 'management/disciplines/');
    let res = await api.post(
      { name: newDiscipline },
      "application/json",
      props.appSetState,
      props.appState,
    );
    if ([200, 201].includes(res.status)) {
      disciplines.push(res.body);
      setDisciplines(disciplines);
    };
    setShowNewDiscipline(false);
    props.handleCloseSpinner();
  };

  const createPaymentType = async () => {
    if (paymentTypes.state !== "hasValue") { return }
    setOpenSpinner({open: true});
    const api = new BaseApi(1, 'management/payment_type/');
    let res = await api.post(
      paymentTypeBody,
      "application/json",
      props.appSetState,
      props.appState,
    );
    if ([200, 201].includes(res.status)) {
      setPaymentTypes({ items:  [ ...paymentTypes.contents.items, res.body ] });
    };
    setShowNewPaymentType(false);
    setOpenSpinner({open: false});
  };

  const handleRemovePaymentType = async () => {
    if (paymentTypes.state !== "hasValue") { return }
    setOpenSpinner({open: true});
    const api = new BaseApi(1, `management/payment_type/${removePaymentType}`);
    let res = await api.delete(
      {},
      props.appSetState,
      props.appState,
    );
    if ([200, 201].includes(res.status)) {
      setPaymentTypes(
        {
          items:  paymentTypes.contents.items.filter(
            pt => pt.id !== removePaymentType
          )
        }
      );
    };
    setRemovePaymentType(0);
    setOpenSpinner({open: false});
  };

  const createDepartment = async () => {
    if (!newDepartment) { return }
    props.handleOpenSpinner();
    const api = new BaseApi(1, 'management/club/department/');
    let res = await api.post(
      { club: props.appState.account_info.clubs[0].id, name: newDepartment },
      "application/json",
      props.appSetState,
      props.appState,
    );
    if ([200, 201].includes(res.status)) {
      setDepartments({ items: [ ...departments.items, res.body ] })
    };
    setShowNewDepartment(false);
    props.handleCloseSpinner();
  };

  const getSalaries = async () => {
    props.handleOpenSpinner();
    const api = new BaseApi(1, 'management/coach/skill/salary/');
    let res = await api.get(
      {club_unit: props.clubUnit.id},
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      setClubUnitSalariesSource(res.body);
    };
    props.handleCloseSpinner();
  };

  const createSalary = async (body: BodyObj) => {
    props.handleOpenSpinner();
    body.club_unit = props.clubUnit.id;
    let res = { status: 500, body: {} };
    if (body.id) {
      const api = new BaseApi(1, `management/coach/skill/salary/${body.id}/`);
      res = await api.post(
        body,
        "application/json",
        props.appSetState,
        props.appState,
      );
    } else {
      const api = new BaseApi(1, 'management/coach/skill/salary/');
      res = await api.put(
        body,
        "application/json",
        props.appSetState,
        props.appState,
      );
    }
    if (res.status === 400) {
      props.handleCloseSpinner();
      return
    }
    if ([200, 201].includes(res.status)) {
      if (body.id) {
        setClubUnitSalariesSource(
          clubUnitSalariesSource.map(
            (s) => {
              if (s.id === res.body.id) {
                return res.body
              } else {
                return s
              }
            }
          )
        )
      } else {
        setClubUnitSalariesSource([ ...clubUnitSalariesSource, res.body ]);
      }
    };
    setShowSalaryManage(false);
    props.handleCloseSpinner();
    await getSalaries();
  };

  const handleEditSalary = (pk: number) => {
    for (let sl of clubUnitSalariesSource) {
      if (sl.id == pk) {
        setEditSalary(sl);
        setShowSalaryManage(true);
        return
      }
    }
  };

  useEffect(() => {
    if (value === 'drill_types') {
      getDrillTypes();
    } else if (value === 'disciplines') {
      getDisciplines();
    } else if (value === 'salary') {
      getSalaries();
    }
  }, [value]);

  useEffect(() => {
    if (!searchString) { return }
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        searchUsers();
        let elem = document.getElementById('users-videoconf-search');
        setAnchorUsersList(anchorUsersList ? null : elem);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [searchString]);

  useEffect(() => {
    let salaries_maps_arr: any = []
    let salaries_data: any = {
      data: [],
      drill_type: {},
    }
    let salaries_drill_type_map: any = {}
    for (let salary of clubUnitSalariesSource) {
      if (!salaries_drill_type_map[salary.drill_type.id]) {
        salaries_drill_type_map[salary.drill_type.id] = {
          data: [],
          drill_type: { id: salary.drill_type.id, name: salary.drill_type.name }
        }
      }
      salaries_drill_type_map[salary.drill_type.id].data.push(salary)
    }

    let skill_salary_map: any = {}
    let _point: any = {}
    for (let salary_arr of Object.values(salaries_drill_type_map)) {
      skill_salary_map = {}
      for (let sl of salary_arr.data) {
        if (!skill_salary_map[sl.skill[0]]) {
          skill_salary_map[sl.skill[0]] = {
            id: sl.skill[1],
            data: []
          }
        }
        _point = { pk: sl.id }
        if (sl.fraction) {
          if (sl?.ticket?.ticket?.name) {
            _point.x = sl.ticket.ticket.name
          } else {
            continue
          }
          if (sl?.ticket?.ticket?.price) {
            _point.y = parseFloat(sl.ticket.ticket.price) * sl.fraction
          } else {
            continue
          }
        } else {
          _point = {
            pk: sl.id,
            x: `${sl.clients_from}-${sl.clients_to}`,
            y: (sl.salary) ? parseFloat(sl.salary) : 0.0,
          }
        }
        skill_salary_map[sl.skill[0]].data.push(_point)
      }
      salaries_maps_arr.push(
        {
          drill_type: { id: salary_arr.drill_type.id, name: salary_arr.drill_type.name },
          data: Object.values(skill_salary_map),
        }
      )
    }

    setClubUnitSalaries(Object.values(salaries_maps_arr))
  }, [clubUnitSalariesSource]);

  return (
    <Box>
      <Typography>
        {`${t('address')}: ${props.clubUnit.location}`}
      </Typography>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={(event: React.SyntheticEvent, value: string) => {
              setValue(value);
            }}
            aria-label="Club units info"
            scrollButtons={true}
            allowScrollButtonsMobile={true}
            visibleScrollbar={true}
            variant='scrollable'
          >
            <Tab label={t('coaches')} value='coaches' />
            <Tab label={t('managers')} value='managers' />
            <Tab label={t('salary')} value='salary' />
            <Tab label={t('drill_types')} value='drill_types' />
            <Tab label={t('disciplines')} value='disciplines' />
            <Tab label={t('departments')} value='departments' />
            <Tab label={t('payment_type')} value='payment_type' />
          </TabList>
        </Box>
        <TabPanel value='coaches' key='tabpanel-key-coaches'>
          <Coaches
            handleCloseSpinner={props.handleCloseSpinner}
            handleOpenSpinner={props.handleOpenSpinner}
            openPopUp={props.openPopUp}
            departments={departments.items}
            device={props.device}
            appState={props.appState}
            appSetState={props.appSetState}
            clubUnitId={props.clubUnit.id}
            sendSocketMessage={props.sendSocketMessage}
          />
        </TabPanel>
        <TabPanel value='managers' key='tabpanel-key-managers'>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: '74vh' }}>
              <Table stickyHeader>
                <TableBody>
                  {props.clubUnit.manager.map((manager, index) => {
                    return (
                      <TableRow
                        tabIndex={-1}
                        key={`row-key-${index}`}
                      >
                        <TableCell key={`key-name-${index}`}>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={openMenu === manager.id ? 'long-menu' : undefined}
                            aria-expanded={openMenu === manager.id ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={(e: React.MouseEvent<HTMLElement>) => {
                              setOpenMenu(manager.id);
                              setAnchorMenu(e.currentTarget);
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          {`${manager.first_name} ${manager.last_name}`}
                        </TableCell>
                        <TableCell key={`key-contacts-${index}`}>
                          <Typography>{manager.phone}</Typography>
                          <Typography>{manager.email}</Typography>
                        </TableCell>
                        <Menu
                          id={`promo-menu-id-${index}`}
                          MenuListProps={{
                            'aria-labelledby': 'long-button',
                          }}
                          anchorEl={anchorMenu}
                          open={openMenu === manager.id}
                          onClose={() => {
                            setOpenMenu(0);
                            setAnchorMenu(null);
                          }}
                        >
                          <MenuItem
                            key={`menu_item-remove-${index}`}
                            onClick={
                              (e) => {
                                setRemoveManager(manager.id);
                                setOpenMenu(0);
                                setAnchorMenu(null);
                              }
                            }
                            className="control-menu-item-wrapper"
                          >
                            {t("actions.remove", { ns: 'tables' })}
                          </MenuItem>
                        </Menu>
                        <ApproveAction
                          handleApprove={
                            () => {
                              props.updateClubUnit(
                                {
                                  manager: props.clubUnit.manager.filter(m => m.id !== manager.id).map((m) => {return m.id})
                                }
                              );
                              setRemoveManager(0);
                            }
                          }
                          handleDecline={() => {setRemoveManager(0)}}
                          action={'close_ticket'}
                          show={removeManager}
                          obj_pk={manager.id}
                        />
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Box
            sx={{
              width: 'fit-content',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '15px',
              marginBottom: '15px',
            }}
          >
            <Button
              variant="contained"
              onClick={() => {setAddManager(true)}}
            >
              {t('add', { ns: 'common' })}
            </Button>
          </Box>
          <BootstrapDialog
            onClose={(e) => {setAddManager(false)}}
            open={addManager}
          >
            <DialogTitle sx={{ paddingLeft: '5px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ fontWeight: '600', fontSize: '1.25em' }}>
                  {t('new_manager')}
                </Typography>
              </Box>
            </DialogTitle>
            <DialogContent dividers className="box-content-center">
              <Paper
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  width: '95%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginBottom: '5px',
                  marginTop: '5px',
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  id="users-videoconf-search"
                  placeholder={t('search_placeholder', { ns: 'common' })}
                  inputProps={{ 'aria-label': 'search google maps' }}
                  onChange={(e) => {setSearchString(e.target.value)}}
                  value={searchString}
                />
                <IconButton
                  type="button"
                  sx={{ p: '10px' }}
                  aria-label="search"
                  onClick={
                    (e) => {
                      searchUsers();
                      let elem = document.getElementById('users-videoconf-search');
                      setAnchorUsersList(anchorUsersList ? null : elem);
                    }
                  }
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
              <Popover
                open={showUsers}
                anchorEl={anchorUsersList}
                onClose={(e) => {setAnchorUsersList(null)}}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
                  <List dense={false}>
                    {users.map((user) => {
                      return (
                        <ListItem
                          onClick={(e) => {
                            selectedManagers.push(user);
                            setSelectedManagers(selectedManagers);
                            setUsers([]);
                            setAnchorUsersList(null);
                            setSearchString('');
                          }}
                          sx={{ cursor: 'pointer' }}
                          key={`conf-user-list-item-${user.id}`}
                        >
                          <ListItemIcon>
                            <ContactPhoneIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={`${user.first_name} ${user.last_name}`}
                          />
                        </ListItem>
                      )
                    })}
                  </List>
                </Box>
              </Popover>
              <List dense={false}>
                {selectedManagers.map((manager) => {
                  return (
                    <ListItem key={`club-unit-manager-list-item-${manager.id}`}>
                      <ListItemIcon
                        onClick={(e) => {
                          setSelectedManagers(selectedManagers.filter(m => m.id !== manager.id));
                          setUsers([]);
                          setAnchorUsersList(null);
                        }}
                        sx={{ cursor: 'pointer' }}
                      >
                        <ClearIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${manager.first_name} ${manager.last_name}`}
                      />
                    </ListItem>
                  )
                })}
              </List>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="info"
                onClick={
                  (e) => {
                    let _exist_managers = props.clubUnit.manager.map((m) => {return m.id});
                    _exist_managers = _exist_managers.concat(selectedManagers.map((m) => {return m.id}));
                    props.updateClubUnit(
                      {
                        location: props.clubUnit.location,
                        manager: _exist_managers,
                        id: props.clubUnit.id,
                        name: props.clubUnit.name,
                      }
                    );
                  }
                }
              >
                <CheckIcon />
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </TabPanel>
        <TabPanel value='salary' key='tabpanel-key-salary'>
          <ClubSalary
            handleCloseSpinner={props.handleCloseSpinner}
            handleOpenSpinner={props.handleOpenSpinner}
            openPopUp={props.openPopUp}
            device={props.device}
            appState={props.appState}
            appSetState={props.appSetState}
            clubUnit={props.clubUnit}
            salaries={clubUnitSalaries}
            manageSalary={handleEditSalary}
            sendSocketMessage={props.sendSocketMessage}
          />
          <Box
            sx={{
              width: 'fit-content',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '15px',
              marginBottom: '15px',
            }}
          >
            <Button
              variant="contained"
              onClick={() => {setShowSalaryManage(true)}}
            >
              {t('add', { ns: 'common' })}
            </Button>
          </Box>
          <BootstrapDialog
            onClose={(e) => {setShowSalaryManage(false)}}
            open={showSalaryManage}
          >
            <DialogTitle sx={{ paddingLeft: '5px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ fontWeight: '600', fontSize: '1.25em' }}>
                  {t('drill_salary.drill_salary')}
                </Typography>
              </Box>
            </DialogTitle>
            <DialogContent dividers className="box-content-center">
              <SalaryManage
                handleCloseSpinner={props.handleCloseSpinner}
                handleOpenSpinner={props.handleOpenSpinner}
                openPopUp={props.openPopUp}
                device={props.device}
                appState={props.appState}
                appSetState={props.appSetState}
                clubUnit={props.clubUnit}
                salaries={clubUnitSalariesSource}
                manageSalary={createSalary}
                managedSalary={editSalary}
                sendSocketMessage={props.sendSocketMessage}
              />
            </DialogContent>
          </BootstrapDialog>
        </TabPanel>
        <TabPanel value='drill_types' key='tabpanel-key-drill-types'>
          <List>
            {drillTypes.map((drill_type) => {
              return (
                <ListItem>
                  <ListItemIcon>
                    <SportsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={drill_type.name}
                    secondary={drill_type.name}
                  />
                </ListItem>
              )
            })}
          </List>
          <Box
            sx={{
              width: 'fit-content',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '15px',
              marginBottom: '15px',
            }}
          >
            <Button
              variant="contained"
              onClick={() => {setShowNewDrillType(true)}}
            >
              {t('add', { ns: 'common' })}
            </Button>
          </Box>
          <BootstrapDialog
            onClose={(e) => {setShowNewDrillType(false)}}
            open={showNewDrillType}
          >
            <DialogTitle sx={{ paddingLeft: '5px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ fontWeight: '600', fontSize: '1.25em' }}>
                  {t('new_drill_type')}
                </Typography>
              </Box>
            </DialogTitle>
            <DialogContent dividers className="box-content-center">
              <Box sx={{ marginBottom: '10px' }}>
                <TextField
                  id='newpdrill-type-id'
                  label={t('new_drill_type')}
                  variant="standard"
                  onChange={(e: any) => {
                    setNewDrillType(e.target.value);
                  }}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="info"
                onClick={createDrillType}
              >
                <CheckIcon />
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </TabPanel>
        <TabPanel value='disciplines' key='tabpanel-key-disciplines'>
          <List>
            {disciplines.map((discipline) => {
              return (
                <ListItem>
                  <ListItemIcon>
                    <SportsGymnasticsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={discipline.name}
                    secondary={discipline.name}
                  />
                </ListItem>
              )
            })}
          </List>
          <Box
            sx={{
              width: 'fit-content',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '15px',
              marginBottom: '15px',
            }}
          >
            <Button
              variant="contained"
              onClick={() => {setShowNewDiscipline(true)}}
            >
              {t('add', { ns: 'common' })}
            </Button>
          </Box>
          <BootstrapDialog
            onClose={(e) => {setShowNewDiscipline(false)}}
            open={showNewDiscipline}
          >
            <DialogTitle sx={{ paddingLeft: '5px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ fontWeight: '600', fontSize: '1.25em' }}>
                  {t('new_discipline')}
                </Typography>
              </Box>
            </DialogTitle>
            <DialogContent dividers className="box-content-center">
              <Box sx={{ marginBottom: '10px' }}>
                <TextField
                  id='newpdrill-type-id'
                  label={t('new_discipline')}
                  variant="standard"
                  onChange={(e) => {
                    setNewDiscipline(e.target.value);
                  }}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="info"
                onClick={createDiscipline}
              >
                <CheckIcon />
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </TabPanel>
        <TabPanel value='departments' key='tabpanel-key-departments'>
          <Box
            sx={{
              width: 'fit-content',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '15px',
              marginBottom: '15px',
            }}
          >
            <Button
              variant="contained"
              onClick={() => {setShowNewDepartment(true)}}
            >
              {t('add', { ns: 'common' })}
            </Button>
          </Box>
          <TabContext value={choosenDepartment}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={(event: React.SyntheticEvent, value: string) => {
                  setChoosenDepartment(value);
                }}
                aria-label="Club units info"
              >
                {departments?.items?.map((department) => {
                  return (
                    <Tab label={department.name} value={String(department.id)} key={`unit-department-tab-${department.id}`} />
                  )
                })}
              </TabList>
            </Box>
            {departments?.items?.map((department) => {
              return (
                <TabPanel value={String(department.id)} key={`tabpanel-key-department-${department.id}`}>
                  <DepartmentInfo
                    department={department}
                    clubUnit={props.clubUnit}
                  />
                </TabPanel>
              )
            })}
          </TabContext>
          <BootstrapDialog
            onClose={(e) => {setShowNewDepartment(false)}}
            open={showNewDepartment}
          >
            <DialogTitle sx={{ paddingLeft: '5px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ fontWeight: '600', fontSize: '1.25em' }}>
                  {t('new_department')}
                </Typography>
              </Box>
            </DialogTitle>
            <DialogContent dividers className="box-content-center">
              <Box sx={{ marginBottom: '10px' }}>
                <TextField
                  id='newpdrill-type-id'
                  label={t('new_department')}
                  variant="standard"
                  onChange={(e) => {
                    setNewDepartment(e.target.value);
                  }}
                  value={newDepartment}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="info"
                onClick={createDepartment}
              >
                <CheckIcon />
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </TabPanel>
        <TabPanel value='payment_type' key='tabpanel-key-payment_type'>
          {paymentTypes.state === "hasValue"
            ? <List>
                {paymentTypes.contents.items.map((paymentType: PaymentType) => {
                  return (
                    <ListItem key={`payment-type-key-${paymentType.id}`}>
                      <ListItemIcon>
                        <AttachMoneyIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={paymentType.shortcut}
                        secondary={paymentType.description}
                      />
                      <Divider orientation="vertical" variant="middle" flexItem />
                      <Typography
                        sx={{ marginLeft: '1rem', cursor: 'pointer' }}
                        onClick={() => {setRemovePaymentType(paymentType.id)}}
                      >
                        {t('remove', { ns: 'common' })}
                      </Typography>
                      <ApproveAction
                        handleApprove={handleRemovePaymentType}
                        handleDecline={() => {setRemovePaymentType(0)}}
                        action={'remove_payment_type'}
                        show={removePaymentType}
                        obj_pk={paymentType.id}
                      />
                    </ListItem>
                  )
                })}
              </List>
            : ''
          }
          <Box
            sx={{
              width: 'fit-content',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '15px',
              marginBottom: '15px',
            }}
          >
            <Button
              variant="contained"
              onClick={() => {setShowNewPaymentType(true)}}
            >
              {t('add', { ns: 'common' })}
            </Button>
          </Box>
          <BootstrapDialog
            onClose={(e) => {setShowNewPaymentType(false)}}
            open={showNewPaymentType}
          >
            <DialogTitle sx={{ paddingLeft: '5px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ fontWeight: '600', fontSize: '1.25em' }}>
                  {t('new_payment_type')}
                </Typography>
              </Box>
            </DialogTitle>
            <DialogContent dividers className="box-content-center">
              <Box sx={{ marginBottom: '10px' }}>
                <TextField
                  id='new-payment-type-id'
                  label={t('shortcut')}
                  variant="standard"
                  onChange={(e) => {
                    setPaymentTypeBody({ ...paymentTypeBody, shortcut: e.target.value });
                  }}
                />
              </Box>
              <Box sx={{ marginBottom: '10px' }}>
                <TextField
                  id='new-payment-type-id'
                  label={t('description')}
                  variant="standard"
                  onChange={(e) => {
                    setPaymentTypeBody({ ...paymentTypeBody, description: e.target.value });
                  }}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="info"
                onClick={createPaymentType}
              >
                <CheckIcon />
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </TabPanel>
      </TabContext>
    </Box>
  )
}
