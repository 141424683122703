import React, { useEffect, FormEvent } from "react";

import { useRecoilValue } from 'recoil';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import { SxProps } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import InputBase from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useTranslation } from 'react-i18next';

import { ClientInfo } from "../../interfaces/User";
import {
  UserTicketCommonInfo,
  NonActiveUserTicketsInfo,
} from "./ClientsTickets";
import { UserManage } from "./UserManage";
import { ClientDebtsRender } from "./ClientDebts";
import { ClientAttendance } from "./Attendance";
import { Register } from "../auth/Registration";
import { TargetObjCommentsDialog } from "../chat/TargetChat";
import { ClientsArrProps, AutocompleteProps } from "../../interfaces/Props";
import { UserTypeInterface } from "../../interfaces/Common";
import { ApproveAction } from "../common/ActionApprove";
import { NotificationCreate } from "../notifications/Notification";
import { QueryObj, BodyObj } from '../../interfaces/Types';
import { ClientFilters } from "../../interfaces/Filters";
import { UserInfoCommon } from "../../interfaces/AccountInfo";
import { ClubUnit } from "../../interfaces/Club";
import { AddClientTicket } from '../common/ClientTicketManage';
import { UserTicketManageRequestBody } from "../../interfaces/ApiBody";
import { BootstrapDialog, BootstrapDialogWidth } from "../common/Dialogs";
import { UserPermissionsManage } from "./UserPermissions";

import { theme as root_theme } from "../../consts";

import { BaseApi } from "../../api/base";

import { accountInfoAtom } from '../../atoms/AccountInfo';

interface Column {
  id: 'full_name' | 'ticket' | 'contacts' | 'representative' | 'birthday' | 'employees';
  minWidth?: number;
  align?: 'right';
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const columns: readonly Column[] = [
  { id: 'full_name', minWidth: 250 },
  { id: 'ticket', minWidth: 265 },
  { id: 'contacts', minWidth: 150 },
  { id: 'representative', minWidth: 150 },
  { id: 'birthday', minWidth: 150 },
  { id: 'employees', minWidth: 220 },
];

export function Clients(props: ClientsArrProps) {
  const { t } = useTranslation(['tables', 'forms', 'common', 'filters']);
  const accountInfo = useRecoilValue(accountInfoAtom);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [clientsCount, setClientsCount] = React.useState<number>(0);
  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null);
  const [openMenu, setOpenMenu] = React.useState<number>();
  const [clients, setClients] = React.useState<ClientInfo[]>([]);
  const [showTicketInfo, setShowTicketInfo] = React.useState<number>(0);
  const [anchorPopover, setAnchorPopover] = React.useState<HTMLElement | null>(null);
  const [newClient, setNewClient] = React.useState<boolean>(false);
  const [editClient, setEditClient] = React.useState<number>(0);
  const [showNonActive, setShowNonActive] = React.useState<number>(0);
  const [showDebts, setShowDebts] = React.useState<number>(0);
  const [removeUser, setRemoveUser] = React.useState<number>(0);
  const [showAttendance, setShowAttendance] = React.useState<number>(0);
  const [showNotifyCreate, setShowNotifyCreate] = React.useState<number>(0);
  const [showNewTicket, setShowNewTicket] = React.useState<number>(0);
  const [clientsQuery, setClientsQuery] = React.useState<QueryObj>(
    {
      sort: 'user_tickets__expired-asc',
      limit: rowsPerPage,
      offset: rowsPerPage * page,
      pks: (props.clientsIds) ? encodeURIComponent(props.clientsIds) : '',
    }
  );
  const [filters, setFilters] = React.useState<ClientFilters>(
    {
      clubs: [],
      club_units: [],
      coaches: [],
      disciplines: [],
      drill_types: [],
      tickets: [],
    }
  );
  const [employees, setEmployees] = React.useState<UserInfoCommon[]>([]);
  const [filtersSpinner, setFiltersSpinner] = React.useState<boolean>(false);
  const [clientsSearch, setClientsSearch] = React.useState<string>('');
  const [showFilters, setShowFilters] = React.useState<boolean>(props.device === 'desktop');
  const [firstRender, setFirstRender] = React.useState<boolean>(true);
  const [userTypes, setUserTypes] = React.useState<UserTypeInterface[]>([]);
  const [showComments, setShowComments] = React.useState<number>(0);
  const [showPermissions, setShowPermissions] = React.useState<number>(0);

  const getUserTypes = async () => {
    const api = new BaseApi(1, 'user/types/');
    let res = await api.get({}, props.appSetState, props.appState);
    if (res.status === 200) {
      setUserTypes(res.body.map((ut: any) => {return ut as UserTypeInterface}));
    }
  }

  const getClients = async () => {
    const api = new BaseApi(2, 'management/get_clients/');
    props.handleOpenSpinner();
    await getUserTypes();
    let res = await api.get(clientsQuery, props.appSetState, props.appState);
    if (res.status === 200) {
      let clients_arr = [];
      let client_tickets_arr = [];
      for (let cl of res.body.results) {
        client_tickets_arr = [];
        for (let t of cl.tickets) {
          client_tickets_arr.push({id: t.id, ticket: t.ticket});
        };
        cl.tickets = client_tickets_arr;
        delete cl.logo
        delete cl.who_confirmed
        clients_arr.push(cl as ClientInfo)
      };
      setClients(clients_arr);
      setClientsCount(res.body.count);
    };
    setFirstRender(false);
    props.handleCloseSpinner();
  };

  const getFilters = async () => {
    const api = new BaseApi(1, 'management/filters_info/');
    setFiltersSpinner(true);
    let res = await api.get({}, props.appSetState, props.appState);
    if (res.status === 200) {
      let _cus = [];
      for (let c of res.body.clubs) {
        _cus.push(...c.units.map((unit: ClubUnit) => {return {id: unit.id, name: unit.name}}));
      };
      res.body.club_units = _cus;
      setFilters(res.body);
    }
    setFiltersSpinner(false);
  };

  useEffect(() => {
    getClients();
  }, [clientsQuery]);

  useEffect(() => {
    if (firstRender) { return }
    setClientsQuery(
      {...clientsQuery, offset: rowsPerPage * page}
    );
  }, [page]);

  useEffect(() => {
    if (firstRender) { return }
    setClientsQuery(
      {...clientsQuery, limit: rowsPerPage, offset: rowsPerPage * page}
    );
  }, [rowsPerPage]);

  useEffect(() => {
    getFilters();
  }, []);

  useEffect(() => {
    setShowFilters(props.device === 'desktop');
  }, [props.device]);

  useEffect(() => {
    let _emps = [];
    for (let depart of props.departments) {
      _emps.push(...depart.employees.map(
        (emp) => {
          return {
            id: emp.employee.id,
            first_name: emp.employee.first_name,
            last_name: emp.employee.last_name,
            patronymic: emp.employee.patronymic,
          } as UserInfoCommon
        }
      ));
    };
    let _unique_emps: UserInfoCommon[] = [];
    let _unique_emp_ids: number[] = [];
    for (let emp of _emps) {
      if (!_unique_emp_ids.includes(emp.id)) {
        _unique_emps.push(emp);
        _unique_emp_ids.push(emp.id);
      }
    }
    setEmployees(_unique_emps);
  }, [props.departments]);

  useEffect(() => {
    if (!clientsSearch) { return }
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        setClientsQuery(
          {...clientsQuery, search: encodeURIComponent(clientsSearch)}
        );
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [clientsSearch]);
  
  const handleClick = (event: React.MouseEvent<HTMLElement>, client_id: number) => {
    setOpenMenu(client_id);
    setAnchorMenu(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorMenu(null);
    setOpenMenu(0);
  };
  
  const handleClosePopover = () => {
    setAnchorPopover(null);
    setShowTicketInfo(0);
    setShowNewTicket(0);
  };
  
  const handleCloseNewClient = () => {
    setNewClient(false);
  };

  const handleCloseClientEdit = () => {
    setEditClient(0);
  };

  const handleCloseNonActive = () => {
    setShowNonActive(0);
  };

  const handleCloseClientDebts = () => {
    setShowDebts(0);
  };

  const handleCloseNotifyCreate = () => {
    setShowNotifyCreate(0);
  }

  const createUser = async (data: FormData) => {
    props.handleOpenSpinner();
    const api = new BaseApi(1, `user/account/`);
    let res = await api.put(
      data, 'multipart/form-data', props.appSetState, props.appState
    );
    if (res.status === 201) {
      setClients([res.body].concat(clients));
      setClientsCount(clientsCount + 1);
    }
    setNewClient(false);
    props.handleCloseSpinner();
  };

  const manageClient = async (client: ClientInfo) => {
    props.handleOpenSpinner();
    const api = new BaseApi(1, `user/account/${client.id}/`);
    let res = await api.post(client, 'multipart/form-data', props.appSetState, props.appState);
    if (res.status === 200) {
      setClients(
        clients.map((cl) => {
          if (cl.id === client.id) {
            return client
          } else {
            return cl
          }
        })
      );
    }
    setEditClient(0);
    props.handleCloseSpinner();
  };
  const closeManageClient = () => {
    setEditClient(0);
  };

  const extendClientTicketsArr = async (client_id: number, ticket: UserTicketManageRequestBody) => {
    let ticket_id: number = 0;
    let ticket_name: string = '';
    if (ticket.id && ticket.ticket_name) {
      ticket_id = ticket.id;
      ticket_name = ticket.ticket_name;
      setClients(
        clients.map(
          (client) => {
            if (client.id === client_id) {
              client.tickets.push({id: ticket_id, ticket: ticket_name});
            }
            return client
          }
        )
      )
    }
  };

  const updateClientTicket = async (
    client_id: number,
    ticket_state: any,
    ticket: UserTicketManageRequestBody,
    extend_arr: boolean = true,
  ) => {
    let res = {status: 404, body: {id: 0}};
    if (ticket.id) {
      const api = new BaseApi(1, `user/user_tickets/${ticket.id}/`);
      res = await api.post(ticket, 'application/json', props.appSetState, props.appState);
    } else {
      ticket.user = client_id;
      const api = new BaseApi(1, `user/user_tickets/`);
      res = await api.put(ticket, 'application/json', props.appSetState, props.appState);
    }
    if ([200, 201].includes(res.status) && extend_arr && res.body.id !== 0) {
      ticket.id = res.body.id;
      await extendClientTicketsArr(client_id, ticket)
    };
    setShowNewTicket(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleListItemClick = (e: React.MouseEvent<HTMLElement>, pk: number) => {
    setAnchorPopover(e.currentTarget);
    setShowTicketInfo(pk);
  };

  const handleRemoveUser = async (user_id: number) => {
    const api = new BaseApi(1, `user/account/${user_id}/`);
    props.handleOpenSpinner();
    let res = await api.delete({}, props.appSetState, props.appState);
    if (res.status === 200) {
      setClients(clients.filter(cl => cl.id !== user_id));
      setClientsCount(clientsCount - 1);
    };
    setRemoveUser(0);
    props.handleCloseSpinner();
  };
  const handleDeclineRemoveUser = () => {
    setRemoveUser(0);
  };

  const handleRegisterUser = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    data.append("type", '0');
    createUser(data);
  };
  
  return (
    <Box className="table-wrapper">
      {!props.clientsIds
        ? <Box>
            <Paper
              sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: (props.device === 'desktop') ? '50%' : '95%',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: '5px',
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={t('search_placeholder', { ns: 'common' })}
                inputProps={{ 'aria-label': 'search google maps' }}
                onChange={(e) => {setClientsSearch(e.target.value)}}
              />
              <IconButton
                type="button"
                sx={{ p: '10px' }}
                aria-label="search"
                onClick={
                  (e) => {
                    setClientsQuery(
                      {...clientsQuery, search: encodeURIComponent(clientsSearch)}
                    );
                  }
                }
              >
                <SearchIcon />
              </IconButton>
            </Paper>
            <Box sx={{ marginBottom: '5px' }}>
              {props.device === 'mobile'
                ? <Box
                    sx={{
                      width: 'fit-content',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => setShowFilters(!showFilters)}
                    >
                      {t('filters', { ns: 'filters' })}
                    </Button>
                  </Box>
                : ''
              }
              {filtersSpinner
                ? <Box
                    sx={{
                      width: 'fit-content',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    <CircularProgress color="inherit" />
                  </Box>
                : <Box>
                    {showFilters
                      ? <Box
                          sx={{
                            display: (props.device === 'desktop') ? 'flex' : 'block',
                            justifyContent: 'space-between',
                            width: (props.device === 'desktop') ? '95%' : '70%',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                          }}
                        >
                          <Autocomplete
                            disablePortal
                            id="clients-club-filter-id"
                            options={
                              filters.clubs.map(
                                (club) => {return {id: club.id, label: club.name}}
                              )
                            }
                            onChange={
                              (
                                e: React.SyntheticEvent,
                                value: AutocompleteProps | null,
                              ) => {
                                if (value && value.id) {
                                  setClientsQuery({...clientsQuery, club: value.id});
                                } else {
                                  delete clientsQuery.club;
                                  setClientsQuery(clientsQuery);
                                }
                              }
                            }
                            sx={{ width: (props.device === 'mobile') ? '100%' : '13%' }}
                            renderInput={
                              (params: any) => <TextField
                                {...params}
                                label={t("club", { ns: 'filters' })}
                              />
                            }
                          />
                          <Autocomplete
                            disablePortal
                            id="clients-club_unit-filter-id"
                            options={
                              filters.club_units.map(
                                (cu) => {return {id: cu.id, label: cu.name}}
                              )
                            }
                            onChange={
                              (
                                e: React.SyntheticEvent,
                                value: AutocompleteProps | null,
                              ) => {
                                if (value && value.id) {
                                  setClientsQuery({...clientsQuery, club_unit: value.id});
                                } else {
                                  delete clientsQuery.club_unit;
                                  setClientsQuery(clientsQuery);
                                }
                              }
                            }
                            sx={{ width: (props.device === 'mobile') ? '100%' : '13%' }}
                            renderInput={
                              (params: any) => <TextField
                                {...params}
                                label={t("club_unit", { ns: 'filters' })}
                              />
                            }
                          />
                          <Autocomplete
                            disablePortal
                            id="clients-disciplines-filter-id"
                            options={
                              filters.disciplines.map(
                                (discipline) => {return {id: discipline.id, label: discipline.name}}
                              )
                            }
                            onChange={
                              (
                                e: React.SyntheticEvent,
                                value: AutocompleteProps | null,
                              ) => {
                                if (value && value.id) {
                                  setClientsQuery({...clientsQuery, discipline: value.id});
                                } else {
                                  delete clientsQuery.discipline;
                                  setClientsQuery(clientsQuery);
                                }
                              }
                            }
                            sx={{ width: (props.device === 'mobile') ? '100%' : '13%' }}
                            renderInput={
                              (params: any) => <TextField
                                {...params}
                                label={t("discipline", { ns: 'filters' })}
                              />
                            }
                          />
                          <Autocomplete
                            disablePortal
                            id="clients-coaches-filter-id"
                            options={
                              filters.coaches.map(
                                (coach) => {return {id: coach.id, label: `${coach.first_name} ${coach.last_name}`}}
                              )
                            }
                            onChange={
                              (
                                e: React.SyntheticEvent,
                                value: AutocompleteProps | null,
                              ) => {
                                if (value && value.id) {
                                  setClientsQuery({...clientsQuery, coach: value.id});
                                } else {
                                  delete clientsQuery.coach;
                                  setClientsQuery(clientsQuery);
                                }
                              }
                            }
                            sx={{ width: (props.device === 'mobile') ? '100%' : '13%' }}
                            renderInput={
                              (params: any) => <TextField
                                {...params}
                                label={t("coach", { ns: 'filters' })}
                              />
                            }
                          />
                          <Autocomplete
                            disablePortal
                            id="clients-tickets-filter-id"
                            options={
                              filters.tickets.map(
                                (ticket) => {return {id: ticket.id, label: ticket.ticket}}
                              )
                            }
                            onChange={
                              (
                                e: React.SyntheticEvent,
                                value: AutocompleteProps | null,
                              ) => {
                                if (value && value.id) {
                                  setClientsQuery({...clientsQuery, ticket: value.id});
                                } else {
                                  delete clientsQuery.ticket;
                                  setClientsQuery(clientsQuery);
                                }
                              }
                            }
                            sx={{ width: (props.device === 'mobile') ? '100%' : '13%' }}
                            renderInput={
                              (params: any) => <TextField
                                {...params}
                                label={t("ticket", { ns: 'filters' })}
                              />
                            }
                          />
                          <Autocomplete
                            disablePortal
                            id="clients-client_types-filter-id"
                            options={
                              [
                                {id: "hooky", label: t("client_types.hooky", { ns: 'filters' })},
                                {id: "non-prolongation", label: t("client_types.non_prolongation", { ns: 'filters' })},
                                {id: "prolongation", label: t("client_types.prolongation", { ns: 'filters' })},
                                {id: "no_ticket", label: t("client_types.no_ticket", { ns: 'filters' })},
                                {id: "frozen", label: t("client_types.frozen", { ns: 'filters' })},
                                {id: "debtor", label: t("client_types.debtor", { ns: 'filters' })},
                              ]
                            }
                            onChange={
                              (
                                e: React.SyntheticEvent,
                                value: AutocompleteProps | null,
                              ) => {
                                if (value && value.id) {
                                  setClientsQuery({...clientsQuery, client_type: value.id});
                                } else {
                                  delete clientsQuery.client_type;
                                  setClientsQuery(clientsQuery);
                                }
                              }
                            }
                            sx={{ width: (props.device === 'mobile') ? '100%' : '13%' }}
                            renderInput={
                              (params: any) => <TextField
                                {...params}
                                label={t("client_type", { ns: 'filters' })}
                              />
                            }
                          />
                          <Autocomplete
                            disablePortal
                            id="clients-employee_clients-filter-id"
                            options={
                              employees.map((emp) => {
                                return {id: emp.id, label: `${emp.first_name} ${emp.last_name}`}
                              })
                            }
                            onChange={
                              (
                                e: React.SyntheticEvent,
                                value: AutocompleteProps | null,
                              ) => {
                                if (value && value.id) {
                                  setClientsQuery({...clientsQuery, employee: value.id});
                                } else {
                                  delete clientsQuery.employee;
                                  setClientsQuery(clientsQuery);
                                }
                              }
                            }
                            sx={{ width: (props.device === 'mobile') ? '100%' : '13%' }}
                            renderInput={
                              (params: any) => <TextField
                                {...params}
                                label={t("employee_clients", { ns: 'filters' })}
                              />
                            }
                          />
                        </Box>
                      : ''
                    }
                  </Box>
              }
            </Box>
          </Box>
        : ''
      }
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: '74vh' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={`cell-${column.id}`}
                    align={column.align}
                    style={{ minWidth: column.minWidth, backgroundColor: 'var(--orange)' }}
                  >
                    {t(`heads.${column.id}`)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {clients.map((client, index) => {
                return (
                  <TableRow tabIndex={-1} key={`row-key-${index}`}>
                    <TableCell key={`key-full_name-${index}`}>
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={openMenu === client.id ? 'long-menu' : undefined}
                        aria-expanded={openMenu === client.id ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={(e) => {handleClick(e, client.id)}}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      {client.first_name} {client.last_name}
                    </TableCell>
                    <TableCell key={`key-ticket-${index}`}>
                      <Box sx={{ width: '100%' }}>
                        <List component="nav" aria-label="secondary mailbox folder">
                          {client.tickets.map((ticket, index) => {
                            return (
                              <Box key={`ticket-arr-item-${index}`}>
                                <ListItemButton
                                  onClick={(event) => handleListItemClick(event, ticket.id)}
                                >
                                  <ListItemText primary={ticket.ticket} />
                                </ListItemButton>
                                <UserTicketCommonInfo
                                  ticket={ticket}
                                  show={(showTicketInfo === ticket.id) ? true : false}
                                  handleClose={handleClosePopover}
                                  anchor={anchorPopover}
                                  handleCloseSpinner={props.handleCloseSpinner}
                                  handleOpenSpinner={props.handleOpenSpinner}
                                  excludeTicket={
                                    () => {
                                      client.tickets = client.tickets.filter(t => t.id !== ticket.id);
                                      setClients(
                                        clients.map(
                                          (cl) => {
                                            if (cl.id === client.id) {
                                              return client
                                            } else {
                                              return cl
                                            }
                                          }
                                        )
                                      );
                                    }
                                  }
                                  appState={props.appState}
                                  appSetState={props.appSetState}
                                  openPopUp={props.openPopUp}
                                  manageUserTickets={(e: any) => {  }}
                                  client_id={client.id}
                                  device={props.device}
                                />
                              </Box>
                            )
                          })}
                        </List>
                      </Box>
                    </TableCell>
                    <TableCell key={`key-contacts-${index}`}>
                      <Typography>{client.phone}</Typography>
                      <Typography>{client.email}</Typography>
                    </TableCell>
                    <TableCell key={`key-representative-${index}`}>
                      {client.trustee_name} {client.trustee_phone}
                    </TableCell>
                    <TableCell key={`key-birthday-${index}`}>
                      {client.birth_date}
                    </TableCell>
                    <TableCell key={`key-employees-${index}`}>
                      {props.departments.map((department) => {
                        return (
                          <Box key={`department-key-${department.id}`}>
                            <Typography>{department.name}</Typography>
                            <List>
                              {department.employees.map((emp) => {
                                if (client.employees.includes(emp.id)) {
                                  return (
                                    <ListItem key={`department-employee-key-${emp.id}`}>
                                      {`${emp.employee.first_name} ${emp.employee.last_name}`}
                                    </ListItem>
                                  )
                                }
                              })}
                            </List>
                            <Divider />
                          </Box>
                        )
                      })}
                    </TableCell>
                    <Menu
                      id={`client-menu-id-${index}`}
                      key={`client-menu-key-${index}`}
                      MenuListProps={{
                        'aria-labelledby': 'long-button',
                      }}
                      anchorEl={anchorMenu}
                      open={openMenu === client.id}
                      onClose={handleClose}
                      data-user_id={client.id}
                    >
                      <MenuItem
                        key={`menu_item-edit-${index}`}
                        onClick={(e) => {
                          setEditClient(client.id);
                          handleClose();
                        }}
                        className="control-menu-item-wrapper"
                      >
                        {t("actions.edit")}
                      </MenuItem>
                      {accountInfo.account_info.permissions.includes('manage_clients')
                        ? <MenuItem
                            key={`menu_item-remove-${index}`}
                            onClick={
                              (e) => {
                                setRemoveUser(client.id);
                                handleClose()
                              }
                            }
                            className="control-menu-item-wrapper"
                          >
                            {t("actions.remove")}
                          </MenuItem>
                        : ''
                      }
                      <MenuItem
                        key={`menu_item-notify-${index}`}
                        onClick={
                          (e) => {
                            setShowNotifyCreate(client.id);
                            handleClose();
                          }
                        }
                        className="control-menu-item-wrapper"
                      >
                        {t("actions.notify")}
                      </MenuItem>
                      {accountInfo.account_info.permissions.includes('manage_clients')
                        ? <MenuItem
                            key={`menu_item-debt-${index}`}
                            onClick={
                              (e) => {
                                setShowDebts(client.id);
                                handleClose();
                              }
                            }
                            className="control-menu-item-wrapper"
                          >
                            {t("actions.debt")}
                          </MenuItem>
                        : ''
                      }
                      {accountInfo.account_info.permissions.includes('manage_clients')
                        ? <MenuItem
                            key={`menu_item-expired_tickets-${index}`}
                            onClick={(e) => {
                              setShowNonActive(client.id)
                              handleClose()
                            }}
                            className="control-menu-item-wrapper"
                          >
                            {t("actions.expired_tickets")}
                          </MenuItem>
                        : ''
                      }
                      {accountInfo.account_info.permissions.includes('manage_clients')
                        ? <MenuItem
                            key={`menu_item-attendance-${index}`}
                            onClick={(e) => {
                              setShowAttendance(client.id);
                              handleClose();
                            }}
                            className="control-menu-item-wrapper"
                          >
                            {t("actions.attendance")}
                          </MenuItem>
                        : ''
                      }
                      {accountInfo.account_info.permissions.includes('manage_clients')
                        ? <MenuItem
                            key={`menu_item-add_ticket-${index}`}
                            onClick={(e) => {
                              setShowNewTicket(client.id);
                              handleClose();
                            }}
                            className="control-menu-item-wrapper"
                          >
                            {t("actions.add_ticket")}
                          </MenuItem>
                        : ''
                      }
                      {accountInfo.account_info.permissions.includes('comment_clients')
                        ? <MenuItem
                            key={`menu_item-comment-${index}`}
                            onClick={(e) => {
                              setShowComments(client.id);
                              handleClose();
                            }}
                            className="control-menu-item-wrapper"
                          >
                            {t("actions.comments")}
                          </MenuItem>
                        : ''
                      }
                      {accountInfo.account_info.permissions.includes('manage_user_permissions')
                        ? <MenuItem
                            key={`menu_item-perms-${index}`}
                            onClick={(e) => {
                              setShowPermissions(client.id);
                              handleClose();
                            }}
                            className="control-menu-item-wrapper"
                          >
                            {t("actions.permissions")}
                          </MenuItem>
                        : ''
                      }
                    </Menu>
                    <BootstrapDialogWidth
                      key={`edit-client-${client.id}`}
                      onClose={handleCloseClientEdit}
                      open={editClient === client.id}
                    >
                      <DialogTitle sx={{ paddingLeft: '5px' }}>
                        {client.first_name} {client.last_name}
                      </DialogTitle>
                      <DialogContent dividers className="box-content-center">
                        <UserManage
                          client={client}
                          departments={props.departments}
                          applyEdit={manageClient}
                          closeEdit={closeManageClient}
                          device={props.device}
                          appState={props.appState}
                          appSetState={props.appSetState}
                          userTypes={userTypes}
                          isClient={true}
                        />
                      </DialogContent>
                    </BootstrapDialogWidth>
                    <ApproveAction
                      handleApprove={handleRemoveUser}
                      handleDecline={handleDeclineRemoveUser}
                      action={'remove_client'}
                      show={removeUser}
                      obj_pk={client.id}
                    />
                    <BootstrapDialog
                      key={`client-notify-key-${client.id}`}
                      open={(showNotifyCreate === client.id)}
                      onClose={(e) => {setShowNotifyCreate(0)}}
                    >
                      <DialogContent
                        dividers
                        className="box-content-center"
                        sx={{ minWidth: '180px' }}
                      >
                        <NotificationCreate
                          client_id={client.id}
                          closeDialog={handleCloseNotifyCreate}
                          openPopUp={props.openPopUp}
                          appState={props.appState}
                          appSetState={props.appSetState}
                          device={props.device}
                        />
                      </DialogContent>
                    </BootstrapDialog>
                    <ClientDebtsRender
                      handleClose={handleCloseClientDebts}
                      show={showDebts}
                      client_id={client.id}
                      handleCloseSpinner={props.handleCloseSpinner}
                      handleOpenSpinner={props.handleOpenSpinner}
                      appState={props.appState}
                      appSetState={props.appSetState}
                      device={props.device}
                    />
                    <NonActiveUserTicketsInfo
                      handleClose={handleCloseNonActive}
                      show={showNonActive}
                      user_id={client.id}
                      manage_user_tickets={updateClientTicket}
                      handleCloseSpinner={props.handleCloseSpinner}
                      handleOpenSpinner={props.handleOpenSpinner}
                      appState={props.appState}
                      appSetState={props.appSetState}
                      device={props.device}
                    />
                    <BootstrapDialog
                      fullScreen
                      open={(showAttendance === client.id)}
                      onClose={(e) => {setShowAttendance(0)}}
                      TransitionComponent={Transition}
                    >
                      <AppBar sx={{ position: 'relative' }}>
                        <Toolbar sx={{ backgroundColor: 'var(--black)' }}>
                          <IconButton
                            edge="start"
                            color="inherit"
                            onClick={(e) => {setShowAttendance(0)}}
                            aria-label="close"
                          >
                            <CloseIcon />
                          </IconButton>
                          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {t('heads.attendance')}
                          </Typography>
                        </Toolbar>
                      </AppBar>
                      <ClientAttendance
                        client_id={client.id}
                        device={props.device}
                        appState={props.appState}
                        appSetState={props.appSetState}
                      />
                    </BootstrapDialog>
                    <AddClientTicket
                      ticket={{id: 0, ticket: ''}}
                      show={showNewTicket === client.id}
                      handleClose={handleClosePopover}
                      anchor={anchorPopover}
                      handleCloseSpinner={props.handleCloseSpinner}
                      handleOpenSpinner={props.handleOpenSpinner}
                      excludeTicket={() => {  }}
                      appState={props.appState}
                      appSetState={props.appSetState}
                      openPopUp={props.openPopUp}
                      manageUserTickets={updateClientTicket}
                      client_id={client.id}
                      device={props.device}
                    />
                    <TargetObjCommentsDialog
                      targetObject={client}
                      show={showComments}
                      closeChat={() => {setShowComments(0)}}
                      device={props.device}
                      appState={props.appState}
                      appSetState={props.appSetState}
                      sendSocketMessage={props.sendSocketMessage}
                      departments={props.departments}
                    />
                    <BootstrapDialog
                      key={`client-permissions-key-${client.id}`}
                      open={(showPermissions === client.id)}
                      onClose={(e) => {setShowPermissions(0)}}
                    >
                      <DialogContent
                        dividers
                        className="box-content-center"
                        sx={{ minWidth: '180px' }}
                      >
                        <UserPermissionsManage
                          user={client}
                          requestKey={showPermissions}
                        />
                      </DialogContent>
                    </BootstrapDialog>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={clientsCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {!props.clientsIds
        ? <Zoom
            key="new-client-key"
            in={true}
          >
            <Fab sx={
                {
                  position: 'absolute',
                  bottom: 36,
                  left: 36,
                  borderColor: root_theme.palette.info.main,
                  borderWidth: '2px',
                  borderStyle: 'solid',
                  '&:hover': {
                    bgcolor: root_theme.palette.background.default,
                    borderColor: root_theme.palette.info.main,
                  },
                } as SxProps
              }
              aria-label="Add client"
              color='info'
              onClick={(e) => {setNewClient(true)}}
            >
              <AddIcon />
            </Fab>
          </Zoom>
        : ''
      }
      <BootstrapDialog
        onClose={handleCloseNewClient}
        open={newClient}
      >
        <DialogTitle sx={{ paddingLeft: '5px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontWeight: '600', fontSize: '1.25em' }}>
              {t('register.header', { ns: 'forms' })}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent
          dividers
          className="box-content-center"
        >
          <Register
            close_form={handleCloseNewClient}
            openPopUp={props.openPopUp}
            register_user={handleRegisterUser}
            userTypes={userTypes}
            appState={props.appState}
            appSetState={props.appSetState}
            device={props.device}
          />
        </DialogContent>
      </BootstrapDialog>
    </Box>
  )
}
