import DialogContent from '@mui/material/DialogContent';

import { BootstrapDialogWidth } from '../common/Dialogs';
import { UnreadComments } from './UnreadComments';

import { ICommentsWindowProps } from '../../interfaces/Comments';

export function Chat(props: ICommentsWindowProps) {
  return (
    <BootstrapDialogWidth
      open={props.show}
      onClose={(e) => { props.closeChat() }}
    >
      <DialogContent
        sx={{
          overflow: 'hidden',
          height: '90vh',
          width: '95%!important',
          padding: (props.device === 'desktop') ? '16px!important' : '2px!important',
        }}
      >
        {props.show
          ? <UnreadComments
              device={props.device}
              appState={props.appState}
              appSetState={props.appSetState}
              messages={props.messages}
              users={props.users}
              sendSocketMessage={props.sendSocketMessage}
              substractUnreadChats={props.substractUnreadChats}
              departments={props.departments}
            />
          : ''
        }
      </DialogContent>
    </BootstrapDialogWidth>
  )
}
