import React, { useEffect } from "react";

import { useRecoilValue } from 'recoil';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import { useTranslation } from 'react-i18next';

import { UserFullProps, AutocompleteProps } from "../../interfaces/Props";
import { ClubUnit } from "../../interfaces/Club";

import { accountInfoAtom } from '../../atoms/AccountInfo';

export function UserManage(props: UserFullProps) {
  const { t } = useTranslation(['user', 'common']);
  const accountInfo = useRecoilValue(accountInfoAtom);
  const [formValid, setFormValid] = React.useState<boolean>(false);
  const regex = /^[A-Za-z0-9\-\.]+@[A-Za-z0-9\-\.]+\.[a-z]{2,3}$/;
  const phoneRegex = /^\+{0,1}[0-9\-\.]{8,}$/;
  const [phone, setPhone] = React.useState<string>(
    (props.client.phone || props.client.trustee_phone) ? props.client.phone || props.client.trustee_phone : ''
  );
  const [phoneValid, setPhoneValid]= React.useState<boolean>(true);
  const [email, setEmail] = React.useState<string>(
    (props.client.email) ? props.client.email : ''
  );
  const [emailValid, setEmailValid]= React.useState<boolean>(true);

  React.useEffect(() => {
    if (phone !== '' && !phoneRegex.test(phone)) {
      setPhoneValid(false);
    } else {
      setPhoneValid(true);
      if (props.client.trustee_phone) {
        props.client.trustee_phone = phone
      } else {
        props.client.phone = phone
      }
    }
  }, [phone]);

  React.useEffect(() => {
    if (email !== '' && !regex.test(email)) {
      setEmailValid(false);
    } else {
      setEmailValid(true);
      props.client.email = email
    }
  }, [email]);

  const checkFormValid = () => {
    if (phone === '' && email === '') {
      setFormValid(false);
    } else if (!phoneValid || !emailValid) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }

  React.useEffect(() => {
    checkFormValid()
  }, [phoneValid]);
  React.useEffect(() => {
    checkFormValid()
  }, [emailValid]);
  
  return (
    <Box>
      <Autocomplete
        disablePortal
        id="clients-club-filter-id"
        options={
          props.userTypes.map(
            (ut) => {return {id: ut.pk, label: ut.user_type}}
          )
        }
        onChange={
          (
            e: React.SyntheticEvent,
            value: AutocompleteProps | null,
          ) => {
            if (value && value.id !== null && value.id !== undefined) {
              props.client.type = Number(value.id);
            }
          }
        }
        sx={{ width: (props.device === 'mobile') ? '100%' : '40%' }}
        renderInput={
          (params: any) => <TextField
            {...params}
            label={t("user_type")}
          />
        }
        defaultValue={
          props.userTypes.map((ut) => {return {id: ut.pk, label: ut.user_type}}).filter(ut => Number(ut.id) === props.client.type)[0]
        }
      />
      {props.client.type === 1
        ? <Autocomplete
            disablePortal
            id="clients-club-filter-id"
            options={
              (props.coachSkills)
                ? props.coachSkills.map(
                    (skill) => {return {id: skill.id, label: skill.name}}
                  )
                : []
            }
            onChange={
              (
                e: React.SyntheticEvent,
                value: AutocompleteProps | null,
              ) => {
                if (value && value.id !== null && value.id !== undefined) {
                  props.client.skill = Number(value.id);
                }
              }
            }
            sx={{ width: (props.device === 'mobile') ? '100%' : '40%', marginTop: '10px' }}
            renderInput={
              (params: any) => <TextField
                {...params}
                label={t("coach_skill")}
              />
            }
            defaultValue={
              (props.coachSkills)
                ? props.coachSkills.map(
                    (skill) => {return {id: skill.id, label: skill.name}}
                  ).filter(skill => skill.label === props.client.text_skill)[0]
                : null
            }
          />
        : ''
      }
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "15px",
          width: (props.device === 'desktop') ? '40%' : '100%',
        }}
      >
        <Typography sx={{ marginRight: "10px" }}>{t("first_name")}:</Typography>
        <Input
          sx={{ width: "100%" }}
          defaultValue={props.client.first_name}
          onChange={(e) => {
            props.client.first_name = e.target.value
          }}
          disabled={!accountInfo.account_info.permissions.includes('manage_clients')}
        />
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "15px",
          width: (props.device === 'desktop') ? '40%' : '100%',
        }}
      >
        <Typography sx={{ marginRight: "10px" }}>{t("last_name")}:</Typography>
        <Input
          sx={{ width: "100%" }}
          defaultValue={props.client.last_name}
          onChange={(e) => {
            props.client.last_name = e.target.value
          }}
          disabled={!accountInfo.account_info.permissions.includes('manage_clients')}
        />
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "15px",
          width: (props.device === 'desktop') ? '40%' : '100%',
        }}
      >
        <Typography sx={{ marginRight: "10px" }}>{t("patronymic")}:</Typography>
        <Input
          sx={{ width: "100%" }}
          defaultValue={props.client.patronymic}
          onChange={(e) => {
            props.client.patronymic = e.target.value
          }}
          disabled={!accountInfo.account_info.permissions.includes('manage_clients')}
        />
      </Box>
      <Divider />
      {props.client.trustee_phone
        ? <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "15px",
              width: (props.device === 'desktop') ? '40%' : '100%',
            }}
          >
            <Typography sx={{ marginRight: "10px" }}>{t("trustee_phone")}:</Typography>
            <Input
              sx={{ width: "100%" }}
              value={phone}
              onChange={
                (e) => {
                  setPhoneValid(false);
                  setPhone(e.target.value)
                }
              }
              disabled={!accountInfo.account_info.permissions.includes('manage_clients')}
              error={!phoneValid}
            />
          </Box>
        : <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "15px",
              width: (props.device === 'desktop') ? '40%' : '100%',
            }}
          >
            <Typography sx={{ marginRight: "10px" }}>{t("phone")}:</Typography>
            <Input
              sx={{ width: "100%" }}
              value={phone}
              onChange={
                (e) => {
                  setPhoneValid(false);
                  setPhone(e.target.value)
                }
              }
              disabled={!accountInfo.account_info.permissions.includes('manage_clients')}
              error={!phoneValid}
            />
          </Box>
      }
      <Divider />
      {props.client.trustee_name
        ? <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "15px",
              width: (props.device === 'desktop') ? '40%' : '100%',
            }}
          >
            <Typography sx={{ marginRight: "10px" }}>{t("trustee_name")}:</Typography>
            <Input
              sx={{ width: "100%" }}
              defaultValue={props.client.trustee_name}
              onChange={
                (e) => {
                  props.client.trustee_name = e.target.value
                }
              }
              disabled={!accountInfo.account_info.permissions.includes('manage_clients')}
            />
          </Box>
        : <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "15px",
              width: (props.device === 'desktop') ? '40%' : '100%',
            }}
          >
            <Typography sx={{ marginRight: "10px" }}>{t("email")}:</Typography>
            <Input
              sx={{ width: "100%" }}
              value={email}
              onChange={
                (e) => {
                  setEmailValid(false);
                  setEmail(e.target.value)
                }
              }
              disabled={!accountInfo.account_info.permissions.includes('manage_clients')}
              error={!emailValid}
            />
          </Box>
      }
      <Divider />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "15px",
          width: (props.device === 'desktop') ? '40%' : '100%',
        }}
      >
        <Typography sx={{ marginRight: "10px" }}>{t("birthday")}:</Typography>
        <Input
          sx={{ width: "100%" }}
          defaultValue={props.client.birth_date}
          onChange={(e) => {
            props.client.birth_date = e.target.value
          }}
          type="date"
          disabled={!accountInfo.account_info.permissions.includes('manage_clients')}
        />
      </Box>
      <Divider />
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: "15px",
            width: (props.device === 'desktop') ? '40%' : '100%',
          }}
        >
          <Typography sx={{ marginRight: "10px" }}>Telegram:</Typography>
          <Input
            sx={{ width: "100%" }}
            defaultValue={props.client.telegram}
            onChange={(e) => {
              props.client.telegram = e.target.value
            }}
            disabled={!accountInfo.account_info.permissions.includes('manage_clients')}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: "15px",
            width: (props.device === 'desktop') ? '40%' : '100%',
          }}
        >
          <Typography sx={{ marginRight: "10px" }}>Whatsapp:</Typography>
          <Input
            sx={{ width: "100%" }}
            defaultValue={props.client.whatsapp}
            onChange={(e) => {
              props.client.whatsapp = e.target.value
            }}
            disabled={!accountInfo.account_info.permissions.includes('manage_clients')}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: "15px",
            width: (props.device === 'desktop') ? '40%' : '100%',
          }}
        >
          <Typography sx={{ marginRight: "10px" }}>Instagram:</Typography>
          <Input
            sx={{ width: "100%" }}
            defaultValue={props.client.instagram}
            onChange={(e) => {
              props.client.instagram = e.target.value
            }}
            disabled={!accountInfo.account_info.permissions.includes('manage_clients')}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: "15px",
            width: (props.device === 'desktop') ? '40%' : '100%',
          }}
        >
          <Typography sx={{ marginRight: "10px" }}>VK:</Typography>
          <Input
            sx={{ width: "100%" }}
            defaultValue={props.client.vk}
            onChange={(e) => {
              props.client.vk = e.target.value
            }}
            disabled={!accountInfo.account_info.permissions.includes('manage_clients')}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: "15px",
            width: (props.device === 'desktop') ? '40%' : '100%',
          }}
        >
          <Typography sx={{ marginRight: "10px" }}>Facebook:</Typography>
          <Input
            sx={{ width: "100%" }}
            defaultValue={props.client.facebook}
            onChange={(e) => {
              props.client.facebook = e.target.value
            }}
            disabled={!accountInfo.account_info.permissions.includes('manage_clients')}
          />
        </Box>
      </Box>
      <Divider />
      {props.isClient
        ? <Box>
            {props.departments.map((department, index) => {
              return (
                <Box key={`department-arr-key-${index}`}>
                  <Typography sx={{marginBottom: "10px"}}>{department.name}</Typography>
                  <Autocomplete
                    disablePortal
                    disabled={!accountInfo.account_info.permissions.includes('manage_clients')}
                    id="user-ticket-discipline-managing"
                    options={
                      department.employees.map((emp) => {
                        return {id: emp.id, label: `${emp.employee.first_name} ${emp.employee.last_name}`}
                      })
                    }
                    sx={{ width: "100%", marginBottom: "15px" }}
                    defaultValue={
                      (props.client.employees && props.client.employees.length)
                        ? department.employees.filter(
                            emp => props.client.employees.includes(emp.id)
                          ).map((emp, index) => {
                            return {id: emp.id, label: `${emp.employee.first_name} ${emp.employee.last_name}`}
                          })
                        : []
                    }
                    renderInput={(params) => (<TextField {...params} label={t("employees")} />)}
                    multiple={true}
                    onChange={(
                      e: React.SyntheticEvent,
                      value: Array<AutocompleteProps> | null,
                      reason: string,
                    ) => {
                      if (value && value.length) {
                        let choosen_emp_ids = value.map((emp) => {return emp.id})
                        props.client.employees = department.employees.filter(
                          emp => choosen_emp_ids.includes(emp.id)
                        ).map((emp) => {return emp.id})
                      } else {
                        props.client.employees = []
                      }
                    }}
                  />
                </Box>
              )
            })}
          </Box>
        : <Box>
            <Autocomplete
              disablePortal
              disabled={!accountInfo.account_info.permissions.includes('manage_clients')}
              id="user-ticket-discipline-managing"
              options={
                (props.clubUnits)
                  ? props.clubUnits.map((cu) => {
                      return {id: cu.id, label: cu.name}
                    })
                  : props.client.club_units.map((cu: ClubUnit) => {
                      return {id: cu.id, label: cu.name}
                    })
              }
              sx={{ width: "100%", marginBottom: "15px" }}
              defaultValue={
                props.client.club_units.map((cu: ClubUnit) => {
                  return {id: cu.id, label: cu.name}
                })
              }
              renderInput={(params) => (<TextField {...params} label={t("club_unit", { ns: 'common' })} />)}
              multiple={true}
              onChange={(
                e: React.SyntheticEvent,
                value: Array<AutocompleteProps> | null,
                reason: string,
              ) => {
                if (value && value.length) {
                  props.client.choosen_club_units_ids = value.map((cu) => {return cu.id});
                } else {
                  props.client.choosen_club_units_ids = [];
                }
              }}
            />
          </Box>
      }
      <Divider />
      <Box>
        <TextField
          id="standard-multiline-static"
          label={t("sketches")}
          multiline
          rows={5}
          defaultValue={props.client.sketches}
          variant="standard"
          sx={{width: "100%"}}
          onChange={(e) => {
            props.client.sketches = e.target.value
          }}
        />
      </Box>
      <Divider />
      <DialogActions
        sx={{ marginBottom: "15px" }}
      >
        <Button
          variant="outlined"
          color="error"
          onClick={(e) => {props.closeEdit()}}
        >
          {t("cancel", { ns: 'common' })}
        </Button>
        <Button
          variant="contained"
          color="info"
          disabled={!formValid}
          onClick={(e) => {
            setFormValid(false)
            props.applyEdit(props.client);
          }}
        >
          {t("save", { ns: 'common' })}
        </Button>
      </DialogActions>
    </Box>
  )
}
