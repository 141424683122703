import React, { useEffect } from "react";

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import { useTranslation } from 'react-i18next';

import {
  UserTicketFullProps,
  AutocompleteProps,
  UserTicketCommonProps,
} from '../../interfaces/Props';
import { UserInfoCommon } from "../../interfaces/AccountInfo";
import { UserTicketFull } from "../../interfaces/ClientTicket";
import {  
  Discipline,
  DrillType,
  PaymentType,
  Promo,
  Ticket,
} from "../../interfaces/Common";
import { CustomObj, PKObjsMapping } from "../../interfaces/Types";
import { ClubUnit } from "../../interfaces/Club";

import { theme as root_theme } from "../../consts";

import { BaseApi } from "../../api/base";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    backgroundColor: root_theme.palette.background.default,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: root_theme.palette.info.main,
  }
}));

export function UserTicketManage(props: UserTicketFullProps) {
  const { t } = useTranslation('tickets');
  const [coaches, setCoaches] = React.useState<UserInfoCommon[]>([]);
  const [disciplines, setDisciplines] = React.useState<Discipline[]>([]);
  const [drillTypes, setDrillTypes] = React.useState<DrillType[]>([]);
  const [paymentTypes, setPaymentTypes] = React.useState<PaymentType[]>([]);
  const [promos, setPromos] = React.useState<Promo[]>([]);
  const [tickets, setTickets] = React.useState<Ticket[]>([]);
  const [ticket, setTicket] = React.useState<UserTicketFull | CustomObj>(
    (props.ticket) ? props.ticket : {active: true, club_units: props.club_unit}
  );
  const [formValid, setFormValid] = React.useState<boolean>((props.ticket) ? true : false);
  const [spinner, setSpinner] = React.useState<boolean>(false);
  const [ticketPromos, setTicketPromos] = React.useState<PKObjsMapping>({});
  const [choosenPromo, setChoosenPromo] = React.useState<Promo | null>(null);
  const [choosenTicket, setChoosenTicket] = React.useState<Ticket | null>(null);

  const getSlugObjects = async () => {
    setSpinner(true);
    const api = new BaseApi(1, 'management/drill_creation_data/');
    let club_unit_id: number[] = [];
    if (props.ticket) {
      club_unit_id = props.ticket.ticket?.club_unit;
    } else if (props.club_unit) {
      club_unit_id = props.club_unit;
    } else {
      props.openPopUp('error', 'ticket_require_club_unit');
      setSpinner(false);
      props.closeEdit();
      return
    }
    let res = await api.get(
      {club_unit: club_unit_id},
      props.appSetState,
      props.appState,
    );
    setCoaches(res.body.coaches);
    setDisciplines(res.body.disciplines);
    setDrillTypes(res.body.drill_types);
    setPaymentTypes(res.body.payment_types);
    setPromos(res.body.promos);
    setTickets(res.body.tickets);

    let _ticket_promos: PKObjsMapping = {};
    for (let pr of res.body.promos) {
      for (let t of pr.tickets) {
        if (!_ticket_promos[t.ticket.id]) {
          _ticket_promos[t.ticket.id] = [];
        }
        _ticket_promos[t.ticket.id].push(pr.id)
      }
    }
    setTicketPromos(_ticket_promos);

    setSpinner(false);
  };

  useEffect(() => {
    getSlugObjects();
  }, [props.club_unit]);

  return (
    <Box>
      {spinner
        ? <Box>
            <CircularProgress color="inherit" />
          </Box>
        : <Box>
            <Autocomplete
              id="user-ticket-ticket-managing"
              options={
                tickets.map(
                  (ticket) => {return {id: ticket.id, label: ticket.ticket}}
                )
              }
              sx={{ width: "100%", marginBottom: "15px" }}
              defaultValue={
                (ticket && ticket.id && ticket.ticket)
                  ? {id: ticket.ticket.id, label: ticket.ticket.ticket}
                  : null
              }
              renderInput={(params) => <TextField {...params} label={t("manage.ticket")} />}
              onChange={(
                e: React.SyntheticEvent,
                value: AutocompleteProps | null,
                reason: string,
              ) => {
                if (value && value.id) {
                  let choosen_ticket = tickets.filter(t => t.id === value.id)
                  if (choosen_ticket.length && choosen_ticket[0]) {
                    ticket.ticket = choosen_ticket[0];
                    setChoosenTicket(choosen_ticket[0]);
                    setFormValid(true);
                  }
                } else {
                  setFormValid(false);
                }
              }}
            />
            {!ticket.id && choosenTicket && ticketPromos[choosenTicket.id]
              ? <Autocomplete
                  id="user-ticket-ticket-managing"
                  options={
                    promos.filter(
                      promo => ticketPromos[ticket.ticket.id].includes(promo.id)
                    ).map(
                      (promo) => {return {id: promo.id, label: promo.name}}
                    )
                  }
                  sx={{ width: "100%", marginBottom: "15px" }}
                  renderInput={(params) => <TextField {...params} label={t("manage.promo")} />}
                  onChange={(
                    e: React.SyntheticEvent,
                    value: AutocompleteProps | null,
                    reason: string,
                  ) => {
                    if (value && value.id) {
                      let choosen_promo = promos.filter(p => p.id === value.id)
                      if (choosen_promo.length && choosen_promo[0]) {
                        setChoosenPromo(choosen_promo[0]);
                        setFormValid(true);
                      }
                    } else {
                      setFormValid(false);
                    }
                  }}
                />
              : ''
            }
            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
              <Typography sx={{ marginRight: "10px" }}>{t("manage.promo_code")}:</Typography>
              <Input
                sx={{ width: "100%" }}
                defaultValue={(ticket) ? ticket.promo_code : null}
                onChange={(e) => {ticket.promo_code = e.target.value}}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
              <Typography sx={{ marginRight: "10px" }}>{t("manage.discount")}:</Typography>
              <Input
                sx={{ width: "100%" }}
                defaultValue={(ticket) ? ticket.discount : null}
                type="number"
                onChange={(e) => {ticket.discount = parseInt(e.target.value)}}
              />
            </Box>
            <Autocomplete
              id="user-ticket-discipline-managing"
              options={
                disciplines.map(
                  (discip) => {return {id: discip.id, label: discip.name}}
                )
              }
              sx={{ width: "100%", marginBottom: "15px" }}
              defaultValue={
                (ticket && ticket.discipline)
                  ? ticket.discipline.map((discip: any) => {return {id: discip.id, label: discip.name}})
                  : []
              }
              renderInput={(params) => <TextField {...params} label={t("manage.disciplines")} />}
              multiple={true}
              onChange={(
                e: React.SyntheticEvent,
                value: Array<AutocompleteProps> | null,
                reason: string,
              ) => {
                if (value && value.length) {
                  let choosen_discips_ids = value.map((d) => {return d.id})
                  ticket.discipline = disciplines.filter(d => choosen_discips_ids.includes(d.id));
                  setFormValid(true);
                } else {
                  setFormValid(false);
                }
              }}
            />
            <Autocomplete
              id="user-ticket-coach-managing"
              options={
                coaches.map(
                  (coach) => {return {id: coach.id, label: `${coach.first_name} ${coach.last_name}`}}
                )
              }
              sx={{ width: "100%", marginBottom: "15px" }}
              defaultValue={
                (ticket && ticket.coaches)
                  ? ticket.coaches.map(
                      (coach: any) => {return {id: coach.id, label: `${coach.first_name} ${coach.last_name}`}}
                    )
                  : []
              }
              renderInput={(params) => <TextField {...params} label={t("manage.coaches")} />}
              multiple={true}
              onChange={(
                e: React.SyntheticEvent,
                value: Array<AutocompleteProps> | null,
                reason: string,
              ) => {
                if (value && value.length) {
                  let choosen_coaches_ids = value.map((c) => {return c.id})
                  ticket.coaches = coaches.filter(c => choosen_coaches_ids.includes(c.id));
                  setFormValid(true);
                } else {
                  setFormValid(false);
                }
              }}
            />
            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
              <Typography sx={{ marginRight: "10px" }}>{t("manage.date_start")}:</Typography>
              <Input
                defaultValue={(ticket) ? ticket.start_date : null}
                type="date"
                onChange={(e) => {ticket.start_date = e.target.value}}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
              <Typography sx={{ marginRight: "10px" }}>{t("manage.expire_date")}:</Typography>
              <Input
                defaultValue={(ticket) ? ticket.expire_date : null}
                type="date"
                onChange={(e) => {ticket.expire_date = e.target.value}}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
              <Typography
                sx={{ marginRight: "10px", width: "100%" }}
              >
                {t("manage.payed_amount")}:
              </Typography>
              <Input
                sx={{ width: "100%" }}
                defaultValue={(ticket) ? ticket.payed_amount : null}
                type="number"
                onChange={(e) => {ticket.payed_amount = parseInt(e.target.value)}}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
              <Typography
                sx={{ marginRight: "10px", width: "100%" }}
              >
                {t("manage.ticket_price")}:
              </Typography>
              <Input
                sx={{ width: "100%" }}
                defaultValue={(ticket) ? ticket.price : null}
                type="number"
                onChange={(e) => {ticket.price = parseFloat(e.target.value)}}
              />
            </Box>
            <Autocomplete
              id="user-ticket-pay-type-managing"
              options={
                paymentTypes.map(
                  (pt) => {return {id: pt.id, label: pt.shortcut}}
                )
              }
              sx={{ width: "100%", marginBottom: "15px" }}
              defaultValue={
                (ticket && ticket.payment_type)
                  ? paymentTypes.filter((pt) => pt.id === ticket.payment_type).map(
                      (pt) => {return {id: pt.id, label: pt.shortcut}}
                    )[0]
                  : null
              }
              renderInput={(params) => <TextField {...params} label={t("manage.payment_type")} />}
              onChange={(
                e: React.SyntheticEvent,
                value: AutocompleteProps | null,
                reason: string,
              ) => {
                if (value && value.id) {
                  ticket.payment_type = value.id
                }
              }}
            />
            <FormControlLabel
              control=<Switch
                defaultChecked={ticket && ticket.id && !ticket.active}
                onChange={(e) => {ticket.active = !e.target.checked}}
              />
              label={t("manage.non_active")}
            />
            <FormControlLabel
              control=<Switch
                defaultChecked={ticket && ticket.frozen}
                onChange={(e) => {ticket.frozen = e.target.checked}}
              />
              label={t("manage.froze")}
            />
            <Divider />
            <DialogActions
              sx={{ marginBottom: "15px" }}
            >
              <Button
                variant="outlined"
                color="error"
                onClick={(e) => {props.closeEdit()}}
              >
                {t("cancel")}
              </Button>
              <Button
                variant="contained"
                color="info"
                disabled={!formValid}
                onClick={(e) => {
                  setFormValid(false);
                  props.applyEdit(
                    props.client_id,
                    ticket,
                    {
                      id: ticket.id,
                      user: props.client_id,
                      active: ticket.active,
                      start_date: ticket.start_date,
                      expire_date: ticket.expire_date,
                      ticket: ticket.ticket.id,
                      discipline: ticket.discipline.map((d: Discipline) => {return d.id}),
                      coaches: ticket.coaches.map((c: UserInfoCommon) => {return c.id}),
                      discount: ticket.discount,
                      expired: ticket.expired,
                      frozen: ticket.frozen,
                      payed: ticket.payed,
                      payed_amount: (ticket.payed_amount !== null && ticket.payed_amount !== undefined) ? ticket.payed_amount : ticket.price,
                      payment_type: ticket.payment_type,
                      price: ticket.price,
                      promo_code: ticket.promo_code,
                      ticket_name: ticket.ticket.ticket,
                      promo: (choosenPromo) ? choosenPromo.id : null,
                      club_units: (props.club_unit) ? props.club_unit : null,
                    },
                    (props.ticket) ? false : true,
                  );
                }}
              >
                {t("save")}
              </Button>
            </DialogActions>
          </Box>
      }
    </Box>
  )
}

export function AddClientTicket(props: UserTicketCommonProps) {
  const { t } = useTranslation('tickets');
  const [clubUnits, setClubUnits] = React.useState<ClubUnit[]>([]);
  const [choosenClubUbit, setChoosenClubUnit] = React.useState<number[]>([]);

  useEffect(() => {
    if (!props.show) { return }
    let _units = [];
    for (let club of props.appState.account_info.clubs) {
      _units.push(...club.units);
    };
    setClubUnits(_units);
  }, [props.show]);

  return (
    <BootstrapDialog
      onClose={(e) => {
        props.handleClose()
      }}
      open={props.show}
    >
      <DialogTitle sx={{ paddingLeft: '5px' }}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontWeight: '600', fontSize: '1.25em' }}>
              {t('manage.new')}
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers
        className="box-content-center"
        sx={{ minWidth: '180px' }}
      >
        <Box sx={{ width: '100%' }}>
          <Autocomplete
            id="combo-box-demo"
            options={
              clubUnits.map((cu) => {return {id: cu.id, label: cu.name}})
            }
            sx={{ width: "100%", marginBottom: "15px", zIndex: '100' }}
            renderInput={(params) => <TextField {...params} label={t('manage.club_unit')} />}
            multiple={true}
            onChange={(
              e: React.SyntheticEvent,
              value: Array<AutocompleteProps> | null,
              reason: string,
            ) => {
              if (value && value.length) {
                setChoosenClubUnit(value.map((c) => {return parseInt(c.id.toString())}))
                // setChoosenClubUnit(parseInt(value.id.toString()));
              } else {
                setChoosenClubUnit([]);
              }
            }}
          />
        </Box>
        {choosenClubUbit.length === 0
          ? <Box></Box>
          : <UserTicketManage
              club_unit={choosenClubUbit}
              closeEdit={props.handleClose}
              applyEdit={props.manageUserTickets}
              handleOpenSpinner={props.handleOpenSpinner}
              handleCloseSpinner={props.handleCloseSpinner}
              appState={props.appState}
              appSetState={props.appSetState}
              openPopUp={props.openPopUp}
              client_id={props.client_id}
              device={props.device}
            />
        }
      </DialogContent>
    </BootstrapDialog>
  )
}
