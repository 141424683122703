import React from "react";

import { useRecoilValue } from 'recoil';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { SxProps } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import allLocales from '@fullcalendar/core/locales-all'

import { useTranslation } from 'react-i18next';

import { CalendarProps, AutocompleteProps } from '../../interfaces/Props';
import {
  QueryObjCalendar,
  PostPutChoice,
  CustomObj,
} from '../../interfaces/Types';
import { ClientFilters } from "../../interfaces/Filters";
import { ClubUnit } from "../../interfaces/Club";
import {
  CalendarDrill,
  ScheduleDrillManage,
} from "../../interfaces/Calendar";
import { UserInfoCommon } from "../../interfaces/AccountInfo";

import {
  DrillManage,
  DrillAttendance,
  ReplaceDrill,
  CancelDrill,
  SubstituteDrill,
} from "../common/DrillManage";

import { BaseApi } from "../../api/base";

import { theme as root_theme } from "../../consts";

import { accountInfoAtom } from '../../atoms/AccountInfo';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    backgroundColor: root_theme.palette.background.default,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: root_theme.palette.info.main,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function Calendar(props: CalendarProps) {
  var curr = new Date;
  var first = curr.getDate() - curr.getDay();
  var last = first + 6;
  
  var firstday = new Date(curr.setDate(first));
  var lastday = new Date(curr.setDate(last));

  const { t } = useTranslation(['calendar', 'filters', 'forms']);
  const accountInfo = useRecoilValue(accountInfoAtom);
  const accountInfoRef = React.useRef(accountInfoAtom);
  const calendarRef = React.useRef(null);
  const [filters, setFilters] = React.useState<ClientFilters>(
    {
      clubs: [],
      club_units: [],
      coaches: [],
      disciplines: [],
      drill_types: [],
      tickets: [],
    }
  );
  const [filtersSpinner, setFiltersSpinner] = React.useState<boolean>(false);
  const [calendarQuery, setCalendarQuery] = React.useState<QueryObjCalendar>(
    {
    }
  );
  const [calendar, setCalendar] = React.useState<CalendarDrill[]>([]);
  const [showFilters, setShowFilters] = React.useState<boolean>(props.device === 'desktop');
  const [newDrill, setNewDrill] = React.useState<boolean>(false);
  const [choosenDrillAttendance, setChoosenDrillAttendance] = React.useState<number>(0);
  const [openDrill, setOpenDrill] = React.useState<CalendarDrill | null>(null);
  const [drillTab, setDrillTab] = React.useState<string>('present_clients');

  React.useEffect(() => {
    if (!calendarQuery.date_start || !calendarQuery.date_stop) { return }
    getTraffic();
  }, [calendarQuery]);

  React.useEffect(() => {
    if (accountInfo.account_info.id === 0 && accountInfo.logged_in) { return }
    if (accountInfo.account_info.type === 1) {
      if (calendarQuery.date_start && calendarQuery.date_stop && calendarQuery.coach === accountInfo.account_info.id) { return }
      setCalendarQuery(
       {
          date_start: firstday.toISOString().split('T')[0],
          date_stop: (props.device === 'desktop') ? lastday.toISOString().split('T')[0] : lastday.toISOString().split('T')[0],
          coach: accountInfo.account_info.id,
        }
      )
    } else {
      if (calendarQuery.date_start && calendarQuery.date_stop) { return }
      setCalendarQuery(
        {
          date_start: firstday.toISOString().split('T')[0],
          date_stop: (props.device === 'desktop') ? lastday.toISOString().split('T')[0] : lastday.toISOString().split('T')[0],
        }
      )
    }
  }, [accountInfo])

  React.useEffect(() => {
    getFilters();
  }, []);

  React.useEffect(() => {
    setShowFilters(props.device === 'desktop');
    if (calendarRef.current) {
      let calendar_obj: any = calendarRef.current;
      calendar_obj.getApi().changeView((props.device === 'desktop') ? 'dayGridWeek' : 'dayGridDay');
    }
  }, [props.device]);

  const getFilters = async () => {
    const api = new BaseApi(1, 'management/filters_info/');
    setFiltersSpinner(true);
    let res = await api.get({}, props.appSetState, props.appState);
    if (res.status === 200) {
      let _cus = [];
      for (let c of res.body.clubs) {
        _cus.push(...c.units.map((unit: ClubUnit) => {return {id: unit.id, name: unit.name}}));
      };
      res.body.club_units = _cus;
      setFilters(res.body);
    }
    setFiltersSpinner(false);
  };

  const getTraffic = async () => {
    const api = new BaseApi(1, 'management/traffic/');
    props.handleOpenSpinner(true);
    let res = await api.get(calendarQuery, props.appSetState, props.appState);
    if (res.status === 200) {
      setCalendar(res.body.drills);
    }
    props.handleCloseSpinner(false);
  }

  const manageDrill = async (drill: QueryObjCalendar, method: string) => {
    const api = new BaseApi(1, `management/traffic/${method}/`);
    setNewDrill(false);
    setChoosenDrillAttendance(0);
    setOpenDrill(null);
    props.handleOpenSpinner(true);
    let res = {status: 400, body: {}};
    res = await api.put(
      drill, 'application/json', props.appSetState, props.appState
    );
    if (res.status === 200) {
      await getTraffic();
    }
    props.handleCloseSpinner(false);
  }

  const manageSchedule = async (schedule: ScheduleDrillManage | QueryObjCalendar) => {
    const api = new BaseApi(1, 'management/schedule/');
    setNewDrill(false);
    setChoosenDrillAttendance(0);
    setOpenDrill(null);
    props.handleOpenSpinner(true);
    let res = {status: 400, body: {}};
    if (schedule.id) {
      res = await api.post(
        schedule, 'application/json', props.appSetState, props.appState
      );
    } else if (!schedule.id) {
      res = await api.put(
        schedule, 'application/json', props.appSetState, props.appState
      );
    }
    if (res.status === 200) {
      await getTraffic();
    }
    props.handleCloseSpinner(false);
  }

  const removeSchedule = async (drill: QueryObjCalendar) => {
    const api = new BaseApi(1, 'management/schedule/');
    setNewDrill(false);
    setChoosenDrillAttendance(0);
    setOpenDrill(null);
    props.handleOpenSpinner(true);
    let res = await api.delete(drill, props.appSetState, props.appState);
    if (res.status === 200) {
      await getTraffic();
    }
    props.handleCloseSpinner(false);
  }

  const CalendarDrillInfo = (event: any) => {
    return (
        <CardContent
          sx={{
            overflow: 'hidden',
          }}
        >
          {event.event.extendedProps.transfer_date
            ? <div
                style={{
                  position: 'absolute',
                  left: '0',
                  top: '0',
                  zIndex: '2',
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: 'transparent',
                }}
              >
                <div
                  style={{
                    padding: '0.25rem',
                    border: '5px dashed',
                    borderColor: 'var(--red)',
                    backgroundColor: '#ffffffa8',
                    width: '100%',
                    boxSizing: 'border-box',
                    transform: 'rotate(-30deg)',
                  }}
                >
                  <span
                    style={{
                      color: 'var(--red)',
                      fontSize: '1rem',
                      fontWeight: '600',
                      display: 'block',
                      textAlign: 'center',
                    }}
                  >
                    {t('replaced')}
                  </span>
                </div>
              </div>
            : ''
          }
          {event.event.extendedProps.deleted
            ? <div
                style={{
                  position: 'absolute',
                  left: '0',
                  top: '0',
                  zIndex: '2',
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: 'transparent',
                }}
              >
                <div
                  style={{
                    padding: '0.25rem',
                    border: '5px dashed',
                    borderColor: 'var(--red)',
                    backgroundColor: '#ffffffa8',
                    width: '100%',
                    boxSizing: 'border-box',
                    transform: 'rotate(-30deg)',
                  }}
                >
                  <span
                    style={{
                      color: 'var(--red)',
                      fontSize: '1rem',
                      fontWeight: '600',
                      display: 'block',
                      textAlign: 'center',
                    }}
                  >
                    {t('removed')}
                  </span>
                </div>
              </div>
            : ''
          }
          {event.event.extendedProps.substitute
            ? <div
                style={{
                  position: 'absolute',
                  left: '0',
                  top: '0',
                  zIndex: '2',
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: 'transparent',
                }}
              >
                <div
                  style={{
                    padding: '0.25rem',
                    border: '5px dashed',
                    borderColor: 'var(--green_transparent)',
                    backgroundColor: '#ffffffa8',
                    width: '100%',
                    boxSizing: 'border-box',
                    transform: 'rotate(-30deg)',
                  }}
                >
                  <span
                    style={{
                      color: 'var(--green_transparent)',
                      fontSize: '1rem',
                      fontWeight: '600',
                      display: 'block',
                      textAlign: 'center',
                    }}
                  >
                    {t('substitute')}
                  </span>
                </div>
              </div>
            : ''
          }
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: 14,
                height: 'fit-content',
                marginBottom: '0',
              }}
              color="text.secondary"
              gutterBottom
            >
              {event.event.extendedProps.drill.club.name}
            </Typography>
          </Box>
          {event.event.extendedProps.transfer_date
            ? <Typography
                variant="h6"
                component="div"
              >
                {`${event.event.extendedProps.transfer_time.drill_time_start.slice(0, 5)} - ${event.event.extendedProps.transfer_time.drill_time_stop.slice(0, 5)}`}
              </Typography>
            : <Typography
                variant="h6"
                component="div"
              >
                {`${event.event.extendedProps.drill.drill_time_start.slice(0, 5)} - ${event.event.extendedProps.drill.drill_time_stop.slice(0, 5)}`}
              </Typography>
          }
          <Typography
            variant="h6"
            component="div"
            sx={{ whiteSpace: 'pre-wrap' }}
          >
            {event.event.extendedProps.drill.discipline.name}
          </Typography>
          {!event.event.extendedProps.substitute
            ? <List
                dense={true}
                sx={{
                  paddingTop: '0',
                  paddingBottom: '0',
                  marginTop: '0',
                  marginBottom: '0',
                }}
              >
                {event.event.extendedProps.coaches.map((coach: UserInfoCommon) => {
                  return (
                  <ListItem
                    key={`drill-coach-${coach.id}`}
                    sx={{
                      paddingTop: '0',
                      paddingBottom: '0',
                      marginTop: '0',
                      marginBottom: '0',
                      padding: '0',
                    }}
                  >
                    <ListItemText
                      sx={{ mb: 1.5, marginBottom: '5px', overflow: 'hidden' }}
                      primary={`${coach.first_name} ${coach.last_name}`}
                    />
                  </ListItem>
                  )
                })}
              </List>
            : <Typography sx={{ overflow: 'hidden' }}>
                {`${event.event.extendedProps.substitute.first_name} ${event.event.extendedProps.substitute.last_name}`}
              </Typography>
          }
          <Typography
            variant="body2"
          >
            {`${t('clients_amount')}: ${event.event.extendedProps.clients_number} (${event.event.extendedProps.drill_type.name[0]})`}
          </Typography>
        </CardContent>
    )
  };
  
  return (
    <Box>
      <Box sx={{ marginBottom: '5px', paddingTop: '5px' }}>
        {props.device === 'mobile'
          ? <Box
              sx={{
                width: 'fit-content',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <Button
                variant="outlined"
                onClick={() => setShowFilters(!showFilters)}
              >
                {t('filters', { ns: 'filters' })}
              </Button>
            </Box>
          : ''
        }
        {filtersSpinner
          ? <Box
              sx={{
                width: 'fit-content',
                marginLeft: 'auto',
                marginRight: 'auto',
                paddingTop: '5px',
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
            : <Box>
                {showFilters
                  ? <Box
                      sx={{
                        display: (props.device === 'desktop') ? 'flex' : 'block',
                        justifyContent: 'space-evenly',
                        paddingTop: '5px',
                        width: (props.device === 'desktop') ? '90%' : '70%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    >
                      <Autocomplete
                        disablePortal
                        id="clients-club-filter-id"
                        options={
                          filters.clubs.map(
                            (club) => {return {id: club.id, label: club.name}}
                          )
                        }
                        onChange={
                          (
                            e: React.SyntheticEvent,
                            value: AutocompleteProps | null,
                          ) => {
                            if (value && value.id) {
                              setCalendarQuery({...calendarQuery, club: value.id});
                            } else {
                              delete calendarQuery.club;
                              setCalendarQuery(calendarQuery);
                            }
                          }
                        }
                        sx={{ width: (props.device === 'mobile') ? '100%' : '20%' }}
                        renderInput={
                          (params: any) => <TextField
                            {...params}
                            label={t("club", { ns: 'filters' })}
                          />
                        }
                      />
                      <Autocomplete
                        disablePortal
                        id="clients-club_unit-filter-id"
                        options={
                          filters.club_units.map(
                            (cu) => {return {id: cu.id, label: cu.name}}
                          )
                        }
                        onChange={
                          (
                            e: React.SyntheticEvent,
                            value: AutocompleteProps | null,
                          ) => {
                            if (value && value.id) {
                              setCalendarQuery({...calendarQuery, club_unit: value.id});
                            } else {
                              delete calendarQuery.club_unit;
                              setCalendarQuery(calendarQuery);
                            }
                          }
                        }
                        sx={{ width: (props.device === 'mobile') ? '100%' : '20%' }}
                        renderInput={
                          (params: any) => <TextField
                            {...params}
                            label={t("club_unit", { ns: 'filters' })}
                          />
                        }
                      />
                      <Autocomplete
                        disablePortal
                        id="clients-coaches-filter-id"
                        options={
                          filters.coaches.map(
                            (coach) => {return {id: coach.id, label: `${coach.first_name} ${coach.last_name}`}}
                          )
                        }
                        onChange={
                          (
                            e: React.SyntheticEvent,
                            value: AutocompleteProps | null,
                          ) => {
                            if (value && value.id) {
                              setCalendarQuery({...calendarQuery, coach: value.id});
                            } else {
                              delete calendarQuery.coach;
                              setCalendarQuery(calendarQuery);
                            }
                          }
                        }
                        sx={{ width: (props.device === 'mobile') ? '100%' : '20%' }}
                        renderInput={
                          (params: any) => <TextField
                            {...params}
                            label={t("coach", { ns: 'filters' })}
                          />
                        }
                      />
                      <Autocomplete
                        disablePortal
                        id="clients-employee_clients-filter-id"
                        options={
                          filters.drill_types.map((dt) => {
                            return {id: dt.id, label: dt.name}
                          })
                        }
                        onChange={
                          (
                            e: React.SyntheticEvent,
                            value: AutocompleteProps | null,
                          ) => {
                            if (value && value.id) {
                              setCalendarQuery({...calendarQuery, drill_type: value.id});
                            } else {
                              delete calendarQuery.drill_type;
                              setCalendarQuery(calendarQuery);
                            }
                          }
                        }
                        sx={{ width: (props.device === 'mobile') ? '100%' : '20%' }}
                        renderInput={
                          (params: any) => <TextField
                            {...params}
                            label={t("drill_type", { ns: 'filters' })}
                          />
                        }
                      />
                    </Box>
                  : ''
                }
              </Box>
        }
      </Box>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          start: 'prev',
          center: '',
          end: 'next',
        }}
        buttonText={{
          today: "current",
          month: "month",
          week: "week",
          day: "day",
          list: "list"
        }}
        locales={allLocales}
        locale={props.appState.account_info.language || 'en'}
        initialView={(props.device === 'desktop') ? 'dayGridWeek' : 'dayGridDay'}
        dayHeaderFormat={{ weekday: 'short', month: 'short', day: 'numeric' }}
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={true}
        events={
          calendar.map((day_drill) => {
          return {
            ...day_drill,
            id: day_drill.id.toString(),
            title: day_drill.drill.discipline.name,
            start: (day_drill.transfer_date) ? `${day_drill.transfer_date}T${day_drill.transfer_time.drill_time_start}` : `${day_drill.drill_date}T${day_drill.drill.drill_time_start}`,
            end: (day_drill.transfer_date) ? `${day_drill.transfer_date}T${day_drill.transfer_time.drill_time_stop}` : `${day_drill.drill_date}T${day_drill.drill.drill_time_stop}`,
            textColor: "black",
            club: day_drill.drill,
          }
        })}
        select={(e) => {console.log("event")}}
        eventContent={(e) => CalendarDrillInfo(e)}
        eventDisplay="block"
        eventClick={(e) => {
          if (e.event.extendedProps.deleted) {
            return
          }
          setChoosenDrillAttendance(Number(e.event.id));
          setOpenDrill(
            {
              client_arrive: e.event.extendedProps.client_arrive,
              clients_number: e.event.extendedProps.clients_number,
              coaches: e.event.extendedProps.coaches,
              completed: e.event.extendedProps.completed,
              deleted: e.event.extendedProps.deleted,
              drill: e.event.extendedProps.drill,
              drill_date: e.event.extendedProps.drill_date,
              drill_type: e.event.extendedProps.drill_type,
              id: Number(e.event.id),
              substitute: e.event.extendedProps.substitute,
              substitute_date: e.event.extendedProps.substitute_date,
              transfer_date: e.event.extendedProps.transfer_date,
              transfer_time: e.event.extendedProps.transfer_time,
            } as CalendarDrill
          );
        }}
        eventsSet={() => console.log("eventsSet")}
        firstDay={0}
        datesSet={
          (e) => {
            if (!calendarQuery.date_start || !calendarQuery.date_stop) { return }
            let _date_start = e.start;
            let _date_stop = e.end;
            _date_start.setDate(_date_start.getDate() + 1);
            if (
              calendarQuery.date_start !== _date_start.toISOString().split('T')[0] ||
              calendarQuery.date_stop !== _date_stop.toISOString().split('T')[0]
            ) {
              setCalendarQuery(
                {
                  ...calendarQuery,
                  date_start: _date_start.toISOString().split('T')[0],
                  date_stop: _date_stop.toISOString().split('T')[0],
                }
              );
            }
          }
        }
        eventDrop={(e) => {console.log("eventDrop")}}
        eventResize={(e) => {console.log("eventResize")}}
        dateClick={(e) => {console.log("dateClick")}}
        eventAdd={(e) => {
          console.log("eventAdd", e);
        }}
        eventChange={(e) => {
          console.log("eventChange", e);
        }}
        eventRemove={(e) => {
          console.log("eventRemove", e);
        }}
        eventMinHeight={ 200 }
      />
      <Zoom
        key="new-client-key"
        in={true}
      >
        <Fab sx={
            {
              position: 'absolute',
              bottom: 36,
              left: 36,
              borderColor: root_theme.palette.info.main,
              borderWidth: '2px',
              borderStyle: 'solid',
              '&:hover': {
                bgcolor: root_theme.palette.background.default,
                borderColor: root_theme.palette.info.main,
              },
            } as SxProps
          }
          aria-label="Add client"
          color='info'
          onClick={(e) => {setNewDrill(true)}}
        >
          <AddIcon />
        </Fab>
      </Zoom>
      <BootstrapDialog
        onClose={() => setNewDrill(false)}
        open={newDrill}
      >
        <DialogTitle sx={{ paddingLeft: '5px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontWeight: '600', fontSize: '1.25em' }}>
              {t('calendar.new_drill', { ns: 'forms' })}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent
          dividers
          className="box-content-center"
          sx={{ width: '80%' }}
        >
          <DrillManage
            appState={props.appState}
            appSetState={props.appSetState}
            openPopUp={props.openPopUp}
            device={props.device}
            closeEdit={() => setNewDrill(false)}
            applyEdit={manageSchedule}
          />
        </DialogContent>
      </BootstrapDialog>
      <BootstrapDialog
        onClose={() => {
          setChoosenDrillAttendance(0);
          setOpenDrill(null);
        }}
        open={choosenDrillAttendance !== 0 && openDrill !== null && choosenDrillAttendance === openDrill.id}
        fullScreen
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar sx={{ backgroundColor: 'var(--black)' }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => {
                setChoosenDrillAttendance(0);
                setOpenDrill(null);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {t('present_clients')}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent
          dividers
          className="box-content-center"
          sx={{ width: '80%' }}
        >
          <TabContext value={drillTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={(e: React.SyntheticEvent, value: string) => {
                  setDrillTab(value);
                }}
                aria-label="Drill manage"
              >
                <Tab label={t('present_clients')} value="present_clients" />
                {accountInfo.account_info.permissions.includes('manage_calendar')
                  ? <Tab label={t('edit')} value="edit" />
                  : ''
                }
                {accountInfo.account_info.permissions.includes('manage_calendar')
                  ? <Tab label={t('replace')} value="replace" />
                  : ''
                }
                {accountInfo.account_info.permissions.includes('manage_calendar')
                  ? <Tab label={t('cancel')} value="cancel" />
                  : ''
                }
                {accountInfo.account_info.permissions.includes('manage_calendar')
                  ? <Tab label={t('substitute')} value="substitute" />
                  : ''
                }
                {accountInfo.account_info.permissions.includes('manage_calendar')
                  ? <Tab label={t('remove')} value="remove" />
                  : ''
                }
              </TabList>
            </Box>
            <TabPanel value="present_clients">
              <DrillAttendance
                appState={props.appState}
                appSetState={props.appSetState}
                device={props.device}
                drillId={choosenDrillAttendance}
                showDrill={openDrill}
              />
            </TabPanel>
            {accountInfo.account_info.permissions.includes('manage_calendar')
              ? <TabPanel value="edit">
                  <DrillManage
                    appState={props.appState}
                    appSetState={props.appSetState}
                    openPopUp={props.openPopUp}
                    device={props.device}
                    closeEdit={() => {
                      setChoosenDrillAttendance(0);
                      setOpenDrill(null);
                    }}
                    applyEdit={manageSchedule}
                    currentDrill={openDrill}
                  />
                </TabPanel>
              : ''
            }
            {accountInfo.account_info.permissions.includes('manage_calendar')
              ? <TabPanel value="replace">
                  <ReplaceDrill
                    appState={props.appState}
                    appSetState={props.appSetState}
                    openPopUp={props.openPopUp}
                    device={props.device}
                    closeEdit={() => {
                      setChoosenDrillAttendance(0);
                      setOpenDrill(null);
                    }}
                    applyEdit={manageDrill}
                    currentDrill={openDrill}
                  />
                </TabPanel>
              : ''
            }
            {accountInfo.account_info.permissions.includes('manage_calendar')
              ? <TabPanel value="cancel">
                  <CancelDrill
                    appState={props.appState}
                    appSetState={props.appSetState}
                    openPopUp={props.openPopUp}
                    device={props.device}
                    closeEdit={() => {
                      setChoosenDrillAttendance(0);
                      setOpenDrill(null);
                    }}
                    applyEdit={
                      (body: CustomObj) => manageDrill(body.body as QueryObjCalendar, body.method)
                    }
                    currentDrill={openDrill}
                    messageKey={'accept_cancel_drill'}
                  />
                </TabPanel>
              : ''
            }
            {accountInfo.account_info.permissions.includes('manage_calendar')
              ? <TabPanel value="substitute">
                  <SubstituteDrill
                    appState={props.appState}
                    appSetState={props.appSetState}
                    openPopUp={props.openPopUp}
                    device={props.device}
                    closeEdit={() => {
                      setChoosenDrillAttendance(0);
                      setOpenDrill(null);
                    }}
                    applyEdit={manageDrill}
                    currentDrill={openDrill}
                  />
                </TabPanel>
              : ''
            }
            {accountInfo.account_info.permissions.includes('manage_calendar')
              ? <TabPanel value="remove">
                  <CancelDrill
                    appState={props.appState}
                    appSetState={props.appSetState}
                    openPopUp={props.openPopUp}
                    device={props.device}
                    closeEdit={() => {
                      setChoosenDrillAttendance(0);
                      setOpenDrill(null);
                    }}
                    applyEdit={
                      (body: CustomObj) => removeSchedule(body.query as QueryObjCalendar)
                    }
                    currentDrill={openDrill}
                    messageKey={'accept_remove_drill'}
                  />
                </TabPanel>
              : ''
            }
          </TabContext>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  )
}
