import React from "react";

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';

import { theme as root_theme } from "../../consts";

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    backgroundColor: root_theme.palette.background.default,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: root_theme.palette.info.main,
  },
}));

export const BootstrapDialogWidth = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    width: '100%',
    maxWidth: '900px',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    backgroundColor: root_theme.palette.background.default,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: root_theme.palette.info.main,
    maxWidth: '1000px',
  },
  '& .MuiDialog-paper': {
    minWidth: '70%',
    margin: '10px',
  },
}));

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
