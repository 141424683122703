import { atom, selector } from 'recoil'

import { BaseApi } from '../api/base';

import { CoachInfo } from '../interfaces/Coach';

export interface ICoachesState {
  items: CoachInfo[]
}

export const coachesSelector = selector({
    key: 'coachesSelector',
    get: async (): Promise<ICoachesState> => {
      const api = new BaseApi(1, 'management/get_coaches/');
      let res = await api.get(
        {limit: 1000, offset: 0},
        () => {},
        {},
      );
      if (res.status === 200) {
        return { items: res.body.results };
      };
      return { items: [] };
    },
    set: ({ set, get }, newData) => {
      const currentState = get(coachesAtom);
      let newState = [ ...currentState.items ];
      if (newData.items) {
        newState = [...currentState.items, ...newData.items as CoachInfo[]];
      }
      set(coachesAtom, {items: newState});
    },
});

export const coachesAtom = atom<ICoachesState>({
  key: 'coaches',
  default: coachesSelector,
})
