import React, { useEffect } from 'react';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import { useTranslation } from 'react-i18next';

import { BoxOfficeAmountProps } from '../../interfaces/Props';

import { theme as root_theme } from "../../consts";

import { BaseApi } from "../../api/base";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    paddingTop: '15px!important',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    backgroundColor: root_theme.palette.background.default,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: root_theme.palette.info.main,
  }
}));

export function BoxOfficeAmount(props: BoxOfficeAmountProps) {
  const { t } = useTranslation('common');
  const [amount, setAmount] = React.useState<number | null>(null);

  const handlePlaceBoxOfficeAmount = async () => {
    if (amount === null) { return }
    const api = new BaseApi(1, 'management/box_office/');
    await api.post(
      {amount: amount},
      'application/json',
      props.appSetState,
      props.appState,
    );
    props.handleClose();
  }

  return (
    <BootstrapDialog
      open={props.show}
      onClose={(e) => { props.handleClose() }}
    >
      <DialogTitle>
        {t('messages.box_office_amount')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <TextField
            required
            id="box-office-amount"
            label={t("amount")}
            defaultValue="0.0"
            type="number"
            onChange={(e) => {setAmount(parseFloat(e.target.value))}}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="error"
          onClick={(e) => {props.handleClose()}}
        >
          <CloseIcon />
        </Button>
        <Button
          variant="contained"
          color="info"
          onClick={
            (e) => {
              handlePlaceBoxOfficeAmount();
            }
          }
        >
          <CheckIcon />
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
