import React from 'react';

import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';

import useWebSocket from 'react-use-websocket';
import { useNavigate } from "react-router-dom";

import { useState, useEffect, FormEvent } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import './App.css';
import './toolbox.css';
import './clients.css';

import { I18nextProvider } from 'react-i18next';

import { Auth } from './components/auth/Auth';
import { Clients } from './components/users/Clients';
import { Error } from './components/error/Error';
import { Toolbox } from './components/toolbox/Toolbox';
import { BoxOfficeAmount } from './components/toolbox/BoxOffice';
import { PopUp } from './components/common/PopUp';
import { Chat } from './components/chat/Chat';
import { ProfilePage } from './components/users/Profile';
import { Calendar } from './components/calendar/Calendar';
import { StatisticPage } from './components/statistic/StatisticPage';
import { BidsPage } from './components/bids/Bids';
import { NotificationsList } from './components/notifications/NotificationsPage';
import { Coaches } from './components/users/Coaches';
import { ClubUnitsSelector } from './components/common/ClubUnitsSelect';
import { ApproveAction } from './components/common/ActionApprove';
import { ServiceItems } from './components/products/ServiceItemsPage';
import { ClubPage } from './components/club/ClubPage';
import { TimeShiftCalendar } from './components/time_shifting/TimeShiftCalendar';
import { TicketChangeRequestsPage } from './components/edit_requests/TicketChangeRequests';
import { CallbackContext } from './components/Context';

import i18n from './i18n';

import { BaseApi } from "./api/base";

import { theme as root_theme } from './consts';

import { AccountInfo, IChatAcountInfo } from './interfaces/AccountInfo';
import { Department, ClubUnit } from './interfaces/Club';
import { NotificationObject } from './interfaces/Notification';
import { IChatMessage } from './interfaces/Socket';
import { ShiftInterface } from './interfaces/Common';
import { BodyObj } from './interfaces/Types';
import { IComment, ICommentUser } from './interfaces/Comments';

import { accountInfoAtom, accountInfoSelector } from './atoms/AccountInfo';
import { departmentsAtom } from './atoms/Departments';
import { popUpAtomSelect, popUpAtom } from './atoms/PopUp';
import { spinnerAtom } from './atoms/Spinner';
import { languageAtom } from './atoms/Language';
import { deviceAtom } from './atoms/Device';

type SettingItem = { [key: string]: any };

function App() {
  let navigate = useNavigate();
  let query = new URLSearchParams(window.location.search);
  let sent_token = query.get('access_token');
  if (sent_token) {
    localStorage.setItem('access_token', sent_token);
  }
  
  const WS_URL = new URL(`${process.env.REACT_APP_BACKEND_SOCKET}/api/chat/ws`);
  WS_URL.searchParams.set('token', localStorage.getItem('access_token') || '');
  const [device, setDevice] = useRecoilState(deviceAtom);
  const [state, setState] = useRecoilState(accountInfoAtom);
  const [language, setLanguage] = useRecoilState(languageAtom);
  const [open, setOpen] = useRecoilState(spinnerAtom);
  const [popUp, setPopUp] = useRecoilState(popUpAtom);
  const [popUpTitle, setPopUpTitle] = React.useState<string>('');
  const [popUpMessage, setPopUpMessage] = React.useState<string>('');
  const [popUpMessageTranslatable, setPopUpMessageTranslatable] = React.useState<boolean>(true);
  const [departments, setDepartments] = useRecoilState(departmentsAtom);
  const [notifications, setNotifications] = useState<NotificationObject[]>([]);
  const [unreadChatMessages, setUnreadChatMessages] = useState<IComment[]>([]);
  const [unreadChatsAmount, setUnreadChatsAmount] = useState<number>(0);
  const [chatMessagesUsers, setChatMessagesUsers] = useState<ICommentUser[]>([]);
  const [showChat, setShowChat] = useState<boolean>(false);
  const [chatLimit, setChatLimit] = useState<number>(20);
  const [chatOffset, setChatOffset] = useState<number>(0);
  const [accessedClubUnits, setAccessedClubUnits] = useState<ClubUnit[]>([]);
  const [currentShift, setCurrentShift] = useState<ShiftInterface | null>(null);
  const [workingTime, setWorkingTime] = useState<string>('');
  const [boxOfficeAmountShow, setBoxOfficeAmountShow] = useState<boolean>(false);
  const [chooseShiftClubUnit, setChooseShiftClubUnit] = useState<boolean>(false);
  const [showMotivatingMessage, setShowMotivatingMessage] = useState<boolean>(false);
  const [motivatingMessageNumber, setMotivatingMessageNumber] = useState<number>(0);
  const [firstRender, setFirstRender] = useState<boolean>(true);

  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(WS_URL.href, {
    onOpen: (event: WebSocketEventMap['open']) => {
      if (state.logged_in) {
        const sock: any = event?.currentTarget;
        if (!sock) { return };
        sock.send(
          JSON.stringify(
            {
              id: null,
              message: null,
              destination_id: null,
              message_type: 'check',
            }
          )
        );
      }
    },
    onMessage: (event: WebSocketEventMap['message']) => {
      let data = JSON.parse(event.data)
      let message: string = '';
      if (data && data.constructor === Object) {
        if (data.message_type === "chat" && data.author_id !== 0) {
          setUnreadChatMessages([...unreadChatMessages, data]);
          message = 'new_comment';
        }
        else if (data.message_type === "event") {
          if (data["event_type"] === "shift_end") {
            setCurrentShift(null);
          }
          message = data.event_message;
        }
        if (message !== '') {
          handleOpenPopUp('warning', message);
        }
      }
      if (!data) data = [];
    },
  });

  const getChatMessages = async () => {
    const api = new BaseApi(1, 'management/chat/messages/');
    let res = await api.get(
      {
        limit: chatLimit,
        offset: chatOffset,
        target_type: -1,
        only_mentioned: (state.account_info.permissions.includes('get_all_new_messages_notifications')) ? 0 : 1,
      },
      apiCallback,
      state,
    );
    if (res.status !== 200) { return };
    setUnreadChatMessages(res.body.messages);
    setChatMessagesUsers(res.body.users);
  }

  const updateState = (key: string, value: any) => {
    if (key === 'logged_in' && value) {
      getAccInfo()
    }
    setState({ ...state, [key]: value });
  }
  
  const handleCloseSpinner = () => {
    setOpen({ open: false });
  };
  const handleOpenSpinner = () => {
    setOpen({ open: true });
  };

  const handleOpenPopUp = (title: string, message: string, translatable: boolean = true) => {
    setPopUp(
      {
        title: title,
        message: message,
        non_translatable: true,
        show: true,
      }
    );
    setTimeout(handleClosePopUp, 3000);
  }
  const handleClosePopUp = () => {
    setPopUp(
      {
        title: '',
        message: '',
        non_translatable: false,
        show: false,
      }
    );
  }

  const handleContinueShifting = async () => {
    if (!currentShift) { return };
    setOpen({ open: true });
    const api = new BaseApi(1, 'management/manager/shift/');
    let res = await api.put({ shift: currentShift.id, active: true }, 'application/json', apiCallback, state);
    if (res.status === 200) {
      setCurrentShift(res.body as ShiftInterface);
    }
    setShowMotivatingMessage(false);
    setOpen({ open: false });
  };
  const handleShiftStart = async (club_unit: number) => {
    setOpen({ open: true });
    const api = new BaseApi(1, 'management/manager/shift/');
    let res = await api.post(
      { shift_start: (new Date()).toISOString(), club_unit: club_unit },
      'application/json',
      apiCallback,
      state,
    );
    if (res.status === 200) {
      setCurrentShift(res.body as ShiftInterface);
    }
    setChooseShiftClubUnit(false);
    setOpen({ open: false });
  };
  const handleShiftStop = async () => {
    setOpen({ open: true });
    const api = new BaseApi(1, 'management/manager/shift/');
    let res = await api.put(
      { shift_end: (new Date()).toISOString(), shift: currentShift?.id },
      'application/json',
      apiCallback,
      state,
    );
    if (res.status === 200) {
      setCurrentShift(null);
    }
    setOpen({ open: false });
  };
  const handleShiftStartStop = () => {
    if (currentShift) {
      handleShiftStop();
    } else {
      setChooseShiftClubUnit(true);
    }
  }

  const getNotifications = async () => {
    const api = new BaseApi(1, 'management/notifications/');
    let res = await api.get({}, apiCallback, state);
    if (res.status === 200) {
      setNotifications(
        res.body.results.map(
          (noti: any) => {return {
            id: noti.id,
            noty_type: noti.noty_type,
            noty_type_str: noti.noty_type_str,
            notify_date: noti.notify_date,
            deleted: noti.deleted,
            closed: noti.closed,
            sketches: noti.sketches,
            message: noti.message,
            client: noti.client,
            author: noti.author,
            bid: noti.bid,
            traffic: noti.traffic?.id,
          }}
        )
      );
    }
  }

  const getManagerShifts = async () => {
    const api = new BaseApi(1, 'management/manager/shift/');
    let res = await api.get({ current: true }, apiCallback, state);
    if (Object.keys(res.body).length) {
      setCurrentShift(res.body as ShiftInterface);
    }
  }

  const getClubDepartments = async () => {
    // await GetDepartments();
    const api = new BaseApi(1, 'management/club/department/');
    let res = await api.get({}, apiCallback, state);
    if (res.status === 200) {
      setDepartments({items: res.body});
    }
  }

  async function getAccInfo() {
    setState({...state, logged_in: true})
    const api = new BaseApi(1, 'user/account/');
    setOpen({ open: true });
    let res = await api.get({}, apiCallback, state)
    if (res.status === 401) {
      logOut();
      return
    }
    let query = new URLSearchParams(window.location.search);
    let sent_token = query.get('access_token');
    if (sent_token) {
      navigate(window.location.pathname);
    }
    if (res.status !== 200) { return }

    let _club_units: ClubUnit[] = [];
    for (let c of res.body.clubs) {
      for (let cu of c.units) {
        _club_units.push({ id: cu.id, name: cu.name } as ClubUnit);
      }
    }
    setAccessedClubUnits(_club_units);
    setState(
      {
        ...state,
        logged_in: (res.status === 200) ? true : false,
        account_info: res.body as AccountInfo,
      }
    );
    let user_lang = getLanguage(res.body.user_settings);
    if (user_lang === '' && res.body.clubs.length) {
      user_lang = getLanguage(res.body.clubs[0].settings);
    }
    if (user_lang !== '') {
      setLanguage(user_lang);
    };
    let user_type: number = Number(res.body.type);
    setOpen({ open: false });
  }

  useEffect(() => {
    if (!state.logged_in) {
      if (localStorage.getItem("access_token")) {
        updateState('logged_in', true);
      }
    }
    if (!state.logged_in || !state.account_info.id || !firstRender) { return }
    setOpen({ open: true });
    getNotifications();
    if (
      state.account_info.permissions.includes('start_stop_managers_shifts') &&
      !currentShift
    ) {
      getManagerShifts();
    }
    getClubDepartments();
    if (!unreadChatMessages.length) {
      getChatMessages();
    }
    setFirstRender(false);
    setOpen({ open: false });
  }, [state]);

  useEffect(() => {
    let _targets_arr: number[] = [];
    for (let mes of unreadChatMessages) {
      if (!_targets_arr.includes(mes.target_id)) {
        _targets_arr.push(mes.target_id);
      }
    }
    setUnreadChatsAmount(_targets_arr.length);
  }, [unreadChatMessages]);

  const logOut = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    setState(
      {
        ...state,
        logged_in: false,
        account_info: {
          id: 0,
          first_name: '',
          last_name: '',
          patronymic: '',
          clubs: [],
          type: 0,
          permissions: [],
        } as AccountInfo,
      }
    );
    setDepartments({ items: [] });
  }
  
  const signIn = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const json_data = Object.fromEntries(data);
  
    const api = new BaseApi(1, 'token/');
    api.post(json_data, 'application/json').then(res => {
      if (res.status !== 200) {
        handleOpenPopUp('error', 'wrong_credentials');
        return
      }
      localStorage.setItem("access_token", res.body.access);
      localStorage.setItem("refresh_token", res.body.refresh);

      getAccInfo();
    });
  }
  
  const signOut = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    logOut();
  }

  const countWorkingTime = () => {
    if (!currentShift) { return '00:00:00' };
    let shift_last_action: Date = new Date(currentShift.last_action);
    let now: Date = new Date();
    let difference: number = Number(now) - Number(shift_last_action);

    let hours: number = parseInt(String(difference / 1000 / 60 / 60));
    let hours_str: string = String(hours);
    if (hours_str.length < 2) { hours_str = `0${hours}` }
    
    let mins: number = parseInt(String(difference / 1000 / 60 - hours * 60));
    let mins_str: string = String(mins);
    if (mins_str.length < 2) { mins_str = `0${mins}` }
    
    let sec: number = parseInt(String(difference / 1000 - hours * 60 * 60 - mins * 60));
    let sec_str: string = String(sec);
    if (sec_str.length < 2) { sec_str = `0${sec}` }

    return `${hours_str}:${mins_str}:${sec_str}`;
  };

  useEffect(() => {
    let _show_motivating = showMotivatingMessage;
    const interval = setInterval(() => {
      if (!currentShift) { return }
      if (
        Number(new Date()) - Number(new Date(currentShift.last_action)) > 60 * 60 * 1000 &&
        !_show_motivating
      ) {
        setMotivatingMessageNumber(Math.floor(Math.random() * 8));
        setShowMotivatingMessage(true);
        _show_motivating = true;
      }
      setWorkingTime(countWorkingTime());
    }, 1000);
    return () => clearInterval(interval);
  }, [currentShift]);

  useEffect(() => {
    if (window.screen.width < 1024) {
      setDevice({ item: 'mobile' });
    }
    if (state.logged_in) {
      getAccInfo();
    };
  }, []);

  useEffect(() => {
    i18n.changeLanguage(language);
    setState(
      {
        ...state,
        account_info: {
          ...state.account_info,
          language: language,
        }
      }
    );
  }, [language]);

  const getSetting = (name: string, settings_arr: SettingItem[]) => {
    var value = ""
    for (let s of settings_arr) {
      if (s.name === name) {
        value = s.value
        break
      }
    }
    return value
  };

  const getLanguage = (settings: SettingItem[]) => {
    return getSetting('language', settings);
  };

  const apiCallback = (
    state: any,
    pop_up_title: string,
    pop_up_message: BodyObj,
    pop_up_show: boolean = false,
  ) => {
    setState(state);
    if ( pop_up_show ) {
      handleOpenPopUp(pop_up_title, pop_up_message.toString(), false)
    }
  };
  
  return (
    <ThemeProvider theme={root_theme}>
      <CallbackContext.Provider value={apiCallback}>
        {!state.logged_in
          ? <I18nextProvider i18n={i18n}>
                <Box className="main-background">
                  <Box
                    sx={
                      {
                        backgroundColor: 'var(--grey)',
                        border: '1px solid var(--orange)',
                        width: '40%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: '10vh',
                        padding: '10px',
                      }
                    }
                  >
                    <Auth
                      signIn={signIn}
                    />
                  </Box>
                </Box>
                {popUp.show
                  ? <PopUp
                      title={popUp.title}
                      message={popUp.message}
                    />
                  : ''
                }
              </I18nextProvider>
            :<I18nextProvider i18n={i18n}>
              <Box className="main-background">
                <Toolbox
                  signIn={signIn}
                  signOut={signOut}
                  account_info={state.account_info}
                  notifications={notifications}
                  unreadChatsAmount={unreadChatsAmount}
                  appState={state}
                  appSetState={apiCallback}
                  device={device.item}
                  openChat={() => {setShowChat(!showChat)}}
                  showBoxOffice={() => {setBoxOfficeAmountShow(true)}}
                  currentShift={currentShift}
                  currentWorkingTime={workingTime}
                  handleShiftControl={handleShiftStartStop}
                />
                <Box className="mainbox-wrapper">
                  <Routes>
                    {state.account_info.permissions.includes('get_clients_list')
                      ? <Route
                          path='/clients'
                          element={
                            <Clients
                              handleCloseSpinner={handleCloseSpinner}
                              handleOpenSpinner={handleOpenSpinner}
                              openPopUp={handleOpenPopUp}
                              departments={departments.items}
                              device={device.item}
                              appState={state}
                              appSetState={apiCallback}
                              sendSocketMessage={sendMessage}
                            />
                          }
                        />
                      : ''
                    }
                    <Route
                      path='/calendar'
                      element={
                        <Calendar
                          handleCloseSpinner={handleCloseSpinner}
                          handleOpenSpinner={handleOpenSpinner}
                          openPopUp={handleOpenPopUp}
                          device={device.item}
                          appState={state}
                          appSetState={apiCallback}
                        />
                      }
                    />
                    <Route
                      path='/profile'
                      element={
                        <ProfilePage
                          appState={state}
                          appSetState={apiCallback}
                          handleCloseSpinner={handleCloseSpinner}
                          handleOpenSpinner={handleOpenSpinner}
                          openPopUp={handleOpenPopUp}
                          tabName={'AccountInfoFormTab'}
                          device={device.item}
                        />
                      }
                    />
                    {[
                      'get_statistic',
                      'get_coach_statistic',
                      'get_tickets_statistic',
                      'get_bids_statistic',
                      'get_purchase_statistic',
                      'get_box_office_statistic',
                      'get_managers_statistic',
                      'get_new_ticket_type_statistic',
                    ].filter(p => state.account_info.permissions.includes(p)).length
                      ? <Route
                          path='/statistic'
                          element={
                            <StatisticPage
                              handleCloseSpinner={handleCloseSpinner}
                              handleOpenSpinner={handleOpenSpinner}
                              openPopUp={handleOpenPopUp}
                              departments={departments.items}
                              device={device.item}
                              appState={state}
                              appSetState={apiCallback}
                              sendSocketMessage={sendMessage}
                            />
                          }
                        />
                      : ''
                    }
                    {state.account_info.permissions.includes('view_bid')
                      ? <Route
                          path='/bids'
                          element={
                            <BidsPage
                              device={device.item}
                              appState={state}
                              appSetState={apiCallback}
                              departments={departments.items}
                              handleCloseSpinner={handleCloseSpinner}
                              handleOpenSpinner={handleOpenSpinner}
                              openPopUp={handleOpenPopUp}
                              sendSocketMessage={sendMessage}
                            />
                          }
                        />
                      : ''
                    }
                    {state.account_info.permissions.includes('view_notification')
                      ? <Route
                          path='/notifications'
                          element={
                            <NotificationsList
                              device={device.item}
                              appState={state}
                              appSetState={apiCallback}
                              handleCloseSpinner={handleCloseSpinner}
                              handleOpenSpinner={handleOpenSpinner}
                              openPopUp={handleOpenPopUp}
                              departments={departments.items}
                              sendSocketMessage={sendMessage}
                            />
                          }
                        />
                      : ''
                    }
                    {state.account_info.permissions.includes('get_coaches_list')
                      ? <Route
                          path='/coaches'
                          element={
                            <Coaches
                              handleCloseSpinner={handleCloseSpinner}
                              handleOpenSpinner={handleOpenSpinner}
                              openPopUp={handleOpenPopUp}
                              departments={departments.items}
                              device={device.item}
                              appState={state}
                              appSetState={apiCallback}
                              sendSocketMessage={sendMessage}
                            />
                          }
                        />
                      : ''
                    }
                    {state.account_info.permissions.includes('get_service_and_items')
                      ? <Route
                          path='/service_items'
                          element={
                            <ServiceItems
                              handleCloseSpinner={handleCloseSpinner}
                              handleOpenSpinner={handleOpenSpinner}
                              openPopUp={handleOpenPopUp}
                              device={device.item}
                              appState={state}
                              appSetState={apiCallback}
                              clubUnits={accessedClubUnits}
                              sendSocketMessage={sendMessage}
                            />
                          }
                        />
                      : ''
                    }
                    {state.account_info.permissions.includes('view_club')
                      ? <Route
                          path='/club'
                          element={
                            <ClubPage
                              handleCloseSpinner={handleCloseSpinner}
                              handleOpenSpinner={handleOpenSpinner}
                              openPopUp={handleOpenPopUp}
                              device={device.item}
                              appState={state}
                              appSetState={apiCallback}
                              sendSocketMessage={sendMessage}
                            />
                          }
                        />
                      : ''
                    }
                    {state.account_info.permissions.includes('change_managershift')
                      ? <Route
                          path='/time_shifting'
                          element={
                            <TimeShiftCalendar
                              handleCloseSpinner={handleCloseSpinner}
                              handleOpenSpinner={handleOpenSpinner}
                              openPopUp={handleOpenPopUp}
                              device={device.item}
                              appState={state}
                              appSetState={apiCallback}
                              sendSocketMessage={sendMessage}
                            />
                          }
                        />
                      : ''
                    }
                    {state.account_info.permissions.includes('view_userticketchange')
                      ? <Route
                          path='/ticket_edit_request'
                          element={
                            <TicketChangeRequestsPage
                              handleCloseSpinner={handleCloseSpinner}
                              handleOpenSpinner={handleOpenSpinner}
                              openPopUp={handleOpenPopUp}
                              device={device.item}
                              appState={state}
                              appSetState={apiCallback}
                              sendSocketMessage={sendMessage}
                            />
                          }
                        />
                      : ''
                    }
                    <Route path='/*' element={<Error />} />
                    <Route
                      path='/'
                      element={
                        <ProfilePage
                          appState={state}
                          appSetState={apiCallback}
                          handleCloseSpinner={handleCloseSpinner}
                          handleOpenSpinner={handleOpenSpinner}
                          openPopUp={handleOpenPopUp}
                          tabName={'AccountInfoFormTab'}
                          device={device.item}
                        />
                      }
                    />
                  </Routes>
                </Box>
              </Box>
              <Backdrop
                sx={{
                  color: root_theme.palette.info.main,
                  zIndex: 1500,
                }}
                open={Boolean(open.open)}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              {popUp.show
                ? <PopUp
                    title={popUp.title}
                    message={popUp.message}
                    non_translatable={popUp.non_translatable}
                  />
                : ''
              }
              <Chat
                show={showChat}
                closeChat={() => {setShowChat(false)}}
                messages={unreadChatMessages}
                users={chatMessagesUsers}
                appState={state}
                appSetState={apiCallback}
                sendSocketMessage={sendMessage}
                device={device.item}
                substractUnreadChats={() => {setUnreadChatsAmount((unreadChatsAmount - 1 > 0) ? unreadChatsAmount - 1 : 0)}}
                departments={departments.items}
              />
              <BoxOfficeAmount
                show={boxOfficeAmountShow}
                handleClose={() => {setBoxOfficeAmountShow(false)}}
                appState={state}
                appSetState={apiCallback}
                device={device.item}
              />
              <ClubUnitsSelector
                clubs={state.account_info.clubs}
                showDialog={chooseShiftClubUnit}
                selectClubUnit={handleShiftStart}
                closeSelector={() => {setChooseShiftClubUnit(false)}}
              />
              <ApproveAction
                handleApprove={handleContinueShifting}
                handleDecline={() => {}}
                action={`motivating_message_${motivatingMessageNumber}`}
                show={Number(showMotivatingMessage)}
                obj_pk={1}
              />
            </I18nextProvider>
        }
        </CallbackContext.Provider>
    </ThemeProvider>
  )
}

export default App;
