import React, { useEffect } from "react";

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import { SxProps } from '@mui/system';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import AddIcon from '@mui/icons-material/Add';

import { theme as root_theme } from "../../consts";

import { useTranslation } from 'react-i18next';

import {
  TicketsStore,
  PromosStore,
  ManageTicket,
  ManagePromo,
} from "./TicketsPromos";
import { BootstrapDialog } from "../common/Dialogs";

import {
  ServiceItemsProps,
  ServiceItemsClubUnitProps,
} from "../../interfaces/Props";
import {
  TicketManage,
  PromoManage,
} from "../../interfaces/Common";
import { BodyObj } from "../../interfaces/Types";

import { BaseApi } from "../../api/base";

export function ServiceItems(props: ServiceItemsProps) {
  const { t } = useTranslation(['products', 'common']);

  const [value, setValue] = React.useState<string>(String(props.clubUnits[0]?.id));

  useEffect(() => {
      if (value !== "undefined") { return }
      setValue(String(props.clubUnits[0]?.id))
    }, [props.clubUnits]
  );
  
  return (
    <Box>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={(event: React.SyntheticEvent, value: string) => {
              setValue(value);
            }}
            aria-label="Products"
          >
            {props.clubUnits.map((cu) => {
              return (
                <Tab label={cu.name} value={String(cu.id)} key={`tab-key-${cu.id}`} />
              )
            })}
          </TabList>
        </Box>
        {props.clubUnits.map((cu) => {
          return (
            <TabPanel value={String(cu.id)} key={`tabpanel-key-${cu.id}`}>
              <ServiceItemsClubUnit
                appState={props.appState}
                appSetState={props.appSetState}
                device={props.device}
                clubUnits={props.clubUnits}
                clubUnit={props.clubUnits.filter(cu => cu.id === Number(value))[0]}
                handleCloseSpinner={props.handleCloseSpinner}
                handleOpenSpinner={props.handleOpenSpinner}
                openPopUp={props.openPopUp}
                sendSocketMessage={props.sendSocketMessage}
              />
            </TabPanel>
          )
        })}
      </TabContext>
    </Box>
  )
}

export function ServiceItemsClubUnit(props: ServiceItemsClubUnitProps) {
  const { t } = useTranslation(['products', 'common']);

  const [value, setValue] = React.useState('TicketsStore');
  const [tickets, setTickets] = React.useState<TicketManage[]>([]);
  const [promos, setPromos] = React.useState<PromoManage[]>([]);
  const [showNewTicket, setShowNewTicket] = React.useState<boolean>(false);
  const [showNewPromo, setShowNewPromo] = React.useState<boolean>(false);

  const handleNewServiceItem = () => {
    if (value === 'TicketsStore') {
      setShowNewPromo(false);
      setShowNewTicket(true);
    } else if (value === 'PromosStore') {
      setShowNewTicket(false);
      setShowNewPromo(true);
    }
  };

  const getTicketsPromos = async () => {
    const api = new BaseApi(1, 'management/clubs/services/');
    props.handleOpenSpinner();
    let res = await api.get(
      { all: 1, club_unit: props.clubUnit.id },
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      if (res.body.tickets[props.clubUnit.id]) {
        setTickets(res.body.tickets[props.clubUnit.id].filter(t => !t.deleted));
      }
      if (res.body.promos[props.clubUnit.id]) {
        setPromos(res.body.promos[props.clubUnit.id]);
      }
    };
    props.handleCloseSpinner();
  };

  const handleUpdateTicket = async (ticket: BodyObj) => {
    props.handleOpenSpinner();
    let res = { status: 500 };
    if (ticket.id) {
      const api = new BaseApi(1, `management/tickets/${ticket.id}/`);
      res = await api.post(
        ticket,
        'application/json',
        props.appSetState,
        props.appState,
      );
    } else {
      const api = new BaseApi(1, `management/tickets/`);
      res = await api.put(
        ticket,
        'application/json',
        props.appSetState,
        props.appState,
      );
    }
    if ([200, 201].includes(res.status)) {
      if (res.body.deleted) {
        setTickets(tickets.filter(t => t.id !== res.body.id));
      } else {
        let _tickets = tickets;
        if (!ticket.id) {
          _tickets.push(res.body);
        } else {
          _tickets = _tickets.map((t) => {
            if (t.id === res.body?.id) {
              return res.body
            } else {
              return t
            }
          });
        }
        setTickets(_tickets);
      }
    }
    props.handleCloseSpinner();
  };

  const handleUpdatePromo = async (promo: BodyObj) => {
    props.handleOpenSpinner();
    const api = new BaseApi(1, `management/promo/`);
    let res = { status: 500 };
    if (promo.id) {
      res = await api.put(
        promo,
        'application/json',
        props.appSetState,
        props.appState,
      );
    } else {
      res = await api.post(
        promo,
        'application/json',
        props.appSetState,
        props.appState,
      );
    }
    if (res.status === 200) {
      if (!promo.id) {
        setPromos([...promos, res.body[0]]);
      } else {
        setPromos(
          promos.map((p) => {
            if (p.id === res.body.id) {
              return res.body
            } else {
              return p
            }
          })
        );
      }
    }
    props.handleCloseSpinner();
  };

  useEffect(() => {
    getTicketsPromos();
  }, [props.clubUnit]);
  
  return (
    <Box>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={(event: React.SyntheticEvent, value: string) => {
              setValue(value);
            }}
            aria-label="lab API tabs example"
          >
            <Tab label={t('tickets')} value="TicketsStore" />
            <Tab label={t('promos')} value="PromosStore" />
          </TabList>
        </Box>
        <TabPanel value="TicketsStore">
          <TicketsStore
            appState={props.appState}
            appSetState={props.appSetState}
            device={props.device}
            clubUnits={props.clubUnits}
            tickets={tickets}
            handleCloseSpinner={props.handleCloseSpinner}
            handleOpenSpinner={props.handleOpenSpinner}
            openPopUp={props.openPopUp}
            updateTicket={handleUpdateTicket}
            clubUnit={props.clubUnit}
            sendSocketMessage={props.sendSocketMessage}
          />
        </TabPanel>
        <TabPanel value="PromosStore">
          <PromosStore
            appState={props.appState}
            appSetState={props.appSetState}
            device={props.device}
            clubUnits={props.clubUnits}
            promos={promos}
            tickets={tickets}
            handleCloseSpinner={props.handleCloseSpinner}
            handleOpenSpinner={props.handleOpenSpinner}
            openPopUp={props.openPopUp}
            updatePromo={handleUpdatePromo}
            clubUnit={props.clubUnit}
            sendSocketMessage={props.sendSocketMessage}
          />
        </TabPanel>
      </TabContext>
      <Zoom
        key="new-client-key"
        in={true}
      >
        <Fab sx={
            {
              position: 'absolute',
              bottom: 36,
              left: 36,
              borderColor: root_theme.palette.info.main,
              borderWidth: '2px',
              borderStyle: 'solid',
              '&:hover': {
                bgcolor: root_theme.palette.background.default,
                borderColor: root_theme.palette.info.main,
              },
            } as SxProps
          }
          aria-label="Add product"
          color='info'
          onClick={(e) => {handleNewServiceItem()}}
        >
          <AddIcon />
        </Fab>
      </Zoom>
      <BootstrapDialog
        open={showNewTicket}
        onClose={() => {
          setShowNewPromo(false);
          setShowNewTicket(false);
        }}
      >
        <DialogTitle sx={{ paddingLeft: '5px' }}>
          {t('ticket')}
        </DialogTitle>
        <DialogContent dividers className="box-content-center">
          <ManageTicket
            appState={props.appState}
            appSetState={props.appSetState}
            device={props.device}
            apply={(data: BodyObj) => {
              handleUpdateTicket(data);
              setShowNewPromo(false);
              setShowNewTicket(false);
            }}
            close={() => {setShowNewTicket(false)}}
            clubUnit={props.clubUnit}
          />
        </DialogContent>
      </BootstrapDialog>
      <BootstrapDialog
        open={showNewPromo}
        onClose={() => {
          setShowNewPromo(false);
          setShowNewTicket(false);
        }}
      >
        <DialogTitle sx={{ paddingLeft: '5px' }}>
          {t('promo')}
        </DialogTitle>
        <DialogContent dividers className="box-content-center">
          <ManagePromo
            appState={props.appState}
            appSetState={props.appSetState}
            device={props.device}
            apply={(data: BodyObj) => {
              handleUpdatePromo(data);
              setShowNewPromo(false);
              setShowNewTicket(false);
            }}
            close={() => {setShowNewPromo(false)}}
            clubUnit={props.clubUnit}
            tickets={tickets}
          />
        </DialogContent>
      </BootstrapDialog>
    </Box>
  )
}
