import React from "react";

import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Input,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";

import { RegisterProps, AutocompleteProps } from "../../interfaces/Props";

export function Register(props: RegisterProps) {
  const { t } = useTranslation(['forms', 'user']);
  const [child, setChild] = React.useState<boolean>(false);
  const [formValid, setFormValid] = React.useState<boolean>(false);
  const regex = /^[A-Za-z0-9\-\.]+@[A-Za-z0-9\-\.]+\.[a-z]{2,3}$/;
  const phoneRegex = /^\+{0,1}[0-9\-\.]{8,}$/;
  const [phone, setPhone] = React.useState<string>('');
  const [phoneValid, setPhoneValid]= React.useState<boolean>(true);
  const [email, setEmail] = React.useState<string>('');
  const [emailValid, setEmailValid]= React.useState<boolean>(true);

  React.useEffect(() => {
    if (phone !== '' && !phoneRegex.test(phone)) {
      setPhoneValid(false);
    } else {
      setPhoneValid(true);
    }
  }, [phone]);

  React.useEffect(() => {
    if (email !== '' && !regex.test(email)) {
      setEmailValid(false);
    } else {
      setEmailValid(true);
    }
  }, [email]);

  const checkFormValid = () => {
    if (phone === '' && email === '' && !child) {
      setFormValid(false);
    } else if (!phoneValid || !emailValid) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }

  React.useEffect(() => {
    checkFormValid()
  }, [phoneValid]);
  React.useEffect(() => {
    checkFormValid()
  }, [emailValid]);

  React.useEffect(() => {
    if (child) {
      setPhone('');
    }
  }, [child]);
  
  return (
    <Box
      component="form"
      onSubmit={
        (e: any) => {
          e.preventDefault();
          let data = new FormData(e.currentTarget);
          let data_obj = Object.fromEntries(data);
          if (data_obj.email === '' && data_obj.phone === '') {
            props.openPopUp('error', 'phone_email');
          } else {
            props.register_user(e);
            if (props.close_form) { props.close_form() }
          }
        }
      }
    >
      <FormControlLabel
        control=<Switch
          onChange={(e) => {
            setPhoneValid(false);
            setChild(e.target.checked)}
          }
        />
        label={t("register.under_age")}
      />
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
        <Typography sx={{ marginRight: "10px" }}>{t("register.first_name")}:</Typography>
        <Input
          id="first-name-input-id"
          placeholder={t("register.first_name")}
          required={true}
          name="first_name"
          type="text"
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
        <Typography sx={{ marginRight: "10px" }}>{t("register.last_name")}:</Typography>
        <Input
          id="last-name-input-id"
          placeholder={t("register.last_name")}
          required={true}
          name="last_name"
          type="text"
        />
      </Box>
      {child
        ? <Box>
            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
              <Typography sx={{ marginRight: "10px" }}>{t("register.trustee_name")}:</Typography>
              <Input
                id="trustee-name-input-id"
                placeholder={t("register.trustee_name")}
                required={false}
                name="trustee_name"
                type="text"
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
              <Typography sx={{ marginRight: "10px" }}>{t("register.trustee_phone")}:</Typography>
              <Input
                id="trustee-phone-input-id"
                placeholder={t("register.trustee_phone")}
                required={false}
                name="trustee_phone"
                type="text"
                onChange={
                  (e) => {
                    setPhoneValid(false);
                    setPhone(e.target.value)
                  }
                }
                error={!phoneValid}
              />
            </Box>
          </Box>
        : <Box sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
            <Typography sx={{ marginRight: "10px" }}>{t("register.phone")}:</Typography>
            <Input
              id="phone-input-id"
              placeholder={t("register.phone")}
              required={false}
              name="phone"
              type="text"
              onChange={
                (e) => {
                  setPhoneValid(false);
                  setPhone(e.target.value)
                }
              }
              error={!phoneValid}
            />
          </Box>
      }
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
        <Typography sx={{ marginRight: "10px" }}>{t("register.email")}:</Typography>
        <Input
          id="email-input-id"
          placeholder={t("register.email")}
          required={false}
          name="email"
          type="text"
          onChange={
            (e) => {
              setEmailValid(false);
              setEmail(e.target.value)
            }
          }
          error={!emailValid}
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
        <Typography sx={{ marginRight: "10px" }}>{t("register.password")}:</Typography>
        <Input
          id="password-input-id"
          placeholder={t("register.password")}
          required={true}
          name="password"
          type="password"
        />
      </Box>
      <Box sx={{ width: 'fit-content', marginRight: 'auto', marginLeft: 'auto' }}>
        <Button
          type="submit"
          fullWidth={false}
          variant="contained"
          color="info"
          disabled={!formValid}
        >
          {t('register.btn')}
        </Button>
      </Box>
    </Box>
  )
}
