import { atom } from 'recoil'

export interface IDeviceState {
  item: 'desktop' | 'mobile'
}

const deviceAtom = atom<IDeviceState>({
  key: 'device',
  default: {
    item: 'desktop',
  },
})

export { deviceAtom }
