import React from "react";
import { useEffect } from 'react';

import { useRecoilValueLoadable } from 'recoil';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import TabPanel from '@mui/lab/TabPanel';

import CloseIcon from '@mui/icons-material/Close';

import { getElementAtEvent } from 'react-chartjs-2';

import { useTranslation } from 'react-i18next';

import { builtISODate } from "../../helpers";

import { BootstrapDialog } from "../common/Dialogs";
import { Clients } from "../users/Clients";
import { ClientTicketsPlot, ClientTicketsAmount } from "./components/ClubUnitClientsTicketsStat";

import { StatisticTabProps } from "../../interfaces/Props";
import { IBarDatasetObj } from "../../interfaces/Common";

import { BaseApi } from "../../api/base";

import { clubUnitsAtom } from '../../atoms/ClubUnits'

interface ITicketsAmountStat {
  activeTickets: number[];
  activeClients: number[];
}

export function TicketsStatisticTab(props: StatisticTabProps) {
  let today = new Date();
  let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  const ticketStatChartRef = React.useRef<any>(null);

  const { t, i18n } = useTranslation(['statistic', 'filters']);

  const translations: any = (i18n.options.resources && Object.keys(i18n.options.resources).includes(i18n.language)) ? i18n.options.resources[i18n.language] : {};
  
  const [statDateStart, setStatDateStart] = React.useState<string>(builtISODate(firstDayOfMonth));
  const [statDateStop, setStatDateStop] = React.useState<string>(builtISODate(lastDayOfMonth));

  const [firstRender, setFirstRender] = React.useState<boolean>(true);
  const clubUnits = useRecoilValueLoadable(clubUnitsAtom)

  const [spinner, setSpinner] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string>('1');
  const [clientsIds, setClientsIds] = React.useState<number[]>([]);
  const [showClients, setShowClients] = React.useState<boolean>(false);
  const [clientsStat, setClientsStat] = React.useState<IBarDatasetObj[]>([])
  const [ticketsAmountStat, setTicketsAmountStat] = React.useState<ITicketsAmountStat>(
    {
      activeTickets: [],
      activeClients: [],
    }
  )

  const getClientsTicketsStat = async () => {
    const api = new BaseApi(1, 'management/tickets/clients/statistic/');
    let res = await api.get(
      { date_start: statDateStart, date_stop: statDateStop, club_unit: Number(value) },
      props.appSetState,
      props.appState,
    );
    if (!res?.body) { return }
    if (res.status === 200) {
      setClientsStat(
        [
          {
            label: translations.statistic.ticket_sales_stat.clients_stat,
            data: [res.body.new.length, res.body.renewed.length, res.body.non_renewed.length, res.body.out_of_stat.length],
            clients: [res.body.new, res.body.renewed, res.body.non_renewed, res.body.out_of_stat],
            backgroundColor: 'rgb(1, 131, 23)',
          }
        ]
      )
      setTicketsAmountStat({activeTickets: res.body.active_tickets, activeClients: res.body.active_clients})
    };
  };

  const getData = async () => {
    setSpinner(true);
    await getClientsTicketsStat();
    setFirstRender(false);
    setSpinner(false);
  }

  useEffect(() => {
    if (props.statType !== 'ticket_stat') { return }
    getData();
  }, [props.statType]);

  useEffect(() => {
    if (firstRender) { return }
    getData();
  }, [statDateStart]);

  useEffect(() => {
    if (firstRender) { return }
    getData();
  }, [statDateStop]);

  useEffect(() => {
    if (firstRender) { return }
    getData();
  }, [value]);

  const showClientsInfo = (e: any) => {
    let elem = getElementAtEvent(ticketStatChartRef.current, e)
    if (elem.length === 0) {
      return
    }
    elem = elem[0];
    console.log(elem)
    setClientsIds(
      clientsStat[0].clients[elem.index]
    );
    setShowClients(true);
  }

  return (
    <Box>
      {spinner
        ? <Box
            sx={{
              width: 'fit-content',
              marginLeft: 'auto',
              marginRight: 'auto',
              paddingTop: '5px',
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        : <Box>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <Box
                sx={{
                  width: 'fit-content',
                  padding: '5px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <TextField
                  id="bid-date-from-id"
                  label={t('date_from', { ns: 'filters' })}
                  sx={{ paddingLeft: '5px', paddingRight: '5px' }}
                  variant="standard"
                  type="date"
                  onChange={(e) => {setStatDateStart(e.target.value)}}
                  defaultValue={statDateStart}
                />
                <TextField
                  id="bid-date-to-id"
                  label={t('date_to', { ns: 'filters' })}
                  sx={{ paddingLeft: '5px', paddingRight: '5px' }}
                  variant="standard"
                  type="date"
                  onChange={(e) => {setStatDateStop(e.target.value)}}
                  defaultValue={statDateStop}
                />
              </Box>
              {clubUnits.state === 'hasValue'
                ? <>
                    <TabContext value={value}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                          onChange={(event: React.SyntheticEvent, newValue: string) => {
                            setValue(newValue);
                          }}
                          aria-label="Clients tickets stat"
                        >
                          {clubUnits.contents.items.map((cu) => {
                            return (
                              <Tab
                                label={`${cu.name} ${cu.id}`}
                                value={cu.id.toString()}
                                key={`club_unit_clients_tickets_stat_tab_${cu.id}`}
                              />
                            )
                          })}
                        </TabList>
                      </Box>
                      {clubUnits.contents.items.map((cu) => {
                        return (
                          <TabPanel value={cu.id.toString()} key={`clients_tickets_stat_${cu.id}`}>
                            <ClientTicketsAmount
                              activeClients={ticketsAmountStat.activeClients}
                              activeTickets={ticketsAmountStat.activeTickets}
                            />
                            <ClientTicketsPlot
                              showClients={showClientsInfo}
                              barRef={ticketStatChartRef}
                              labels={
                                [
                                  translations.statistic.ticket_sales_stat.new,
                                  translations.statistic.ticket_sales_stat.renewed,
                                  translations.statistic.ticket_sales_stat.non_renewed,
                                  translations.statistic.ticket_sales_stat.out_of_stat,
                                ]
                              }
                              datasets={clientsStat}
                              titleText={translations.statistic.tickets_stat.new.header}
                            />
                          </TabPanel>
                        )
                      })}
                    </TabContext>
                  </>
                : <></>
              }
            </Box>
          </Box>
      }
      <BootstrapDialog
        onClose={(e) => {setShowClients(false)}}
        open={showClients}
        fullScreen
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar sx={{ backgroundColor: 'var(--black)' }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => {setShowClients(false)}}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {t('clients')}
            </Typography>
          </Toolbar>
        </AppBar>
        <Clients
          handleCloseSpinner={props.handleCloseSpinner}
          handleOpenSpinner={props.handleOpenSpinner}
          openPopUp={props.openPopUp}
          departments={props.departments}
          device={props.device}
          appState={props.appState}
          appSetState={props.appSetState}
          clientsIds={clientsIds.toString()}
          sendSocketMessage={props.sendSocketMessage}
        />
      </BootstrapDialog>
    </Box>
  )
}
