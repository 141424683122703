import React from "react";
import { useEffect } from 'react';

import { useRecoilState, useRecoilValueLoadable, useRecoilValue } from 'recoil';

import { CSVLink } from "react-csv";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';

import { useTranslation } from 'react-i18next';

import { accountInfoAtom } from "../../atoms/AccountInfo";
import { deviceAtom } from '../../atoms/Device';
import { spinnerAtom } from "../../atoms/Spinner";
import { allTicketsAtom } from "../../atoms/Tickets";
import { promosAtom } from "../../atoms/Promos";

import { builtISODate } from "../../helpers";

import { AutocompleteProps } from "../../interfaces/Props";

import { BaseApi } from "../../api/base";


interface IExportStatProps {
  statType: string
}

interface IExportRequestBody {
  date_start: string
  date_stop: string
  club_unit: number
  except_tickets: number[]
  except_promos: number[]
  export_type: string
}

interface ICSVRow {
  id: number
  clientName: string
  drillDate: string
  ticketName: string
  startDate: string
  endDate: string
  sailDate: string
}

export function ExportStatistic(props: IExportStatProps) {
  let today = new Date();
  let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  
  const device = useRecoilValue(deviceAtom);

  const { t, i18n } = useTranslation(['statistic', 'filters', 'common']);

  const [statDateStart, setStatDateStart] = React.useState<string>(builtISODate(firstDayOfMonth));
  const [statDateStop, setStatDateStop] = React.useState<string>(builtISODate(lastDayOfMonth));
  const [requestBody, setRequestBody] = React.useState<IExportRequestBody>(
    {
      date_start: statDateStart,
      date_stop: statDateStop,
      club_unit: 0,
      except_tickets: [],
      except_promos: [],
      export_type: 'attendance',
    }
  )
  const [state, setState] = useRecoilState(accountInfoAtom);
  const [spinner, setSpinner] = useRecoilState(spinnerAtom);
  const tickets = useRecoilValueLoadable(allTicketsAtom);
  const [selectTickets, setSelectTickets] = React.useState<AutocompleteProps[]>(
    (tickets.state === "hasValue"
      ? tickets.contents.items.map(
          ticket => {return {id: ticket.id, label: ticket.ticket}}
        )
      : []
    )
  )
  const promos = useRecoilValueLoadable(promosAtom);
  const [selectPromos, setSelectPromos] = React.useState<AutocompleteProps[]>(
    (promos.state === "hasValue"
      ? promos.contents.items.map(
          promo => {return {id: promo.id, label: promo.name}}
        )
      : []
    )
  )

  const [csvData, setCSVData] = React.useState<ICSVRow[]>([]);
  
  const [clubUnits, setClubUnits] = React.useState<AutocompleteProps[]>(
    state.account_info.clubs.map(
      club => club.units
    ).flat().map(
      unit => {return {id: unit.id, label: unit.name}}
    )
  );

  useEffect(() => {
    if (props.statType !== 'export_stat') { return }
  }, [props.statType]);

  useEffect(() => {
    setRequestBody({ ...requestBody, date_start: statDateStart })
  }, [statDateStart])

  useEffect(() => {
    setRequestBody({ ...requestBody, date_stop: statDateStop })
  }, [statDateStop])

  useEffect(() => {
    if (requestBody.club_unit) {
      setSelectTickets(
        (tickets.state === "hasValue"
          ? tickets.contents.items.filter(
            ticket => ticket.club_unit.map(u => u.id).includes(requestBody.club_unit)
          ).map(
              ticket => {
                return {id: ticket.id, label: ticket.ticket}
              }
            )
          : []
        )
      )
      setSelectPromos(
        (promos.state === "hasValue"
          ? promos.contents.items.filter(
              promo => promo.club_unit.id === requestBody.club_unit
            ).map(
              promo => {return {id: promo.id, label: promo.name}}
            )
          : []
        )
      )
    } else {
      setSelectTickets(
        (tickets.state === "hasValue"
          ? tickets.contents.items.map(
              ticket => {
                return {id: ticket.id, label: ticket.ticket}
              }
            )
          : []
        )
      )
      setSelectPromos(
        (promos.state === "hasValue"
          ? promos.contents.items.map(
              promo => {return {id: promo.id, label: promo.name}}
            )
          : []
        )
      )
    }
    if (
      requestBody.date_start &&
      requestBody.date_stop &&
      requestBody.club_unit
    ) {
        getData()
      }
  }, [requestBody])

  const getData = async () => {
    const api = new BaseApi(2, 'management/export_data/');
    setSpinner({ open: true });
    let res = await api.post(requestBody, 'multipart/form-data', () => {}, state);
    if (res.status === 200) {
      setCSVData(
        res.body.data.map(
          (row: any) => {
            return {
              id: row[0],
              clientName: row[1],
              drillDate: row[2],
              ticketName: row[3],
              startDate: row[4],
              endDate: row[5],
              sailDate: row[6],
            }
          }
        )
      )
    };
    setSpinner({ open: false });
  };

  return (
    <Box>
      <Box>
        <Box
          sx={{
            width: 'fit-content',
            padding: '5px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <TextField
            id="bid-date-from-id"
            label={t('date_from', { ns: 'filters' })}
            sx={{ paddingLeft: '5px', paddingRight: '5px' }}
            variant="standard"
            type="date"
            onChange={(e) => {setStatDateStart(e.target.value)}}
            defaultValue={statDateStart}
          />
          <TextField
            id="bid-date-to-id"
            label={t('date_to', { ns: 'filters' })}
            sx={{ paddingLeft: '5px', paddingRight: '5px' }}
            variant="standard"
            type="date"
            onChange={(e) => {setStatDateStop(e.target.value)}}
            defaultValue={statDateStop}
          />
        </Box>
      </Box>
      <Box sx={{ marginBottom: '5px' }}>
        <Card sx={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
          <CardContent>
            <Box sx={{ display: 'flex' }}>
              <Autocomplete
                disablePortal
                id="export-stat-club-unit-filter-id"
                key="export-stat-club-unit-filter-key"
                options={clubUnits}
                onChange={
                  (
                    e: React.SyntheticEvent,
                    value: AutocompleteProps | null,
                  ) => {
                    if (value && value.id) {
                      setRequestBody({ ...requestBody, club_unit: Number(value.id) });
                    } else {
                      setRequestBody({ ...requestBody, club_unit: 0 });
                    }
                  }
                }
                sx={{
                  width: (device.item === 'mobile') ? '100%' : '30%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
                renderInput={
                  (params: any) => <TextField
                    {...params}
                    label={t("club_unit", { ns: 'filters' })}
                  />
                }
              />
              <Autocomplete
                disablePortal
                id="export-stat-except-tickets-filter-id"
                key="export-stat-except-tickets-filter-key"
                multiple
                options={selectTickets}
                onChange={
                  (
                    e: React.SyntheticEvent,
                    value: AutocompleteProps[] | null,
                  ) => {
                    if (value && value.length) {
                      setRequestBody({ ...requestBody, except_tickets: value.map((d) => {return Number(d.id)}) });
                    } else {
                      setRequestBody({ ...requestBody, except_tickets: [] });
                    }
                  }
                }
                sx={{
                  width: (device.item === 'mobile') ? '100%' : '30%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
                renderInput={
                  (params: any) => <TextField
                    {...params}
                    label={t("export_filters.exclude_tickets")}
                  />
                }
              />
              <Autocomplete
                disablePortal
                id="export-stat-except-promos-filter-id"
                key="export-stat-except-promos-filter-key"
                multiple
                options={selectPromos}
                onChange={
                  (
                    e: React.SyntheticEvent,
                    value: AutocompleteProps[] | null,
                  ) => {
                    if (value && value.length) {
                      setRequestBody({ ...requestBody, except_promos: value.map((d) => {return Number(d.id)}) });
                    } else {
                      setRequestBody({ ...requestBody, except_promos: [] });
                    }
                  }
                }
                sx={{
                  width: (device.item === 'mobile') ? '100%' : '30%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
                renderInput={
                  (params: any) => <TextField
                    {...params}
                    label={t("export_filters.exclude_promos")}
                  />
                }
              />
            </Box>
            {csvData.length
              ? <Box sx={{ width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', marginTop: '10px' }}>
                  <CSVLink data={csvData}>
                    <Button
                      variant="contained"
                      color="info"
                    >
                      {t("download", { ns: 'common' })}
                    </Button>
                  </CSVLink>
                </Box>
              : <></>
            }
          </CardContent>
        </Card>
      </Box>
    </Box>
  )
}
