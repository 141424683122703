import React from "react";

import Box from '@mui/material/Box';
import TabPanel from '@mui/lab/TabPanel';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { Bar } from 'react-chartjs-2';

import { useTranslation } from 'react-i18next';

import { ClubUnit } from '../../../interfaces/Club'
import { IBarDatasetObj } from "../../../interfaces/Common"

interface IClientsTicketsPlotProps {
  showClients: CallableFunction
  barRef: any
  labels: string[]
  datasets: IBarDatasetObj[]
  titleText: string
}

interface IClientTicketsAmountProps {
  activeTickets: number[];
  activeClients: number[];
}

export function ClientTicketsAmount(props: IClientTicketsAmountProps) {
  const { t, i18n } = useTranslation(['statistic']);

  return (
    <Box sx={{ marginBottom: '5px' }}>
      <Card sx={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
        <CardContent>
          <Typography>
            {t('tickets_stat.active_tickets')}: {props.activeTickets.length}
          </Typography>
          <Typography>
            {t('tickets_stat.active_clients')}: {props.activeClients.length}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  )
}

export function ClientTicketsPlot(props: IClientsTicketsPlotProps) {
  return (
    <Box sx={{ marginBottom: '5px' }}>
      <Card sx={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
        <CardContent>
          <Bar
            ref={props.barRef}
            data={
              {
                labels: props.labels,
                datasets: props.datasets,
              }
            }
            options={
              {
                plugins: {
                  title: {
                    display: true,
                    text: props.titleText,
                  },
                },
                responsive: true,
                scales: {
                  x: {
                    stacked: true,
                  },
                  y: {
                    stacked: true,
                    min: 0,
                    ticks: {
                      stepSize: 1,
                    },
                  },
                },
              }
            }
            height={100}
            onClick={(e: any) => {props.showClients(e)}}
          />
        </CardContent>
      </Card>
    </Box>
  )
}
