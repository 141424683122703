import { atom } from 'recoil'

import { Department } from '../interfaces/Club';

export interface IDepartmentsState {
  items: Department[]
}

const departmentsAtom = atom<IDepartmentsState>({
  key: 'departments',
  default: {
    items: [],
  },
})

export { departmentsAtom }
