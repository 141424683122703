import React, { useEffect } from "react";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import CheckIcon from '@mui/icons-material/Check';

import { ResponsiveHeatMap } from '@nivo/heatmap'

import { useTranslation } from 'react-i18next';

import { BaseApi } from "../../api/base";

import {
  ClubUnitSalariesProps,
  ClubUnitSalaryManageProps,
  AutocompleteProps,
} from "../../interfaces/Props";
import { BodyObj } from "../../interfaces/Types";
import {
  TicketCommon,
  CoachSkillInterface,
  DrillType,
} from "../../interfaces/Common";

export function ClubSalary(props: ClubUnitSalariesProps) {

  return (
    <Box>
      {props.salaries.map((salary: any) => {
        let _salaries_arr: any = [];
        for (let c of salary.data) {
          for (let d of c.data) {
            if (!d.y) { continue }
            _salaries_arr.push(d.y);
          }
        }
        let _max_value = Math.max(..._salaries_arr);
        return (
          <Box key={`drill-type-salary-${salary.drill_type.id}`}>
            <Typography variant='h6'>{salary.drill_type.name}</Typography>
            <Box
              sx={{
                height: '400px',
                width: '1000px',
                margin: '20px',
              }}
            >
              <ResponsiveHeatMap
                data={salary.data}
                margin={{ top: 60, right: 90, bottom: 60, left: 90 }}
                axisTop={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -10,
                    legend: '',
                    legendOffset: 46
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -60,
                    legend: '',
                    legendOffset: -72
                }}
                colors={{
                    type: 'diverging',
                    scheme: 'yellow_orange_brown',
                    divergeAt: 0.8,
                    minValue: 0,
                    maxValue: _max_value
                }}
                emptyColor="#555555"
                legends={[]}
                labelTextColor="black"
                onClick={(cell: any, event: any) => {
                  props.manageSalary(cell.data.pk);
                }} 
              />
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}

export function SalaryManage(props: ClubUnitSalaryManageProps) {
  const { t } = useTranslation(['club', 'common', 'tables']);

  const [salaryBody, setSalaryBody] = React.useState<BodyObj>(
    (props.managedSalary)
      ? {
          id: props.managedSalary.id,
          skill: props.managedSalary.skill[0],
          drill_type: props.managedSalary.drill_type.id,
          clients_from: props.managedSalary.clients_from,
          clients_to: props.managedSalary.clients_to,
          salary: props.managedSalary.salary,
          ticket: props.managedSalary?.ticket?.ticket?.id,
          fraction: props.managedSalary.fraction,
        }
      : {}
  );
  const [fixSalary, setFixSalary] = React.useState<boolean>((props.managedSalary) ? Boolean(!props.managedSalary.fraction) : true);
  const [coachSkills, setCoachSkills] = React.useState<CoachSkillInterface[]>([]);
  const [tickets, setTickets] = React.useState<TicketCommon[]>([]);
  const [drillTypes, setDrillTypes] = React.useState<DrillType[]>([]);
  const [spinner, setSpinner] = React.useState<boolean>(false);

  const getSlugObjects = async () => {
    setSpinner(true);
    const api = new BaseApi(1, 'management/coach_skill_choices/');
    let res = await api.get(
      {},
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      setCoachSkills(res.body);
    }

    api.url = api.create_url(1, 'management/tickets/');
    res = await api.get(
      {club_unit: props.clubUnit.id},
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      setTickets(res.body);
    }

    api.url = api.create_url(1, 'management/drills_types/');
    res = await api.get(
      {},
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      setDrillTypes(res.body.results);
    }

    setSpinner(false);
  };

  useEffect(() => {
    getSlugObjects();
  }, [props.clubUnit]);
  
  return (
    <Box>
      {spinner
        ? <Box>
            <CircularProgress color="inherit" />
          </Box>
        : <Box>
            <Autocomplete
              id="salary-coach-skill-id"
              options={
                coachSkills.map(
                  (cs) => {return {id: cs.id, label: cs.name}}
                )
              }
              sx={{ width: "100%", marginBottom: "15px" }}
              defaultValue={
                (props.managedSalary)
                  ? {id: props.managedSalary.skill[0], label: props.managedSalary.skill[1]}
                  : null
              }
              renderInput={(params) => <TextField {...params} label={t("drill_salary.coach_skill")} />}
              onChange={(
                e: React.SyntheticEvent,
                value: AutocompleteProps | null,
                reason: string,
              ) => {
                if (value) {
                  setSalaryBody({ ...salaryBody, skill: value.id });
                }
              }}
            />
            <Autocomplete
              id="salary-drill-type-id"
              options={
                drillTypes.map(
                  (dt) => {return {id: dt.id, label: dt.name}}
                )
              }
              sx={{ width: "100%", marginBottom: "15px" }}
              defaultValue={
                (props.managedSalary)
                  ? {id: props.managedSalary.drill_type.id, label: props.managedSalary.drill_type.name}
                  : null
              }
              renderInput={(params) => <TextField {...params} label={t("drill_salary.drill_type")} />}
              onChange={(
                e: React.SyntheticEvent,
                value: AutocompleteProps | null,
                reason: string,
              ) => {
                if (value) {
                  setSalaryBody({ ...salaryBody, drill_type: value.id });
                }
              }}
            />
            <FormControlLabel
              control=<Switch
                defaultChecked={fixSalary}
                onChange={(e) => {setFixSalary(!fixSalary)}}
              />
              label={t("drill_salary.fix_pay")}
            />
            {!fixSalary
              ? <Box>
                  <Autocomplete
                    id="salary-ticket-id"
                    options={
                      tickets.map(
                        (t) => {return {id: t.id, label: t.ticket}}
                      )
                    }
                    sx={{ width: "100%", marginBottom: "15px" }}
                    defaultValue={
                      (props.managedSalary)
                        ? {id: props.managedSalary.ticket.ticket.id, label: props.managedSalary.ticket.ticket.name}
                        : null
                    }
                    renderInput={(params) => <TextField {...params} label={t("drill_salary.ticket")} />}
                    onChange={(
                      e: React.SyntheticEvent,
                      value: AutocompleteProps | null,
                      reason: string,
                    ) => {
                      if (value) {
                        setSalaryBody({ ...salaryBody, ticket: value.id });
                      }
                    }}
                  />
                  <Box sx={{ marginBottom: '10px' }}>
                    <TextField
                      id='salary-fraction-id'
                      label={t('drill_salary.ticket_fraction')}
                      variant="standard"
                      type="number"
                      onChange={(e: any) => {
                        setSalaryBody({ ...salaryBody, fraction: parseFloat(e.target.value) });
                      }}
                      defaultValue={(props.managedSalary) ? props.managedSalary.fraction : null}
                    />
                  </Box>
                </Box>
              : <Box>
                  <Box sx={{ marginBottom: '10px' }}>
                    <TextField
                      id='salary-coach-salary-id'
                      label={t('drill_salary.coach_salary')}
                      variant="standard"
                      type="number"
                      onChange={(e: any) => {
                        setSalaryBody({ ...salaryBody, salary: parseFloat(e.target.value) });
                      }}
                      defaultValue={(props.managedSalary) ? props.managedSalary.salary : null}
                    />
                  </Box>
                  <Box sx={{ marginBottom: '10px' }}>
                    <TextField
                      id='salary-clients-from-id'
                      label={t('drill_salary.clients_from')}
                      variant="standard"
                      type="number"
                      onChange={(e: any) => {
                        setSalaryBody({ ...salaryBody, clients_from: parseInt(e.target.value) });
                      }}
                      defaultValue={(props.managedSalary) ? props.managedSalary.clients_from : null}
                    />
                  </Box>
                  <Box sx={{ marginBottom: '10px' }}>
                    <TextField
                      id='salary-clients-to-id'
                      label={t('drill_salary.clients_to')}
                      variant="standard"
                      type="number"
                      onChange={(e: any) => {
                        setSalaryBody({ ...salaryBody, clients_to: parseInt(e.target.value) });
                      }}
                      defaultValue={(props.managedSalary) ? props.managedSalary.clients_to : null}
                    />
                  </Box>
                </Box>
            }
            <DialogActions>
              <Button
                variant="contained"
                color="info"
                onClick={() => {props.manageSalary(salaryBody)}}
              >
                <CheckIcon />
              </Button>
            </DialogActions>
          </Box>
      }
    </Box>
  )
}
