import React from "react";
import { useEffect } from 'react';

import { useSetRecoilState, useRecoilValue } from 'recoil';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { ResponsiveLine } from '@nivo/line'

import { useTranslation } from 'react-i18next';

import { builtISODate } from "../../helpers";

import { BaseApi } from "../../api/base";
import { getData } from "../../api/baseMethods";

import { StatisticTabProps } from "../../interfaces/Props";
import { ITicketsSalesStat } from '../../interfaces/Statistic'
import { UserInfoCommon } from "../../interfaces/AccountInfo"
import { ClubUnit } from "../../interfaces/Club"

import { spinnerAtom } from "../../atoms/Spinner";
import { accountInfoAtom } from '../../atoms/AccountInfo';

import { CallbackContext } from '../Context';

import { TicketsSalesStatCard } from "./components/PurchaseStatisticTable";

interface IPurchaseData {
  x: string | number;
  y: string | number;
}

interface IPurchaseStat {
  id: string;
  color: string;
  data: IPurchaseData[];
}

export function PurchaseStatTab(props: StatisticTabProps) {
  const accountInfo = useRecoilValue(accountInfoAtom);
  let today = new Date();
  let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  const ticketStatChartRef = React.useRef<any>(null);
  
  const { t, i18n } = useTranslation(['statistic', 'filters']);

  const translations: any = (i18n.options.resources && Object.keys(i18n.options.resources).includes(i18n.language)) ? i18n.options.resources[i18n.language] : {};
  
  const [statDateStart, setStatDateStart] = React.useState<string>(builtISODate(firstDayOfMonth));
  const [statDateStop, setStatDateStop] = React.useState<string>(builtISODate(lastDayOfMonth));
  
  const setOpenSpinner = useSetRecoilState(spinnerAtom);
  const [value, setValue] = React.useState<string>('manager');
  const [statData, setStatData] = React.useState<IPurchaseStat[]>([]);
  const [maxValue, setMaxValue] = React.useState<number>(0);

  const [firstRender, setFirstRender] = React.useState<boolean>(true);
  const [ticketsSalesStat, setTicketsSalesStat] = React.useState<ITicketsSalesStat[]>([])
  const apiCallback: CallableFunction = React.useContext(CallbackContext);

  const getPurchaseStat = async () => {
    setOpenSpinner({ open: true });
    const api = new BaseApi(1, 'management/sails/statistic/');
    let res = await api.get(
      { date_start: statDateStart, date_stop: statDateStop, stat_divider: value },
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      let _totals: number[] = [];
      let _data: IPurchaseStat[] = [];
      let _data_item: IPurchaseStat = { id: '', color: '', data: [] };
      let _color: number = 20;
      let _startDate = new Date(statDateStart);
      let _stopDate = new Date(statDateStop);
      let _lastPurchaseAmount: number = 0;
      for (let k of Object.keys(res.body.statistic)) {
        _data_item = { id: '', color: '', data: [] };
        _data_item.id = res.body.statistic[k][value];
        if (value === 'club_unit') {
          _data_item.id = res.body.statistic[k][value].name;
        } else {
          _data_item.id = `${res.body.statistic[k][value].first_name} ${res.body.statistic[k][value].last_name}`;
        }
        _data_item.color = `hsl(${_color}, 70%, 50%)`;
        _data_item.data = [];
        _lastPurchaseAmount = 0;
        _startDate = new Date(statDateStart);
        while (_startDate <= _stopDate) {
          _lastPurchaseAmount = res.body.statistic[k].data[builtISODate(_startDate)] || _lastPurchaseAmount;
          _data_item.data.push(
            {
              x: builtISODate(_startDate),
              y: _lastPurchaseAmount,
            }
          )
          _startDate.setDate(_startDate.getDate() + 1);
        }
        _data.push(_data_item);
        _totals.push(res.body.statistic[k].total);
        _color += 40;
      }
      setStatData(_data);
      setMaxValue(Math.max( ..._totals ));
    };
    setFirstRender(false);
    setOpenSpinner({ open: false });
  };

  const getTicketsSalesStat = async () => {
    await getData(
      setOpenSpinner,
      accountInfo,
      apiCallback,
      (data: any) => {
        setTicketsSalesStat(
          data.map(
            ( d: any ) => { return {
              id: d.id,
              ticket_name: d.ticket_name,
              start_date: d.start_date,
              expire_date: d.expire_date,
              closing_date: d.closing_date,
              coaches: d.coaches.map(
                 ( c: any ) => { return {
                  id: c.id,
                  first_name: c.first_name,
                  last_name: c.last_name,
                  phone: c.phone,
                  email: c.email,
                } as UserInfoCommon }
              ),
              client: {
                id: d.client.id,
                first_name: d.client.first_name,
                last_name: d.client.last_name,
                phone: d.client.phone,
                email: d.client.email,
              } as UserInfoCommon,
              purchase_date: d.purchase_date,
              club_units: d.club_units.map(
                 ( cu: any ) => { return {
                  id: cu.id,
                  name: cu.name,
                } as ClubUnit }
              ),
              price: d.price,
              discount: d.discount,
              payed_amount: d.payed_amount,
            } as ITicketsSalesStat }
          )
        )
      },
      2,
      'management/tickets/sales/statistic/',
      {date_start: statDateStart, date_stop: statDateStop},
    )
  }

  useEffect(() => {
    if (props.statType !== 'sales_stat') { return }
    getPurchaseStat();
    getTicketsSalesStat();
  }, [props.statType]);

  useEffect(() => {
    if (firstRender) { return }
    getPurchaseStat();
    getTicketsSalesStat();
  }, [value]);

  useEffect(() => {
    if (firstRender) { return }
    getPurchaseStat();
    getTicketsSalesStat();
  }, [statDateStart]);

  useEffect(() => {
    if (firstRender) { return }
    getPurchaseStat();
    getTicketsSalesStat();
  }, [statDateStop]);

  return (
    <Box>
      <Box>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={(event: React.SyntheticEvent, newValue: string) => {
                  setValue(newValue);
                }}
                aria-label="Profile page"
              >
                <Tab
                  label={t('purchase_stat.managers')}
                  value='manager'
                />
                <Tab
                  label={t('purchase_stat.club_units')}
                  value='club_unit'
                />
                <Tab
                  label={t('purchase_stat.coaches')}
                  value='coach'
                />
              </TabList>
            </Box>
            <Box
              sx={{
                width: 'fit-content',
                padding: '5px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <TextField
                id="bid-date-from-id"
                label={t('date_from', { ns: 'filters' })}
                sx={{ paddingLeft: '5px', paddingRight: '5px' }}
                variant="standard"
                type="date"
                onChange={(e) => {setStatDateStart(e.target.value)}}
                defaultValue={statDateStart}
              />
              <TextField
                id="bid-date-to-id"
                label={t('date_to', { ns: 'filters' })}
                sx={{ paddingLeft: '5px', paddingRight: '5px' }}
                variant="standard"
                type="date"
                onChange={(e) => {setStatDateStop(e.target.value)}}
                defaultValue={statDateStop}
              />
            </Box>
            {['club_unit', 'manager', 'coach'].map((tab) => {
              return (
                <TabPanel value={tab} key={`${tab}-key`}>
                  <Box sx={{ marginBottom: '5px' }}>
                    <Card sx={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                      <CardContent sx={{ height: '600px' }}>
                        <ResponsiveLine
                          data={statData}
                          isInteractive={Boolean(statData.length)}
                          margin={{ top: 50, right: 110, bottom: 100, left: 60 }}
                          xScale={{ type: 'point' }}
                          yScale={{
                              type: 'linear',
                              min: 'auto',
                              stacked: false,
                              reverse: false,
                              max: maxValue,
                          }}
                          colors={{ scheme: 'spectral' }}
                          lineWidth={3}
                          yFormat=" >-.2f"
                          axisTop={null}
                          axisRight={null}
                          axisBottom={{
                              tickSize: 5,
                              tickPadding: 5,
                              tickRotation: 60,
                              legend: 'Дата',
                              legendOffset: 60,
                              legendPosition: 'middle',
                          }}
                          axisLeft={{
                              tickSize: 5,
                              tickPadding: 5,
                              tickRotation: 0,
                              legend: 'Сумма',
                              legendOffset: -50,
                              legendPosition: 'middle'
                          }}
                          pointSize={10}
                          pointColor={{ theme: 'background' }}
                          pointBorderWidth={2}
                          pointBorderColor={{ from: 'serieColor' }}
                          pointLabelYOffset={-12}
                          useMesh={true}
                          legends={[
                              {
                                  anchor: 'bottom-right',
                                  direction: 'column',
                                  justify: false,
                                  translateX: 100,
                                  translateY: 0,
                                  itemsSpacing: 0,
                                  itemDirection: 'left-to-right',
                                  itemWidth: 80,
                                  itemHeight: 20,
                                  itemOpacity: 0.75,
                                  symbolSize: 12,
                                  symbolShape: 'circle',
                                  symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                  effects: [
                                      {
                                          on: 'hover',
                                          style: {
                                              itemBackground: 'rgba(0, 0, 0, .03)',
                                              itemOpacity: 1
                                          }
                                      }
                                  ]
                              }
                          ]}
                        />
                      </CardContent>
                    </Card>
                  </Box>
                </TabPanel>
              )
            })}
            <Card
              sx={{
                width: '95%',
                marginLeft: 'auto',
                marginRight: 'auto',
                padding: '24px',
                boxSizing: 'border-box',
              }}
            >
              <CardContent sx={{ height: '600px' }}>
                <TicketsSalesStatCard data={ticketsSalesStat} />
              </CardContent>
            </Card>
          </TabContext>
        </Box>
      </Box>
      
    </Box>
  )
}
