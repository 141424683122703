import React from "react";

import { Box, Button, Input } from "@mui/material";

import { useTranslation } from 'react-i18next';

import { AuthProps } from "../../interfaces/Common";

export function Auth(props: AuthProps) {
  const { t } = useTranslation('forms');
  
  return (
    <Box
      component="form"
      onSubmit={
        (e: any) => {
          e.preventDefault();
          props.signIn(e);
        }
      }
    >
      <Input
        id="username-input-id"
        placeholder="username"
        required={true}
        name="username"
        type="text"
        fullWidth={true}
      />
      <Input
        id="password-input-id"
        placeholder="password"
        required={true}
        name="password"
        type="password"
        fullWidth={true}
      />
      <Button
        type="submit"
        fullWidth={true}
      >
        {t('auth.btn')}
      </Button>
    </Box>
  )
}
