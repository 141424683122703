import { useEffect, useState, useRef } from 'react';

import { useRecoilState, useRecoilValue } from "recoil";
import { Mention, MentionsInput } from 'react-mentions';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

import { useTranslation } from 'react-i18next';

import { BaseApi } from "../../api/base";

import { ChatMessage } from './Comments';
import { BootstrapDialogWidth } from '../common/Dialogs';

import {
  IComment,
  ICommentUser,
  ICommentsProps,
  IGroupedComments,
  IObjGroupedCommentsProps,
  BaseCommentsProps,
  DirectMessageChat,
} from '../../interfaces/Comments';
import { CommentTargetType, QueryObj, BodyObj } from '../../interfaces/Types';
import { ClientInfo } from '../../interfaces/User';
import { Bid } from '../../interfaces/Bids';

import { employeesStaffAtom } from '../../atoms/Employees';

interface ITargetObjComments extends BaseCommentsProps {
  targetObject: ClientInfo | Bid | DirectMessageChat;
  messages: IGroupedComments;
}

interface ITargetObjCommentsDialog extends BaseCommentsProps {
  targetObject: ClientInfo | Bid | DirectMessageChat;
  show: number;
  closeChat: CallableFunction;
}

export function ObjCommentsGroup(props: IObjGroupedCommentsProps) {
  const { t } = useTranslation('chat');

  const messageBoxRef = useRef(null);

  const [message, setMessage] = useState<string>('');
  const employeesStaff = useRecoilValue(employeesStaffAtom);

  const scrollToBottom = () => {
    messageBoxRef.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
  }

  useEffect(() => {
    scrollToBottom();
  }, [props.messages]);

  const renderHeaderByObj = () => {
    switch (props.targetType) {
      case CommentTargetType.UserTarget:
        return <Typography variant='h6' sx={{ marginLeft: '5px' }}>
          {`${t('client')}: ${props.targetObject.first_name} ${props.targetObject.last_name}`}
        </Typography>
      case CommentTargetType.BidTarget:
        return <Typography variant='h6' sx={{ marginLeft: '5px' }}>
          {`${t('bid')}: ${(props.targetObject.child) ? props.targetObject.child_name : props.targetObject.contact_name}`}
        </Typography>
      default:
        return <Typography variant='h6' sx={{ marginLeft: '5px' }}>
          {`${t('chat')}`}
        </Typography>
    }
  }

  useEffect(() => {
    if (!message) { return }
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        sendMessage();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [message]);

  const sendMessage = () => {
    if (!message) { return }
    props.sendSocketMessage(message);
    setMessage('');
  }

  return (
    <Box>
      {renderHeaderByObj()}
      {props.spinner
        ? <Box>
            <CircularProgress color="inherit" />
          </Box>
        : ''
      }
      <Box
        sx={{
          height: 'calc(100vh - 200px)',
        }}
        className='scrollable'
      >
        {props.messages.messages.map(
          (mes: IComment) => {
            return (
              <ChatMessage
                key={`object-key-message-${mes.pk}`}
                message={mes}
                users={props.messages.users}
                appState={props.appState}
                appSetState={props.appSetState}
                device={props.device}
              />
            )
          }
        )}
        <div ref={messageBoxRef}></div>
      </Box>
      <Box>
        <Paper
          component="form"
          sx={{
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: 700,
            maxWidth: '90%!important',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <MentionsInput
				    	placeholder={t('message')}
				    	value={message}
              onChange={(e: any) =>
				    		setMessage(e.target.value)
				    	}
				    	a11ySuggestionsListLabel={'Suggested mentions'}
              allowSuggestionsAboveCursor={true}
              forceSuggestionsAboveCursor={true}
              style={
                {
                  control: {
                    backgroundColor: 'transparent',
                    fontSize: 14,
                    fontWeight: 'normal',
                  },
                
                  '&multiLine': {
                    highlighter: {
                      padding: 9,
                      border: 'none',
                    },
                    input: {
                      padding: 9,
                      border: 'none',
                      outline: 'none',
                    },
                  },
                
                  '&singleLine': {
                    display: 'inline-block',
                    width: 180,
                
                    highlighter: {
                      padding: 1,
                      border: 'none',
                    },
                    input: {
                      padding: 1,
                      border: 'none',
                      outline: 'none',
                    },
                  },
                
                  suggestions: {
                    list: {
                      backgroundColor: 'var(--grey)',
                      border: '1px solid var(--orange)',
                      fontSize: '18px',
                      paddingTop: '5px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      maxHeight: '300px',
                      overflow: 'auto',
                    },
                    item: {
                      padding: '5px 15px',
                      borderBottom: '1px solid var(--orange)',
                      '&focused': {
                        backgroundColor: 'transparent',
                      },
                    },
                  },
                }
              }
				    >
				    	<Mention
                data={employeesStaff.items.map(
                  (emp) => { return {id: emp.id, display: `${emp.first_name} ${emp.last_name}`} }
                )}
                style={
                  {
                    position: 'relative',
                    zIndex: '1',
                    color: '#1673ed',
                    textDecoration: 'none',
                    pointerEvents: 'none',
                  }
                }
              />
				    </MentionsInput>
          </Box>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton
            color="primary"
            sx={{ p: '10px' }}
            aria-label="send-message"
            onClick={sendMessage}
          >
            <ArrowCircleRightIcon />
          </IconButton>
        </Paper>
      </Box>
    </Box>
  )
}

export function TargetObjComments(props: ITargetObjComments) {
  const [objComments, setObjComments] = useState<IGroupedComments>(
    {
      messages: [], target_id: props.targetObject?.id, users: []
    }
  );
  const [commentTargetType, setCommentTargetType] = useState<CommentTargetType | null>(null);
  const [spinner, setSpinner] = useState<boolean>(true);
  const [messagesArrQuery, setMessagesArrQuery] = useState<QueryObj>(
    {
      limit: 10,
      offset: 0,
      all_messages: 1,
    }
  );
  const [employeesStaff, setEmployeesStaff] = useRecoilState(employeesStaffAtom);

  const getComments = async () => {
    if (messagesArrQuery.target_type === undefined) { return }
    const api = new BaseApi(1, 'management/chat/messages/');
    setSpinner(true);
    let res = await api.get(
      messagesArrQuery,
      props.appSetState,
      props.appState,
    );
    if (res.status !== 200) { return };
    let _messages = [...res.body.messages.reverse(), ...objComments.messages];
    setObjComments(
      {
        messages: _messages,
        target_id: props.targetObject.id,
        users: res.body.users,
      }
    );
    setSpinner(false);
  };

  const getEmployeesStaff = async () => {
    if (employeesStaff?.items?.length) { return }
    setSpinner(true);
    const api = new BaseApi(1, 'management/club/staff/employees/');
    let res = await api.get(
      {},
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      setEmployeesStaff({items: res.body});
    };
    setSpinner(false);
  };

  const createComment = async (data: BodyObj) => {
    const api = new BaseApi(1, 'management/chat/messages/');
    let res = await api.post(
      data, 'application/json', props.appSetState, props.appState,
    );
    if (res.status === 200) {
      let _messages = objComments.messages;
      _messages.push(res.body.message);
      let _users = objComments.users;
      _users.push(...res.body.users);
      setObjComments(
        {
          messages: _messages,
          target_id: props.targetObject.id,
          users: _users,
        }
      );
    }
  }

  const sendMessage = (message: string) => {
    let _mentions: number[] = [];
    // if (props.targetObject?.employees?.length) {
    //   for (let dep of props.departments) {
    //     for (let emp of dep.employees) {
    //       if (props.targetObject.employees.includes(emp.id)) {
    //         _mentions.push(emp.employee.id);
    //       }
    //     }
    //   }
    // }
    if (message.match(/\(\d+\)/g)) {
      _mentions = message.match(/\(\d+\)/g).map(
        (pk) => {return Number(pk.replace('(', '').replace(')', ''))}
      )
    }
    let data: BodyObj = {
      author_id: props.appState.account_info.id,
      message: message,
      target_id: props.targetObject.id,
      target_type: commentTargetType,
      mentioned_users: _mentions,
    };
    // props.sendSocketMessage(JSON.stringify(data));
    createComment(data);
  }

  useEffect(() => {
    getEmployeesStaff();
    if (props.targetObject.type !== undefined) {
      setCommentTargetType(CommentTargetType.UserTarget);
    } else if (props.targetObject.source !== undefined) {
      setCommentTargetType(CommentTargetType.BidTarget);
    } else {
      setCommentTargetType(CommentTargetType.EmptyTarget);
    }
  }, []);

  useEffect(() => {
    if (commentTargetType === null) { return }
    setMessagesArrQuery(
      {
        ...messagesArrQuery,
        target_type: commentTargetType,
        target_id: props.targetObject.id,
      }
    )
  }, [commentTargetType]);

  useEffect(() => {
    if (objComments.messages.length) { return }
    getComments()
  }, [messagesArrQuery]);
  
  return (
    <Box>
      <ObjCommentsGroup
        device={props.device}
        appState={props.appState}
        appSetState={props.appSetState}
        messages={objComments}
        sendSocketMessage={sendMessage}
        targetObject={props.targetObject}
        spinner={spinner}
        targetType={commentTargetType}
        departments={props.departments}
      />
    </Box>
  )
}

export function TargetObjCommentsDialog(props: ITargetObjCommentsDialog) {
  return (
    <BootstrapDialogWidth
      open={props.show === props.targetObject.id}
      onClose={(e) => { props.closeChat() }}
    >
      <DialogContent
        sx={{
          overflow: 'hidden',
          height: '90vh',
          overflowY: 'auto',
        }}
      >
        {props.show === props.targetObject.id
          ? <TargetObjComments
              device={props.device}
              appState={props.appState}
              appSetState={props.appSetState}
              messages={{messages: [], target_id: props.targetObject.id, users: []}}
              sendSocketMessage={props.sendSocketMessage}
              targetObject={props.targetObject}
              departments={props.departments}
            />
          : ''
        }
      </DialogContent>
    </BootstrapDialogWidth>
  )

}
