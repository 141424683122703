import React, { useEffect, FormEvent } from "react";

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useTranslation } from 'react-i18next';

import { BaseApi } from "../../api/base";

import { buildISODateTime } from "../../helpers";

import { QueryObj, BodyObj } from '../../interfaces/Types';
import { TicketChangeRequestsArrProps } from "../../interfaces/Props";
import {
  ITicketChangeRequestCommon,
  ITicketChangeRequestFull,
} from "../../interfaces/TicketChangeRequests";

import { BootstrapDialog } from "../common/Dialogs";
import { ApproveAction } from "../common/ActionApprove";

interface Column {
  id: 'author' | 'client' |  'ticket' | 'description' | 'request_date';
  minWidth?: number;
  align?: 'right';
}

interface ColumnChanges {
  id: 'field' | 'changes' |  'ticket';
  minWidth?: number;
  align?: 'right';
}

const columns: readonly Column[] = [
  { id: 'author', minWidth: 150 },
  { id: 'client', minWidth: 150 },
  { id: 'ticket', minWidth: 150 },
  { id: 'description', minWidth: 250 },
  { id: 'request_date', minWidth: 120 },
];

const changesColumns: readonly ColumnChanges[] = [
  { id: 'field', minWidth: 100 },
  { id: 'changes', minWidth: 150 },
  { id: 'ticket', minWidth: 150 },
]

interface ITicketChangeRequestDetailProps {
  changeRequest: ITicketChangeRequestFull | null;
  applyRequest: CallableFunction;
  declineRequest: CallableFunction;
}

export function TicketChangeRequestsPage(props: TicketChangeRequestsArrProps) {
  const { t } = useTranslation(['tables', 'forms', 'common', 'filters']);

  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [requestsCount, setRequestsCount] = React.useState<number>(0);
  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null);
  const [openMenu, setOpenMenu] = React.useState<number>();
  const [requestsQuery, setRequestsQuery] = React.useState<QueryObj>(
    {
      limit: rowsPerPage,
      offset: rowsPerPage * page,
    }
  );
  const [requestsArr, setRequestsArr] = React.useState<ITicketChangeRequestCommon[]>([]);
  const [firstRender, setFirstRender] = React.useState<boolean>(true);
  const [viewRequest, setViewRequest] = React.useState<number>(0);
  const [acceptRequest, setAcceptRequest] = React.useState<number>(0);
  const [declineRequest, setDeclineRequest] = React.useState<number>(0);
  const [editingTicket, setEditingTicket] = React.useState<ITicketChangeRequestFull | null>(null);

  const getTicketChangeRequests = async () => {
    const api = new BaseApi(1, 'user/ticket_changes/');
    props.handleOpenSpinner();
    let res = await api.get(requestsQuery, props.appSetState, props.appState);
    if (res.status === 200) {
      setRequestsArr(res.body.results);
    };
    setFirstRender(false);
    props.handleCloseSpinner();
  };

  const getTicketChangeRequestDetail = async () => {
    const api = new BaseApi(1, `user/ticket_changes/${viewRequest}`);
    props.handleOpenSpinner();
    let res = await api.get({}, props.appSetState, props.appState);
    if (res.status === 200) {
      setEditingTicket(res.body);
    };
    props.handleCloseSpinner();
  };

  const acceptDeclineRequestChange = async (status: number) => {
    let _ticketId: number = 0;
    if (acceptRequest) {
      _ticketId = acceptRequest;
    } else if (declineRequest) {
      _ticketId = declineRequest;
    } else if (viewRequest) {
      _ticketId = viewRequest;
    }
    if (!_ticketId) { return }
    const api = new BaseApi(1, `user/ticket_changes/${_ticketId}/`);
    props.handleOpenSpinner();
    let res = await api.post(
      { status: status },
      'application/json',
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      setRequestsArr(
        requestsArr.filter(r => r.id !== _ticketId)
      );
    };
    setEditingTicket(null);
    setViewRequest(0);
    handleCloseRequestView();
    props.handleCloseSpinner();
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>, req_id: number) => {
    setOpenMenu(req_id);
    setAnchorMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorMenu(null);
    setOpenMenu(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseRequestView = () => {
    setViewRequest(0);
  };

  useEffect(() => {
    getTicketChangeRequests();
  }, [requestsQuery]);

  useEffect(() => {
    if (firstRender) { return }
    setRequestsQuery(
      {...requestsQuery, offset: rowsPerPage * page}
    );
  }, [page]);

  useEffect(() => {
    if (firstRender) { return }
    setRequestsQuery(
      {...requestsQuery, limit: rowsPerPage, offset: rowsPerPage * page}
    );
  }, [rowsPerPage]);

  useEffect(() => {
    if ( viewRequest === 0 ) { return }
    getTicketChangeRequestDetail();
  }, [viewRequest]);
  
  return (
    <Box>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: '74vh' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={`cell-${column.id}`}
                    align={column.align}
                    style={{ minWidth: column.minWidth, backgroundColor: 'var(--orange)' }}
                  >
                    {t(`heads.${column.id}`)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {requestsArr.map((req, index) => {
                return (
                  <TableRow tabIndex={-1} key={`row-key-${index}`}>
                    <TableCell key={`key-created-by-${index}`}>
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={openMenu === req.id ? 'long-menu' : undefined}
                        aria-expanded={openMenu === req.id ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={(e) => {handleClick(e, req.id)}}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      {req.created_by.first_name} {req.created_by.last_name}
                    </TableCell>
                    <TableCell key={`key-user-${index}`}>
                      <Typography>
                        {req.user.first_name} {req.user.last_name}
                      </Typography>
                    </TableCell>
                    <TableCell key={`key-ticket-${index}`}>
                      <Typography>{req.ticket}</Typography>
                    </TableCell>
                    <TableCell key={`key-description-${index}`}>
                      <Typography>{req.reason}</Typography>
                    </TableCell>
                    <TableCell key={`key-request-date-${index}`}>
                      <Typography>
                        {buildISODateTime(new Date(req.created_at.split('+')[0]))}
                      </Typography>
                    </TableCell>
                    <Menu
                      id={`coach-menu-id-${index}`}
                      MenuListProps={{
                        'aria-labelledby': 'long-button',
                      }}
                      anchorEl={anchorMenu}
                      open={openMenu === req.id}
                      onClose={handleClose}
                    >
                      <MenuItem
                        key={`menu_item-edit-${index}`}
                        onClick={(e) => {
                          setViewRequest(req.id);
                          handleClose();
                        }}
                        className="control-menu-item-wrapper"
                      >
                        {t("actions.view")}
                      </MenuItem>
                      <MenuItem
                        key={`menu_item-remove-${index}`}
                        onClick={
                          (e) => {
                            setAcceptRequest(req.id);
                            handleClose()
                          }
                        }
                        className="control-menu-item-wrapper"
                      >
                        {t("actions.accept")}
                      </MenuItem>
                      <MenuItem
                        key={`menu_item-remove-${index}`}
                        onClick={
                          (e) => {
                            setDeclineRequest(req.id);
                            handleClose()
                          }
                        }
                        className="control-menu-item-wrapper"
                      >
                        {t("actions.decline")}
                      </MenuItem>
                    </Menu>
                    <BootstrapDialog
                      key={`edit-request-${req.id}`}
                      onClose={handleCloseRequestView}
                      open={Boolean(viewRequest === req.id && editingTicket)}
                      fullScreen
                    >
                      <AppBar sx={{ position: 'relative' }}>
                        <Toolbar sx={{ backgroundColor: 'var(--black)' }}>
                          <IconButton
                            edge="start"
                            color="inherit"
                            onClick={(e) => {handleCloseRequestView()}}
                            aria-label="close"
                          >
                            <CloseIcon />
                          </IconButton>
                          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {req.ticket} ({req.user.first_name} {req.user.last_name})
                          </Typography>
                        </Toolbar>
                      </AppBar>
                      <DialogContent dividers className="box-content-center">
                        <TicketChangeTable
                          changeRequest={editingTicket}
                          applyRequest={acceptDeclineRequestChange}
                          declineRequest={handleCloseRequestView}
                        />
                      </DialogContent>
                    </BootstrapDialog>
                    <ApproveAction
                      handleApprove={() => acceptDeclineRequestChange(2)}
                      handleDecline={() => setAcceptRequest(0)}
                      action={'accept_change_request'}
                      show={acceptRequest}
                      obj_pk={req.id}
                    />
                    <ApproveAction
                      handleApprove={() => acceptDeclineRequestChange(3)}
                      handleDecline={() => setDeclineRequest(0)}
                      action={'decline_change_request'}
                      show={declineRequest}
                      obj_pk={req.id}
                    />
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={requestsCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  )
}

function TicketChangeTable(props: ITicketChangeRequestDetailProps) {
  const { t } = useTranslation(['tables', 'common', 'ticket_change_request']);

  return (
    <Box>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: '74vh' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {changesColumns.map((column) => (
                  <TableCell
                    key={`cell-${column.id}`}
                    align={column.align}
                    style={{ minWidth: column.minWidth, backgroundColor: 'var(--orange)' }}
                  >
                    {t(`heads.${column.id}`)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow tabIndex={-1} key={`row-key-ticket`}>
                <TableCell key={`key-ticket-field-name`}>
                  <Typography>{t('ticket', { ns: 'ticket_change_request' })}</Typography>
                </TableCell>
                <TableCell key={`key-ticket-change-value`}>
                  <Typography>
                    {props.changeRequest?.ticket}
                  </Typography>
                </TableCell>
                <TableCell key={`key-ticket-current-value`}>
                  <Typography>
                    {props.changeRequest?.user_ticket?.ticket?.ticket}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow tabIndex={-1} key={`row-key-discount`}>
                <TableCell key={`key-discount-field-name`}>
                  <Typography>{t('discount', { ns: 'ticket_change_request' })}</Typography>
                </TableCell>
                <TableCell key={`key-discount-change-value`}>
                  <Typography>
                    {props.changeRequest?.discount}
                  </Typography>
                </TableCell>
                <TableCell key={`key-discount-current-value`}>
                  <Typography>
                    {props.changeRequest?.user_ticket?.discount}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow tabIndex={-1} key={`row-key-discipline`}>
                <TableCell key={`key-discipline-field-name`}>
                  <Typography>{t('disciplines', { ns: 'ticket_change_request' })}</Typography>
                </TableCell>
                <TableCell key={`key-discipline-change-value`}>
                  <Typography>
                    {props.changeRequest?.discipline?.map((d) => {return d.name}).toString()}
                  </Typography>
                </TableCell>
                <TableCell key={`key-discipline-current-value`}>
                  <Typography>
                    {props.changeRequest?.user_ticket?.discipline?.map((d) => {return d.name}).toString()}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow tabIndex={-1} key={`row-key-coaches`}>
                <TableCell key={`key-coaches-field-name`}>
                  <Typography>{t('coaches', { ns: 'ticket_change_request' })}</Typography>
                </TableCell>
                <TableCell key={`key-coaches-change-value`}>
                  <Typography>
                    {props.changeRequest?.coaches?.map((c) => {return `${c.first_name} ${c.last_name}`}).toString()}
                  </Typography>
                </TableCell>
                <TableCell key={`key-coaches-current-value`}>
                  <Typography>
                    {props.changeRequest?.user_ticket?.coaches.map((c) => {return `${c.first_name} ${c.last_name}`}).toString()}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow tabIndex={-1} key={`row-key-start-date`}>
                <TableCell key={`key-start-date-field-name`}>
                  <Typography>{t('date_start', { ns: 'ticket_change_request' })}</Typography>
                </TableCell>
                <TableCell key={`key-start-date-change-value`}>
                  <Typography>
                    {props.changeRequest?.start_date}
                  </Typography>
                </TableCell>
                <TableCell key={`key-start-date-current-value`}>
                  <Typography>
                    {props.changeRequest?.user_ticket?.start_date}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow tabIndex={-1} key={`row-key-expire-date`}>
                <TableCell key={`key-expire-date-field-name`}>
                  <Typography>{t('date_stop', { ns: 'ticket_change_request' })}</Typography>
                </TableCell>
                <TableCell key={`key-expire-date-change-value`}>
                  <Typography>
                    {props.changeRequest?.expire_date}
                  </Typography>
                </TableCell>
                <TableCell key={`key-expire-date-current-value`}>
                  <Typography>
                    {props.changeRequest?.user_ticket?.expire_date}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow tabIndex={-1} key={`row-key-active`}>
                <TableCell key={`key-active-field-name`}>
                  <Typography>{t('active', { ns: 'ticket_change_request' })}</Typography>
                </TableCell>
                <TableCell key={`key-active-change-value`}>
                  <Typography>
                    {props.changeRequest?.active}
                  </Typography>
                </TableCell>
                <TableCell key={`key-active-current-value`}>
                  <Typography>
                    {props.changeRequest?.user_ticket?.active}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow tabIndex={-1} key={`row-key-payed`}>
                <TableCell key={`key-active-field-name`}>
                  <Typography>{t('payed', { ns: 'ticket_change_request' })}</Typography>
                </TableCell>
                <TableCell key={`key-payed-change-value`}>
                  <Typography>
                    {props.changeRequest?.payed}
                  </Typography>
                </TableCell>
                <TableCell key={`key-payed-current-value`}>
                  <Typography>
                    {props.changeRequest?.user_ticket?.payed}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow tabIndex={-1} key={`row-key-frozen`}>
                <TableCell key={`key-frozen-field-name`}>
                  <Typography>{t('frozen', { ns: 'ticket_change_request' })}</Typography>
                </TableCell>
                <TableCell key={`key-frozen-change-value`}>
                  <Typography>
                    {props.changeRequest?.frozen}
                  </Typography>
                </TableCell>
                <TableCell key={`key-frozen-current-value`}>
                  <Typography>
                    {props.changeRequest?.user_ticket?.frozen}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Divider />
      <DialogActions
        sx={{ marginBottom: "15px" }}
      >
        <Button
          variant="outlined"
          color="error"
          onClick={(e) => {props.applyRequest(3)}}
        >
          {t("decline", { ns: 'common' })}
        </Button>
        <Button
          variant="contained"
          color="info"
          onClick={(e) => {props.applyRequest(2)}}
        >
          {t("accept", { ns: 'common' })}
        </Button>
      </DialogActions>
    </Box>
  )
}
