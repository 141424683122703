import { BaseApi } from './base';

export const getData = async (
  setSpinner: CallableFunction,
  accountInfo: any,
  errback: CallableFunction,
  callback: CallableFunction,
  v: number,
  url: string,
  query: any,
) => {
  setSpinner({ open: true });
  const api = new BaseApi(v, url, query);
  let res = await api.get(query, errback, accountInfo);
  if (res.status === 200) {
    callback(res.body)
  }
  setSpinner({ open: false });
}
