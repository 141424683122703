import React from "react";
import { useEffect } from 'react';

import { useRecoilState } from 'recoil';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

import { useTranslation } from 'react-i18next';

import { BaseApi } from "../../api/base";

import { builtISODate } from "../../helpers";

import { accountInfoAtom } from "../../atoms/AccountInfo";

interface IManagersStatProps {
  statType: string;
}

interface IClubUnitManagerShifting {
  id: number;
  hours: number;
  minutes: number;
  name: string;
}

interface IManagerShifting {
  id: number;
  first_name: string;
  last_name: string;
  club_units: IClubUnitManagerShifting[];
}

export function ManagersStatistic(props: IManagersStatProps) {
  let today = new Date();
  let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  const { t, i18n } = useTranslation(['statistic', 'filters', 'common']);

  const [statDateStart, setStatDateStart] = React.useState<string>(builtISODate(firstDayOfMonth));
  const [statDateStop, setStatDateStop] = React.useState<string>(builtISODate(lastDayOfMonth));

  const [firstRender, setFirstRender] = React.useState<boolean>(true);

  const [spinner, setSpinner] = React.useState<boolean>(false);
  
  const [state, setState] = useRecoilState(accountInfoAtom);
  const [managerStat, setManagerStat] = React.useState<IManagerShifting[]>([]);

  const getManagersTimeShifting = async () => {
    const api = new BaseApi(1, 'management/managers/statistic/');
    let res = await api.get(
      { date_start: statDateStart, date_stop: statDateStop },
      setState,
      state,
    );
    if (res.status === 200) {
      let _managerShifting: IManagerShifting[] = [];
      for (let k of Object.keys(res.body.results)) {
        _managerShifting.push(
          {
            id: Number(k),
            first_name: res.body.results[k].first_name,
            last_name: res.body.results[k].last_name,
            club_units: res.body.results[k].club_units,
          }
        )
      }
      setManagerStat(_managerShifting);
      setFirstRender(false);
    };
  };

  useEffect(() => {
    if (props.statType !== 'managers_stat') { return }
    getManagersTimeShifting();
  }, [props.statType]);

  useEffect(() => {
    if (firstRender) { return }
    getManagersTimeShifting();
  }, [statDateStart]);

  useEffect(() => {
    if (firstRender) { return }
    getManagersTimeShifting();
  }, [statDateStop]);

  return (
    <Box>
      {spinner
        ? <Box
            sx={{
              width: 'fit-content',
              marginLeft: 'auto',
              marginRight: 'auto',
              paddingTop: '5px',
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        : <Box>
            <Box
              sx={{
                width: 'fit-content',
                padding: '5px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <TextField
                id="bid-date-from-id"
                label={t('date_from', { ns: 'filters' })}
                sx={{ paddingLeft: '5px', paddingRight: '5px' }}
                variant="standard"
                type="date"
                onChange={(e) => {setStatDateStart(e.target.value)}}
                defaultValue={statDateStart}
              />
              <TextField
                id="bid-date-to-id"
                label={t('date_to', { ns: 'filters' })}
                sx={{ paddingLeft: '5px', paddingRight: '5px' }}
                variant="standard"
                type="date"
                onChange={(e) => {setStatDateStop(e.target.value)}}
                defaultValue={statDateStop}
              />
            </Box>
            <Box>
              <Card
                sx={{
                  width: '100%',
                  marginRight: '5px',
                }}
              >
                <CardContent>
                  <List>
                  {managerStat.map((manager) => {
                    return (
                      <ListItem key={`manager-stat-key-${manager.id}`}>
                        <Box>
                          <Box sx={{ display: 'flex' }}>
                            <ListItemIcon>
                              <AccountCircleIcon />
                            </ListItemIcon>
                            <ListItemText>
                              {`${manager.first_name} ${manager.last_name}`}
                            </ListItemText>
                          </Box>
                          <List>
                            {manager.club_units.map((shift) => {
                              return (
                                <ListItem key={`club-unit-shift-key-${manager.id}-${shift.id}`}>
                                  <ListItemIcon>
                                    <AccessTimeFilledIcon />
                                  </ListItemIcon>
                                  <ListItemText>
                                    {shift.name}: {shift.hours}{t('hour', { ns: 'common' })} {shift.minutes}{t('minute', { ns: 'common' })}
                                  </ListItemText>
                                </ListItem>
                              )
                            })}
                          </List>
                        </Box>
                      </ListItem>
                    )
                  })}
                  </List>
                </CardContent>
              </Card>
            </Box>
          </Box>
      }
    </Box>
  )
}
