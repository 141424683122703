import React from "react";
import { useEffect } from 'react';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import { SxProps } from '@mui/system';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import AddIcon from '@mui/icons-material/Add';

import { useTranslation } from 'react-i18next';

import { theme as root_theme } from "../../consts";
import { builtISODate } from "../../helpers";

import {
  BidsPageProps,
  AutocompleteProps,
} from "../../interfaces/Props";
import { BodyObj, PKStatusObj } from '../../interfaces/Types';
import { Bid, BidSource } from '../../interfaces/Bids';
import { ClubUnit } from '../../interfaces/Club';
import { NotificationCreate } from "../notifications/Notification";
import { ApproveAction } from "../common/ActionApprove";
import { BidManage, BidCreate } from "./BidManage";
import { TargetObjCommentsDialog } from "../chat/TargetChat";
import { BootstrapDialog, BootstrapDialogWidth } from "../common/Dialogs";

import { BaseApi } from "../../api/base";

interface Column {
  id: 'full_name' | 'contacts' | 'club_unit' | 'bid_date' | 'bid_status' | 'employees';
  minWidth?: number;
  align?: 'right';
}

const columns: readonly Column[] = [
  { id: 'full_name', minWidth: 250 },
  { id: 'contacts', minWidth: 150 },
  { id: 'club_unit', minWidth: 150 },
  { id: 'bid_date', minWidth: 150 },
  { id: 'bid_status', minWidth: 150 },
  { id: 'employees', minWidth: 220 },
];

export function BidsPage(props: BidsPageProps) {
  let today = new Date();
  let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  
  const { t } = useTranslation(['tables', 'forms', 'common', 'filters', 'bids']);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [bidsDateStart, setBidsDateStart] = React.useState<string>(builtISODate(firstDayOfMonth));
  const [bidsDateStop, setBidsDateStop] = React.useState<string>(builtISODate(lastDayOfMonth));
  const [bidsCount, setBidsCount] = React.useState<number>(0);
  const [bidsQuery, setBidsQuery] = React.useState<BodyObj>(
    {
      sort: '-bid_date',
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        date_start: bidsDateStart,
        date_stop: bidsDateStop,
    }
  );
  const [bids, setBids] = React.useState<Bid[]>([]);
  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null);
  const [openMenu, setOpenMenu] = React.useState<number>();
  const [editBid, setEditBid] = React.useState<number>(0);
  const [showFilters, setShowFilters] = React.useState<boolean>(props.device === 'desktop');
  const [filtersSpinner, setFiltersSpinner] = React.useState<boolean>(false);
  const [bidsSearch, setBidsSearch] = React.useState<string>('');
  const [bidsSources, setBidsSources] = React.useState<BidSource[]>([]);
  const [bidsStatuses, setBidsStatuses] = React.useState<PKStatusObj>({});
  const [bidsColors, setBidsColors] = React.useState<PKStatusObj>({});
  const [clubUnits, setClubUnits] = React.useState<ClubUnit[]>([]);
  const [removeBid, setRemoveBid] = React.useState<number>(0);
  const [showNotifyCreate, setShowNotifyCreate] = React.useState<number>(0);
  const [showBidToClient, setShowBidToClient] = React.useState<number>(0);
  const [firstRender, setFirstRender] = React.useState<boolean>(true);
  const [newBid, setNewBid] = React.useState<boolean>(false);
  const [showComments, setShowComments] = React.useState<number>(0);

  const getBids = async () => {
    const api = new BaseApi(1, 'management/bids/');
    props.handleOpenSpinner();
    let res = await api.get(bidsQuery, props.appSetState, props.appState);
    if (res.status === 200) {
      setBids(res.body.results.map((bid: any) => {return bid as Bid}));
      setBidsCount(res.body.count);
    };
    props.handleCloseSpinner();
    setFirstRender(false);
  };

  const manageBid = async (bid: BodyObj) => {
    props.handleOpenSpinner();
    const api = new BaseApi(1, `management/bids/`);
    let res = await api.put(bid, 'application/json', props.appSetState, props.appState);
    if (res.status === 200) {
      setBids(
        bids.map((b) => {
          if (b.id === res.body.id) {
            return res.body as Bid
          } else {
            return b
          }
        })
      );
    }
    setEditBid(0);
    props.handleCloseSpinner();
  };

  const createBid = async (bid: BodyObj) => {
    props.handleOpenSpinner();
    const api = new BaseApi(1, `management/bids/`);
    let res = await api.post(bid, 'application/json', props.appSetState, props.appState);
    setNewBid(false);
    await getBids();
    props.handleCloseSpinner();
  };

  const getFilters = async () => {
    const api = new BaseApi(1, 'management/bid_source/');
    setFiltersSpinner(true);
    let res = await api.get({}, props.appSetState, props.appState);
    if (res.status === 200) {
      setBidsSources(res.body.map((s: any) => {return s as BidSource}));
    }
    api.url = api.create_url(1, 'management/bid_consts/')
    res = await api.get({}, props.appSetState, props.appState);
    if (res.status === 200) {
      setBidsStatuses(res.body.statuses);
      setBidsColors(res.body.colors);
    }
    api.url = api.create_url(1, 'management/club_units/')
    res = await api.get(
      {short: 1, my: 1},
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      setClubUnits(res.body.map((cu: any) => {return cu as ClubUnit}));
    }
    setFiltersSpinner(false);
  };

  const handleRemoveBid = async (bid_id: number) => {
    const api = new BaseApi(1, `management/bids/`);
    props.handleOpenSpinner();
    let res = await api.delete(
      {bid: bid_id},
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      setBids(bids.filter(bid => bid.id !== bid_id));
      setBidsCount(bidsCount - 1);
    };
    setRemoveBid(0);
    props.handleCloseSpinner();
  };
  const handleDeclineRemoveBid = () => {
    setRemoveBid(0);
  };

  const handleBidToClient = async (bid_id: number) => {
    const api = new BaseApi(1, `management/bids/`);
    props.handleOpenSpinner();
    let res = await api.put(
      {bid: bid_id, success: true},
      'application/json',
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      let _bids = bids.map((bid) => {
        if (bid.id === Number(bid_id)) {
          return res.body as Bid
        } else {
          return bid
        }
      });
      setBids(_bids);
    };
    setShowBidToClient(0);
    props.handleCloseSpinner();
  };
  const handleDeclineBidToClient = () => {
    setShowBidToClient(0);
  };

  useEffect(() => {
    getBids();
  }, [bidsQuery]);

  useEffect(() => {
    if (firstRender) { return }
    setBidsQuery(
      {...bidsQuery, offset: rowsPerPage * page}
    );
  }, [page]);

  useEffect(() => {
    if (firstRender) { return }
    setBidsQuery(
      {...bidsQuery, limit: rowsPerPage, offset: rowsPerPage * page}
    );
  }, [rowsPerPage]);

  useEffect(() => {
    if (firstRender) { return }
    setBidsQuery(
      {...bidsQuery, date_start: bidsDateStart}
    );
  }, [bidsDateStart]);

  useEffect(() => {
    if (firstRender) { return }
    setBidsQuery(
      {...bidsQuery, date_stop: bidsDateStop}
    );
  }, [bidsDateStop]);

  useEffect(() => {
    getFilters();
  }, []);

  useEffect(() => {
    setShowFilters(props.device === 'desktop');
  }, [props.device]);

  useEffect(() => {
    if (!bidsSearch) { return }
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        setBidsQuery(
          {...bidsQuery, search: encodeURIComponent(bidsSearch)}
        );
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [bidsSearch]);
  
  const handleClickMenu = (event: React.MouseEvent<HTMLElement>, client_id: number) => {
    setOpenMenu(client_id);
    setAnchorMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorMenu(null);
    setOpenMenu(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  return (
    <Box className="table-wrapper">
      <Paper
        sx={{
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          width: (props.device === 'desktop') ? '50%' : '95%',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginBottom: '5px',
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder={t('phone_search_placeholder', { ns: 'common' })}
          inputProps={{ 'aria-label': 'search google maps' }}
          onChange={(e) => {setBidsSearch(e.target.value)}}
        />
        <IconButton
          type="button"
          sx={{ p: '10px' }}
          aria-label="search"
          onClick={
            (e) => {
              setBidsQuery(
                {...bidsQuery, search: encodeURIComponent(bidsSearch)}
              );
            }
          }
        >
          <SearchIcon />
        </IconButton>
      </Paper>
      <Box sx={{ marginBottom: '5px' }}>
        {props.device === 'mobile'
          ? <Box
              sx={{
                width: 'fit-content',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <Button
                variant="outlined"
                onClick={() => setShowFilters(!showFilters)}
              >
                {t('filters', { ns: 'filters' })}
              </Button>
            </Box>
          : ''
        }
        {filtersSpinner
          ? <Box
              sx={{
                width: 'fit-content',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          : <Box>
              {showFilters
                ? <Box
                    sx={{
                      display: (props.device === 'desktop') ? 'flex' : 'block',
                      justifyContent: 'space-around',
                      width: (props.device === 'desktop') ? '95%' : '70%',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    <Autocomplete
                      disablePortal
                      id="bids-staus-filter-id"
                      options={
                        Object.keys(bidsStatuses).map(
                          (k) => {return {id: k, label: bidsStatuses[Number(k)]}}
                        )
                      }
                      onChange={
                        (
                          e: React.SyntheticEvent,
                          value: AutocompleteProps | null,
                        ) => {
                          if (value && value.id) {
                            setBidsQuery({...bidsQuery, status: value.id});
                          } else {
                            delete bidsQuery.status;
                            setBidsQuery(bidsQuery);
                          }
                        }
                      }
                      sx={{ width: (props.device === 'mobile') ? '100%' : '13%' }}
                      renderInput={
                        (params: any) => <TextField
                          {...params}
                          label={t("bid_status", { ns: 'filters' })}
                        />
                      }
                    />
                    <Autocomplete
                      disablePortal
                      id="bids-source-filter-id"
                      options={
                        bidsSources.map(
                          (bs) => {return {id: bs.id, label: bs.source}}
                        )
                      }
                      onChange={
                        (
                          e: React.SyntheticEvent,
                          value: AutocompleteProps | null,
                        ) => {
                          if (value && value.id) {
                            setBidsQuery({...bidsQuery, bid_source: value.id});
                          } else {
                            delete bidsQuery.bid_source;
                            setBidsQuery(bidsQuery);
                          }
                        }
                      }
                      sx={{ width: (props.device === 'mobile') ? '100%' : '13%' }}
                      renderInput={
                        (params: any) => <TextField
                          {...params}
                          label={t("bid_source", { ns: 'filters' })}
                        />
                      }
                    />
                    <TextField
                      id="bid-date-from-id"
                      label={t('date_from', { ns: 'filters' })}
                      variant="standard"
                      type="date"
                      onChange={(e) => {setBidsDateStart(e.target.value)}}
                      defaultValue={bidsDateStart}
                    />
                    <TextField
                      id="bid-date-to-id"
                      label={t('date_to', { ns: 'filters' })}
                      variant="standard"
                      type="date"
                      onChange={(e) => {setBidsDateStop(e.target.value)}}
                      defaultValue={bidsDateStop}
                    />
                  </Box>
                : ''
              }
            </Box>
        }
      </Box>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: '74vh' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={`cell-${column.id}`}
                    align={column.align}
                    style={{ minWidth: column.minWidth, backgroundColor: 'var(--orange)' }}
                  >
                    {t(`heads.${column.id}`)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {bids.map((bid, index) => {
                return (
                  <TableRow
                    tabIndex={-1}
                    key={`row-key-${index}`}
                    sx={{ backgroundColor: bid.color_val }}
                  >
                    <TableCell key={`key-full_name-${index}`}>
                      <Box>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={openMenu === bid.id ? 'long-menu' : undefined}
                          aria-expanded={openMenu === bid.id ? 'true' : undefined}
                          aria-haspopup="true"
                          onClick={(e) => {handleClickMenu(e, bid.id)}}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        {bid.contact_name}
                      </Box>
                      <FormControlLabel
                        control={
                          <Switch
                            defaultChecked={bid.child}
                            onChange={(event: any) => {
                              manageBid({bid: bid.id, child: event.target.checked});
                            }}
                          />
                        }
                        label={
                          (bid.child)
                            ? `${t('child', { ns: 'bids' })}: ${bid.child_name}`
                            : t('adult', { ns: 'bids' })
                        }
                      />
                    </TableCell>
                    <TableCell key={`key-contacts-${index}`}>
                      <Typography>{bid.contact_phone}</Typography>
                      <Typography>{bid.contact_email}</Typography>
                    </TableCell>
                    <TableCell key={`key-club_unit-${index}`}>
                      <Typography>{bid.club_unit}</Typography>
                    </TableCell>
                    <TableCell key={`key-bid_date-${index}`}>
                      <Typography>{bid.bid_date}</Typography>
                    </TableCell>
                    <TableCell key={`key-bid_status-${index}`}>
                      <Autocomplete
                        disablePortal
                        id="bid-status-managing"
                        options={
                          Object.keys(bidsStatuses).map((k) => {
                            return {id: String(k), label: bidsStatuses[Number(k)]}
                          })
                        }
                        sx={{ width: "100%", marginBottom: "15px" }}
                        value={
                          {id: String(bid.bid_status), label: bid.bid_status_name}
                        }
                        renderInput={(params) => (<TextField {...params} label={t("bids.status")} />)}
                        onChange={(
                          e: React.SyntheticEvent,
                          value: AutocompleteProps | null,
                          reason: string,
                        ) => {
                          if (value) {
                            manageBid({bid: bid.id, bid_status: value.id});
                          }
                        }}
                      />
                      <Box sx={{ width: 'fit-content', marginRight: 'auto', marginLeft: 'auto' }}>
                        {Object.keys(bidsColors).map((k) => {
                          return (
                            <Brightness1Icon
                              sx={{
                                color: bidsColors[Number(k)],
                                cursor: 'pointer',
                                marginRight: '5px',
                                marginLeft: '5px',

                              }}
                              onClick={() => {manageBid({bid: bid.id, color: k})}}
                            />
                          )
                        })}
                      </Box>
                    </TableCell>
                    <TableCell key={`key-employees-${index}`}>
                      {props.departments.map((department) => {
                        return (
                          <Box key={`department-key-${department.id}`}>
                            <Typography>{department.name}</Typography>
                            <List>
                              {department.employees.map((emp) => {
                                if (bid.employees.includes(emp.id)) {
                                  return (
                                    <ListItem key={`department-employee-key-${emp.id}`}>
                                      {`${emp.employee.first_name} ${emp.employee.last_name}`}
                                    </ListItem>
                                  )
                                }
                              })}
                            </List>
                            <Divider />
                          </Box>
                        )
                      })}
                    </TableCell>
                    <Menu
                      id={`client-menu-id-${index}`}
                      MenuListProps={{
                        'aria-labelledby': 'long-button',
                      }}
                      anchorEl={anchorMenu}
                      open={openMenu === bid.id}
                      onClose={handleCloseMenu}
                      data-user_id={bid.id}
                    >
                      <MenuItem
                        key={`menu_item-edit-${index}`}
                        onClick={(e) => {
                          setEditBid(bid.id);
                          handleCloseMenu();
                        }}
                        className="control-menu-item-wrapper"
                      >
                        {t("actions.edit")}
                      </MenuItem>
                      <MenuItem
                        key={`menu_item-remove-${index}`}
                        onClick={
                          (e) => {
                            setRemoveBid(bid.id);
                            handleCloseMenu()
                          }
                        }
                        className="control-menu-item-wrapper"
                      >
                        {t("actions.remove")}
                      </MenuItem>
                      <MenuItem
                        key={`menu_item-notify-${index}`}
                        onClick={
                          (e) => {
                            setShowNotifyCreate(bid.id);
                            handleCloseMenu();
                          }
                        }
                        className="control-menu-item-wrapper"
                      >
                        {t("actions.notify")}
                      </MenuItem>
                      <MenuItem
                        key={`menu_item-create-client-${index}`}
                        onClick={
                          (e) => {
                            setShowBidToClient(bid.id);
                            handleCloseMenu();
                          }
                        }
                        className="control-menu-item-wrapper"
                      >
                        {t("actions.bid_to_client")}
                      </MenuItem>
                      {props.appState.account_info.permissions.includes('comment_bids')
                        ? <MenuItem
                            key={`menu_item-comment-${index}`}
                            onClick={(e) => {
                              setShowComments(bid.id);
                              handleCloseMenu();
                            }}
                            className="control-menu-item-wrapper"
                          >
                            {t("actions.comments")}
                          </MenuItem>
                        : ''
                      }
                    </Menu>
                    <BootstrapDialogWidth
                      key={`edit-bid-${bid.id}`}
                      onClose={() => {setEditBid(0)}}
                      open={editBid === bid.id}
                    >
                      <DialogTitle sx={{ paddingLeft: '5px' }}>
                        {bid.contact_name}
                      </DialogTitle>
                      <DialogContent dividers className="box-content-center">
                        <BidManage
                          bid={bid}
                          departments={props.departments}
                          applyEdit={manageBid}
                          closeEdit={() => {setEditBid(0)}}
                          device={props.device}
                          statuses={bidsStatuses}
                          sources={bidsSources}
                          clubUnits={clubUnits}
                        />
                      </DialogContent>
                    </BootstrapDialogWidth>
                    <ApproveAction
                      handleApprove={handleRemoveBid}
                      handleDecline={handleDeclineRemoveBid}
                      action={'remove_client'}
                      show={removeBid}
                      obj_pk={bid.id}
                    />
                    <BootstrapDialog
                      key={`bid-notify-key-${bid.id}`}
                      open={(showNotifyCreate === bid.id)}
                      onClose={(e) => {setShowNotifyCreate(0)}}
                    >
                      <DialogContent
                        dividers
                        className="box-content-center"
                        sx={{ minWidth: '180px' }}
                      >
                        <NotificationCreate
                          bid_id={bid.id}
                          closeDialog={() => {setShowNotifyCreate(0)}}
                          openPopUp={props.openPopUp}
                          appState={props.appState}
                          appSetState={props.appSetState}
                          device={props.device}
                        />
                      </DialogContent>
                    </BootstrapDialog>
                    <ApproveAction
                      handleApprove={handleBidToClient}
                      handleDecline={handleDeclineBidToClient}
                      action={'bid_to_client'}
                      show={showBidToClient}
                      obj_pk={bid.id}
                    />
                    <TargetObjCommentsDialog
                      targetObject={bid}
                      show={showComments}
                      closeChat={() => {setShowComments(0)}}
                      device={props.device}
                      appState={props.appState}
                      appSetState={props.appSetState}
                      sendSocketMessage={props.sendSocketMessage}
                      departments={props.departments}
                    />
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={bidsCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Zoom
        key="new-client-key"
        in={true}
      >
        <Fab sx={
            {
              position: 'absolute',
              bottom: 36,
              left: 36,
              borderColor: root_theme.palette.info.main,
              borderWidth: '2px',
              borderStyle: 'solid',
              '&:hover': {
                bgcolor: root_theme.palette.background.default,
                borderColor: root_theme.palette.info.main,
              },
            } as SxProps
          }
          aria-label="Add client"
          color='info'
          onClick={(e) => {setNewBid(true)}}
        >
          <AddIcon />
        </Fab>
      </Zoom>
      <BootstrapDialog
        onClose={() => setNewBid(false)}
        open={newBid}
      >
        <DialogTitle sx={{ paddingLeft: '5px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontWeight: '600', fontSize: '1.25em' }}>
              {t('register.header', { ns: 'forms' })}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent
          dividers
          className="box-content-center"
        >
          <BidCreate
            departments={props.departments}
            applyEdit={createBid}
            closeEdit={() => setNewBid(false)}
            device={props.device}
            sources={bidsSources}
            clubUnits={clubUnits}
          />
        </DialogContent>
      </BootstrapDialog>
    </Box>
  )
}
