import React from "react";
import { useEffect } from 'react';

import { useRecoilValue } from 'recoil';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import { SxProps } from '@mui/system';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import DialogActions from '@mui/material/DialogActions';

import AddIcon from '@mui/icons-material/Add';
import DuoIcon from '@mui/icons-material/Duo';
import SearchIcon from '@mui/icons-material/Search';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import ListItemIcon from '@mui/material/ListItemIcon';

import { useTranslation } from 'react-i18next';

import {
  ProfilePageProps,
  AutocompleteProps,
} from "../../interfaces/Props";
import { AccountInfo } from '../../interfaces/AccountInfo';
import { ClubUnit } from '../../interfaces/Club';
import { FormDataObj, PKStatusObj } from "../../interfaces/Types";
import {
  Ticket,
  Promo,
  CoachSalaryObj,
  Videoconf,
  VideoconfCreation,
} from '../../interfaces/Common';

import { theme as root_theme } from "../../consts";

import { BaseApi } from "../../api/base";

import { accountInfoAtom } from '../../atoms/AccountInfo';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    backgroundColor: root_theme.palette.background.default,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: root_theme.palette.info.main,
  },
}));

export function ProfilePage(props: ProfilePageProps) {
  const { t } = useTranslation('profile');
  const [value, setValue] = React.useState('AccountInfoFormTab');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="Profile page">
            <Tab label={t('profile')} value="AccountInfoFormTab" />
            <Tab label={t('tickets')} value="TicketsInfoTab" />
            <Tab label={t('videoconfs')} value="VideoconfsTab" />
          </TabList>
        </Box>
        <TabPanel value="AccountInfoFormTab">
          <AccountInfoFormTab
            appState={props.appState}
            appSetState={props.appSetState}
            handleCloseSpinner={props.handleCloseSpinner}
            handleOpenSpinner={props.handleOpenSpinner}
            openPopUp={props.openPopUp}
            tabName={value}
            device={props.device}
          />
        </TabPanel>
        <TabPanel value="TicketsInfoTab">
          <TicketsInfoTab
            appState={props.appState}
            appSetState={props.appSetState}
            handleCloseSpinner={props.handleCloseSpinner}
            handleOpenSpinner={props.handleOpenSpinner}
            openPopUp={props.openPopUp}
            tabName={value}
            device={props.device}
          />
        </TabPanel>
        <TabPanel value="VideoconfsTab">
          <VideoconfsTab
            appState={props.appState}
            appSetState={props.appSetState}
            handleCloseSpinner={props.handleCloseSpinner}
            handleOpenSpinner={props.handleOpenSpinner}
            openPopUp={props.openPopUp}
            tabName={value}
            device={props.device}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

function AccountInfoFormTab(props: ProfilePageProps) {
  const { t } = useTranslation(['profile', 'common', 'forms']);
  const [disabledSave, setDisabledSave] = React.useState<boolean>(true);

  const updateUser = async (data: FormDataObj) => {
    props.handleOpenSpinner();
    const api = new BaseApi(1, `user/account/${props.appState.account_info.id}/`);
    let res = await api.post(data, 'multipart/form-data', props.appSetState, props.appState);
    if (res.status === 200) {
      props.appSetState({...props.appState, account_info: res.body as AccountInfo});
    }
    props.handleCloseSpinner();
  }

  return (
    <Box>
      <Box
        component="form"
        onSubmit={
          (e: any) => {
            e.preventDefault();
            let data = new FormData(e.currentTarget);
            let data_obj = Object.fromEntries(data);
            delete data_obj.who_confirmed
            delete data_obj.logo
            if (data_obj.email === '' && data_obj.phone === '') {
              props.openPopUp('error', 'phone_email');
            } else {
              updateUser(data_obj);
              setDisabledSave(true);
            }
          }
        }
      >
        <Box sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }} key='first_name-input-key'>
          <Typography sx={{ marginRight: "10px" }}>{t("register.first_name", { ns: 'forms' })}:</Typography>
          <Input
            id="first-name-input-id"
            placeholder={t("register.first_name", { ns: 'forms' })}
            required={true}
            name="first_name"
            type="text"
            onChange={(e) => {
              props.appSetState({...props.appState, account_info: {...props.appState.account_info, first_name: e.target.value}})
              setDisabledSave(false)
            }}
            value={props.appState.account_info.first_name}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }} key='last_name-input-key'>
          <Typography sx={{ marginRight: "10px" }}>{t("register.last_name", { ns: 'forms' })}:</Typography>
          <Input
            id="last-name-input-id"
            placeholder={t("register.last_name", { ns: 'forms' })}
            required={true}
            name="last_name"
            type="text"
            onChange={(e) => {
              props.appSetState({...props.appState, account_info: {...props.appState.account_info, last_name: e.target.value}})
              setDisabledSave(false)
            }}
            value={props.appState.account_info.last_name}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }} key='phone-input-key'>
          <Typography sx={{ marginRight: "10px" }}>{t("register.phone", { ns: 'forms' })}:</Typography>
          <Input
            id="phone-input-id"
            placeholder={t("register.phone", { ns: 'forms' })}
            required={false}
            name="phone"
            type="text"
            onChange={(e) => {
              props.appSetState({...props.appState, account_info: {...props.appState.account_info, phone: e.target.value}})
              setDisabledSave(false)
            }}
            value={props.appState.account_info.phone}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }} key='email-input-key'>
          <Typography sx={{ marginRight: "10px" }}>{t("register.email", { ns: 'forms' })}:</Typography>
          <Input
            id="email-input-id"
            placeholder={t("register.email", { ns: 'forms' })}
            required={false}
            name="email"
            type="text"
            onChange={(e) => {
              props.appSetState({...props.appState, account_info: {...props.appState.account_info, email: e.target.value}})
              setDisabledSave(false)
            }}
            value={props.appState.account_info.email}
          />
        </Box>
        <Box sx={{ width: 'fit-content', marginRight: 'auto', marginLeft: 'auto' }} key='save-btn-key'>
          <Button
            type="submit"
            fullWidth={false}
            variant="contained"
            color="info"
            disabled={disabledSave}
          >
            {t('save', { ns: 'common' })}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

function TicketsInfoTab(props: ProfilePageProps) {
  const { t } = useTranslation(['profile', 'common', 'tables']);
  const accountInfo = useRecoilValue(accountInfoAtom);
  const [tickets, setTickets] = React.useState<Ticket[]>([]);
  const [choosenTicket, setChoosenTicket] = React.useState<Ticket | null>(null);
  const [promos, setPromos] = React.useState<Promo[]>([]);
  const [choosenPromo, setChoosenPromo] = React.useState<Promo | null>(null);
  const [salaries, setSalaries] = React.useState<CoachSalaryObj>({});
  const [clubUnit, setClubUnit] = React.useState<number>(
    (props.appState.account_info.clubs[0]?.units.length)
      ? props.appState.account_info.clubs[0].units[0].id
      : 0
  );

  useEffect(() => {
    if (props.tabName !== 'TicketsInfoTab') { return }
    getObjects();
  }, [clubUnit]);

  const getObjects = async () => {
    props.handleOpenSpinner();
    await getTickets();
    await getPromos();
    if (accountInfo.account_info.permissions.includes('view_coachdrillsalary')) {
      await getSalaries();
    };
    props.handleCloseSpinner();
  }

  const getTickets = async () => {
    if (clubUnit === 0) { return }
    const api = new BaseApi(1, 'management/tickets/');
    let res = await api.get({club_unit: clubUnit}, props.appSetState, props.appState);
    if (res.status === 200) {
      setTickets(res.body);
    };
  };

  const getPromos = async () => {
    const api = new BaseApi(1, 'management/promo/');
    let res = await api.get(
      {club_unit: clubUnit, limit: 1000, offset: 0},
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      setPromos(res.body.results);
    };
  };

  const getSalaries = async () => {
    const api = new BaseApi(1, 'payment/salary/group/');
    let res = await api.get({}, props.appSetState, props.appState);
    if (res.status === 200) {
      setSalaries(res.body);
    };
  };

  return (
    <Box>
      <Box
        sx={{ overflow: 'auto', maxHeight: '80vh' }}
      >
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={props.appState.account_info.clubs[0].units.map((unit: ClubUnit) => {return {id: unit.id, label: unit.name}})}
          sx={{ width: '100%', marginTop: '5px' }}
          renderInput={(params) => <TextField {...params} label={t('club_unit', { ns: 'common' })} />}
          defaultValue={
            (props.appState.account_info.clubs[0]?.units.length)
              ? {id: props.appState.account_info.clubs[0].units[0].id, label: props.appState.account_info.clubs[0].units[0].name}
              : null
          }
          onChange={(
            e: React.SyntheticEvent,
            value: AutocompleteProps | null,
          ) => {
            if (value && value.id) {
              setClubUnit(Number(value.id));
            } else {
              setClubUnit(props.appState.account_info.clubs[0].units[0].id);
            }
          }}
        />
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={tickets.map((ticket) => {return {id: ticket.id, label: ticket.ticket}})}
          sx={{ width: '100%', marginTop: '10px' }}
          renderInput={(params) => <TextField {...params} label={t('ticket', { ns: 'common' })} />}
          onChange={(
            e: React.SyntheticEvent,
            value: AutocompleteProps | null,
          ) => {
            if (value && value.id) {
              setChoosenTicket(tickets.filter(ticket => ticket.id === value.id)[0]);
            } else {
              setChoosenTicket(null);
            }
          }}
        />
        {choosenTicket
          ? <Box>
              <Card sx={{ minWidth: 275, backgroundColor: 'var(--grey)' }}>
                <CardContent>
                  {choosenTicket.price
                    ? <Typography variant="body2">
                      {t('tickets_info.price', { ns: 'tables' })} {choosenTicket.price}
                      </Typography>
                    : ''
                  }
                  {choosenTicket.profit
                    ? <Typography variant="body2">
                        {t('tickets_info.profit', { ns: 'tables' })} {choosenTicket.profit}
                      </Typography>
                    : ''
                  }
                  {choosenTicket.freeze
                    ? <Typography variant="body2">
                        {t('tickets_info.freeze', { ns: 'tables' })}
                      </Typography>
                    : ''
                  }
                  {choosenTicket.bonus
                    ? <Typography variant="body2">
                        {t('tickets_info.bonus', { ns: 'tables' })}: {choosenTicket.bonus}
                      </Typography>
                    : ''
                  }
                  {choosenTicket.days
                    ? <Typography variant="body2">
                        {t('tickets_info.valid', { ns: 'tables' })} {choosenTicket.days} {t('tickets_info.days', { ns: 'tables' })}
                      </Typography>
                    : ''
                  }
                </CardContent>
              </Card>
            </Box>
          : ''
        }
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={promos.map((promo) => {return {id: promo.id, label: promo.name}})}
          sx={{ width: '100%', marginTop: '10px' }}
          renderInput={(params) => <TextField {...params} label={t('promo', { ns: 'common' })} />}
          onChange={(
            e: React.SyntheticEvent,
            value: AutocompleteProps | null,
          ) => {
            if (value && value.id) {
              setChoosenPromo(promos.filter(promo => promo.id === value.id)[0]);
            } else {
              setChoosenPromo(null);
            }
          }}
        />
        {choosenPromo
          ? <Box>
              <Card sx={{ minWidth: 275, backgroundColor: 'var(--grey)' }}>
                <CardContent>
                  {choosenPromo.description
                    ? <Typography variant="body2">
                      {t('promo_info.description', { ns: 'tables' })}: {choosenPromo.description}
                      </Typography>
                    : ''
                  }
                </CardContent>
              </Card>
            </Box>
          : ''
        }
        {accountInfo.account_info.permissions.includes('view_coachdrillsalary')
          ? <Box>
              <Typography>{t('rates', { ns: 'common' })}</Typography>
              {Object.keys(salaries).map((coach_type, index) => {
                return (
                  <TableContainer
                    component={Paper}
                    key={`coach-rate-${index}`}
                  >
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>{t('rates.clients', { ns: 'tables' })}</TableCell>
                          <TableCell>{t('rates.rate', { ns: 'tables' })}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.keys(salaries[coach_type]).map((rate) => (
                          <TableRow
                            key={`coach-type-rate-${rate}`}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              {rate}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {salaries[coach_type][rate]}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )
              })}
            </Box>
          : ''
        }
      </Box>
    </Box>
  )
}

function VideoconfsTab(props: ProfilePageProps) {
  const { t } = useTranslation(['videoconfs', 'common', 'tables']);
  const [videoconfs, setVideoconfs] = React.useState<Videoconf[]>([]);
  const [showParticipants, setShowParticipants] = React.useState<PKStatusObj>({});
  const [createConf, setCreateConf] = React.useState<boolean>(false);
  const [newConfName, setNewConfName] = React.useState<string>('');
  const [searchString, setSearchString] = React.useState<string>('');
  const [users, setUsers] = React.useState<AccountInfo[]>([]);
  const [anchorUsersList, setAnchorUsersList] = React.useState<null | HTMLElement>(null);
  const showUsers: boolean = Boolean(anchorUsersList);
  const [selectedParticipants, setSelectedParticipants] = React.useState<AccountInfo[]>([]);

  const getVideoconfs = async () => {
    props.handleOpenSpinner();
    const api = new BaseApi(1, 'management/videoconf/');
    let res = await api.get({}, props.appSetState, props.appState);
    if (res.status === 200) {
      setVideoconfs(res.body);
    };
    props.handleCloseSpinner();
  };

  const searchUsers = async () => {
    props.handleOpenSpinner();
    const api = new BaseApi(2, 'management/get_clients/');
    let res = await api.get(
      {limit: 5, offset: 0, search: encodeURIComponent(searchString)},
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      setUsers(res.body.results);
    };
    props.handleCloseSpinner();
  };

  const createVideoconf = async () => {
    props.handleOpenSpinner();
    const api = new BaseApi(1, 'management/videoconf/');
    let res = await api.post(
      {
        conf_name: newConfName,
        participants: selectedParticipants.map((p) => {return p.id}),
      },
      'application/json',
      props.appSetState,
      props.appState,
    );
    if (res.status === 201) {
      videoconfs.splice(0, 0, res.body);
      setVideoconfs(videoconfs);
    }
    setCreateConf(false);
    props.handleCloseSpinner();
  }

  useEffect(() => {
    if (props.tabName !== 'VideoconfsTab') { return }
    getVideoconfs();
  }, [props.tabName]);

  useEffect(() => {
    if (!searchString) { return }
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        searchUsers();
        let elem = document.getElementById('users-videoconf-search');
        setAnchorUsersList(anchorUsersList ? null : elem);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [searchString]);

  return (
    <Box>
      <List dense={false}>
        {videoconfs.map((videoconf, index) => {
          return (
            <ListItem key={`conf-list-item-${videoconf.id}`}>
              <ListItemAvatar>
                <Avatar
                  onClick={(e) => {
                    let _show_participants: PKStatusObj = {};
                    for (let conf of videoconfs) {
                      _show_participants[conf.id] = false;
                    };
                    _show_participants[videoconf.id] = !_show_participants[videoconf.id];
                    setShowParticipants(_show_participants);
                  }}
                >
                  <DuoIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItem>
                <Link
                  href={videoconf.conf_link}
                  color="inherit"
                  underline="hover"
                  target="_blank"
                >
                  {(videoconf.conf_name) ? videoconf.conf_name : `${t('videoconf')} ${index}`}
                </Link>
              </ListItem>
            </ListItem>
          )
        })}
      </List>
      <Zoom
        key="new-client-key"
        in={true}
      >
        <Fab sx={
            {
              position: 'absolute',
              bottom: 36,
              left: 36,
              borderColor: root_theme.palette.info.main,
              borderWidth: '2px',
              borderStyle: 'solid',
              '&:hover': {
                bgcolor: root_theme.palette.background.default,
                borderColor: root_theme.palette.info.main,
              },
            } as SxProps
          }
          aria-label="Add client"
          color='info'
          onClick={(e) => {setCreateConf(true)}}
        >
          <AddIcon />
        </Fab>
      </Zoom>
      <BootstrapDialog
        onClose={(e) => {setCreateConf(false)}}
        open={createConf}
      >
        <DialogTitle sx={{ paddingLeft: '5px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontWeight: '600', fontSize: '1.25em' }}>
              {t('new_conf')}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers className="box-content-center">
          <TextField
            label={t('name')}
            variant="standard"
            onChange={(e) => {
              setNewConfName(e.target.value);
            }}
          />
          <Paper
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              width: '95%',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginBottom: '5px',
              marginTop: '5px',
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              id="users-videoconf-search"
              placeholder={t('search_placeholder', { ns: 'common' })}
              inputProps={{ 'aria-label': 'search google maps' }}
              onChange={(e) => {setSearchString(e.target.value)}}
              value={searchString}
            />
            <IconButton
              type="button"
              sx={{ p: '10px' }}
              aria-label="search"
              onClick={
                (e) => {
                  searchUsers();
                  let elem = document.getElementById('users-videoconf-search');
                  setAnchorUsersList(anchorUsersList ? null : elem);
                }
              }
            >
              <SearchIcon />
            </IconButton>
          </Paper>
          <Popover
            open={showUsers}
            anchorEl={anchorUsersList}
            onClose={(e) => {setAnchorUsersList(null)}}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
              <List dense={false}>
                {users.map((user) => {
                  return (
                    <ListItem
                      onClick={(e) => {
                        selectedParticipants.push(user);
                        setSelectedParticipants(selectedParticipants);
                        setUsers([]);
                        setAnchorUsersList(null);
                        setSearchString('');
                      }}
                      sx={{ cursor: 'pointer' }}
                      key={`conf-user-list-item-${user.id}`}
                    >
                      <ListItemIcon>
                        <ContactPhoneIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${user.first_name} ${user.last_name}`}
                      />
                    </ListItem>
                  )
                })}
              </List>
            </Box>
          </Popover>
          <List dense={false}>
            {selectedParticipants.map((participant) => {
              return (
                <ListItem key={`conf-participatn-list-item-${participant.id}`}>
                  <ListItemIcon
                    onClick={(e) => {
                      setSelectedParticipants(selectedParticipants.filter(p => p.id !== participant.id));
                      setUsers([]);
                      setAnchorUsersList(null);
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    <ClearIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${participant.first_name} ${participant.last_name}`}
                  />
                </ListItem>
              )
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="info"
            onClick={
              (e) => {
                if (newConfName === '' && !selectedParticipants.length) {
                  return
                }
                createVideoconf();
              }
            }
          >
            <CheckIcon />
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Box>
  )
}
