import React from "react";

import { useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from "recoil";

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';

import { ClientInfo } from "../../interfaces/User";
import { IPermission } from "../../interfaces/Common";
import { BaseProps } from '../../interfaces/Props';

import { permissionsAtom } from "../../atoms/Permissions";
import { accountInfoAtom } from '../../atoms/AccountInfo';
import { spinnerAtom } from '../../atoms/Spinner';
import { languageAtom } from '../../atoms/Language';

import { getUserPermissions, manageUserPermission } from "../../api/permissions";

import { CallbackContext } from '../Context';

interface IUserPermissionsProps {
  user: ClientInfo;
  requestKey: number;
}

export function UserPermissionsManage(props: IUserPermissionsProps) {
  const accountInfo = useRecoilValue(accountInfoAtom);
  const permissions = useRecoilValueLoadable(permissionsAtom);
  const [userPermissions, setUserPermissions] = React.useState<string[]>([]);
  const apiCallback: CallableFunction = React.useContext(CallbackContext);
  const setOpenSpinner = useSetRecoilState(spinnerAtom);
  const language = useRecoilValue(languageAtom);

  const handleUpdateUserPermission = async (
    permission_id: number, action: 'add' | 'remove'
  ) => {
    await manageUserPermission(
      setOpenSpinner,
      accountInfo,
      apiCallback,
      () => {
        if (permissions.state !== 'hasValue') { return }
        if (action === 'add') {
          setUserPermissions(
            [
              ...userPermissions,
              ...permissions.contents.items.filter(
                p => p.id === permission_id
              ).map(p => p.codename)
            ]
          )
        } else if (action === 'remove') {
          let remove_perms = permissions.contents.items.filter(
            p => p.id === permission_id
          ).map(p => p.codename)
          setUserPermissions(
            [
              ...userPermissions.filter(p => !remove_perms.includes(p)),
            ]
          )
        }
      },
      props.user.id,
      permission_id,
      action,
    )
  }

  React.useEffect(() => {
    if (props.requestKey !== props.user.id) { return }
    getUserPermissions(
      setOpenSpinner,
      accountInfo,
      apiCallback,
      (perms: string[]) => {setUserPermissions(perms)},
      props.user.id,
      language,
    )
  }, [props.requestKey])

  return (
    <Box>
      {permissions.state === 'hasValue'
        ? <List sx={{ width: '100%', maxWidth: 360 }}>
        {permissions.contents.items?.map(
          (perm: IPermission) => {
            return (
              <ListItem
                key={`perm-key-${perm.id}`}
                disablePadding
              >
                <ListItemButton
                  role={undefined}
                  onClick={
                    (e) => {
                      handleUpdateUserPermission(perm.id, (e.target.checked) ? 'add' : 'remove')
                    }
                  }
                  dense
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={userPermissions.includes(perm.codename)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': `perm-item-${perm.id}` }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={`perm-item-${perm.id}`}
                    primary={perm.name}
                  />
                </ListItemButton>
              </ListItem>
            );
          }
        )}
      </List>
      : ''
      }
    </Box>
  )
}
