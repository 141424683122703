import React from 'react';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { useTranslation } from 'react-i18next';

import { PopUpProps } from '../../interfaces/Props';

import { theme as root_theme } from "../../consts";

export function PopUp(props: PopUpProps) {
  const { t } = useTranslation('common');

  return (
    <Alert
      severity="error"
      sx={
        {
          zIndex: 2,
          position: 'absolute',
          bottom: '5%',
          left: '40%',
        }
      }
    >
      <AlertTitle>{t(props.title)}</AlertTitle>
      {(!props.non_translatable) ? t(`messages.${props.message}`) : props.message}
    </Alert>
  )
}
