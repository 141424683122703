import { atom, selector } from 'recoil'

import { BaseApi } from '../api/base';

import { ClubTicket } from '../interfaces/Club';

export interface ITicketsState {
  items: ClubTicket[]
}

export const unlimitTicketsSelector = selector({
    key: 'unlimitTicketsSelector',
    get: async (): Promise<ITicketsState> => {
      const api = new BaseApi(1, 'management/tickets/');
      let res = await api.get(
        {drills_amount: -1},
        () => {},
        {},
      );
      if (res.status === 200) {
        return { items: res.body };
      };
      return { items: [] };
    },
    set: ({ set, get }, newData) => {
      const currentState = get(unlimitTicketsAtom);
      let newState = [ ...currentState.items ];
      if (newData.items) {
        newState = [...currentState.items, ...newData.items as ClubTicket[]];
      }
      set(unlimitTicketsAtom, {items: newState});
    },
});

export const unlimitTicketsAtom = atom<ITicketsState>({
  key: 'unlimitTickets',
  default: unlimitTicketsSelector,
})

export const allTicketsSelector = selector({
    key: 'allTicketsSelector',
    get: async (): Promise<ITicketsState> => {
      const api = new BaseApi(1, 'management/tickets/');
      let res = await api.get(
        {},
        () => {},
        {},
      );
      if (res.status === 200) {
        return { items: res.body };
      };
      return { items: [] };
    },
    set: ({ set, get }, newData) => {
      const currentState = get(allTicketsAtom);
      let newState = [ ...currentState.items ];
      if (newData.items) {
        newState = [...currentState.items, ...newData.items as ClubTicket[]];
      }
      set(allTicketsAtom, {items: newState});
    },
});

export const allTicketsAtom = atom<ITicketsState>({
  key: 'allTickets',
  default: allTicketsSelector,
})
