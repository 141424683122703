import React, { useEffect } from 'react';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import { useTranslation } from 'react-i18next';

import { ApproveDialogProps } from '../../interfaces/Props';

import { theme as root_theme } from "../../consts";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    backgroundColor: root_theme.palette.background.default,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: root_theme.palette.info.main,
  }
}));

export function ApproveAction(props: ApproveDialogProps) {
  const { t } = useTranslation('common');

  return (
    <BootstrapDialog
      open={props.show === props.obj_pk}
      onClose={(e) => { props.handleDecline() }}
    >
      <DialogTitle>
        {t('approve_actions.accept_action')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(`approve_actions.${props.action}`)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="error"
          onClick={(e) => {props.handleDecline()}}
        >
          <CloseIcon />
        </Button>
        <Button
          variant="contained"
          color="info"
          onClick={
            (e) => {
              if (props.obj_pk) {
                props.handleApprove(props.obj_pk);
              } else {
                props.handleApprove();
              }
            }
          }
        >
          <CheckIcon />
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
