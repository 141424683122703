import { BaseApi } from "./base";

export const getUserPermissions = async (
  setSpinner: CallableFunction,
  accountInfo: any,
  errback: CallableFunction,
  callback: CallableFunction,
  user_id: number,
  lang: string,
) => {
  setSpinner({ open: true });
  const api = new BaseApi(
    1, `user/user_permissions/list/${user_id}/`, {lang: lang}
  );
  let res = await api.get(
    {},
    errback,
    accountInfo,
  );
  if (res.status === 200) {
    callback(res.body)
  }
  setSpinner({ open: false });
}

export const manageUserPermission = async (
  setSpinner: CallableFunction,
  accountInfo: any,
  errback: CallableFunction,
  callback: CallableFunction,
  user_id: number,
  permission_id: number,
  action: 'add' | 'remove',
) => {
  setSpinner({ open: true });
  const api = new BaseApi(1, `user/user_permissions/${action}/`);
  let res = await api.post(
    {
      user_id: user_id,
      permission_id: permission_id,
    },
    'application/json',
    errback,
    accountInfo,
  );
  if (res.status === 200) {
    callback(res.body)
  }
  setSpinner({ open: false });
}
