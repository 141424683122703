import React from "react";
import { useEffect } from 'react';

import { useRecoilValueLoadable, useRecoilValue } from "recoil";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveTimeRange } from '@nivo/calendar'

import { useTranslation } from 'react-i18next';

import { builtISODate } from "../../helpers";

import { UserInfoCommon } from "../../interfaces/AccountInfo";
import { ClubPromo, ClubTicket } from "../../interfaces/Club";
import { StatisticTabProps, AutocompleteProps } from "../../interfaces/Props";
import { BarChartDataCoachStat, TimeRangeData } from "../../interfaces/Plots";
import { QueryObj, CustomObj } from "../../interfaces/Types";
import { DrillType } from "../../interfaces/Common";

import { BaseApi } from "../../api/base";

import { BootstrapDialog } from "../common/Dialogs";
import { Clients } from "../users/Clients";

import { drillTypesAtom } from "../../atoms/DrillTypes";
import { accountInfoAtom } from "../../atoms/AccountInfo";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function NewTicketStatisticTab(props: StatisticTabProps) {
  let today = new Date();
  let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  
  const { t, i18n } = useTranslation(['statistic', 'filters']);
  
  const translations: any = (i18n.options.resources && Object.keys(i18n.options.resources).includes(i18n.language)) ? i18n.options.resources[i18n.language] : {};
  const new_tickets = (Object.keys(translations).length) ? translations.statistic.plots.new_tickets : "New tickets";
  
  const coachChartRef = React.useRef<any>(null);
  
  const [spinner, setSpinner] = React.useState<boolean>(false);
  
  const drillTypes = useRecoilValueLoadable(drillTypesAtom);
  const state = useRecoilValue(accountInfoAtom);
  
  const [statDateStart, setStatDateStart] = React.useState<string>(builtISODate(firstDayOfMonth));
  const [statDateStop, setStatDateStop] = React.useState<string>(builtISODate(lastDayOfMonth));
  
  const [clientsIds, setClientsIds] = React.useState<number[]>([]);
  const [showClients, setShowClients] = React.useState<boolean>(false);
  const [drillTypeTickets, setDrillTypeTickets] = React.useState<CustomObj>({});
  
  const [newTicketBarData, setNewTicketBarData] = React.useState<BarChartDataCoachStat>(
    {
      labels: [],
      datasets: [
        {
          label: new_tickets,
          data: [],
          clients: [],
          backgroundColor: 'rgb(1, 131, 23)',
        },
      ],
    }
  );

  const getNewTicketStatData = async () => {
    setSpinner(true);
    const api = new BaseApi(2, 'management/club/new_ticket_type/stat/');
    let query: QueryObj = {
      agg_type: 'by_coach',
      date_start: statDateStart,
      date_stop: statDateStop,
      drill_type_id: drillTypeTickets.id,
    };
    let res = await api.get(
      query,
      props.appSetState,
      state,
    );
    if (res.status === 200) {
      setNewTicketBarData(
        {
          labels: res.body.by_coach.map(
             (c: any) => {return `${c.coach.first_name} ${c.coach.last_name}`}
          ),
          datasets: [
            {
              label: new_tickets,
              data: res.body.by_coach.map((c: any) => {return c.clients.length}),
              clients: res.body.by_coach.map((c: any) => {return c.clients}),
              backgroundColor: 'rgb(1, 131, 23)',
            },
          ]
        }
      )
    };
    setSpinner(false);
  };

  useEffect(() => {
    if (!drillTypeTickets.id) { return }
    getNewTicketStatData();
  }, [drillTypeTickets]);

  useEffect(() => {
    if (!drillTypeTickets.id) { return }
    getNewTicketStatData();
  }, [statDateStart]);

  useEffect(() => {
    if (!drillTypeTickets.id) { return }
    getNewTicketStatData();
  }, [statDateStop]);

  useEffect(() => {
    if (clientsIds.length) {
      setShowClients(true);
    }
  }, [clientsIds]);

  useEffect(() => {
    if (!showClients) {
      setClientsIds([]);
    }
  }, [showClients]);

  return (
    <Box>
      <Box>
        <Box
          sx={{
            width: 'fit-content',
            padding: '5px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <TextField
            id="bid-date-from-id"
            label={t('date_from', { ns: 'filters' })}
            sx={{ paddingLeft: '5px', paddingRight: '5px' }}
            variant="standard"
            type="date"
            onChange={(e) => {setStatDateStart(e.target.value)}}
            defaultValue={statDateStart}
          />
          <TextField
            id="bid-date-to-id"
            label={t('date_to', { ns: 'filters' })}
            sx={{ paddingLeft: '5px', paddingRight: '5px' }}
            variant="standard"
            type="date"
            onChange={(e) => {setStatDateStop(e.target.value)}}
            defaultValue={statDateStop}
          />
        </Box>
        <Box sx={{ marginBottom: '5px' }}>
          <Card sx={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
            <CardContent>
              <Autocomplete
                disablePortal
                id="stat-drill_type_new_ticket-filter-id"
                options={
                  (
                    drillTypes.state === "hasValue"
                      ? drillTypes.contents.items.map(
                        (dt) => {
                          return {
                            id: dt.id,
                            label: dt.name
                          }
                        }
                      )
                    : []
                  )
                }
                onChange={
                  (
                    e: React.SyntheticEvent,
                    value: AutocompleteProps | null,
                  ) => {
                    if (value && value.id) {
                      setDrillTypeTickets({id: value.id, name: value.label});
                    } else {
                      setDrillTypeTickets({});
                    }
                  }
                }
                sx={{
                  width: (props.device === 'mobile') ? '100%' : '30%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
                renderInput={
                  (params: any) => <TextField
                    {...params}
                    label={t("drill_type", { ns: 'filters' })}
                  />
                }
              />
              {spinner
                ? <Box
                    sx={{
                      width: 'fit-content',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      paddingTop: '5px',
                    }}
                  >
                    <CircularProgress color="inherit" />
                  </Box>
                : <Bar
                    ref={coachChartRef}
                    data={
                      {
                        labels: newTicketBarData.labels,
                        datasets: newTicketBarData.datasets,
                      }
                    }
                    options={
                      {
                        plugins: {
                          title: {
                            display: true,
                            text: new_tickets,
                          },
                        },
                        responsive: true,
                        scales: {
                          x: {
                            stacked: true,
                          },
                          y: {
                            stacked: true,
                            min: 0,
                            ticks: {
                              stepSize: 1,
                            },
                          },
                        },
                      }
                    }
                    height={100}
                    onClick={
                      (e: any) => {
                      let elem: any = getElementAtEvent(coachChartRef.current, e);
                        if (elem.length === 0) {
                          return
                        }
                        elem = elem[0];
                        setClientsIds(
                          newTicketBarData.datasets[elem.datasetIndex].clients[elem.index]
                        );
                      }
                    }
                  />
              }
            </CardContent>
          </Card>
        </Box>
      </Box>
      <BootstrapDialog
        onClose={(e) => {setShowClients(false)}}
        open={showClients}
        fullScreen
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar sx={{ backgroundColor: 'var(--black)' }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => {setShowClients(false)}}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {t('clients')}
            </Typography>
          </Toolbar>
        </AppBar>
        <Clients
          handleCloseSpinner={props.handleCloseSpinner}
          handleOpenSpinner={props.handleOpenSpinner}
          openPopUp={props.openPopUp}
          departments={props.departments}
          device={props.device}
          appState={props.appState}
          appSetState={props.appSetState}
          clientsIds={clientsIds.toString()}
          sendSocketMessage={props.sendSocketMessage}
        />
      </BootstrapDialog>
    </Box>
  )
}
