import React, { useState, useEffect } from "react";

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { useTranslation } from 'react-i18next';

import { BootstrapDialog } from './Dialogs';

import { ClubUnitsSelectorProps, AutocompleteProps } from "../../interfaces/Props";
import { ClubUnit } from "../../interfaces/Club";

export function ClubUnitsSelector(props: ClubUnitsSelectorProps) {
  const { t } = useTranslation('common');
  const [clubUnits, setClubUnits] = useState<ClubUnit[]>([]);
  const [choosenClubUnit, setChoosenClubUnit] = useState<number>(0);

  useEffect(() => {
    if (!props.showDialog) { return }
    let _club_units: ClubUnit[] = [];
    for (let c of props.clubs) {
      for (let cu of c.units) {
        _club_units.push(cu);
      }
    }
    setClubUnits(_club_units);
  }, [props.showDialog]);
  
  return (
    <BootstrapDialog
      onClose={() => {props.closeSelector()}}
      open={props.showDialog}
    >
      <DialogTitle sx={{ paddingLeft: '5px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ fontWeight: '600', fontSize: '1.25em' }}>
            {t('register.header', { ns: 'forms' })}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers
        className="box-content-center"
      >
        <Autocomplete
          id="user-ticket-pay-type-managing"
          options={
            clubUnits.map(
              (cu) => {return {id: cu.id, label: cu.name}}
            )
          }
          renderInput={
            (params) => <TextField
              {...params}
              label={t("club_unit")}
              sx={{ width: '150px' }}
            />
          }
          onChange={(
            e: React.SyntheticEvent,
            value: AutocompleteProps | null,
            reason: string,
          ) => {
            if (value && value.id) {
              setChoosenClubUnit(Number(value.id));
            }
          }}
        />
      </DialogContent>
      <DialogActions
        sx={{ marginBottom: "15px" }}
      >
        <Button
          variant="outlined"
          color="error"
          onClick={(e) => {props.closeSelector()}}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="info"
          disabled={choosenClubUnit === 0}
          onClick={(e) => {props.selectClubUnit(choosenClubUnit)}}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  )
}
