export type QueryObj = { [key: string]: string | number | boolean };
export type BodyObj = { [key: string]: string | number | boolean | string[] | number[] | BodyObj | BodyObj[] | null };
export type QueryObjCalendar = { [key: string]: string | number }
export type CustomObj = { [key: string]: any };
export type FormDataObj = { [key: string]: FormDataEntryValue };
export type PKStatusObj = { [key: number]: any };
export declare type PostPutChoice = 'post' | 'put';
export type PKObjsMapping = { [key: number]: number[] };

export enum CommentTargetType {
  EmptyTarget = 0,
  UserTarget = 1,
  BidTarget = 2,
}
