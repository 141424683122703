import { atom, selector } from 'recoil'

import { BaseApi } from '../api/base';

import { IPermission } from '../interfaces/Common';

import { languageAtom } from './Language';

export interface IPermissionState {
  items: IPermission[]
}

export const permissionsSelector = selector({
    key: 'permissionsSelector',
    get: async ({ get }): Promise<IPermissionState> => {
      const language = get(languageAtom);
      const api = new BaseApi(1, 'permissions/list/', {lang: language});
      let res = await api.get(
        { limit: 1000 },
        () => {},
        {},
      );
      if (res.status === 200) {
        return { items: res.body.results };
      };
      return { items: [] };
    },
    set: ({ set, get }, newData) => {
      const currentState = get(permissionsAtom);
      let newState = [ ...currentState.items ];
      if (newData.items) {
        newState = [...currentState.items, ...newData.items as IPermissionState[]];
      }
      set(permissionsAtom, {items: newState});
    },
});

export const permissionsAtom = atom<IPermissionState>({
  key: 'permissionsArr',
  default: permissionsSelector,
})
