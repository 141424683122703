import React from "react";
import { useEffect } from 'react';

import { useRecoilValue } from 'recoil';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import allLocales from '@fullcalendar/core/locales-all'

import { useTranslation } from 'react-i18next';

import { builtISODate, buildISODateTime } from "../../helpers";

import { BaseApi } from "../../api/base";

import { TimeShiftingCalendarProps } from '../../interfaces/Props';
import { QueryObj, BodyObj } from "../../interfaces/Types";
import { ClubUnit } from "../../interfaces/Club";

import { BootstrapDialog } from "../common/Dialogs";

import { accountInfoAtom } from '../../atoms/AccountInfo';

interface ICallendarEvent {
  id: string;
  title: string;
  start: string | null;
  end: string | null;
  textColor: string;
  clubUnit: ClubUnit;
  active: boolean;
}

interface IShiftItemProps {
  event: ICallendarEvent;
  updateShift: CallableFunction;
}

export function TimeShiftCalendar(props: TimeShiftingCalendarProps) {
  let today = new Date();
  let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  const timeShiftingRef = React.useRef<any>(null);
  
  const { t, i18n } = useTranslation(['statistic', 'filters']);
  const accountInfo = useRecoilValue(accountInfoAtom);

  const [statDates, setStatDates] = React.useState<QueryObj>(
    { date_start: builtISODate(firstDayOfMonth), date_stop: builtISODate(lastDayOfMonth) }
  );
  
  const [timeShiftingData, setTimeShiftingData] = React.useState<ICallendarEvent[]>([]);

  const [showShiftEdit, setShowShiftEdit] = React.useState<boolean>(false);
  const [editingShift, setEditingShift] = React.useState<ICallendarEvent>(
    {
      id: '',
      title: '',
      start: '',
      end: '',
      textColor: '',
      clubUnit: { id: 0, name: '' },
      active: false,
    }
  );

  const getTimeShiftingArr = async () => {
    props.handleOpenSpinner(true);
    const api = new BaseApi(1, 'management/manager/shift/');
    let res = await api.get(
      statDates,
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      let _clubUnits: ClubUnit[] = [];
      let _shiftData: ICallendarEvent[] = [];
      for (let c of props.appState.account_info.clubs) {
        for (let cu of c.units) {
          _clubUnits.push(cu);
        }
      }
      for (let shift of res.body.shifts) {
        if (_clubUnits.filter(cu => cu.id === shift.club_unit).length) {
          _shiftData.push(
            {
              id: shift.id.toString(),
              title: `${shift.manager.first_name[0]}. ${shift.manager.last_name}`,
              start: (shift.shift_start) ? buildISODateTime(new Date(shift.shift_start.split('+')[0])) : null,
              end: (shift.shift_end) ? buildISODateTime(new Date(shift.shift_end.split('+')[0])) : null,
              textColor: 'black',
              clubUnit: _clubUnits.filter(cu => cu.id === shift.club_unit)[0],
              active: shift.active,
            } as ICallendarEvent
          )
        }
      }
      setTimeShiftingData(_shiftData);
    }
    props.handleCloseSpinner(false);
  };

  const updateTimeShift = async (shift: BodyObj) => {
    props.handleOpenSpinner(true);
    const api = new BaseApi(1, 'management/manager/shift/');
    let res = await api.put(
      shift,
      'application/json',
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      getTimeShiftingArr();
    }
    props.handleCloseSpinner(false);
  };

  useEffect(() => {
    getTimeShiftingArr();
  }, [statDates]);

  const CalendarTimeShiftingItem = (event: any) => {
    return (
        <Box
          sx={{
            overflow: 'hidden',
            backgroundColor: (event.event.extendedProps.active) ? 'var(--green)!important' : 'transparent',
          }}
        >
          <Typography
            component="div"
            sx={{
              fontSize: '12px',
              backgroundColor: (event.event.extendedProps.active) ? 'var(--green)!important' : 'transparent',
            }}
          >
            {event.event.extendedProps.clubUnit.name}
          </Typography>
          <Typography
            component="div"
            sx={{
              fontSize: '14px',
              backgroundColor: (event.event.extendedProps.active) ? 'var(--green)!important' : 'transparent',
            }}
          >
            {event.event.title}
          </Typography>
          <Typography
            component="div"
            sx={{
              whiteSpace: 'pre-wrap',
              fontSize: '12px',
              backgroundColor: (event.event.extendedProps.active) ? 'var(--green)!important' : 'transparent',
            }}
          >
            {event.event.start?.toLocaleTimeString('ru-RU')} - {event.event.end?.toLocaleTimeString('ru-RU')}
          </Typography>
        </Box>
    )
  };
  
  return (
    <Box>
      <FullCalendar
        ref={timeShiftingRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          start: 'prev',
          center: 'title',
          end: 'next',
        }}
        buttonText={{
          today: "current",
          month: "month",
          week: "week",
          day: "day",
          list: "list"
        }}
        locales={allLocales}
        locale={props.appState.account_info.language || 'en'}
        initialView={(props.device === 'desktop') ? 'dayGridMonth' : 'dayGridDay'}
        dayHeaderFormat={
          (props.device === 'desktop')
            ? {weekday: 'short'}
            : { weekday: 'short', month: 'short', day: 'numeric' }
        }
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={true}
        events={timeShiftingData}
        select={(e) => {}}
        eventContent={(e) => CalendarTimeShiftingItem(e)}
        eventDisplay="block"
        eventClick={(e) => {
          if (!accountInfo.account_info.permissions.includes('change_managershift')) { return }
          if (e.event.extendedProps.active) { return }
          setEditingShift(
            {
              id: e.event.id,
              title: e.event.title,
              start: (e.event.start) ? buildISODateTime(e.event.start) : '',
              end: (e.event.end) ? buildISODateTime(e.event.end) : '',
              textColor: 'black',
              clubUnit: e.event.extendedProps.clubUnit,
              active: e.event.extendedProps.active,
            }
          );
          setShowShiftEdit(true);
        }}
        eventsSet={() => console.log("eventsSet")}
        firstDay={0}
        datesSet={
          (e) => {
            let _date_start = e.start;
            let _date_stop = e.end;
            _date_start.setDate(_date_start.getDate() + 1);
            if (
              statDates.date_start !== _date_start.toISOString().split('T')[0] ||
              statDates.date_stop !== _date_stop.toISOString().split('T')[0]
            ) {
              setStatDates({
                date_start: _date_start.toISOString().split('T')[0],
                date_stop: _date_stop.toISOString().split('T')[0],
              });
            }
          }
        }
        eventDrop={(e) => {console.log("eventDrop")}}
        eventResize={(e) => {console.log("eventResize")}}
        dateClick={(e) => {console.log("dateClick")}}
        eventAdd={(e) => {
          console.log("eventAdd", e);
        }}
        eventChange={(e) => {
          console.log("eventChange", e);
        }}
        eventRemove={(e) => {
          console.log("eventRemove", e);
        }}
        eventMinHeight={ 200 }
      />
      <BootstrapDialog
        onClose={() => setShowShiftEdit(false)}
        open={showShiftEdit}
      >
        <ShiftControl
          event={editingShift}
          updateShift={updateTimeShift}
        />
      </BootstrapDialog>
    </Box>
  )
}

function ShiftControl(props: IShiftItemProps) {
  const { t, i18n } = useTranslation(['statistic', 'filters']);
  
  return (
    <Box sx={{ margin: '15px' }}>
      <Typography
        variant='h6'
        sx={{ width: 'fit-content', marginLeft: 'auto', marginRight: 'auto' }}
      >
        {props.event.clubUnit.name}
      </Typography>
      <Typography
        variant='h5'
        sx={{ width: 'fit-content', marginLeft: 'auto', marginRight: 'auto' }}
      >
        {props.event.title}
      </Typography>
      <Box>
        <TextField
          id="shift-start-edit-id"
          label={t('date_from', { ns: 'filters' })}
          sx={{ paddingLeft: '5px', paddingRight: '5px' }}
          variant="standard"
          type="datetime-local"
          onChange={
            (e) => {
              props.updateShift(
                {
                  shift: props.event.id,
                  shift_start: e.target.value,
                  shift_end: props.event.end,
                }
              )
            }
          }
          defaultValue={props.event.start}
        />
      </Box>
      <Box>
        <TextField
          id="shift-end-edit-id"
          label={t('date_to', { ns: 'filters' })}
          sx={{ paddingLeft: '5px', paddingRight: '5px' }}
          variant="standard"
          type="datetime-local"
          onChange={
            (e) => {
              props.updateShift(
                {
                  shift: props.event.id,
                  shift_start: props.event.start,
                  shift_end: e.target.value,
                }
              )
            }
          }
          defaultValue={props.event.end}
        />
      </Box>
    </Box>
  )
}
