import { atom, selector } from 'recoil'

import { BaseApi } from '../api/base';

import { ClubPromo } from '../interfaces/Club';

export interface IPromosState {
  items: ClubPromo[]
}

export const promosSelector = selector({
    key: 'promosSelector',
    get: async (): Promise<IPromosState> => {
      const api = new BaseApi(1, 'management/promo/');
      let res = await api.get(
        {limit: 100, offset: 0},
        () => {},
        {},
      );
      if (res.status === 200) {
        return { items: res.body.results };
      };
      return { items: [] };
    },
    set: ({ set, get }, newData) => {
      const currentState = get(promosAtom);
      let newState = [ ...currentState.items ];
      if (newData.items) {
        newState = [...currentState.items, ...newData.items as ClubPromo[]];
      }
      set(promosAtom, {items: newState});
    },
});

export const promosAtom = atom<IPromosState>({
  key: 'promos',
  default: promosSelector,
})
