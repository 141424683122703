import React from "react";
import { useEffect } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { ResponsiveFunnel } from '@nivo/funnel'

import { useTranslation } from 'react-i18next';

import { builtISODate } from "../../helpers";

import { BaseApi } from "../../api/base";

import { QueryObj } from "../../interfaces/Types";
import { BidSource } from "../../interfaces/Common";
import { StatisticTabProps, AutocompleteProps } from "../../interfaces/Props";

interface IBidsStatistic {
  all: number;
  bids_to_tickets: number;
  closed: number;
  declined: number;
  denial: number;
  new: number;
  placed: number;
  prolongation: number;
  waiting: number;
};

interface IPlotLabels {
  all: string;
  bids_to_tickets: string;
  closed: string;
  declined: string;
  denial: string;
  new: string;
  placed: string;
  prolongation: string;
  waiting: string;
}

export function BidsStatTab(props: StatisticTabProps) {
  let today = new Date();
  let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  const boxOfficeStatRef = React.useRef<any>(null);
  
  const { t, i18n } = useTranslation(['statistic', 'filters']);
  const translations: any = (i18n.options.resources && Object.keys(i18n.options.resources).includes(i18n.language)) ? i18n.options.resources[i18n.language] : {};

  const [bidsStatQuery, setBidsStatQuery] = React.useState<QueryObj>(
    {
      date_start: builtISODate(firstDayOfMonth),
      date_stop: builtISODate(lastDayOfMonth),
    }
  );
  const [spinner, setSpinner] = React.useState<boolean>(false);
  const [bidSources, setBidSources] = React.useState<BidSource[]>([]);
  const [firstRender, setFirstRender] = React.useState<boolean>(true);
  const [bidsStat, setBidsStat] = React.useState<IBidsStatistic>(
    {
      all: 0,
      bids_to_tickets: 0,
      closed: 0,
      declined: 0,
      denial: 0,
      new: 0,
      placed: 0,
      prolongation: 0,
      waiting: 0,
    }
  );
  const plotLabels: IPlotLabels = {
    all: translations.statistic.bids_stat.all,
    bids_to_tickets: translations.statistic.bids_stat.bids_to_tickets,
    closed: translations.statistic.bids_stat.closed,
    declined: translations.statistic.bids_stat.declined,
    denial: translations.statistic.bids_stat.denial,
    new: translations.statistic.bids_stat.new,
    placed: translations.statistic.bids_stat.placed,
    prolongation: translations.statistic.bids_stat.prolongation,
    waiting: translations.statistic.bids_stat.waiting,
  }

  const getBidSources = async () => {
    setSpinner(true);
    const api = new BaseApi(1, 'management/bid_source/');
    let res = await api.get(
      {},
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      setBidSources(res.body);
    }
    setFirstRender(false);
    setSpinner(false);
  };

  const getBidsStat = async () => {
    props.handleOpenSpinner();
    const api = new BaseApi(1, 'management/bid_client/statistic/');
    let res = await api.get(
      bidsStatQuery,
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      setBidsStat(res.body.statistic);
    }
    props.handleCloseSpinner();
  };

  useEffect(() => {
    if (props.statType !== 'bid_stat') { return }
    getBidSources();
  }, [props.statType]);

  useEffect(() => {
    if (!bidsStatQuery.bid_source_id) { return }
    getBidsStat();
  }, [bidsStatQuery]);

  return (
    <Box>
      {spinner
        ? <Box
            sx={{
              width: 'fit-content',
              marginLeft: 'auto',
              marginRight: 'auto',
              paddingTop: '5px',
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        : <Box>
            <Box
              sx={{
                width: 'fit-content',
                padding: '5px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <TextField
                id="bid-date-from-id"
                label={t('date_from', { ns: 'filters' })}
                sx={{ paddingLeft: '5px', paddingRight: '5px' }}
                variant="standard"
                type="date"
                onChange={(e) => {setBidsStatQuery({ ...bidsStatQuery, date_start: e.target.value })}}
                defaultValue={bidsStatQuery.date_start}
              />
              <TextField
                id="bid-date-to-id"
                label={t('date_to', { ns: 'filters' })}
                sx={{ paddingLeft: '5px', paddingRight: '5px' }}
                variant="standard"
                type="date"
                onChange={(e) => {setBidsStatQuery({ ...bidsStatQuery, date_stop: e.target.value })}}
                defaultValue={bidsStatQuery.date_stop}
              />
            </Box>
            <Card sx={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
              <CardContent>
                <Autocomplete
                  disablePortal
                  id="stat-promo-filter-id"
                  options={
                    bidSources.map(
                      (bs) => {
                        return {
                          id: bs.id,
                          label: bs.source,
                        }
                      }
                    )
                  }
                  onChange={
                    (
                      e: React.SyntheticEvent,
                      value: AutocompleteProps | null,
                    ) => {
                      if (value && value.id) {
                        setBidsStatQuery({ ...bidsStatQuery, bid_source_id: value.id });
                      }
                    }
                  }
                  sx={{
                    width: (props.device === 'mobile') ? '100%' : '30%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                  renderInput={
                    (params: any) => <TextField
                      {...params}
                      label={t("bid_source")}
                    />
                  }
                />
                <Box sx={{ height: '400px' }}>
                  <ResponsiveFunnel
                    data={
                      Object.keys(plotLabels).map(
                         (pl: any) => {
                          return {
                            id: pl,
                            value: bidsStat[pl],
                            label: plotLabels[pl],
                          }
                        }
                    )
                    }
                    margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                    valueFormat=">-.4s"
                    colors={{ scheme: 'spectral' }}
                    borderWidth={0}
                    labelColor={
                      {
                        from: 'color',
                        modifiers: [
                          [
                            'darker',
                            3,
                          ]
                        ]
                      }
                    }
                    beforeSeparatorLength={100}
                    beforeSeparatorOffset={20}
                    afterSeparatorLength={100}
                    afterSeparatorOffset={20}
                    currentPartSizeExtension={10}
                    currentBorderWidth={40}
                    motionConfig="wobbly"
                    interpolation="linear"
                  />
                </Box>
              </CardContent>
            </Card>
          </Box>
      }
    </Box>
  )
}
