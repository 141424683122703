import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  lng: 'en',
  resources: {
    en:{
      week: {
        monday_shortcut:"Mon",
        tuesday_shortcut:"Tue",
        wednesday_shortcut:"Wed",
        thursday_shortcut:"Thu",
        friday_shortcut:"Fri",
        saturday_shortcut:"Sat",
        sunday_shortcut:"Sun",
      },
      menu: {
        profile: "Profile",
        club: "Club",
        statistic: "Statistic",
        clients: "Clients",
        coaches: "Coaches",
        calendar: "Calendar",
        bids: "Bids",
        time_shifting: "Time shifting",
        service_items: "Services and items",
        notifications: "Notifications",
        ticket_edit_request: "Ticket edit requests",
        market: "Market",
        rent: "Rent",
      },
      filters: {
        filters: "Filters",
        club: "Club",
        club_unit: "Club unit",
        discipline: "Discipline",
        coach: "Coach",
        ticket: "Ticket",
        client_type: "Client type",
        client: "Client",
        employee_clients: "Employee's clients",
        drill_type: "Drill type",
        all_drills: "All drills",
        all_notifications: "All notifications",
        ticket_drills: "Drills for exist tickets",
        time_period: "Period of time",
        bid_status: "Bid's status",
        bid_source: "Bid's source",
        phone: "Phone",
        date_start: "Date start",
        date_stop: "Date stop",
        date_from: "From",
        date_to: "To",
        apply: "Apply",
        promo: "Promo",
        employee_clients_values: {
          all: "All",
          mine: "My",
        },
        client_type_values: {
          hooky: "Hooky players",
          non_prolongation: "Non-prolongation ticket",
          prolongation: "Prolongation ticket",
          no_ticket: "Without ticket",
          frozen: "Frozen ticket",
          debtor: "Debtors",
        },
        client_types: {
          hooky: "Hooky",
          non_prolongation: "Non prolongated",
          prolongation: "Prolongated",
          no_ticket: "No ticket",
          frozen: "Frozen",
          debtor: "Debtors",
        },
      },
      sorting: {
        ticket_exist_with: "By ticket existance (with ticket first)",
        ticket_exist_without: "By ticket existance (without ticket first)",
        created_date_asc: "By creation date (from oldest)",
        created_at_desc: "By creation date (from newest)",
      },
      tables: {
        heads: {
          full_name: "FULL NAME",
          ticket: "TICKET",
          contacts: "CONTACTS",
          representative: "REPRESENTATIVE",
          birthday: "BIRTHDAY",
          socials: "SOCIALS",
          employees: "EMPLOYEES",
          notes: "NOTES",
          club_unit: "CLUB/CLUB UNIT",
          traffic_source: "TRAFFIC SOURCE",
          bid_date: "BID DATE",
          bid_status: "STATUS",
          drill: "DRILL",
          date_time: "DATE/TIME",
          coaches: "COACHES",
          attendance: "ATTENDANCE",
          client_bid: "CLIENT/BID",
          type: "TYPE",
          noty_date: "NOTIFICATION DATE",
          author: "AUTHOR",
          disciplines: "DISCIPLINES",
          coach_type: "CATEGORY",
          name: "NAME",
          drills_amount: "DRILLS AMOUNT",
          drill_type: "DRILL TYPE",
          discount: "DISCOUNT",
          price: "PRICE",
          client_price: "CLIENT'S PRICE",
          payment: "PAYMENT",
          category: "CATEGORY",
          client: "CLIENT",
          description: "DESCRIPTION",
          request_date: "REQUEST DATE",
          field: "FIELD",
          changes: "CHANGES",
        },
        body: {
          phone_shortcut: "Tel",
          name: "Name",
          surname: "Surname",
          birthday_shortcut: "Birth",
          accept: "Accept",
          decline: "Decline",
          non_active_tickets: "Not active tickets",
          attendance: "Attendance",
          coach_disciplines: "Coach's disciplines",
          coach_club_units: "Coach's club units",
          status: {
            opened: "Open",
            closed: "Closed",
          },
        },
        actions: {
          remove: "Remove",
          notify: "Notification",
          debt: "Debts",
          expired_tickets: "Expired tickets",
          attendance: "Attendance",
          edit: "Edit",
          add_ticket: "Add ticket",
          close: "Close",
          bid_to_client: "Create client",
          show_notify_client: "Client's info",
          accept: "Accept",
          decline: "Decline",
          view: "View",
          comments: "Comments",
          permissions: "Permissions",
        },
        debts: {
          name: "Name",
          date: "Date",
          price: "Price",
          payed: "Payed",
          categories: "Categories",
          debts: "Debts",
        },
        rates: {
          clients: "Clients amount",
          rate: "Rate",
        },
        tickets_info: {
          price: "Price",
          profit: "Profit",
          freeze: "Freeze accept",
          bonus: "Bonuses",
          valid: "Valid for",
          days: "days",
        },
        promo_info: {
          description: "Description",
        },
        bids: {
          status: "Status",
        },
      },
      tickets: {
        expired: "Expired",
        non_active: "Non-active",
        frozen: "Frozen",
        expired_header: "Expired tickets",
        lost_drills: "Lost drills",
        completed_drills: "Completed drills",
        edit: "Edit",
        close: "Close",
        remove: "Remove",
        save: "Save",
        cancel: "Cancel",
        no_ticket: "No ticket",
        club_units: "Club units",
        sail_date: "Sail date",
        manage: {
          ticket: "Ticket",
          promo_code: "Promo code",
          discount: "Discount",
          date_start: "Start date",
          expire_date: "Expiring date",
          payed_amount: "Payed amount",
          ticket_price: "Ticket price",
          froze: "Froze",
          non_active: "Not active",
          new: "New ticket",
          disciplines: "Disciplines",
          coaches: "Coaches",
          payment_type: "Payment type",
          club_unit: "Club unit",
          promo: "Promo",
        },
      },
      user: {
        first_name: "First name",
        last_name: "Last name",
        patronymic: "Patronymic",
        phone: "Phone",
        email: "Email",
        trustee_phone: "Trustee phone",
        trustee_name: "Trustee name",
        birthday: "Birthdate",
        employees: "Employees",
        sketches: "Sketches",
        user_type: "User type",
        coach_skill: "Skill",
      },
      user_type: {
        client: "Client",
        coach: "Coach",
        admin: "Administrator",
        director: "Director",
        owner: "Owner",
      },
      forms: {
        register: {
          password: "Password",
          phone: "Phone",
          first_name: "First name",
          last_name: "Last name",
          patronymic: "Patronymic",
          email: "Email",
          under_age: "Under age",
          trustee_name: "Trustee name",
          trustee_phone: "Trustee phone",
          header: "New client",
          coach_header: "New coach",
          btn: "SignUp",
          required: "Phone or email required",
        },
        auth: {
          btn: "LogIn",
        },
        calendar: {
          new_drill: "New drill",
          club_unit: "Club unit",
          drill_type: "Drill type",
          discipline: "Discipline",
          weekdays: "Weekdays",
          weekday: "Weekday",
          coaches: "Coaches",
          time_start_stop: "Drill time start and stop",
          time_start: "Start time",
          time_stop: "Stop time",
          single: "One time drill",
          drill_date: "Drill date",
        },
        choose: {
          exist_user: "Registered user",
          new_user: "Register new user",
        },
      },
      common: {
        error: "Error",
        save: "Save",
        cancel: "Cancel",
        remove: "Remove",
        show: "Show",
        hide: "Hide",
        create: "Create",
        notify_type: "Notification type",
        notify_date: "Notification date",
        search_placeholder: "Name Surname",
        phone_search_placeholder: "Phone number",
        amount: "Amount",
        ticket: "Ticket",
        club_unit: "Club unit",
        promo: "Promo",
        rates: "Rates",
        apply: "Apply",
        disciplines: "Disciplines",
        page_no_access: "Page not available",
        add: "Add",
        accept: "Accept",
        decline: "Decline",
        hour: "h",
        minute: "min",
        second: "sec",
        download: "Download",
        messages: {
          phone_email: "Phone or email required",
          notify_date_type: "Notification type and date required",
          wrong_credentials: "Wrong login/password pair",
          failed_notify_create: "Notification creation failed",
          ticket_require_club_unit: "Choose club unit for ticket edit and creation",
          box_office_amount: "Cash lost amount",
          fill_all_fields: "Fill all fields in form please",
          remove_manager: "Remove administrator from club unit, isn't it?",
          new_comment: "New Comment",
        },
        approve_actions: {
          accept_action: "Accept an action",
          remove_ticket: "Are you sure that you want to remove the ticket?",
          close_ticket: "Are you sure that you want close the ticket?",
          remove_client: "Are you sure that you want to remove the client?",
          remove_coach: "Are you sure that you want to remove the coach?",
          close_noty: "Close notification?",
          remove_noty: "Are you sure that you want to remove the notification?",
          bid_to_client: "Do you want to close bid and create client?",
          continue_shifting: "Are you still working?",
          accept_change_request: "Do you want to accept request for change?",
          decline_change_request: "Do you want to decline request for change?",
          remove_payment_type: "Do you want to remove payment type?",
          remove_manager: "Are you sure that you want to remove manager from department?",
          motivating_message_0: "Всякая работа трудна до времени, пока ее не полюбишь, а потом — она возбуждает и становится легче. (М. Горький)",
          motivating_message_1: "Только два стимула заставляют работать людей: жажда заработной платы и боязнь её потерять. (Г. Форд)",
          motivating_message_2: "Работайте как в аду. Я имею ввиду, что вы должны работать от 80 до 100 часов в неделю каждую неделю. Это повышает шансы на успех. Если остальные будут работать по 40 часов в неделю, а вы будете работать 100 часов, то даже если вы будете делать одинаковые вещи, вы за 4 месяца достигнете того, на что у остальных уйдёт целый год. (И. Маск)",
          motivating_message_3: "Пенни не падают к нам с небес, мы вынуждены зарабатывать их здесь на земле. (М. Тэтчер)",
          motivating_message_4: "Кто не работает, тот не занимается любовью. (А. Челентано)",
          motivating_message_5: "Есть только один способ проделать большую работу — полюбить ее. Если вы к этому не пришли, подождите. Не бросайтесь за дело. Как и со всем другим, подсказать интересное дело вам поможет собственное сердце. (С. Джобс)",
          motivating_message_6: "Работа необходима для здоровья. (Гиппократ)",
          motivating_message_7: "Работа — лучшее лекарство от всех бед. (Э. Хемингуэй)",
          motivating_message_8: "Работа, которую мы делаем охотно, исцеляет боли. (У. Шекспир)",
        },
      },
      chat: {
        contacts: "Contacts",
        chat: "Chat",
        message: "Message",
        edited: "Edited",
        today: "Today",
        client: "Client",
        bid: "Bid",
      },
      profile: {
        profile: "Profile",
        tickets: "Tickets",
        videoconfs: "Videoconfs",
      },
      videoconfs: {
        videoconf: "Videoconference",
        participants: "Participants",
        new_conf: "Create videoconference",
        name: "Name",
      },
      calendar: {
        clients_amount: "Clients",
        present_clients: "Clients",
        edit: "Edit",
        replace: "Replace",
        cancel: "Cancel",
        substitute: "Substitute",
        remove: "Remove",
        choose_drill_new_date: "Choose drill for replace",
        replaced: "Repalced",
        removed: "Removed",
        accept_cancel_drill: "Cancel drill. Are you sure?",
        accept_remove_drill: "Remove all futher drills. Are you sure?",
        substitute_coach: "Choose coach to substitute",
        drill_time: "Drill time",
        actions: {},
        drill_attendance: {
          client: "Client",
          tickets: "Clients' tickets",
          choose_ticket: "Tickets",
          new_ticket: "New ticket",
        },
      },
      statistic: {
        export: "Statistic export",
        ticket_stat: "Ticket's statistic",
        coach_stat: "Coaches' statistic",
        bid_stat: "Bid's statistic",
        sales_stat: "Sales statistic",
        box_office_stat: "Boxoffice report",
        managers_stat: "Managers' statistic",
        new_tickets: "New tickets",
        salary_stat: "Salary",
        export_stat: "Export",
        clients: "Clients",
        report: "Report",
        total: "Total",
        total_drills: "Total amount of completed drills",
        total_attendance: "Total amount of clients",
        bid_source: "Bid source",
        coach_statistic: {
          date_start: "From",
          date_stop: "To",
          prolong_promos: "Prolongate promos for coaches",
          promo: "Promo",
          coach_stat: "Coach's prolongated promos",
          coach: "Coach",
          unlimit_ticket_stat: "Unlimit tickets attendance",
          extend_promos: "Promotion extensions",
        },
        tickets_stat: {
          header: "Tickets sails",
          all: "All",
          prolongated: "Prolongated",
          non_prolongated: "NonProlongated",
          new: "New",
          frozen: "Frozen",
          active: "Active",
          active_tickets: "Active tickets",
          active_clients: "Active clients",
          table: {
            date: "Date",
            client: "Client name",
            ticket: "Ticket",
            start_date: "Start date",
            expire_date: "Expire date",
            closing_date: "Closing date",
            coaches: "Coaches",
            club_unit: "Club units",
            price: "Price",
            discount: "Discount",
            payed_amount: "Payed",
          },
        },
        ticket_sales_stat: {
          clients_stat: 'Clients statistic',
          new: 'New',
          renewed: 'Renewed',
          non_renewed: 'Not renewed',
          out_of_stat: 'Out of stat',
        },
        purchase_stat: {
          managers: "Managers",
          club_units: "Club units",
          coaches: "Coaches",
        },
        plots: {
          prolongated: "Prolongated",
          non_prolongated: "Non-prolongated",
          new_tickets: "New tickets",
        },
        day_report: {
          payment_type_income: "Incomes by payment type",
          attendance: "Passability",
          new_clients: "New clients amount",
          predicted_profit: "Predicted profit",
          real_income: "Real income",
          income: "Income from",
          average_bill: "Average_bill",
        },
        bids_stat: {
          all: "All bids",
          new: "New bids",
          waiting: "Awaited",
          denial: "Denied",
          declined: "Declined",
          placed: "Recorded",
          closed: "Closed",
          bids_to_tickets: "Bought tickets",
          prolongation: "Prolongated ticket",
        },
        salary_stat_data: {
          sail_time: "Sail time",
          ticket: "Ticket",
          coach: "Coach",
          sum: "Sum",
        },
        export_filters: {
          exclude_tickets: "Not counted tickets",
          exclude_promos: "Not counted promos",
        },
      },
      bids: {
        child: "Child",
        adult: "Adult",
        contact_name: "Full name",
        child_name: "Child name",
        phone: "Phone",
        email: "Email",
        employees: "Employees",
        bid_source: "Bid source",
        bid_date: "Bid date",
        club_unit: "Club unit",
      },
      notification: {
        date: "Notification date",
        sketches: "Sketches",
      },
      products: {
        tickets: "Tickets",
        promos: "Promos",
        ticket: "Ticket",
        promo: "Promo",
        no_limit: "No limit",
        ticket_manage: {
          name: "Name",
          drills_amount: "Drills amount",
          days_duration: "Duration period",
          disciplines: "Disciplines",
          drill_type: "Drill type",
          illusive_ticket: "Illusive ticket",
          coach_skill_related: "Price related to coach skill",
          price: "Price",
          profit: "Profit",
          freeze_approve: "Freeze approve",
          bonus: "Bonuses",
        },
        promo_manage: {
          name: "Name",
          description: "Description",
          discount: "Discount",
          closed: "Closed",
        },
      },
      club: {
        club_unit: "Club unit",
        address: "Address",
        coaches: "Coaches",
        managers: "Administrators",
        salary: "Salary",
        disciplines: "Disciplines",
        drill_types: "Drills types",
        new_manager: "Add administrator",
        new_drill_type: "New drill type",
        new_discipline: "New discipline",
        new_club_unit: "New club unit",
        new_payment_type: "New payment type",
        shortcut: "Shortcut",
        description: "Description",
        name: "Name",
        departments: "Departments",
        new_department: "New department",
        payment_type: "Payment type",
        drill_salary: {
          drill_salary: "Drill payment",
          coach_skill: "Coach skill",
          drill_type: "Drill type",
          fix_pay: "Fixed payment",
          coach_salary: "Coach salary",
          clients_from: "Clients from",
          clients_to: "Clients to",
          ticket: "Ticket",
          ticket_fraction: "Ticket's fraction",
        },
      },
      ticket_change_request: {
        ticket: "Ticket",
        discount: "Discount",
        disciplines: "Disciplines",
        coaches: "Coaches",
        date_start: "Start date",
        date_stop: "Stop date",
        active: "Active",
        payed: "Payed",
        frozen: "Frozen",
      },
    },
    ru: {
      week: {
        monday_shortcut:"Пн",
        tuesday_shortcut:"Вт",
        wednesday_shortcut:"Ср",
        thursday_shortcut:"Чт",
        friday_shortcut:"Пт",
        saturday_shortcut:"Сб",
        sunday_shortcut:"Вс",
      },
      menu: {
        profile: "Профиль",
        club: "Клуб",
        statistic: "Статистика",
        clients: "Клиенты",
        coaches: "Тренеры",
        calendar: "Календарь",
        bids: "Заявки",
        time_shifting: "Учет времени",
        service_items: "Товары и услуги",
        notifications: "Уведомления",
        ticket_edit_request: "Запрос на редактирование",
        market: "Магазин",
        rent: "Аренда",
      },
      filters: {
        filters: "Фильтры",
        club: "Клуб",
        club_unit: "Филиал клуба",
        discipline: "Дисциплина",
        coach: "Тренер",
        ticket: "Абонемент",
        client_type: "Тип клиента",
        client: "Клиент",
        employee_clients: "Клиенты сотрудника",
        drill_type: "Тип тренировки",
        all_drills: "Все тренировки",
        all_notifications: "Все уведомления",
        ticket_drills: "Тренировки по абонементу",
        time_period: "Период создания заявки",
        bid_status: "Статус заявки",
        bid_source: "Источник заявки",
        phone: "Телефон",
        date_start: "Дата начала",
        date_stop: "Дата окончания",
        date_from: "С",
        date_to: "По",
        apply: "Применить",
        promo: "Акция",
        employee_clients_values: {
          all: "Все",
          mine: "Мои",
        },
        client_type_values: {
          hooky: "Прогульщики",
          non_prolongation: "Непродленный абонемент",
          prolongation: "Продленный абонемент",
          no_ticket: "Без абонемента",
          frozen: "Замороженный абонемент",
          debtor: "Должники",
        },
        client_types: {
          hooky: "Прогульщики",
          non_prolongation: "Непродленные",
          prolongation: "Продленные",
          no_ticket: "Без абонемента",
          frozen: "Замороженные",
          debtor: "Должники",
        },
      },
      sorting: {
        ticket_exist_with: "По наличию абонементов (сначала с абонементами)",
        ticket_exist_without: "По наличию абонементов (сначала без абонементов)",
        created_date_asc: "По дате создания по увеличению",
        created_at_desc: "По дате создания по убыванию",
      },
      tables: {
        heads: {
          full_name: "ФИО",
          ticket: "АБОНЕМЕНТ",
          contacts: "КОНТАКТЫ",
          representative: "ПРЕДСТАВИТЕЛЬ",
          birthday: "ДАТА РОЖДЕНИЯ",
          socials: "СОЦСЕТИ",
          employees: "СОТРУДНИКИ",
          notes: "ЗАМЕТКИ",
          club_unit: "КЛУБ/ФИЛИАЛ",
          traffic_source: "ИСТОЧНИК ТРАФИКА",
          bid_date: "ДАТА ЗАЯВКИ",
          bid_status: "СТАТУС",
          drill: "ТРЕНИРОВКА",
          date_time: "ДАТА/ВРЕМЯ",
          coaches: "ТРЕНЕРЫ",
          attendance: "ПОСЕЩАЕМОСТЬ",
          client_bid: "КЛИЕНТ/ЗАЯВКА",
          type: "ТИП",
          noty_date: "ДАТА НАПОМИНАНИЯ",
          author: "АВТОР",
          disciplines: "ДИСЦИПЛИНЫ",
          coach_type: "КАТЕГОРИЯ",
          name: "НАЗВАНИЕ",
          drills_amount: "КОЛ-ВО ТЕНИРОВОК",
          drill_type: "ТИП ТРЕНИРОВКИ",
          discount: "СКИДКА",
          price: "ЦЕНА",
          client_price: "ЦЕНА КЛИЕНТА",
          payment: "ОПЛАТА",
          category: "КАТЕГОРИЯ",
          client: "КЛИЕНТ",
          description: "ОПИСАНИЕ",
          request_date: "ДАТА ЗАПРОСА",
          field: "ПОЛЕ",
          changes: "ИЗМЕНЕНИЯ",
        },
        body: {
          phone_shortcut: "Тел",
          name: "Имя",
          surname: "Фамилия",
          birthday_shortcut: "ДР",
          accept: "Принять",
          decline: "Отклонить",
          non_active_tickets: "Неактивные абонементы",
          attendance: "Посещаемость",
          coach_disciplines: "Дисциплины тренера",
          coach_club_units: "Филиалы тренера",
          status: {
            opened: "Открыта",
            closed: "Закрыта",
          },
        },
        actions: {
          remove: "Удалить",
          notify: "Уведомление",
          debt: "Долги",
          expired_tickets: "Неактивные абонементы",
          attendance: "Посещаемость",
          edit: "Редактировать",
          add_ticket: "Новый абонемент",
          close: "Закрыть",
          bid_to_client: "Создать клиента",
          show_notify_client: "Информация о клиенте",
          accept: "Принять",
          decline: "Отклонить",
          view: "Просмотр",
          comments: "Комментарии",
          permissions: "Права доступа",
        },
        debts: {
          name: "Название",
          date: "Дата",
          price: "Цена",
          payed: "Оплата",
          categories: "Категории",
          debts: "Долги",
        },
        rates: {
          clients: "Кол-во клиентов",
          rate: "Ставка",
        },
        tickets_info: {
          price: "Цена",
          profit: "Выгода",
          freeze: "Разрешена заморозка",
          bonus: "Бонусы",
          valid: "Действует в течении",
          days: "дней",
        },
        promo_info: {
          description: "Описание",
        },
        bids: {
          status: "Статус",
        },
      },
      tickets: {
        expired: "Истекшие",
        non_active: "Неактивные",
        frozen: "Замороженные",
        expired_header: "Истекшие абонементы",
        lost_drills: "Осталось тренировок",
        completed_drills: "Закончено тренировок",
        edit: "Редактировать",
        close: "Закрыть",
        remove: "Удалить",
        save: "Сохранить",
        cancel: "Отмена",
        no_ticket: "Без абонемента",
        club_units: "Филиалы",
        sail_date: "Дата продажи",
        manage: {
          ticket: "Абонемент",
          promo_code: "Промокод",
          discount: "Скидка",
          date_start: "Дата начала",
          expire_date: "Дата окончания",
          payed_amount: "Оплаченная сумма",
          ticket_price: "Стоимость абонемента",
          froze: "Заморозить",
          non_active: "Не активирован",
          new: "Новый абонемент",
          disciplines: "Виды спорта",
          coaches: "Тренеры",
          payment_type: "Тип оплаты",
          club_unit: "Филиал",
          promo: "Акция",
        },
      },
      user: {
        first_name: "Имя",
        last_name: "Фамилия",
        patronymic: "Отчество",
        phone: "Телефон",
        email: "Email",
        trustee_phone: "Телефон представителя",
        trustee_name: "Имя представителя",
        birthday: "Дата рождения",
        employees: "Сотрудники",
        sketches: "Заметки",
        user_type: "Тип пользователя",
        coach_skill: "Категория",
      },
      user_type: {
        client: "Клиент",
        coach: "Тренер",
        admin: "Администратор",
        director: "Директор",
        owner: "Владелец",
      },
      forms: {
        register: {
          password: "Пароль",
          phone: "Телефон",
          first_name: "Имя",
          last_name: "Фамилия",
          patronymic: "Отчество",
          email: "Email",
          under_age: "Несовершеннолетний",
          trustee_name: "Имя представителя",
          trustee_phone: "Телефон представителя",
          header: "Новый клиент",
          coach_header: "Новый тренер",
          btn: "Регистрация",
          required: "Необходимо заполнить телефон или email",
        },
        auth: {
          btn: "Вход",
        },
        calendar: {
          new_drill: "Новая тренировка",
          club_unit: "Филиал клуба",
          drill_type: "Тип тренировки",
          discipline: "Вид спорта",
          weekdays: "Дни недели",
          weekday: "День недели",
          coaches: "Тренеры",
          time_start_stop: "Время начала и конца",
          time_start: "Время начала",
          time_stop: "Время конца",
          single: "Разовая тренировка",
          drill_date: "Дата тренировки",
        },
        choose: {
          exist_user: "Зарегестрированный пользователь",
          new_user: "Создать нового пользователя",
        },
      },
      common: {
        error: "Ошибка",
        save: "Сохранить",
        cancel: "Отменить",
        remove: "Удалить",
        show: "Показать",
        hide: "Скрыть",
        create: "Создать",
        notify_type: "Тип уведомления",
        notify_date: "Дата уведомления",
        search_placeholder: "Имя Фамилия",
        phone_search_placeholder: "Номер телефона",
        amount: "Сумма",
        ticket: "Абонемент",
        club_unit: "Филиал",
        promo: "Акция",
        rates: "Ставки",
        apply: "Подтвердить",
        disciplines: "Дисциплины",
        page_no_access: "Страница недоступна",
        add: "Добавить",
        accept: "Принять",
        decline: "Отклонить",
        hour: "ч.",
        minute: "мин.",
        second: "сек.",
        download: "Скачать",
        messages: {
          phone_email: "Необходимо заполнить телефон или email",
          notify_date_type: "Тип уведомления и дата обязательны",
          wrong_credentials: "Неверная пара логин/пароль",
          failed_notify_create: "Уведомление не было создано",
          ticket_require_club_unit: "Необходим выбрать филиал для редактирования и создания абонемента",
          box_office_amount: "Остаток в кассе",
          fill_all_fields: "Заполни все поля формы",
          remove_manager: "Убрать администратора из филиала?",
          new_comment: "Новый комментарий",
        },
        approve_actions: {
          accept_action: "Подтвердите действие",
          remove_ticket: "Вы уверены, что хотите удалить абонемент?",
          close_ticket: "Вы уверены, что хотите закрыть абонемент?",
          remove_client: "Вы уверены, что хотите удалить клиента?",
          remove_coach: "Вы уверены, что хотите удалить тренера?",
          close_noty: "Закрыть уведомление?",
          remove_noty: "Вы уверены, что хотите удалить уведомление?",
          bid_to_client: "Вы хотите закрыть заявку и создать клиента?",
          continue_shifting: "Все еще работаете?",
          accept_change_request: "Принять запрос на редактирование?",
          decline_change_request: "Отклонить запрос на редактирование?",
          remove_manager: "Вы уверены, что хотите удалить сотрудника из отдела?",
          remove_payment_type: "Вы уверены, что хотите удалить тип оплаты?",
          motivating_message_0: "Всякая работа трудна до времени, пока ее не полюбишь, а потом — она возбуждает и становится легче. (М. Горький)",
          motivating_message_1: "Только два стимула заставляют работать людей: жажда заработной платы и боязнь её потерять. (Г. Форд)",
          motivating_message_2: "Работайте как в аду. Я имею ввиду, что вы должны работать от 80 до 100 часов в неделю каждую неделю. Это повышает шансы на успех. Если остальные будут работать по 40 часов в неделю, а вы будете работать 100 часов, то даже если вы будете делать одинаковые вещи, вы за 4 месяца достигнете того, на что у остальных уйдёт целый год. (И. Маск)",
          motivating_message_3: "Пенни не падают к нам с небес, мы вынуждены зарабатывать их здесь на земле. (М. Тэтчер)",
          motivating_message_4: "Кто не работает, тот не занимается любовью. (А. Челентано)",
          motivating_message_5: "Есть только один способ проделать большую работу — полюбить ее. Если вы к этому не пришли, подождите. Не бросайтесь за дело. Как и со всем другим, подсказать интересное дело вам поможет собственное сердце. (С. Джобс)",
          motivating_message_6: "Работа необходима для здоровья. (Гиппократ)",
          motivating_message_7: "Работа — лучшее лекарство от всех бед. (Э. Хемингуэй)",
          motivating_message_8: "Работа, которую мы делаем охотно, исцеляет боли. (У. Шекспир)",
        },
      },
      chat: {
        contacts: "Контакты",
        chat: "Чат",
        message: "Сообщение",
        edited: "Отредактировано",
        today: "Сегодня",
        client: "Клиент",
        bid: "Заявка",
      },
      profile: {
        profile: "Профиль",
        tickets: "Абонементы",
        videoconfs: "Видеоконференции",
      },
      videoconfs: {
        videoconf: "Конференция",
        participants: "Участники",
        new_conf: "Новая видеоконференция",
        name: "Имя",
      },
      calendar: {
        clients_amount: "Клиентов",
        present_clients: "Клиенты",
        edit: "Редактировать",
        replace: "Перенести",
        cancel: "Отменить",
        substitute: "Замена",
        remove: "Удалить",
        choose_drill_new_date: "Выбери новую дату тренировки",
        replaced: "Перенесена",
        removed: "Отменена",
        accept_cancel_drill: "Отменить тренировку?",
        accept_remove_drill: "Удалить все последующие тренировки?",
        substitute_coach: "Выбери тренера на замену",
        drill_time: "Время тренировки",
        actions: {},
        drill_attendance: {
          client: "Клиент",
          tickets: "Абонементы клиента",
          choose_ticket: "Абонементы",
          new_ticket: "Добавить абонемент",
        },
      },
      statistic: {
        export: "Экспорт статистики",
        ticket_stat: "Статистика по абонементам",
        coach_stat: "Статистика по тренерам",
        bid_stat: "Статистика по заявкам",
        sales_stat: "Статистика по продажам",
        box_office_stat: "Отчет по кассе",
        managers_stat: "Статистика по менеджерам",
        new_tickets: "Новые абонементы",
        salary_stat: "Зарплата",
        export_stat: "Экспорт",
        clients: "Клиенты",
        report: "Отчет",
        total: "Всего",
        total_drills: "Всего проведено тренировок",
        total_attendance: "Всего пришло клиентов",
        bid_source: "Источник заявки",
        coach_statistic: {
          date_start: "С",
          date_stop: "По",
          prolong_promos: "Абонементы, купленные после акции ",
          promo: "Акция",
          coach_stat: "Купленные абонементы после акций к тренеру ",
          coach: "Тренер",
          unlimit_ticket_stat: "Посещаемость по безлимитным абонементам",
          extend_promos: "Продление акций",
        },
        tickets_stat: {
          header: "Продажи абонементов",
          all: "Всего",
          prolongated: "Продленные",
          non_prolongated: "Непродленные",
          new: "Новые",
          frozen: "Замороженные",
          active: "Активные",
          active_tickets: "Активные абонементы",
          active_clients: "Активные клиенты",
          table: {
            date: "Дата",
            client: "Имя клиента",
            ticket: "Абоенмент",
            start_date: "Дата начала",
            expire_date: "Дата окончания",
            closing_date: "Дата закрытия",
            coaches: "Тренеры",
            club_unit: "Филиалы",
            price: "Цена",
            discount: "Скидка",
            payed_amount: "Оплата",
          },
        },
        ticket_sales_stat: {
          clients_stat: 'Статистика по клиентам',
          new: 'Новые',
          renewed: 'Продленные',
          non_renewed: 'Непродленные',
          out_of_stat: 'Не попавшие в статистику',
        },
        purchase_stat: {
          managers: "Менеджеры",
          club_units: "Филиалы",
          coaches: "Тренеры",
        },
        plots: {
          prolongated: "Продленные",
          non_prolongated: "Непродленные",
          new_tickets: "Новые абонементы",
        },
        day_report: {
          payment_type_income: "Выручка по способу оплаты",
          attendance: "Проходимость",
          new_clients: "Новых клиентов",
          predicted_profit: "Ожидаемая прибыль",
          real_income: "Реальная прибыль",
          income: "Доход от",
          average_bill: "Средний чек",
        },
        bids_stat: {
          all: "Всего заявок",
          new: "Новые",
          waiting: "Думающие",
          denial: "Отказ",
          declined: "Отклоненные",
          placed: "Запись",
          closed: "Закрытые",
          bids_to_tickets: "Купили абонемент",
          prolongation: "Продлили абонемент",
        },
        salary_stat_data: {
          sail_time: "Дата продажи",
          ticket: "Абонемент",
          coach: "Тренер",
          sum: "Сумма",
        },
        export_filters: {
          exclude_tickets: "Неучитываемые абонементы",
          exclude_promos: "Неучитываемые акции",
        },
      },
      bids: {
        child: "Ребенок",
        adult: "Взрослый",
        contact_name: "ФИО",
        child_name: "Имя ребенка",
        phone: "Телефон",
        email: "Email",
        employees: "Сотрудники",
        bid_source: "Источник заявки",
        bid_date: "Дата заявки",
        club_unit: "Филиал",
      },
      notification: {
        date: "Дата уведомления",
        sketches: "Заметки",
      },
      products: {
        tickets: "Абонементы",
        promos: "Акции",
        ticket: "Абонемент",
        promo: "Акция",
        no_limit: "Безлимит",
        ticket_manage: {
          name: "Название",
          drills_amount: "Кол-во тренировок",
          days_duration: "Кол-во дней действия",
          disciplines: "Дисциплины",
          drill_type: "Тип тренировки",
          illusive_ticket: "Призрачный абонемент",
          coach_skill_related: "Цена зависит от уровня тренера",
          price: "Цена",
          profit: "Выгода",
          freeze_approve: "Разрешена заморозка",
          bonus: "Бонусы",
        },
        promo_manage: {
          name: "Название",
          description: "Описание",
          discount: "Скидка",
          closed: "Закрыта",
        },
      },
      club: {
        club_unit: "Филиал",
        address: "Адрес",
        coaches: "Тренеры",
        managers: "Администраторы",
        salary: "Оплата труда",
        disciplines: "Дисциплины",
        drill_types: "Типы тренировок",
        new_manager: "Новый администратор",
        new_drill_type: "Новый тип тренировки",
        new_discipline: "Новая дисциплина",
        new_club_unit: "Новый филиал",
        new_payment_type: "Новый тип оплаты",
        shortcut: "Сокращение",
        description: "Описание",
        name: "Название",
        departments: "Отделы клуба",
        new_department: "Новый отдел",
        payment_type: "Тип оплаты",
        drill_salary: {
          drill_salary: "Оплата тренировки",
          coach_skill: "Уровень тренера",
          drill_type: "Тип тренировки",
          fix_pay: "Фиксированная плата",
          coach_salary: "Оплата тренера",
          clients_from: "Клиентов от",
          clients_to: "Клиентов до",
          ticket: "Абонемент",
          ticket_fraction: "Доля от стоимости абонемента",
        },
      },
      ticket_change_request: {
        ticket: "Абонемент",
        discount: "Скидка",
        disciplines: "Дисциплины",
        coaches: "Тренеры",
        date_start: "Дата начала",
        date_stop: "Дата окончания",
        active: "Активный",
        payed: "Оплачен",
        frozen: "Заморожен",
      },
    },
  },
});

export default i18n;
