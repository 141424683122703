import React, { useEffect } from "react";

import { useRecoilValue } from 'recoil';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useTranslation } from 'react-i18next';

import { BaseApi } from "../../api/base";

import { BootstrapDialog } from "../common/Dialogs";
import { ApproveAction } from "../common/ActionApprove";

import {
  TicketsManageProps,
  PromosManageProps,
  NewTicketProps,
  NewPromoProps,
  AutocompleteProps,
} from "../../interfaces/Props";
import { BodyObj } from "../../interfaces/Types";
import {
  Discipline,
  DrillType,
  CoachSkillInterface,
} from "../../interfaces/Common";

import { accountInfoAtom } from '../../atoms/AccountInfo';

interface BaseColumn {
  minWidth?: number;
  align?: 'right';
}

interface TicketsColumn extends BaseColumn {
  id: 'name' | 'drills_amount' |  'drill_type' | 'disciplines';
}

const ticket_columns: readonly TicketsColumn[] = [
  { id: 'name', minWidth: 250 },
  { id: 'drills_amount', minWidth: 150 },
  { id: 'drill_type', minWidth: 150 },
  { id: 'disciplines', minWidth: 250 },
];

interface PromoColumn extends BaseColumn {
  id: 'name' | 'ticket' |  'discount';
}

const promo_columns: readonly PromoColumn[] = [
  { id: 'name', minWidth: 250 },
  { id: 'ticket', minWidth: 150 },
  { id: 'discount', minWidth: 150 },
];

export function TicketsStore(props: TicketsManageProps) {
  const { t } = useTranslation(['tables', 'products', 'common']);
  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null);
  const [openMenu, setOpenMenu] = React.useState<number>();
  const [editTicket, setEditTicket] = React.useState<number>(0);
  const [removeTicket, setRemoveTicket] = React.useState<number>(0);
  
  const handleClose = () => {
    setAnchorMenu(null);
    setOpenMenu(0);
  };
  
  return (
    <Box>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: '74vh' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {ticket_columns.map((column) => (
                  <TableCell
                    key={`cell-${column.id}`}
                    align={column.align}
                    style={{ minWidth: column.minWidth, backgroundColor: 'var(--orange)' }}
                  >
                    {t(`heads.${column.id}`)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.tickets.map((ticket, index) => {
                return (
                  <TableRow
                    tabIndex={-1}
                    key={`row-key-${index}`}
                    sx={{ backgroundColor: (!ticket.deleted) ? 'transparent' : 'var(--orange)' }}
                  >
                    <TableCell key={`key-name-${index}`}>
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={openMenu === ticket.id ? 'long-menu' : undefined}
                        aria-expanded={openMenu === ticket.id ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          setOpenMenu(ticket.id);
                          setAnchorMenu(e.currentTarget);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      {ticket.ticket}
                    </TableCell>
                    <TableCell key={`key-drills-amount-${index}`}>
                      <Typography>
                        {(ticket.drill_number >= 0) ? ticket.drill_number : t('no_limit', { ns: 'products' })}
                      </Typography>
                    </TableCell>
                    <TableCell key={`key-drill-type-${index}`}>
                      <Typography>{ticket.drill_type?.name}</Typography>
                    </TableCell>
                    <TableCell key={`key-disciplines-${index}`}>
                      <Typography variant='h6'>{t('disciplines', { ns: 'common' })}:</Typography>
                      <List component="nav">
                        {ticket.discipline.map((discip, ind) => {
                          return (
                            <ListItemText
                              key={`ticket-discipline-${ticket.id}-${ind}`}
                              primary={discip.name}
                            />
                          )
                        })}
                      </List>
                    </TableCell>
                    <Menu
                      id={`ticket-menu-id-${index}`}
                      MenuListProps={{
                        'aria-labelledby': 'long-button',
                      }}
                      anchorEl={anchorMenu}
                      open={openMenu === ticket.id}
                      onClose={handleClose}
                    >
                      <MenuItem
                        key={`menu_item-edit-${index}`}
                        onClick={(e) => {
                          setEditTicket(ticket.id);
                          handleClose();
                        }}
                        className="control-menu-item-wrapper"
                      >
                        {t("actions.edit")}
                      </MenuItem>
                      <MenuItem
                        key={`menu_item-remove-${index}`}
                        onClick={
                          (e) => {
                            setRemoveTicket(ticket.id);
                            handleClose()
                          }
                        }
                        className="control-menu-item-wrapper"
                      >
                        {t("actions.close")}
                      </MenuItem>
                    </Menu>
                    <BootstrapDialog
                      key={`edit-ticket-${ticket.id}`}
                      onClose={() => {setEditTicket(0)}}
                      open={editTicket === ticket.id}
                    >
                      <DialogTitle sx={{ paddingLeft: '5px' }}>
                        {ticket.ticket}
                      </DialogTitle>
                      <DialogContent dividers className="box-content-center">
                        <ManageTicket
                          appState={props.appState}
                          appSetState={props.appSetState}
                          device={props.device}
                          apply={(data: BodyObj) => {
                            props.updateTicket(data);
                            setEditTicket(0);
                          }}
                          close={() => {setEditTicket(0)}}
                          clubUnit={props.clubUnit}
                          ticket={ticket}
                        />
                      </DialogContent>
                    </BootstrapDialog>
                    <ApproveAction
                      handleApprove={
                        () => {
                          props.updateTicket(
                            {
                              id: ticket.id,
                              deleted: true,
                              club_unit: ticket.club_unit,
                              discipline: ticket.discipline.map((d) => {return d.id}),
                              ticket: ticket.ticket,
                            }
                          );
                          setRemoveTicket(0);
                        }
                      }
                      handleDecline={() => {setRemoveTicket(0)}}
                      action={'close_ticket'}
                      show={removeTicket}
                      obj_pk={ticket.id}
                    />
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  )
}

export function PromosStore(props: PromosManageProps) {
  const { t } = useTranslation(['tables', 'products', 'common']);
  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null);
  const [openMenu, setOpenMenu] = React.useState<number>();
  const [editPromo, setEditPromo] = React.useState<number>(0);
  const [removePromo, setRemovePromo] = React.useState<number>(0);
  
  const handleClose = () => {
    setAnchorMenu(null);
    setOpenMenu(0);
  };
  
  
  return (
    <Box>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: '74vh' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {promo_columns.map((column) => (
                  <TableCell
                    key={`cell-${column.id}`}
                    align={column.align}
                    style={{ minWidth: column.minWidth, backgroundColor: 'var(--orange)' }}
                  >
                    {t(`heads.${column.id}`)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.promos.map((promo, index) => {
                return (
                  <TableRow
                    tabIndex={-1}
                    key={`row-key-${index}`}
                    sx={{ backgroundColor: (!promo.closed) ? 'transparent' : 'var(--orange)' }}
                  >
                    <TableCell key={`key-name-${index}`}>
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={openMenu === promo.id ? 'long-menu' : undefined}
                        aria-expanded={openMenu === promo.id ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          setOpenMenu(promo.id);
                          setAnchorMenu(e.currentTarget);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      {promo.name}
                    </TableCell>
                    <TableCell key={`key-tickets-${index}`}>
                      <Typography variant='h6'>{t('tickets', { ns: 'products' })}:</Typography>
                      <List component="nav">
                        {promo.tickets.map((ticket, ind) => {
                          return (
                            <ListItemText
                              key={`promo-ticket-${promo.id}-${ind}`}
                              primary={ticket.ticket.ticket}
                            />
                          )
                        })}
                      </List>
                    </TableCell>
                    <TableCell key={`key-discount-${index}`}>
                      <Typography>
                        {
                          (promo.tickets.map((t) => {return t.discount}).length)
                            ? (promo.tickets.map((t) => {return t.discount})[0] * 100).toFixed(2)
                            : 0.0
                        }
                      </Typography>
                    </TableCell>
                    <Menu
                      id={`promo-menu-id-${index}`}
                      MenuListProps={{
                        'aria-labelledby': 'long-button',
                      }}
                      anchorEl={anchorMenu}
                      open={openMenu === promo.id}
                      onClose={handleClose}
                    >
                      <MenuItem
                        key={`menu_item-edit-${index}`}
                        onClick={(e) => {
                          setEditPromo(promo.id);
                          handleClose();
                        }}
                        className="control-menu-item-wrapper"
                      >
                        {t("actions.edit")}
                      </MenuItem>
                      <MenuItem
                        key={`menu_item-remove-${index}`}
                        onClick={
                          (e) => {
                            setRemovePromo(promo.id);
                            handleClose()
                          }
                        }
                        className="control-menu-item-wrapper"
                      >
                        {t("actions.close")}
                      </MenuItem>
                    </Menu>
                    <BootstrapDialog
                      key={`edit-promo-${promo.id}`}
                      onClose={() => {setEditPromo(0)}}
                      open={editPromo === promo.id}
                    >
                      <DialogTitle sx={{ paddingLeft: '5px' }}>
                        {promo.name}
                      </DialogTitle>
                      <DialogContent dividers className="box-content-center">
                        <ManagePromo
                          appState={props.appState}
                          appSetState={props.appSetState}
                          device={props.device}
                          apply={(data: BodyObj) => {
                            props.updatePromo(data);
                            setEditPromo(0);
                          }}
                          close={() => {setEditPromo(0)}}
                          clubUnit={props.clubUnit}
                          promo={promo}
                          tickets={props.tickets}
                        />
                      </DialogContent>
                    </BootstrapDialog>
                    <ApproveAction
                      handleApprove={
                        () => {
                          props.updatePromo(
                            {
                              id: promo.id,
                              closed: true,
                              club_units: [promo.club_unit.id],
                              name: promo.name,
                              description: promo.description,
                              tickets: promo.tickets.map((t) => {return {ticket: t.ticket.id, discount: t.discount}}),
                            }
                          );
                          setRemovePromo(0);
                        }
                      }
                      handleDecline={() => {setRemovePromo(0)}}
                      action={'close_ticket'}
                      show={removePromo}
                      obj_pk={promo.id}
                    />
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  )
}

export function ManageTicket(props: NewTicketProps) {
  const { t } = useTranslation(['products', 'common']);
  const accountInfo = useRecoilValue(accountInfoAtom);
  const [clubUnits, setClubUnits] = React.useState<ClubUnit[]>([]);
  const [skillPriceRelated, setSkillPriceRelated] = React.useState<boolean>(
    Boolean(props.ticket?.coach_drill_cost?.length)
  );
  const [disciplines, setDisciplines] = React.useState<Discipline[]>([]);
  const [drillTypes, setDrillTypes] = React.useState<DrillType[]>([]);
  const [coachSkills, setCoachSkills] = React.useState<CoachSkillInterface[]>([]);
  const [spinner, setSpinner] = React.useState<boolean>(true);
  const [formValid, setFormValid] = React.useState<boolean>((props.ticket) ? true : false);
  const [manageBody, setManageBody] = React.useState<BodyObj>(
    (props.ticket)
      ? {
          id: props.ticket.id,
          ticket: props.ticket.ticket,
          drill_number: props.ticket.drill_number,
          club_unit: props.ticket.club_unit,
          discipline: props.ticket.discipline.map((d) => {return d.id}),
          drill_type: props.ticket.drill_type?.id,
          illusive: props.ticket.illusive,
          freeze: Boolean(props.ticket.freeze),
          bonus: (props.ticket.bonus) ? props.ticket.bonus : '',
          profit: (props.ticket.profit) ? props.ticket.profit : null,
          days: (props.ticket.days) ? props.ticket.days : 1,
          price: (props.ticket.price) ? props.ticket.price : null,
          coach_tickets: (props.ticket.coach_drill_cost)
            ? props.ticket.coach_drill_cost.map(
                (cdc) => {
                  return {
                    skill: cdc.skill,
                    price: Number(cdc.price),
                    profit: Number(cdc.profit),
                  } as BodyObj
                }
              )
            : []
        }
      : { club_unit: [props.clubUnit.id] }
  );

  const getObjects = async () => {
    setSpinner(true);
    const api = new BaseApi(1, 'management/disciplines/');
    let res = await api.get(
      {},
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      setDisciplines(res.body.results);
    };

    api.url = api.create_url(1, 'management/drills_types/');
    res = await api.get(
      {},
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      setDrillTypes(res.body.results);
    };

    api.url = api.create_url(1, 'management/coach_skill_choices/');
    res = await api.get(
      {},
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      setCoachSkills(res.body);
    };
    
    setSpinner(false);
  };

  useEffect(() => {
    getObjects();
    let _units = [];
    for (let club of accountInfo.account_info.clubs) {
      _units.push(...club.units);
    };
    setClubUnits(_units);
  }, []);

  useEffect(() => {
    if (
      !manageBody.price &&
      !manageBody.coach_tickets
    ) {
      setFormValid(false);
      return
    }
    if (
      !manageBody.club_unit ||
      !manageBody.ticket ||
      !manageBody.discipline ||
      !manageBody.drill_type
    ) {
      setFormValid(false);
      return
    }
    setFormValid(true);
  }, [manageBody]);

  return (
    <Box>
      {spinner
        ? <Box
            sx={{
              width: 'fit-content',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        : <Box>
            <Box sx={{ width: '100%' }}>
              <Autocomplete
                id="combo-box-demo"
                options={
                  clubUnits.map(
                    (cu: any) => {return {id: cu.id, label: cu.name}}
                  )
                }
                defaultValue={
                  clubUnits.filter(
                    cu => manageBody.club_unit.includes(cu.id)
                  ).map(
                    (cu: any) => {return {id: cu.id, label: cu.name}}
                  )
                }
                sx={{ width: "100%", marginBottom: "15px", zIndex: '100' }}
                renderInput={(params) => <TextField {...params} label={t('club_unit', { ns: 'common' })} />}
                multiple={true}
                onChange={(
                  e: React.SyntheticEvent,
                  value: Array<AutocompleteProps> | null,
                  reason: string,
                ) => {
                  if (value && value.length) {
                    setManageBody(
                      {
                        ...manageBody,
                        club_unit: value.map((c) => {return parseInt(c.id.toString())})
                      }
                    )
                  } else {
                    setManageBody({ ...manageBody, club_unit: [] })
                  }
                }}
              />
            </Box>
            <Box sx={{ marginBottom: '10px' }}>
              <TextField
                id={`ticket-name-${props.ticket?.id}`}
                label={t('ticket_manage.name')}
                variant="standard"
                onChange={(e) => {
                  setManageBody({ ...manageBody, ticket: e.target.value })
                }}
                defaultValue={props.ticket?.ticket}
              />
            </Box>
            <Divider />
            <Box sx={{ marginBottom: '10px' }}>
              <TextField
                id={`ticket-drills-amount-${props.ticket?.id}`}
                label={t('ticket_manage.drills_amount')}
                variant="standard"
                type='number'
                onChange={(e) => {
                  setManageBody({ ...manageBody, drill_number: Number(e.target.value) })
                }}
                defaultValue={props.ticket?.drill_number}
              />
            </Box>
            <Divider />
            <Box sx={{ marginBottom: '10px' }}>
              <TextField
                id={`ticket-days-duration-${props.ticket?.id}`}
                label={t('ticket_manage.days_duration')}
                variant="standard"
                type='number'
                onChange={(e) => {
                  setManageBody({ ...manageBody, days: Number(e.target.value) })
                }}
                defaultValue={props.ticket?.days}
              />
            </Box>
            <Divider />
            <Autocomplete
              id="ticket-discipline-managing"
              options={
                disciplines.map(
                  (discip) => {return {id: discip.id, label: discip.name}}
                )
              }
              sx={{ width: "100%", marginBottom: "15px", marginTop: "15px" }}
              defaultValue={
                (props.ticket && props.ticket.discipline)
                  ? props.ticket.discipline.map((discip: any) => {return {id: discip.id, label: discip.name}})
                  : []
              }
              renderInput={(params) => <TextField {...params} label={t("ticket_manage.disciplines")} />}
              multiple={true}
              onChange={(
                e: React.SyntheticEvent,
                value: Array<AutocompleteProps> | null,
                reason: string,
              ) => {
                if (value && value.length) {
                  setManageBody(
                    {
                      ...manageBody,
                      discipline: value.map((d) => {return Number(d.id)}),
                    }
                  );
                  setFormValid(true);
                }
              }}
            />
            <Autocomplete
              id="ticket-drill-type-managing"
              options={
                drillTypes.map(
                  (drill_type) => {return {id: drill_type.id, label: drill_type.name}}
                )
              }
              sx={{ width: "100%", marginBottom: "15px" }}
              defaultValue={
                (props.ticket && props.ticket.drill_type)
                  ? { id: props.ticket.drill_type.id, label: props.ticket.drill_type.name }
                  : null
              }
              renderInput={(params) => <TextField {...params} label={t("ticket_manage.drill_type")} />}
              onChange={(
                e: React.SyntheticEvent,
                value: AutocompleteProps | null,
                reason: string,
              ) => {
                if (value) {
                  setManageBody(
                    {
                      ...manageBody,
                      drill_type: Number(value.id),
                    }
                  );
                  setFormValid(true);
                }
              }}
            />
            <Divider />
            <Box sx={{ marginBottom: '10px' }}>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(e: React.ChangeEvent) => {
                      setManageBody(
                        { ...manageBody, illusive: e.target.checked }
                      );
                    }}
                    defaultChecked={props.ticket?.illusive}
                  />
                }
                label={t('ticket_manage.illusive_ticket')}
              />
            </Box>
            <Divider />
            <Box sx={{ marginBottom: '5px' }}>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(e: React.ChangeEvent) => {
                      let _manageBody = manageBody;
                      if (e.target.checked) {
                        _manageBody.price = null;
                        _manageBody.profit = null;
                      } else {
                        _manageBody.coach_tickets = [];
                      }
                      setManageBody(_manageBody);
                      setSkillPriceRelated(e.target.checked)
                    }}
                    defaultChecked={Boolean(props.ticket?.coach_drill_cost?.length)}
                  />
                }
                label={t('ticket_manage.coach_skill_related')}
              />
            </Box>
            <Divider />
            <Box sx={{ marginBottom: '10px' }}>
              {skillPriceRelated
                ? <TableContainer sx={{ maxHeight: '74vh' }}>
                    <Table stickyHeader>
                      <TableBody>
                        {coachSkills.map((skill, index) => {
                          return (
                            <TableRow tabIndex={-1} key={`row-key-${index}`}>
                              <TableCell key={`key-coach-skill-${index}`}>
                                <Typography>
                                  {skill.name}
                                </Typography>
                              </TableCell>
                              <TableCell key={`key-coach-price-${index}`}>
                                <TextField
                                  id={`ticket-coach-price-${skill.id}-${index}`}
                                  label={t('ticket_manage.price')}
                                  variant="standard"
                                  type='number'
                                  onChange={(e) => {
                                    let coach_tickets = manageBody.coach_tickets;
                                    if (!coach_tickets) {
                                      coach_tickets = [];
                                    }
                                    let skill_data = coach_tickets?.filter((s: any) => {return s.skill === skill.id});
                                    if (skill_data && skill_data.length) {
                                      coach_tickets = coach_tickets.map((ct: any) => {
                                        if (ct.skill === skill.id) {
                                          return { skill: skill.id, price: parseFloat(e.target.value), profit: ct.profit }
                                        } else {
                                          return ct
                                        }
                                      });
                                    } else {
                                      coach_tickets.push(
                                        { skill: skill.id, price: parseFloat(e.target.value) }
                                      )
                                    }
                                    setManageBody({ ...manageBody, coach_tickets: coach_tickets })
                                  }}
                                  defaultValue={
                                    props.ticket?.coach_drill_cost?.filter(cdc => cdc.skill === skill.id)[0]?.price
                                  }
                                />
                              </TableCell>
                              <TableCell key={`key-disciplines-${index}`}>
                                <TextField
                                  id={`ticket-coach-profit-${skill.id}-${index}`}
                                  label={t('ticket_manage.profit')}
                                  variant="standard"
                                  type='number'
                                  onChange={(e) => {
                                    let coach_tickets = manageBody.coach_tickets;
                                    if (!coach_tickets) {
                                      coach_tickets = [];
                                    }
                                    let skill_data = coach_tickets?.filter((s: any) => {return s.skill === skill.id});
                                    if (skill_data && skill_data.length) {
                                      coach_tickets = coach_tickets?.map((ct: any) => {
                                        if (ct.skill === skill.id) {
                                          return { skill: skill.id, price: ct.price, profit: parseFloat(e.target.value) }
                                        } else {
                                          return ct
                                        }
                                      });
                                    } else {
                                      coach_tickets.push(
                                        { skill: skill.id, profit: parseFloat(e.target.value) }
                                      )
                                    }
                                    setManageBody({ ...manageBody, coach_tickets: coach_tickets })
                                  }}
                                  defaultValue={
                                    props.ticket?.coach_drill_cost?.filter(cdc => cdc.skill === skill.id)[0]?.profit
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  : <Box>
                      <Box>
                        <TextField
                          id={`ticket-price-${props.ticket?.id}`}
                          label={t('ticket_manage.price')}
                          variant="standard"
                          type='number'
                          onChange={(e) => {
                            setManageBody({ ...manageBody, price: Number(e.target.value) })
                          }}
                          defaultValue={props.ticket?.price}
                        />
                      </Box>
                      <Box>
                        <TextField
                          id={`ticket-profit-${props.ticket?.id}`}
                          label={t('ticket_manage.profit')}
                          variant="standard"
                          type='number'
                          onChange={(e) => {
                            setManageBody({ ...manageBody, profit: Number(e.target.value) })
                          }}
                          defaultValue={props.ticket?.profit}
                        />
                      </Box>
                    </Box>
              }
            </Box>
            <Divider />
            <Box sx={{ marginBottom: '10px' }}>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(e: React.ChangeEvent) => {
                      setManageBody(
                        { ...manageBody, freeze: e.target.checked }
                      );
                    }}
                    defaultChecked={Boolean(props.ticket?.freeze)}
                  />
                }
                label={t('ticket_manage.freeze_approve')}
              />
            </Box>
            <Divider />
            <Box sx={{ marginBottom: '10px' }}>
              <TextField
                id={`ticket-bonus-${props.ticket?.id}`}
                label={t('ticket_manage.bonus')}
                multiline
                rows={6}
                variant="standard"
                fullWidth
                onChange={(e) => {
                  setManageBody({ ...manageBody, bonus: e.target.value })
                }}
                defaultValue={props.ticket?.bonus}
              />
            </Box>
            <Divider />
          </Box>
      }
      <DialogActions
        sx={{ marginBottom: "15px" }}
      >
        <Button
          variant="outlined"
          color="error"
          onClick={() => {props.close()}}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="info"
          disabled={!formValid}
          onClick={(e) => {
            props.apply(manageBody);
          }}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Box>
  )
}

export function ManagePromo(props: NewPromoProps) {
  const { t } = useTranslation(['products', 'common']);
  const [formValid, setFormValid] = React.useState<boolean>((props.promo) ? true : false);
  const [manageBody, setManageBody] = React.useState<BodyObj>(
    (props.promo)
      ? {
          id: props.promo.id,
          name: props.promo.name,
          club_units: [props.promo.club_unit.id],
          description: props.promo.description,
          closed: props.promo.closed,
          tickets: props.promo.tickets.map((t) => {return {ticket: t.ticket.id, discount: t.discount}}),
        }
      : { club_units: [props.clubUnit.id] }
  );
  const [discount, setDiscount] = React.useState<number>(
    (props.promo)
      ? props.promo.tickets?.map((t) => {return t.discount})[0]
      : 0
  );

  useEffect(() => {
    if (!manageBody.name) {
      setFormValid(false);
      return
    }
    setFormValid(true);
  }, [manageBody]);

  useEffect(() => {
    setManageBody(
      {
        ...manageBody,
        tickets: manageBody.tickets?.map((t: any) => {return {ticket: t.ticket, discount: discount}}),
      }
    )
  }, [discount]);

  return (
    <Box>
      <Box sx={{ marginBottom: '10px' }}>
        <TextField
          id={`ticket-name-${props.promo?.id}`}
          label={t('promo_manage.name')}
          variant="standard"
          onChange={(e) => {
            setManageBody({ ...manageBody, name: e.target.value })
          }}
          defaultValue={props.promo?.name}
        />
      </Box>
      <Divider />
      <Box sx={{ marginBottom: '10px' }}>
        <TextField
          id={`ticket-bonus-${props.promo?.id}`}
          label={t('promo_manage.description')}
          multiline
          rows={6}
          variant="standard"
          fullWidth
          onChange={(e) => {
            setManageBody({ ...manageBody, description: e.target.value })
          }}
          defaultValue={props.promo?.description}
        />
      </Box>
      <Divider />
      <Autocomplete
        id="ticket-discipline-managing"
        options={
          props.tickets.map(
            (t) => {return {id: t.id, label: t.ticket}}
          )
        }
        sx={{ width: "100%", marginBottom: "15px", marginTop: "15px" }}
        defaultValue={
          (props.promo && props.promo.tickets)
            ? props.tickets.filter(
                t => props.promo?.tickets.map((t) => {return t.ticket.id}).includes(t.id)
              ).map((t) => {return {id: t.id, label: t.ticket}})
            : []
        }
        renderInput={(params) => <TextField {...params} label={t("tickets")} />}
        multiple={true}
        onChange={(
          e: React.SyntheticEvent,
          value: Array<AutocompleteProps> | null,
          reason: string,
        ) => {
          if (value && value.length) {
            setManageBody(
              {
                ...manageBody,
                tickets: value.map((t) => {return {ticket: t.id, discount: discount}}),
              }
            );
            setFormValid(true);
          }
        }}
      />
      <Divider />
      <Box>
        <TextField
          id={`ticket-profit-${props.promo?.id}`}
          label={t('promo_manage.discount')}
          variant="standard"
          type='number'
          onChange={(e) => {
            setDiscount(parseFloat(e.target.value));
          }}
          defaultValue={props.promo?.tickets?.map((t) => {return t.discount * 100})[0]}
        />
      </Box>
      <Divider />
      {props.promo
        ? <Box>
            <Box sx={{ marginBottom: '5px' }}>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(e: React.ChangeEvent) => {
                      setManageBody({ ...manageBody, closed: e.target.checked });
                    }}
                    defaultChecked={Boolean(props.promo?.closed)}
                  />
                }
                label={t('promo_manage.closed')}
              />
            </Box>
            <Divider />
          </Box>
        : ''
      }
      <DialogActions
        sx={{ marginBottom: "15px" }}
      >
        <Button
          variant="outlined"
          color="error"
          onClick={() => {props.close()}}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="info"
          disabled={!formValid}
          onClick={(e) => {
            props.apply(manageBody);
          }}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Box>
  )
}
