import { atom, selector } from 'recoil'

import { BaseApi } from '../api/base';

import { DrillType } from '../interfaces/Common';

export interface IDrillTypeState {
  items: DrillType[]
}

export const drillTypesSelector = selector({
    key: 'drillTypesSelector',
    get: async (): Promise<IDrillTypeState> => {
      const api = new BaseApi(1, 'management/drills_types/');
      let res = await api.get(
        {},
        () => {},
        {},
      );
      if (res.status === 200) {
        return { items: res.body.results };
      };
      return { items: [] };
    },
    set: ({ set, get }, newData) => {
      const currentState = get(drillTypesAtom);
      let newState = [ ...currentState.items ];
      if (newData.items) {
        newState = [...currentState.items, ...newData.items as DrillType[]];
      }
      set(drillTypesAtom, {items: newState});
    },
});

export const drillTypesAtom = atom<IDrillTypeState>({
  key: 'drillTypes',
  default: drillTypesSelector,
})
