import { atom } from 'recoil'

export interface ISpinnerState {
  open: boolean;
}

const spinnerAtom = atom<ISpinnerState>({
  key: 'spinner',
  default: {
    open: false,
  },
})

export { spinnerAtom }
