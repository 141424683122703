import React, { useEffect } from "react";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';

import { useTranslation } from 'react-i18next';

import { ClientAttendanceProps } from '../../interfaces/Props';
import { ClientDrillAttendance } from "../../interfaces/Attendance";
import { UserInfoCommon } from "../../interfaces/AccountInfo";

import { BaseApi } from "../../api/base";

interface Column {
  id: 'club_unit' | 'drill' | 'date_time' | 'ticket' | 'coaches' | 'attendance';
  minWidth?: number;
  align?: 'right';
}

const columns: readonly Column[] = [
  { id: 'club_unit', minWidth: 250 },
  { id: 'drill', minWidth: 265 },
  { id: 'date_time', minWidth: 150 },
  { id: 'ticket', minWidth: 150 },
  { id: 'coaches', minWidth: 150 },
  { id: 'attendance', minWidth: 220 },
];

export function ClientAttendance(props: ClientAttendanceProps) {
  const { t } = useTranslation(['tables', 'common', 'filters', 'tickets']);
  const [attendance, setAttendance] = React.useState<ClientDrillAttendance[]>([]);
  const [clientInfo, setClientInfo] = React.useState<UserInfoCommon | null>(null);
  const [showAttendance, setShowAttendance] = React.useState<number>(0);
  const [anchorScheduleList, setAnchorAnchorScheduleList] = React.useState<HTMLButtonElement | null>(null);
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1)
  const [dateStart, setDateStart] = React.useState<string>(today.toISOString().split('T')[0]);
  const [dateStop, setDateStop] = React.useState<string>(tomorrow.toISOString().split('T')[0]);

  const handleGetClientAttendance = async () => {
    console.log(`Request for client attendance for ${props.client_id} for period ${dateStart}-${dateStop}`);
    const api = new BaseApi(1, `user/attendance/${props.client_id}/`);
    let res = await api.get(
      {date_start: dateStart, date_stop: dateStop},
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      setClientInfo(res.body.client);
      setAttendance(res.body.traffic);
    }
  };

  useEffect(() => {
    handleGetClientAttendance();
  }, [props.client_id]);

  useEffect(() => {
    handleGetClientAttendance();
  }, [dateStart]);

  useEffect(() => {
    handleGetClientAttendance();
  }, [dateStop]);

  return (
    <Box sx={{padding: '20px'}}>
      <Box>
        <Box
          sx={{
            width: 'fit-content',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '10px',
          }}
        >
          <Typography>
            {(clientInfo) ? `${clientInfo.first_name} ${clientInfo.last_name}` : ''}
          </Typography>
          <Typography>
            {(clientInfo) ? `${clientInfo.phone}` : ''}
          </Typography>
        </Box>
        <Box
          sx={{
            width: 'fit-content',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '10px',
            display: (props.device === 'desktop') ? 'flex' : 'block',
            alignItems: 'center',
          }}
        >
          <TextField
            required
            label={t('date_start', { ns: 'filters' })}
            type='date'
            defaultValue={dateStart}
            onChange={(e) => {setDateStart(e.target.value)}}
            sx={{marginRight: '5px'}}
          />
          <TextField
            required
            label={t('date_stop', { ns: 'filters' })}
            type='date'
            defaultValue={dateStop}
            onChange={(e) => {setDateStop(e.target.value)}}
            sx={{marginRight: '5px'}}
          />
          <Box>
          <Button
            variant="outlined"
            onClick={(e) => {handleGetClientAttendance()}}
          >
            {t('apply', { ns: 'filters' })}
          </Button>
          </Box>
        </Box>
      </Box>
      <TableContainer sx={{ maxHeight: '80vh' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={`cell-${column.id}`}
                  align={column.align}
                  style={{ minWidth: column.minWidth, backgroundColor: 'var(--orange)' }}
                >
                  {t(`heads.${column.id}`)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {attendance.map((att, index) => {
              return (
                <TableRow tabIndex={-1} key={`attendance-row-key-${index}`}>
                  <TableCell key={`attendance-cell-club_unit-${index}`}>
                    {att.drill.club.name}
                  </TableCell>
                  <TableCell key={`attendance-cell-drill-${index}`}>
                    <Typography>{att.drill.discipline.name}</Typography>
                    <Typography>{att.drill.drill_type.name}</Typography>
                  </TableCell>
                  <TableCell key={`attendance-cell-date_time-${index}`}>
                    {att.drill_date} {att.drill.drill_time_start}
                  </TableCell>
                  {att.ticket
                    ? <TableCell key={`attendance-cell-ticket-${index}`}>
                        <Typography>{att.ticket.ticket.name}</Typography>
                        <Typography>{att.ticket.start_date} - {att.ticket.expire_date}</Typography>
                        <Typography>{t('lost_drills', { ns: 'tickets' })}: {att.ticket.lost_drills}</Typography>
                        <Typography>{t('completed_drills', { ns: 'tickets' })}: {att.ticket.completed_drills.count}</Typography>
                      </TableCell>
                    : <TableCell key={`attendance-cell-ticket-${index}`}>
                        <Typography>{t('no_ticket', { ns: 'tickets' })}</Typography>
                      </TableCell>
                  }
                  <TableCell key={`attendance-cell-coaches-${index}`}>
                    <Box>
                      {att.coaches.map((coach) => {
                        return (
                          <Typography key={`attendance-coach-${coach.id}`}>
                            {coach.first_name} {coach.last_name}
                          </Typography>
                        )
                      })}
                    </Box>
                  </TableCell>
                  <TableCell key={`attendance-cell-schedule-${index}`}>
                    <Button
                      id={`id-show-schedule-${att.id}`}
                      aria-controls={(showAttendance === att.id) ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={(showAttendance === att.id) ? 'true' : undefined}
                      onClick={(e) => {
                        setAnchorAnchorScheduleList(e.currentTarget);
                        setShowAttendance(att.id)
                      }}
                    >
                      {(showAttendance === att.id) ? t('hide', { ns: 'common' }) : t('show', { ns: 'common' })}
                    </Button>
                    <Popover
                      id={`id-show-schedule-${att.id}`}
                      open={(showAttendance === att.id)}
                      anchorEl={anchorScheduleList}
                      onClose={(e) => {
                        setAnchorAnchorScheduleList(null);
                        setShowAttendance(0);
                      }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <Box>
                        {att.ticket?.completed_drills.schedule.map((sc, index) => {
                          return (
                            <Typography key={`attendance-schedule-${index}`}>
                              {sc.drill_date} {sc.drill_time}
                            </Typography>
                          )
                        })}
                      </Box>
                    </Popover>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
