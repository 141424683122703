import React, { useEffect } from "react";

import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { TransitionProps } from '@mui/material/transitions';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import Popper from '@mui/material/Popper';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';

import { useTranslation } from 'react-i18next';

import { NotificationType, NotificationObject } from '../../interfaces/Notification';
import { NotificationsListProps, AutocompleteProps } from "../../interfaces/Props";
import { QueryObj, BodyObj } from '../../interfaces/Types';
import { ApproveAction } from "../common/ActionApprove";
import { Clients } from "../users/Clients";

import { theme as root_theme } from "../../consts";
import { builtISODate } from "../../helpers";

import { BaseApi } from "../../api/base";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    backgroundColor: root_theme.palette.background.default,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: root_theme.palette.info.main,
  },
}));

const BootstrapDialogWidth = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    width: '90%',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    backgroundColor: root_theme.palette.background.default,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: root_theme.palette.info.main,
  },
  '& .MuiDialog-paper': {
    width: '70%',
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Column {
  id: 'client_bid' | 'contacts' | 'type'  | 'bid_status' | 'noty_date' | 'author';
  minWidth?: number;
  align?: 'right';
}

const columns: readonly Column[] = [
  { id: 'client_bid', minWidth: 250 },
  { id: 'contacts', minWidth: 150 },
  { id: 'type', minWidth: 150 },
  { id: 'bid_status', minWidth: 150 },
  { id: 'noty_date', minWidth: 220 },
  { id: 'author', minWidth: 150 },
];

export function NotificationsList(props: NotificationsListProps) {
  let today = new Date();
  let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  
  const { t } = useTranslation(['tables', 'forms', 'common', 'filters', 'notification']);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [filtersSpinner, setFiltersSpinner] = React.useState<boolean>(false);
  const [allNotifys, setAllNotifys] = React.useState<number>(0);
  const [choosenNotifyType, setChoosenNotifyType] = React.useState<NotificationType | null>(null);
  const [notisDateStart, setNotisDateStart] = React.useState<string>(builtISODate(firstDayOfMonth));
  const [notisDateStop, setNotisDateStop] = React.useState<string>(builtISODate(lastDayOfMonth));
  const [showFilters, setShowFilters] = React.useState<boolean>(props.device === 'desktop');
  const [notifyTypes, setNotifyTypes] = React.useState<NotificationType[]>([]);
  const [notificationsQuery, setNotificationsQuery] = React.useState<QueryObj>(
    {
      sort: 'user_tickets__expired-asc',
      limit: rowsPerPage,
      offset: rowsPerPage * page,
      all: allNotifys,
      full: 1,
      date_start: notisDateStart,
      date_stop: notisDateStop,
    }
  );
  const [notifications, setNotifications] = React.useState<NotificationObject[]>([]);
  const [NotificationsCount, setNotificationsCount] = React.useState<number>(0);
  const [firstRender, setFirstRender] = React.useState<boolean>(true);
  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null);
  const [openMenu, setOpenMenu] = React.useState<number>();
  const [editNoty, setEditNoty] = React.useState<number>(0);
  const [removeNoty, setRemoveNoty] = React.useState<number>(0);
  const [closeNoty, setCloseNoty] = React.useState<number>(0);
  const [showClientInfo, setShowClientInfo] = React.useState<number>(0);
  const [updateNotyBody, setUpdateNotyBody] = React.useState<BodyObj>({});
  const [anchorNotyMessage, setAnchorNotyMessage] = React.useState<HTMLButtonElement | null>(null);
  const [showNotyMessage, setShowNotyMessage] = React.useState<number>(0);

  const getNotificationsTypes = async () => {
    const api = new BaseApi(1, 'management/notify_types/');
    setFiltersSpinner(true);
    let res = await api.get({}, props.appSetState, props.appState);
    if (res.status === 200) {
      setNotifyTypes(res.body.map((nt: any) => {return nt as NotificationType}));
    };
    setFiltersSpinner(false);
  };

  const getNotifications = async () => {
    const api = new BaseApi(1, 'management/notifications/');
    props.handleOpenSpinner();
    let res = await api.get(notificationsQuery, props.appSetState, props.appState);
    if (res.status === 200) {
      setNotifications(
        res.body.results.map(
          (noti: any) => {return {
            id: noti.id,
            noty_type: noti.noty_type,
            noty_type_str: noti.noty_type_str,
            notify_date: noti.notify_date,
            deleted: noti.deleted,
            closed: noti.closed,
            sketches: noti.sketches,
            message: noti.message,
            client: noti.client,
            author: noti.author,
            bid: noti.bid,
            traffic: noti.traffic?.id,
          }}
        )
      );
      setNotificationsCount(res.body.count);
    };
    props.handleCloseSpinner();
    setFirstRender(false);
  };

  const handleManageNoty = async (body: BodyObj) => {
    const api = new BaseApi(1, `management/notifications/`);
    props.handleOpenSpinner();
    let res = await api.put(
      body,
      "application/json",
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      if (body.closed || body.deleted) {
        setNotifications(notifications.filter(noty => noty.id !== body.id));
        setNotificationsCount(NotificationsCount - 1);
      } else {
        setNotifications(notifications.map((noty) => {
          if (noty.id === body.id) {
              return {
              id: res.body.id,
              noty_type: res.body.noty_type,
              noty_type_str: res.body.noty_type_str,
              notify_date: res.body.notify_date,
              deleted: res.body.deleted,
              closed: res.body.closed,
              sketches: res.body.sketches,
              message: res.body.message,
              client: res.body.client,
              author: res.body.author,
              bid: res.body.bid,
              traffic: res.body.traffic?.id,
            }
          } else {
            return noty
          }
        }));
      }
    };
    setUpdateNotyBody({});
    setRemoveNoty(0);
    setCloseNoty(0);
    setEditNoty(0);
    props.handleCloseSpinner();
  };
  const handleManageNotyClose = () => {
    setRemoveNoty(0);
    setCloseNoty(0);
    setEditNoty(0);
  };

  useEffect(() => {
    getNotifications();
  }, [notificationsQuery]);

  useEffect(() => {
    if (firstRender) { return }
    setNotificationsQuery(
      {...notificationsQuery, offset: rowsPerPage * page}
    );
  }, [page]);

  useEffect(() => {
    if (firstRender) { return }
    setNotificationsQuery(
      {...notificationsQuery, limit: rowsPerPage, offset: rowsPerPage * page}
    );
  }, [rowsPerPage]);

  useEffect(() => {
    if (firstRender) { return }
    setNotificationsQuery(
      {...notificationsQuery, date_start: notisDateStart}
    );
  }, [notisDateStart]);

  useEffect(() => {
    if (firstRender) { return }
    setNotificationsQuery(
      {...notificationsQuery, date_stop: notisDateStop}
    );
  }, [notisDateStop]);

  useEffect(() => {
    if (firstRender) { return }
    setNotificationsQuery(
      {...notificationsQuery, noty_type: (choosenNotifyType) ? choosenNotifyType.id : ''}
    );
  }, [choosenNotifyType]);

  useEffect(() => {
    if (firstRender) { return }
    setNotificationsQuery(
      {...notificationsQuery, all: allNotifys}
    );
  }, [allNotifys]);

  useEffect(() => {
    getNotificationsTypes();
  }, []);

  useEffect(() => {
    setShowFilters(props.device === 'desktop');
  }, [props.device]);

  const handleCloseMenu = () => {
    setAnchorMenu(null);
    setOpenMenu(0);
  };

  return (
    <Box className='table-wrapper'>
      <Box sx={{ marginBottom: '5px' }}>
        {props.device === 'mobile'
          ? <Box
              sx={{
                width: 'fit-content',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <Button
                variant="outlined"
                onClick={() => setShowFilters(!showFilters)}
              >
                {t('filters', { ns: 'filters' })}
              </Button>
            </Box>
          : ''
        }
        {filtersSpinner
          ? <Box
              sx={{
                width: 'fit-content',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          : <Box>
              {showFilters
                ? <Box
                    sx={{
                      display: (props.device === 'desktop') ? 'flex' : 'block',
                      justifyContent: 'space-between',
                      width: (props.device === 'desktop') ? '95%' : '70%',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    <Autocomplete
                      disablePortal
                      id="noti-types-filter-id"
                      options={
                        notifyTypes.map(
                          (nt) => {return {id: nt.id, label: nt.type}}
                        )
                      }
                      onChange={
                        (
                          e: React.SyntheticEvent,
                          value: AutocompleteProps | null,
                        ) => {
                          if (value && value.id) {
                            setChoosenNotifyType({id: Number(value.id), type: value.label});
                          } else {
                            setChoosenNotifyType(null);
                          }
                        }
                      }
                      sx={{ width: (props.device === 'mobile') ? '100%' : '13%' }}
                      renderInput={
                        (params: any) => <TextField
                          {...params}
                          label={t("notify_type", { ns: 'common' })}
                        />
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e: any) => {setAllNotifys(Number(e.target.checked))}}
                        />
                      }
                      label={t("all_notifications", { ns: 'filters' })}
                    />
                    <TextField
                      id="noti-date-from-id"
                      label={t('date_from', { ns: 'filters' })}
                      variant="standard"
                      type="date"
                      onChange={(e) => {setNotisDateStart(e.target.value)}}
                      defaultValue={notisDateStart}
                    />
                    <TextField
                      id="noti-date-to-id"
                      label={t('date_to', { ns: 'filters' })}
                      variant="standard"
                      type="date"
                      onChange={(e) => {setNotisDateStop(e.target.value)}}
                      defaultValue={notisDateStop}
                    />
                  </Box>
                : ''
              }
            </Box>
        }
      </Box>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: '74vh' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={`cell-${column.id}`}
                    align={column.align}
                    style={{ minWidth: column.minWidth, backgroundColor: 'var(--orange)' }}
                  >
                    {t(`heads.${column.id}`)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {notifications.map((noty, index) => {
                let contact_name: string = '';
                let phone: string | undefined | null = '';
                let email: string | undefined | null = '';
                if (noty.client) {
                  contact_name = `${noty.client.first_name} ${noty.client.last_name}`;
                  phone = noty.client.phone;
                  email = noty.client.email;
                } else if (noty.bid) {
                  contact_name = noty.bid.contact_name;
                  phone = noty.bid.contact_phone;
                  email = noty.bid.contact_email;
                }
                return (
                  <TableRow
                    tabIndex={-1}
                    key={`row-key-${index}`}
                  >
                    <TableCell
                      key={`key-full_name-${index}`}
                      onMouseEnter={
                        (event: React.MouseEvent<HTMLElement>) => {
                          if (!noty.message) { return }
                          setAnchorNotyMessage(event.currentTarget);
                          setShowNotyMessage(noty.id);
                        }
                      }
                      onMouseLeave={() => {
                        setAnchorNotyMessage(null);
                        setShowNotyMessage(0);
                      }}
                    >
                      <Box>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={openMenu === noty.id ? 'long-menu' : undefined}
                          aria-expanded={openMenu === noty.id ? 'true' : undefined}
                          aria-haspopup="true"
                          onClick={(e) => {
                            setOpenMenu(noty.id);
                            setAnchorMenu(e.currentTarget);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        {contact_name}
                      </Box>
                    </TableCell>
                    <TableCell
                      key={`key-contacts-${index}`}
                      onMouseEnter={
                        (event: React.MouseEvent<HTMLElement>) => {
                          if (!noty.message) { return }
                          setAnchorNotyMessage(event.currentTarget);
                          setShowNotyMessage(noty.id);
                        }
                      }
                      onMouseLeave={() => {
                        setAnchorNotyMessage(null);
                        setShowNotyMessage(0);
                      }}
                    >
                      <Typography>{phone}</Typography>
                      <Typography>{email}</Typography>
                    </TableCell>
                    <TableCell
                      key={`key-noty-type-${index}`}
                      onMouseEnter={
                        (event: React.MouseEvent<HTMLElement>) => {
                          if (!noty.message) { return }
                          setAnchorNotyMessage(event.currentTarget);
                          setShowNotyMessage(noty.id);
                        }
                      }
                      onMouseLeave={() => {
                        setAnchorNotyMessage(null);
                        setShowNotyMessage(0);
                      }}
                    >
                      <Typography>{noty.noty_type_str}</Typography>
                    </TableCell>
                    <TableCell
                      key={`key-noty-status-${index}`}
                      onMouseEnter={
                        (event: React.MouseEvent<HTMLElement>) => {
                          if (!noty.message) { return }
                          setAnchorNotyMessage(event.currentTarget);
                          setShowNotyMessage(noty.id);
                        }
                      }
                      onMouseLeave={() => {
                        setAnchorNotyMessage(null);
                        setShowNotyMessage(0);
                      }}
                    >
                      <Typography>
                        {(noty.closed) ? t('body.status.closed') : t('body.status.opened')}
                      </Typography>
                    </TableCell>
                    <TableCell
                      key={`key-noty-date-${index}`}
                      onMouseEnter={
                        (event: React.MouseEvent<HTMLElement>) => {
                          if (!noty.message) { return }
                          setAnchorNotyMessage(event.currentTarget);
                          setShowNotyMessage(noty.id);
                        }
                      }
                      onMouseLeave={() => {
                        setAnchorNotyMessage(null);
                        setShowNotyMessage(0);
                      }}
                    >
                      <Typography>{noty.notify_date}</Typography>
                    </TableCell>
                    <TableCell
                      key={`key-noty-author-${index}`}
                      onMouseEnter={
                        (event: React.MouseEvent<HTMLElement>) => {
                          if (!noty.message) { return }
                          setAnchorNotyMessage(event.currentTarget);
                          setShowNotyMessage(noty.id);
                        }
                      }
                      onMouseLeave={() => {
                        setAnchorNotyMessage(null);
                        setShowNotyMessage(0);
                      }}
                    >
                      <Typography>
                        {`${noty.author.first_name} ${noty.author.last_name}`}
                      </Typography>
                    </TableCell>
                    <Menu
                      id={`client-menu-id-${index}`}
                      MenuListProps={{
                        'aria-labelledby': 'long-button',
                      }}
                      anchorEl={anchorMenu}
                      open={openMenu === noty.id}
                      onClose={handleCloseMenu}
                    >
                      <MenuItem
                        key={`menu_item-edit-${index}`}
                        onClick={(e) => {
                          setEditNoty(noty.id);
                          handleCloseMenu();
                        }}
                        className="control-menu-item-wrapper"
                      >
                        {t("actions.edit")}
                      </MenuItem>
                      <MenuItem
                        key={`menu_item-remove-${index}`}
                        onClick={
                          (e) => {
                            setRemoveNoty(noty.id);
                            handleCloseMenu()
                          }
                        }
                        className="control-menu-item-wrapper"
                      >
                        {t("actions.remove")}
                      </MenuItem>
                      <MenuItem
                        key={`menu_item-notify-${index}`}
                        onClick={
                          (e) => {
                            setCloseNoty(noty.id);
                            handleCloseMenu();
                          }
                        }
                        className="control-menu-item-wrapper"
                      >
                        {t("actions.close")}
                      </MenuItem>
                      {noty.client
                        ? <MenuItem
                            key={`menu_item-client-info-${index}`}
                            onClick={
                              (e) => {
                                setShowClientInfo(noty.id);
                                handleCloseMenu();
                              }
                            }
                            className="control-menu-item-wrapper"
                          >
                            {t("actions.show_notify_client")}
                          </MenuItem>
                        : ''
                      }
                    </Menu>
                    <BootstrapDialogWidth
                      key={`edit-noty-${noty.id}`}
                      onClose={() => {setEditNoty(0)}}
                      open={editNoty === noty.id}
                    >
                      <DialogTitle sx={{ paddingLeft: '5px' }}>
                        {t('actions.notify')}
                      </DialogTitle>
                      <DialogContent dividers className="box-content-center">
                        <TextField
                          id="noti-date-edit-id"
                          label={t('date', { ns: 'notification' })}
                          variant="standard"
                          type="date"
                          onChange={
                            (e) => {
                              setUpdateNotyBody(
                                {...updateNotyBody, notify_date: e.target.value, id: noty.id}
                              );
                            }
                          }
                          defaultValue={noty.notify_date}
                        />
                        <TextField
                          id="standard-multiline-static"
                          label={t("sketches")}
                          multiline
                          rows={5}
                          defaultValue={noty.sketches}
                          variant="standard"
                          sx={{width: "100%"}}
                          onChange={(e) => {
                            setUpdateNotyBody(
                              {...updateNotyBody, message: e.target.value, id: noty.id}
                            );
                          }}
                        />
                        <Button
                          variant="contained"
                          color="info"
                          sx={{ marginTop: '5px' }}
                          onClick={(e) => {
                            handleManageNoty(updateNotyBody);
                          }}
                        >
                          {t("save", { ns: 'common' })}
                        </Button>
                      </DialogContent>
                    </BootstrapDialogWidth>
                    <ApproveAction
                      handleApprove={
                        () => {
                          handleManageNoty({id: noty.id, closed: false, deleted: true})
                        }
                      }
                      handleDecline={handleManageNotyClose}
                      action={'remove_noty'}
                      show={removeNoty}
                      obj_pk={noty.id}
                    />
                    <ApproveAction
                      handleApprove={
                        () => {
                          handleManageNoty({id: noty.id, closed: true, deleted: false})
                        }
                      }
                      handleDecline={handleManageNotyClose}
                      action={'close_noty'}
                      show={closeNoty}
                      obj_pk={noty.id}
                    />
                    {showClientInfo === noty.id
                      ? <BootstrapDialog
                          fullScreen
                          open={(showClientInfo === noty.id)}
                          onClose={(e) => {setShowClientInfo(0)}}
                          TransitionComponent={Transition}
                        >
                          <AppBar sx={{ position: 'relative' }}>
                            <Toolbar sx={{ backgroundColor: 'var(--black)' }}>
                              <IconButton
                                edge="start"
                                color="inherit"
                                onClick={(e) => {setShowClientInfo(0)}}
                                aria-label="close"
                              >
                                <CloseIcon />
                              </IconButton>
                              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                {`${noty.client?.first_name} ${noty.client?.last_name}`}
                              </Typography>
                            </Toolbar>
                          </AppBar>
                          <Clients
                            handleCloseSpinner={props.handleCloseSpinner}
                            handleOpenSpinner={props.handleOpenSpinner}
                            openPopUp={props.openPopUp}
                            departments={props.departments}
                            device={props.device}
                            appState={props.appState}
                            appSetState={props.appSetState}
                            clientsIds={(noty.client) ? String(noty.client.id) : ''}
                          />
                        </BootstrapDialog>
                      : ''
                    }
                    <Popper
                      id='time-shifting-icon-id'
                      open={showNotyMessage === noty.id}
                      anchorEl={anchorNotyMessage}
                    >
                      <Typography
                        sx={{
                          p: 2,
                          backgroundColor: 'var(--orange)',
                          color: 'var(--black)',
                        }}
                      >
                        {noty.message}
                      </Typography>
                    </Popper>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={NotificationsCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={
            (event: unknown, newPage: number) => {
              setPage(newPage);
            }
          }
          onRowsPerPageChange={
            (event: React.ChangeEvent<HTMLInputElement>) => {
              setRowsPerPage(+event.target.value);
              setPage(0);
            }
          }
        />
      </Paper>
    </Box>
  )
}
