import React, { useEffect } from "react";

import { useRecoilState, useSetRecoilState } from "recoil";

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputBase from '@mui/material/InputBase';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import ListItemIcon from '@mui/material/ListItemIcon';
import ClearIcon from '@mui/icons-material/Clear';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';

import { BootstrapDialog } from "../common/Dialogs";

import { BaseWithSlugObjsProps } from "../../interfaces/Props";
import { Department } from "../../interfaces/Club";
import { Employee, ClubUnit } from '../../interfaces/Club';
import { BodyObj } from "../../interfaces/Types";
import { AccountInfo } from '../../interfaces/AccountInfo';

import { ApproveAction } from "../common/ActionApprove";

import { useTranslation } from 'react-i18next';

import { BaseApi } from "../../api/base";

import { accountInfoAtom } from '../../atoms/AccountInfo';
import { popUpAtom } from '../../atoms/PopUp';
import { departmentsAtom } from "../../atoms/Departments";
import { spinnerAtom } from "../../atoms/Spinner";

interface IDepartmentProps {
  department: Department;
  clubUnit: ClubUnit;
}

function DepartmentInfo(props: IDepartmentProps) {
  const { t } = useTranslation(['club', 'common', 'tables']);
  
  const [openMenu, setOpenMenu] = React.useState<number>();
  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null);
  const [removeEmployee, setRemoveEmployee] = React.useState<number>(0);
  const [state, setState] = useRecoilState(accountInfoAtom);
  const [popUp, setPopUp] = useRecoilState(popUpAtom);
  const [departments, setDepartments] = useRecoilState(departmentsAtom);
  const [addEmployee, setAddEmployee] = React.useState<boolean>(false);
  const [searchString, setSearchString] = React.useState<string>('');
  const [users, setUsers] = React.useState<AccountInfo[]>([]);
  const [anchorUsersList, setAnchorUsersList] = React.useState<null | HTMLElement>(null);
  const showUsers: boolean = Boolean(anchorUsersList);
  const [selectedEmployee, setSelectedEmployee] = React.useState<AccountInfo | null>(null);
  const setOpenSpinner = useSetRecoilState(spinnerAtom);

  const apiCallback = (
    state: any,
    pop_up_title: string,
    pop_up_message: BodyObj,
    pop_up_show: boolean = false,
  ) => {
    setState(state);
    if ( pop_up_show ) {
      setPopUp(
        {
          title: pop_up_title,
          message: pop_up_message,
          translatable: false,
          show: true,
        }
      );
      setTimeout(
        () => {
          setPopUp(
            {
              title: '',
              message: '',
              translatable: true,
              show: false,
            }
          )
        },
        3000
      );
    }
  };

  useEffect(() => {
    if (!searchString) { return }
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        searchUsers();
        let elem = document.getElementById('employee-videoconf-search');
        setAnchorUsersList(anchorUsersList ? null : elem);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [searchString]);

  const searchUsers = async () => {
    setOpenSpinner({open: true});
    const api = new BaseApi(2, 'management/get_clients/');
    let res = await api.get(
      {limit: 5, offset: 0, search: encodeURIComponent(searchString)},
      apiCallback,
      state,
    );
    if (res.status === 200) {
      setUsers(res.body.results);
    };
    setOpenSpinner({open: false});
  };

  const removeEmployeeFromDepartment = async (employee: Employee) => {
    setOpenSpinner({open: true});
    const api = new BaseApi(1, `management/club/employees/${employee.id}/`);
    let res = await api.put(
      {
        club_units: employee.club_units.filter(cu => cu !== props.clubUnit.id)
      },
      'application/json',
      apiCallback,
      state,
    );
    if (res.status === 200) {
      let _departments: Department[] = departments.items.map((d) => { return { ...d } });
      for (let dep of _departments) {
        if (dep.id === props.department.id) {
          dep.employees = dep.employees.map(
            (emp) => {
              if (emp.id === Number(res.body.id)) {
                return res.body
              } else {
                return emp
              }
            }
          )
          break
        }
      }
      setDepartments({ items: _departments });
    }
    setOpenSpinner({open: false});
  };

  const addEmployeeToDepartment = async () => {
    if (!selectedEmployee) { return }
    setOpenSpinner({open: true});
    let exist_emp: Employee | null = null;
    for (let emp of props.department.employees) {
      if (emp.employee.id === selectedEmployee.id) {
        exist_emp = emp;
        break
      }
    }
    let res;
    if (exist_emp) {
      const api = new BaseApi(1, `management/club/employees/${exist_emp.id}/`);
      res = await api.put(
        {
          club_units: [ ...exist_emp.club_units, props.clubUnit.id ],
        },
        'application/json',
        apiCallback,
        state,
      );
    } else {
      const api = new BaseApi(1, `management/club/employees/`);
      res = await api.post(
        {
          department: props.department.id,
          employee: selectedEmployee.id,
          club_units: [props.clubUnit.id],
        },
        'application/json',
        apiCallback,
        state,
      );
    }
    if (res.status === 200) {
      let _departments: Department[] = departments.items.map((d) => { return { ...d } });
      for (let dep of _departments) {
        if (dep.id === props.department.id) {
          if (exist_emp) {
            dep.employees = dep.employees.map(
              (emp) => {
                if (emp.id === Number(res.body.id)) {
                  return res.body
                } else {
                  return emp
                }
              }
            )
          } else {
            dep.employees = [...dep.employees, res.body];
          }
        }
      }
      setDepartments({ items: _departments });
    }
    setAddEmployee(false)
    setSelectedEmployee(null);
    setUsers([]);
    setAnchorUsersList(null);
    setSearchString('');
    setOpenSpinner({open: false});
  };
  
  return (
    <Box>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: '74vh' }}>
          <Table stickyHeader>
            <TableBody>
              {props.department.employees.filter(emp => emp.club_units.includes(props.clubUnit.id)).map((employee, index) => {
                return (
                  <TableRow
                    tabIndex={-1}
                    key={`row-key-${index}`}
                  >
                    <TableCell key={`key-name-${index}`}>
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={openMenu === employee.employee.id ? 'long-menu' : undefined}
                        aria-expanded={openMenu === employee.employee.id ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          setOpenMenu(employee.employee.id);
                          setAnchorMenu(e.currentTarget);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      {`${employee.employee.first_name} ${employee.employee.last_name}`}
                    </TableCell>
                    <TableCell key={`key-contacts-${index}`}>
                      <Typography>{employee.employee.phone}</Typography>
                      <Typography>{employee.employee.email}</Typography>
                    </TableCell>
                    <Menu
                      id={`promo-menu-id-${index}`}
                      MenuListProps={{
                        'aria-labelledby': 'long-button',
                      }}
                      anchorEl={anchorMenu}
                      open={openMenu === employee.employee.id}
                      onClose={() => {
                        setOpenMenu(0);
                        setAnchorMenu(null);
                      }}
                    >
                      <MenuItem
                        key={`menu_item-remove-${index}`}
                        onClick={
                          (e) => {
                            setRemoveEmployee(employee.employee.id);
                            setOpenMenu(0);
                            setAnchorMenu(null);
                          }
                        }
                        className="control-menu-item-wrapper"
                      >
                        {t("actions.remove", { ns: 'tables' })}
                      </MenuItem>
                    </Menu>
                    <ApproveAction
                      handleApprove={
                        () => {
                          removeEmployeeFromDepartment(employee);
                          setRemoveEmployee(0);
                        }
                      }
                      handleDecline={() => {setRemoveEmployee(0)}}
                      action={'remove_manager'}
                      show={removeEmployee}
                      obj_pk={employee.employee.id}
                    />
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Box
        sx={{
          width: 'fit-content',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '15px',
          marginBottom: '15px',
        }}
      >
        <Button
          variant="contained"
          onClick={() => {setAddEmployee(true)}}
        >
          {t('add', { ns: 'common' })}
        </Button>
      </Box>
      <BootstrapDialog
        onClose={(e) => {setAddEmployee(false)}}
        open={addEmployee}
      >
        <DialogTitle sx={{ paddingLeft: '5px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontWeight: '600', fontSize: '1.25em' }}>
              {t('new_manager')}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers className="box-content-center">
          <Paper
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              width: '95%',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginBottom: '5px',
              marginTop: '5px',
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              id="employee-videoconf-search"
              placeholder={t('search_placeholder', { ns: 'common' })}
              inputProps={{ 'aria-label': 'search google maps' }}
              onChange={(e) => {setSearchString(e.target.value)}}
              value={searchString}
            />
            <IconButton
              type="button"
              sx={{ p: '10px' }}
              aria-label="search"
              onClick={
                (e) => {
                  searchUsers();
                  let elem = document.getElementById('users-videoconf-search');
                  setAnchorUsersList(anchorUsersList ? null : elem);
                }
              }
            >
              <SearchIcon />
            </IconButton>
          </Paper>
          <Popover
            open={showUsers}
            anchorEl={anchorUsersList}
            onClose={(e) => {setAnchorUsersList(null)}}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
              <List dense={false}>
                {users.map((user) => {
                  return (
                    <ListItem
                      onClick={(e) => {
                        setSelectedEmployee(user);
                        setUsers([]);
                        setAnchorUsersList(null);
                        setSearchString('');
                      }}
                      sx={{ cursor: 'pointer' }}
                      key={`conf-user-list-item-${user.id}`}
                    >
                      <ListItemIcon>
                        <ContactPhoneIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${user.first_name} ${user.last_name}`}
                      />
                    </ListItem>
                  )
                })}
              </List>
            </Box>
          </Popover>
          {selectedEmployee
            ? <Box>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-haspopup="true"
                  onClick={(e) => {setSelectedEmployee(null)}}
                >
                  <ClearIcon />
                </IconButton>
                {selectedEmployee.first_name} {selectedEmployee.last_name}
              </Box>
            : ''
          }
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="info"
            onClick={
              (e) => {addEmployeeToDepartment()}
            }
          >
            <CheckIcon />
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Box>
  )
}

export { DepartmentInfo }
