import React, { useEffect } from "react";

import { useRecoilValue } from 'recoil';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import { DataGrid } from '@mui/x-data-grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import InputLabel from '@mui/material/InputLabel';

import SearchIcon from '@mui/icons-material/Search';

import { useTranslation } from 'react-i18next';

import { UserInfoCommon } from "../../interfaces/AccountInfo";
import { 
  DrillManageProps,
  AutocompleteProps,
  DrillAttendanceProps,
  DrillCancelProps,
} from '../../interfaces/Props';
import {  
  Discipline,
  DrillType,
  PaymentType,
  Promo,
  Ticket,
} from "../../interfaces/Common";
import { ClubUnit } from "../../interfaces/Club";
import { ScheduleDrillManage } from "../../interfaces/Calendar";
import { CustomObj, QueryObj } from "../../interfaces/Types";
import { ClientInfo } from "../../interfaces/User";

import { BaseApi } from "../../api/base";

import { accountInfoAtom } from '../../atoms/AccountInfo';

export function DrillManage(props: DrillManageProps) {
  const { t } = useTranslation(['forms', 'week', 'common']);
  const accountInfo = useRecoilValue(accountInfoAtom);
  const [coaches, setCoaches] = React.useState<UserInfoCommon[]>([]);
  const [disciplines, setDisciplines] = React.useState<Discipline[]>([]);
  const [drillTypes, setDrillTypes] = React.useState<DrillType[]>([]);
  const [paymentTypes, setPaymentTypes] = React.useState<PaymentType[]>([]);
  const [promos, setPromos] = React.useState<Promo[]>([]);
  const [tickets, setTickets] = React.useState<Ticket[]>([]);
  const [spinner, setSpinner] = React.useState<boolean>(false);
  const [clubUnitId, setClubUnitId] = React.useState<number | undefined>(
    (props.currentDrill) ? props.currentDrill.drill.club.id : props.appState.account_info.clubs[0]?.units[0]?.id
  );
  const [scheduleDrill, setScheduleDrill] = React.useState<ScheduleDrillManage>(
    {
      id: (props.currentDrill) ? props.currentDrill.id : 0,
      club_unit: (props.currentDrill) ? props.currentDrill.drill.club.id : 0,
      coaches: (props.currentDrill) ? props.currentDrill.coaches.map((c) => {return c.id}) : [],
      discipline: (props.currentDrill) ? props.currentDrill.drill.discipline.id : 0,
      drill_date: (props.currentDrill) ? props.currentDrill.drill_date : '',
      drill_time_start: (props.currentDrill) ? props.currentDrill.drill.drill_time_start : '',
      drill_time_stop: (props.currentDrill) ? props.currentDrill.drill.drill_time_stop : '',
      drill_type: (props.currentDrill) ? props.currentDrill.drill_type.id : 0,
      single: (props.currentDrill) ? !props.currentDrill.drill.repeatable : false,
      weekday: (props.currentDrill) ? [props.currentDrill.drill.weekday - 1] : [],
    }
  );
  const [formValid, setFormValid] = React.useState<boolean>((props.currentDrill) ? true : false);
  const [weekdays, setWeekdays] = React.useState<string[]>(
    [
      t('monday_shortcut', { ns: 'week' }),
      t('tuesday_shortcut', { ns: 'week' }),
      t('wednesday_shortcut', { ns: 'week' }),
      t('thursday_shortcut', { ns: 'week' }),
      t('friday_shortcut', { ns: 'week' }),
      t('saturday_shortcut', { ns: 'week' }),
      t('sunday_shortcut', { ns: 'week' }),
    ]
  );

  const getSlugObjects = async () => {
    setSpinner(true);
    const api = new BaseApi(1, 'management/drill_creation_data/');
    if (!clubUnitId) {
      setSpinner(false);
      return
    }
    let res = await api.get(
      {club_unit: clubUnitId},
      props.appSetState,
      props.appState,
    );
    setCoaches(res.body.coaches);
    setDisciplines(res.body.disciplines);
    setDrillTypes(res.body.drill_types);
    setPaymentTypes(res.body.payment_types);
    setPromos(res.body.promos);
    setTickets(res.body.tickets);
    setSpinner(false);
  };

  useEffect(() => {
    getSlugObjects();
    setScheduleDrill(
      {
        ...scheduleDrill,
        club_unit: (clubUnitId) ? clubUnitId : 0,
      }
    );
  }, [clubUnitId]);

  useEffect(() => {
    let obj: CustomObj = scheduleDrill;
    for (let k in scheduleDrill) {
      if (!['id', 'single', 'drill_date'].includes(k) && !obj[k]) {
        console.log(k, obj[k]);
        setFormValid(false);
        return
      }
    }
    if (scheduleDrill.single && !scheduleDrill.drill_date) {
      setFormValid(false);
      return
    }
    setFormValid(true);
  }, [scheduleDrill]);

  console.log(props.appState.account_info)
  
  return (
    <Box>
      <Autocomplete
        id="drill-manage-club-unit-id"
        options={
          props.appState.account_info.clubs[0]?.units.map(
             (unit: ClubUnit) => {return {id: unit.id, label: unit.name}}
          )
        }
        sx={{ width: "100%", marginBottom: "15px" }}
        defaultValue={
          props.appState.account_info.clubs[0]?.units.filter((u: ClubUnit) => u.id === clubUnitId).map((u: ClubUnit) => {return {id: u.id, label: u.name}})[0]
        }
        renderInput={
          (params: any) => <TextField
            {...params}
            label={t("calendar.club_unit")}
          />
        }
        onChange={(
          e: React.SyntheticEvent,
          value: AutocompleteProps | null,
          reason: string,
        ) => {
          if (value && value.id) {
            setClubUnitId(Number(value.id));
          }
        }}
      />
      {clubUnitId
        ? <Box>
            <Autocomplete
              id="drill-manage-drill-type-id"
              options={
                drillTypes.map(
                   (dt: DrillType) => {return {id: dt.id, label: dt.name}}
                )
              }
              sx={{ width: "100%", marginBottom: "15px" }}
              defaultValue={
                (props.currentDrill) ? {id: props.currentDrill.drill_type.id, label: props.currentDrill.drill_type.name} : null
              }
              renderInput={
                (params: any) => <TextField
                  {...params}
                  label={t("calendar.drill_type")}
                />
              }
              onChange={(
                e: React.SyntheticEvent,
                value: AutocompleteProps | null,
                reason: string,
              ) => {
                if (value && value.id) {
                  setScheduleDrill({...scheduleDrill, drill_type: Number(value.id)});
                }
              }}
            />
            <Autocomplete
              id="drill-manage-discipline-id"
              options={
                disciplines.map(
                   (discipline: Discipline) => {return {id: discipline.id, label: discipline.name}}
                )
              }
              sx={{ width: "100%", marginBottom: "15px" }}
              defaultValue={
                (props.currentDrill) ? {id: props.currentDrill.drill.discipline.id, label: props.currentDrill.drill.discipline.name} : null
              }
              renderInput={
                (params: any) => <TextField
                  {...params}
                  label={t("calendar.discipline")}
                />
              }
              onChange={(
                e: React.SyntheticEvent,
                value: AutocompleteProps | null,
                reason: string,
              ) => {
                if (value && value.id) {
                  setScheduleDrill({...scheduleDrill, discipline: Number(value.id)});
                } else {
                  setScheduleDrill({...scheduleDrill, discipline: 0});
                }
              }}
            />
            {weekdays && props.currentDrill && props.currentDrill !== null
              ? <Autocomplete
                  id="current-drill-manage-weekdays-id"
                  options={
                    weekdays.map(
                        (day: string, index: number) => {return {id: index, label: day}}
                    )
                  }
                  sx={{ width: "100%", marginBottom: "15px" }}
                  defaultValue={
                    (props.currentDrill) ? {id: props.currentDrill.drill.weekday - 1, label: weekdays[props.currentDrill.drill.weekday - 1]} : null
                  }
                  renderInput={
                    (params: any) => <TextField
                      {...params}
                      label={t("calendar.weekday")}
                    />
                }
                  onChange={(
                    e: React.SyntheticEvent,
                    value: AutocompleteProps | null,
                    reason: string,
                  ) => {
                    if (value && value.id) {
                      setScheduleDrill({...scheduleDrill, weekday: [Number(value.id)]});
                    } else {
                      setScheduleDrill({...scheduleDrill, weekday: []});
                    }
                  }}
                />
              : ''
            }
            {weekdays && (!props.currentDrill || props.currentDrill === null)
              ? <Autocomplete
                  id="drill-manage-weekdays-id"
                  multiple
                  options={
                    weekdays.map(
                        (day: string, index: number) => {return {id: index, label: day}}
                    )
                  }
                  sx={{ width: "100%", marginBottom: "15px" }}
                  renderInput={
                    (params: any) => <TextField
                      {...params}
                      label={t("calendar.weekdays")}
                    />
                }
                  onChange={(
                    e: React.SyntheticEvent,
                    value: AutocompleteProps[] | null,
                    reason: string,
                  ) => {
                    if (value && value.length) {
                      setScheduleDrill({...scheduleDrill, weekday: value.map((d) => {return Number(d.id)})});
                    } else {
                      setScheduleDrill({...scheduleDrill, weekday: []});
                    }
                  }}
                />
              : ''
            }
            <Autocomplete
              id="drill-manage-coaches-id"
              multiple
              options={
                coaches.map(
                   (coach: UserInfoCommon) => {return {id: coach.id, label: `${coach.first_name} ${coach.last_name}`}}
                )
              }
              sx={{ width: "100%", marginBottom: "15px" }}
              defaultValue={
                (props.currentDrill)
                  ? props.currentDrill.coaches.map((coach: UserInfoCommon) => {
                      return {id: coach.id, label: `${coach.first_name} ${coach.last_name}`}
                    })
                  : []
              }
              renderInput={
                (params: any) => <TextField
                  {...params}
                  label={t("calendar.coaches")}
                />
              }
              onChange={(
                e: React.SyntheticEvent,
                value: AutocompleteProps[] | null,
                reason: string,
              ) => {
                if (value && value.length) {
                  setScheduleDrill({...scheduleDrill, coaches: value.map((v) => {return Number(v.id)})});
                } else {
                  setScheduleDrill({...scheduleDrill, coaches: []});
                }
              }}
            />
          </Box>
        : ''
      }
      <Typography variant="h6">
        {t("calendar.time_start_stop")}
      </Typography>
      <TextField
        id="drill-manage-time-start-id"
        label={t("calendar.time_start")}
        variant="outlined"
        type="time"
        value={scheduleDrill.drill_time_start}
        sx={{ width: '100%' }}
        onChange={
          (e) => {
            setScheduleDrill({...scheduleDrill, drill_time_start: e.target.value})
          }
        }
      />
      <TextField
        id="drill-manage-time-stop-id"
        label={t("calendar.time_stop")}
        variant="outlined"
        type="time"
        value={scheduleDrill.drill_time_stop}
        sx={{ width: '100%', marginTop: '5px' }}
        onChange={
          (e) => {
            setScheduleDrill({...scheduleDrill, drill_time_stop: e.target.value})
          }
        }
      />
      <Box sx={{ marginTop: '5px', marginBottom: '5px' }} >
        <FormControlLabel
          control=<Switch
            defaultChecked={scheduleDrill.single}
            onChange={(e) => {setScheduleDrill({ ...scheduleDrill, single: e.target.checked})}}
          />
          label={t("calendar.single")}
        />
      </Box>
      {scheduleDrill.single
        ? <TextField
            id="drill-manage-drill-date-id"
            label={t("calendar.drill_date")}
            variant="outlined"
            type="date"
            value={scheduleDrill.drill_date}
            sx={{ width: '100%', marginTop: '5px' }}
            onChange={
              (e) => {
                setScheduleDrill({...scheduleDrill, drill_date: e.target.value})
              }
            }
          />
        : ''
      }
      <Button
        variant="contained"
        color="info"
        disabled={!formValid}
        sx={{ marginTop: '5px' }}
        onClick={(e) => {
          props.applyEdit(scheduleDrill);
        }}
      >
        {t("save", { ns: 'common' })}
      </Button>
    </Box>
  )
}

export function DrillAttendance(props: DrillAttendanceProps) {
  const { t } = useTranslation(['calendar', 'common']);
  const accountInfo = useRecoilValue(accountInfoAtom);
  const [firstRender, setFirstRender] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [clients, setClients] = React.useState<ClientInfo[]>([]);
  const [spinner, setSpinner] = React.useState<boolean>(false);
  const [clientsSearch, setClientsSearch] = React.useState<string>('');
  const [clientsQuery, setClientsQuery] = React.useState<QueryObj>(
    {
      sort: 'user_tickets__expired-asc',
      limit: rowsPerPage,
      offset: page * rowsPerPage,
    }
  );
  const [clientsCount, setClientsCount] = React.useState<number>(0);

  const getClients = async () => {
    const api = new BaseApi(2, 'management/get_clients/');
    setSpinner(true);
    let res = await api.get(
      {
        ...clientsQuery,
        drill: props.drillId,
      },
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      let clients_arr = [];
      let client_tickets_arr = [];
      for (let cl of res.body.results) {
        client_tickets_arr = [];
        for (let t of cl.tickets) {
          client_tickets_arr.push({id: t.id, ticket: t.ticket});
        };
        cl.tickets = client_tickets_arr;
        delete cl.logo
        delete cl.who_confirmed
        clients_arr.push(cl as ClientInfo)
      };
      setClients(clients_arr);
      setClientsCount(res.body.count);
    };
    setSpinner(false);
    setFirstRender(false);
  };

  const placeClient = async (
    client_id: number, ticket_id: number | string, attend: boolean
  ) => {
    const api = new BaseApi(1, 'management/traffic/');
    setSpinner(true);
    let attend_clients = [];
    let remove_clients = [];
    if (attend) {
      attend_clients.push({id: client_id, ticket_id: ticket_id});
    } else {
      remove_clients.push(client_id);
    }
    let res = await api.post(
      {
        drill: props.drillId,
        clients: attend_clients,
        remove_clients: remove_clients,
      },
      'application/json',
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      setClients(
        clients.map((cl) => {
          if (cl.id === client_id) {
            if (res.body.clients[0].drill_ticket && res.body.clients[0].drill_ticket.length) {
              cl.drill_ticket = [true, res.body.clients[0].drill_ticket[1]];
            } else {
              cl.drill_ticket = res.body.clients[0].drill_ticket;
            }
          }
          return cl
        })
      )
    };
    setSpinner(false);
  }

  useEffect(() => {
    if (firstRender) { return }
    getClients();
  }, [clientsQuery]);

  useEffect(() => {
    if (firstRender) { return }
    setClientsQuery(
      {...clientsQuery, offset: rowsPerPage * page}
    );
  }, [page]);

  useEffect(() => {
    if (firstRender) { return }
    setClientsQuery(
      {...clientsQuery, limit: rowsPerPage, offset: rowsPerPage * page}
    );
  }, [rowsPerPage]);

  useEffect(() => {
    if (props.drillId === 0 || !props.showDrill || props.drillId !== props.showDrill.id) { return }
    getClients();
  }, [props.drillId]);

  useEffect(() => {
    if (!clientsSearch) { return }
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        setClientsQuery(
          {...clientsQuery, search: encodeURIComponent(clientsSearch)}
        );
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [clientsSearch]);
  
  return (
    <Box>
      {spinner
        ? <Box
            sx={{width: 'fit-content', marginLeft: 'auto', marginRight: 'auto'}}
          >
            <CircularProgress color="inherit" />
          </Box>
        : <Box>
            <Paper
              sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: (props.device === 'desktop') ? '50%' : '95%',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: '5px',
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={t('search_placeholder', { ns: 'common' })}
                inputProps={{ 'aria-label': 'search google maps' }}
                onChange={(e) => {setClientsSearch(e.target.value)}}
              />
              <IconButton
                type="button"
                sx={{ p: '10px' }}
                aria-label="search"
                onClick={
                  (e) => {
                    setClientsQuery(
                      {...clientsQuery, search: encodeURIComponent(clientsSearch)}
                    );
                  }
                }
              >
                <SearchIcon />
              </IconButton>
            </Paper>
            <Box>
              <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size='medium'
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>{t('drill_attendance.client')}</TableCell>
                        <TableCell>{t('drill_attendance.tickets')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {clients.map((client, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={Boolean(client.drill_ticket && client.drill_ticket.length && client.drill_ticket[0])}
                            tabIndex={-1}
                            key={`client-attend-key-${client.id}`}
                            selected={Boolean(client.drill_ticket && client.drill_ticket.length && client.drill_ticket[0])}
                            sx={{ cursor: 'pointer' }}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                defaultChecked={Boolean(client.drill_ticket && client.drill_ticket.length && client.drill_ticket[0])}
                                inputProps={{
                                  'aria-labelledby': `client-checkbox-id-${client.id}`,
                                }}
                                onChange={(e: any) => {
                                  placeClient(client.id, '', e.target.checked);
                                }}
                                id={`client-checkbox-id-${client.id}`}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={`client-checkbox-id-${client.id}`}
                              scope="row"
                              padding="none"
                              onClick={(event: any) => {
                                placeClient(client.id, '', !(client.drill_ticket && client.drill_ticket.length && client.drill_ticket[0]));
                              }}
                            >
                              {`${client.first_name} ${client.last_name}`}
                            </TableCell>
                            <TableCell>
                              {accountInfo.account_info.permissions.includes('change_clienttraffic')
                                ? <Select
                                    labelId={`ticket-select-label-id-${client.id}`}
                                    defaultValue={
                                      (
                                        client.drill_ticket &&
                                        client.drill_ticket.length > 1 &&
                                        client.tickets.filter(t => client.drill_ticket && t.id === client.drill_ticket[1]).length
                                      )
                                        ? client.tickets.filter(t => client.drill_ticket && t.id === client.drill_ticket[1])[0].id
                                        : '0'
                                    }
                                    onChange={(e) => {
                                      placeClient(client.id, Number(e.target.value), Number(e.target.value) !== 0);
                                    }}
                                    label={t('drill_attendance.choose_ticket')}
                                    variant="standard"
                                  >
                                    <MenuItem value="0">
                                      <em>{t('drill_attendance.choose_ticket')}</em>
                                    </MenuItem>
                                    {client.tickets.map((t) => {
                                      return (
                                        <MenuItem
                                          key={`ticket-item-id-${t.id}`}
                                          value={t.id}
                                        >
                                          {t.ticket}
                                        </MenuItem>
                                      )
                                    })}
                                  </Select>
                                : <Box>
                                    {client.drill_ticket && client.drill_ticket.length > 1 && client.drill_ticket[0]
                                      ? <Typography>
                                          {client.tickets.filter((t) => t.id === client.drill_ticket[1])[0]?.ticket}
                                        </Typography>
                                      : ''
                                    }
                                  </Box>
                              }
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={clientsCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(event: unknown, newPage: number) => setPage(newPage)}
                  onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setRowsPerPage(+event.target.value);
                    setPage(0);
                  }}
                />
              </Paper>
            </Box>
          </Box>
      }
    </Box>
  )
}

export function ReplaceDrill(props: DrillManageProps) {
  const { t } = useTranslation(['calendar', 'common']);
  const [drillNewDate, setDrillNewDate] = React.useState<string>('')

  return (
    <Box>
      <Box>
        <Typography>{t('choose_drill_new_date')}</Typography>
        <TextField
          id="choose-drill-new-date-id"
          variant="standard"
          type='datetime-local'
          onChange={(e) => {setDrillNewDate(e.target.value)}}
        />
      </Box>
      <Button
        variant="contained"
        color="info"
        sx={{ marginTop: '5px' }}
        disabled={!Boolean(drillNewDate)}
        onClick={(e) => {
          props.applyEdit(
            {drill: props.currentDrill?.id, transfer_date: drillNewDate},
            'transfer_date'
          );
        }}
      >
        {t("save", { ns: 'common' })}
      </Button>
    </Box>
  )
}

export function CancelDrill(props: DrillCancelProps) {
  const { t } = useTranslation(['calendar', 'common']);

  return (
    <Box>
      <Typography>{t(props.messageKey)}</Typography>
      <Button
        variant="contained"
        color="info"
        sx={{ marginTop: '5px' }}
        onClick={(e) => {
          props.applyEdit(
            {
              body: {drill: props.currentDrill?.id, deleted: true},
              query: {schedule: props.currentDrill?.drill?.id, drill_date: props.currentDrill?.drill_date},
              method: 'cancel',
            }
          );
        }}
      >
        {t("apply", { ns: 'common' })}
      </Button>
    </Box>
  )
}

export function SubstituteDrill(props: DrillManageProps) {
  const { t } = useTranslation(['calendar', 'common', 'forms']);
  const [spinner, setSpinner] = React.useState<boolean>(false);
  const [coaches, setCoaches] = React.useState<UserInfoCommon[]>([]);
  const currentDrillCoachesIds: number[] = (props.currentDrill)
    ? props.currentDrill.coaches.map((coach) => {return coach.id})
    : [];
  const [substituteCoach, setSubstituteCoach] = React.useState<number>(0);

  const getCoaches = async () => {
    setSpinner(true);
    const api = new BaseApi(1, 'management/get_coaches/');
    let res = await api.get(
      {drill: props.currentDrill?.id},
      props.appSetState,
      props.appState,
    );
    setCoaches(res.body);
    setSpinner(false);
  };

  useEffect(() => {
    if (!props.currentDrill?.id) { return }
    getCoaches();
  }, [props.currentDrill]);

  return (
    <Box>
      {spinner
        ? <Box
            sx={{width: 'fit-content', marginLeft: 'auto', marginRight: 'auto'}}
          >
            <CircularProgress color="inherit" />
          </Box>
        : <Box>
            <Typography>{t('substitute_coach')}</Typography>
            <Autocomplete
              id="drill-substitute-coaches-id"
              options={
                coaches.filter(
                  coach => !currentDrillCoachesIds.includes(coach.id)
                ).map(
                   (coach: UserInfoCommon) => {return {id: coach.id, label: `${coach.first_name} ${coach.last_name}`}}
                )
              }
              sx={{ width: "100%", marginBottom: "15px" }}
              renderInput={(params) => <TextField {...params} label={t("calendar.coaches", { ns: 'forms' })} />}
              onChange={(
                e: React.SyntheticEvent,
                value: AutocompleteProps | null,
                reason: string,
              ) => {
                if (value) {
                  setSubstituteCoach(Number(value.id));
                } else {
                  setSubstituteCoach(0);
                }
              }}
            />
            <Button
              variant="contained"
              color="info"
              sx={{ marginTop: '5px' }}
              disabled={substituteCoach === 0}
              onClick={(e) => {
                props.applyEdit({drill: props.currentDrill?.id, substitute: substituteCoach}, 'substitute');
              }}
            >
              {t("save", { ns: 'common' })}
            </Button>
          </Box>
      }
    </Box>
  )
}
