import { atom, selector } from 'recoil'

import { BaseApi } from '../api/base';

import { PaymentType } from '../interfaces/Common';

export interface IPaymentTypeState {
  items: PaymentType[]
}

export const paymentTypesSelector = selector({
    key: 'paymentTypesSelector',
    get: async (): Promise<IPaymentTypeState> => {
      const api = new BaseApi(1, 'management/payment_type/');
      let res = await api.get(
        {},
        () => {},
        {},
      );
      if (res.status === 200) {
        return { items: res.body.results };
      };
      return { items: [] };
    },
    set: ({ set, get }, newData) => {
      const currentState = get(paymentTypesAtom);
      let newState = [ ...currentState.items ];
      if (newData.items) {
        newState = [...currentState.items, ...newData.items as PaymentType[]];
      }
      set(paymentTypesAtom, {items: newState});
    },
});

export const paymentTypesAtom = atom<IPaymentTypeState>({
  key: 'paymentTypes',
  default: paymentTypesSelector,
})
