import React from "react";
import { useEffect } from 'react';

import { useRecoilValue, useRecoilValueLoadable } from "recoil";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveTimeRange } from '@nivo/calendar'

import { useTranslation } from 'react-i18next';

import { builtISODate } from "../../helpers";

import { UserInfoCommon } from "../../interfaces/AccountInfo";
import { ClubPromo, ClubTicket } from "../../interfaces/Club";
import { StatisticTabProps, AutocompleteProps } from "../../interfaces/Props";
import { BarChartDataCoachStat, TimeRangeData } from "../../interfaces/Plots";
import { QueryObj, CustomObj } from "../../interfaces/Types";
import { DrillType } from "../../interfaces/Common";

import { BaseApi } from "../../api/base";

import { BootstrapDialog } from "../common/Dialogs";
import { Clients } from "../users/Clients";

import { promosAtom } from "../../atoms/Promos";
import { unlimitTicketsAtom } from "../../atoms/Tickets";
import { coachesAtom } from "../../atoms/Coaches";
import { drillTypesAtom } from "../../atoms/DrillTypes";
import { accountInfoAtom } from '../../atoms/AccountInfo';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function CoachStatisticTab(props: StatisticTabProps) {
  let today = new Date();
  let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  
  const { t, i18n } = useTranslation(['statistic', 'filters']);
  
  const translations: any = (i18n.options.resources && Object.keys(i18n.options.resources).includes(i18n.language)) ? i18n.options.resources[i18n.language] : {};
  const prolongated = (Object.keys(translations).length) ? translations.statistic.plots.prolongated : "Prolongated";
  const non_prolongated = (Object.keys(translations).length) ? translations.statistic.plots.non_prolongated : "Non-prolongated";
  const plot_header = (Object.keys(translations).length) ? translations.statistic.coach_statistic.extend_promos : "Promotion extensions";
  
  const promoChartRef = React.useRef<any>(null);
  const coachChartRef = React.useRef<any>(null);
  const [spinner, setSpinner] = React.useState<boolean>(false);
  const accountInfo = useRecoilValue(accountInfoAtom);
  const promos = useRecoilValueLoadable(promosAtom);
  const coaches = useRecoilValueLoadable(coachesAtom);
  const tickets = useRecoilValueLoadable(unlimitTicketsAtom);
  const drillTypes = useRecoilValueLoadable(drillTypesAtom);
  const [statDateStart, setStatDateStart] = React.useState<string>(builtISODate(firstDayOfMonth));
  const [statDateStop, setStatDateStop] = React.useState<string>(builtISODate(lastDayOfMonth));
  const [statPromo, setStatPromo] = React.useState<QueryObj>({});
  const [statCoach, setStatCoach] = React.useState<QueryObj>(
    (accountInfo.account_info.permissions.includes('get_statistic'))
      ? {}
      : {
          id: props.appState.account_info.id,
          value: `${props.appState.account_info.first_name} ${props.appState.account_info.last_name}`,
        }
  );
  const [statTicket, setStatTicket] = React.useState<QueryObj>({});
  const [unlimitTicketClients, setUnlimitTicketClients] = React.useState<QueryObj[]>([]);
  const [promoBarData, setPromoBarData] = React.useState<BarChartDataCoachStat>(
    {
      labels: [],
      datasets: [
        {
          label: non_prolongated,
          data: [],
          clients: [],
          backgroundColor: 'rgb(189, 0, 40)',
        },
        {
          label: prolongated,
          data: [],
          clients: [],
          backgroundColor: 'rgb(1, 131, 23)',
        },
      ],
    }
  );
  const [coachBarData, setCoachBarData] = React.useState<BarChartDataCoachStat>(
    {
      labels: [],
      datasets: [
        {
          label: non_prolongated,
          data: [],
          clients: [],
          backgroundColor: 'rgb(189, 0, 40)',
        },
        {
          label: prolongated,
          data: [],
          clients: [],
          backgroundColor: 'rgb(1, 131, 23)',
        },
      ],
    }
  );
  const [unlimitTicketStatData, setUnlimitTicketStatData] = React.useState<CustomObj[]>([]);
  const [unlimitTicketAttendanceClient, setUnlimitTicketAttendanceClient] = React.useState<number>(0);
  const [unlimitTicketResponseBody, setUnlimitTicketResponseBody] = React.useState<CustomObj>({});
  const [coachDrillDensity, setCoachDrillDensity] = React.useState<CustomObj>(
    (accountInfo.account_info.permissions.includes('get_statistic'))
      ? {}
      : {
         id: props.appState.account_info.id,
         value: `${props.appState.account_info.first_name} ${props.appState.account_info.last_name}`,
       }
  );
  const [totalCoachDrillDensity, setTotalCoachDrillDensity] = React.useState<number>(0);
  const [drillTypeDensity, setDrillTypeDensity] = React.useState<CustomObj>({});
  const [coachDrillDensityData, setCoachDrillDensityData] = React.useState<TimeRangeData[]>([]);
  const [coachDrillAttendance, setCoachDrillAttendance] = React.useState<CustomObj>(
    (accountInfo.account_info.permissions.includes('get_statistic'))
      ? {}
      : {
         id: props.appState.account_info.id,
         value: `${props.appState.account_info.first_name} ${props.appState.account_info.last_name}`,
       }
  );
  const [drillTypeAttendance, setDrillTypeAttendance] = React.useState<CustomObj>({});
  const [coachDrillAttendanceData, setCoachDrillAttendanceData] = React.useState<TimeRangeData[]>([]);
  const [totalCoachDrillAttendance, setTotalCoachDrillAttendance] = React.useState<number>(0);
  const [clientsIds, setClientsIds] = React.useState<number[]>([]);
  const [showClients, setShowClients] = React.useState<boolean>(false);


  const updatePromoBarData = (
    body: CustomObj, promo_prolongation: number[][], promo_non_prolongation: number[][]
  ) => {
    let labels: string[] = Array.from(
      Object.keys(body.statistic),
      (k) => body.statistic[k]['coach']
    );
    setPromoBarData(
      {
        labels: labels,
        datasets: [
          {
            label: non_prolongated,
            data: Array.from(promo_non_prolongation, p => p.length),
            clients: promo_non_prolongation,
            backgroundColor: 'rgb(189, 0, 40)',
          },
          {
            label: prolongated,
            data: Array.from(promo_prolongation, p => p.length),
            clients: promo_prolongation,
            backgroundColor: 'rgb(1, 131, 23)',
          },
        ],
      }
    );
  }

  const updateCoachBarData = (
    body: CustomObj, promo_prolongation: number[][], promo_non_prolongation: number[][]
  ) => {
    let labels: string[] = Array.from(
      Object.keys(body.statistic),
      (k) => body.statistic[k]['promo']
    );
    setCoachBarData(
      {
        labels: labels,
        datasets: [
          {
            label: non_prolongated,
            data: Array.from(promo_non_prolongation, p => p.length),
            clients: promo_non_prolongation,
            backgroundColor: 'rgb(189, 0, 40)',
          },
          {
            label: prolongated,
            data: Array.from(promo_prolongation, p => p.length),
            clients: promo_prolongation,
            backgroundColor: 'rgb(1, 131, 23)',
          },
        ],
      }
    );
  }

  const updatePromoCoachBarData = (body: CustomObj, func: string) => {
      let promo_prolongation = Array.from(
        Object.keys(body.statistic),
        (k) => body.statistic[k]['prolongation']
      );
      let promo_non_prolongation = Array.from(
        Object.keys(body.statistic),
        (k) => body.statistic[k]['non_prolongation']
      );
      switch (func) {
        case 'updatePromoBarData': {
          updatePromoBarData(body, promo_prolongation, promo_non_prolongation);
          break;
        }
        case 'updateCoachBarData': {
          updateCoachBarData(body, promo_prolongation, promo_non_prolongation);
          break;
        }
      }
  }

  const getStatData = async (query_obj: QueryObj, statistic_type: string) => {
    const api = new BaseApi(1, 'management/coaches/statistic/');
    let query: QueryObj = {
      statistic_type: statistic_type,
      date_start: statDateStart,
      date_stop: statDateStop,
    };
    let res = await api.get(
      {...query, ...query_obj},
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      switch (statistic_type) {
        case 'promo_prolongation': {
          let func: string = '';
          if (query_obj.promo_id) {
            func = 'updatePromoBarData';
          } else if (query_obj.coach_id) {
            func = 'updateCoachBarData';
          }
          updatePromoCoachBarData(res.body, func);
          break;
        }
        case 'unlimit_attendance': {
          setUnlimitTicketResponseBody(res.body.statistic);
          setUnlimitTicketStatData(
            Array.from(
              Object.keys(res.body.statistic).filter(v => v !== "clients"),
              (k) => new Object(
                {
                  'id': res.body.statistic[k].coach,
                  'label': res.body.statistic[k].coach,
                  'value': (!unlimitTicketAttendanceClient)
                    ? res.body.statistic[k].attendance
                    : res.body.statistic[k][unlimitTicketAttendanceClient],
                }
              )
            )
          );
          setUnlimitTicketClients(res.body.statistic.clients);
          break;
        }
      }
    };
  };

  const getTotalAmount = (arr: any) => {
    let _sum: number = 0;
    for (let i of arr) {
      _sum += i.value;
    }
    return _sum
  }

  const getCoachStatData = async (query_obj: QueryObj, statistic_type: string) => {
    const api = new BaseApi(1, 'management/coaches/drills/statistic/');
    let query: QueryObj = {
      stat_type: statistic_type,
      date_start: statDateStart,
      date_stop: statDateStop,
    };
    let res = await api.get(
      {...query, ...query_obj},
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      switch (statistic_type) {
        case 'drill_stat': {
          setCoachDrillDensityData(
            res.body.drills?.map(
               (d: any) => {return {value: d.amount, day: d.day}}
            )
          );
          break;
        }
        case 'attendence_stat': {
          setCoachDrillAttendanceData(
            res.body.drills?.map(
               (d: any) => {return {value: d.amount, day: d.day}}
            )
          );
          break;
        }
      }
    };
  };

  useEffect(() => {
    setTotalCoachDrillDensity(getTotalAmount(coachDrillDensityData));
  }, [coachDrillDensityData]);
  useEffect(() => {
    setTotalCoachDrillAttendance(getTotalAmount(coachDrillAttendanceData));
  }, [coachDrillAttendanceData]);

  useEffect(() => {
    if (props.statType !== 'coach_stat') { return }
    if (statPromo.id) {
      getStatData({promo_id: statPromo.id}, 'promo_prolongation');
    }
    if (statCoach.id) {
      getStatData({coach_id: statCoach.id}, 'promo_prolongation');
    }
    if (statTicket.id) {
      getStatData({ticket_id: statTicket.id}, 'unlimit_attendance');
    }
    if (coachDrillDensity.id && drillTypeDensity.id) {
      getCoachStatData(
        {
          coach_id: coachDrillDensity.id,
          drill_type_id: drillTypeDensity.id,
        },
        'drill_stat',
      );
    }
  }, [statDateStart]);
  
  useEffect(() => {
    if (props.statType !== 'coach_stat') { return }
    if (statPromo.id) {
      getStatData({promo_id: statPromo.id}, 'promo_prolongation');
    }
    if (statCoach.id) {
      getStatData({coach_id: statCoach.id}, 'promo_prolongation');
    }
    if (statTicket.id) {
      getStatData({ticket_id: statTicket.id}, 'unlimit_attendance');
    }
    if (coachDrillDensity.id && drillTypeDensity.id) {
      getCoachStatData(
        {
          coach_id: coachDrillDensity.id,
          drill_type_id: drillTypeDensity.id,
        },
        'drill_stat',
      );
    }
  }, [statDateStop]);
  
  useEffect(() => {
    if (props.statType !== 'coach_stat') { return }
    if (!statTicket.id) { return }
    getStatData({ticket_id: statTicket.id}, 'unlimit_attendance');
  }, [statTicket]);
  
  useEffect(() => {
    if (props.statType !== 'coach_stat') { return }
    if (statPromo.id) {
      getStatData({promo_id: statPromo.id}, 'promo_prolongation');
    } else {
      setPromoBarData(
        {
          labels: [],
          datasets: [
            {
              label: non_prolongated,
              data: [],
              clients: [],
              backgroundColor: 'rgb(189, 0, 40)',
            },
            {
              label: prolongated,
              data: [],
              clients: [],
              backgroundColor: 'rgb(1, 131, 23)',
            },
          ],
        }
      )
    }
  }, [statPromo]);
  
  useEffect(() => {
    if (props.statType !== 'coach_stat') { return }
    if (statCoach.id) {
      getStatData({coach_id: statCoach.id}, 'promo_prolongation');
    } else {
      setCoachBarData(
        {
          labels: [],
          datasets: [
            {
              label: non_prolongated,
              data: [],
              clients: [],
              backgroundColor: 'rgb(189, 0, 40)',
            },
            {
              label: prolongated,
              data: [],
              clients: [],
              backgroundColor: 'rgb(1, 131, 23)',
            },
          ],
        }
      )
    }
  }, [statCoach]);

  useEffect(() => {
    setUnlimitTicketStatData(
      Array.from(
        Object.keys(unlimitTicketResponseBody).filter(v => v !== "clients"),
        (k) => new Object(
          {
            'id': unlimitTicketResponseBody[k].coach,
            'label': unlimitTicketResponseBody[k].coach,
            'value': (!unlimitTicketAttendanceClient)
              ? unlimitTicketResponseBody[k].attendance
              : unlimitTicketResponseBody[k][unlimitTicketAttendanceClient],
          }
        )
      )
    );
  }, [unlimitTicketAttendanceClient]);

  useEffect(() => {
    if (!coachDrillDensity.id || !drillTypeDensity.id) { return }
    getCoachStatData(
      {
        coach_id: coachDrillDensity.id,
        drill_type_id: drillTypeDensity.id,
      },
      'drill_stat',
    );
  }, [coachDrillDensity]);

  useEffect(() => {
    if (!coachDrillDensity.id || !drillTypeDensity.id) { return }
    getCoachStatData(
      {
        coach_id: coachDrillDensity.id,
        drill_type_id: drillTypeDensity.id,
      },
      'drill_stat',
    );
  }, [drillTypeDensity]);

  useEffect(() => {
    if (!coachDrillAttendance.id || !drillTypeAttendance.id) { return }
    getCoachStatData(
      {
        coach_id: coachDrillAttendance.id,
        drill_type_id: drillTypeAttendance.id,
      },
      'attendence_stat',
    );
  }, [coachDrillAttendance]);

  useEffect(() => {
    if (!coachDrillAttendance.id || !drillTypeAttendance.id) { return }
    getCoachStatData(
      {
        coach_id: coachDrillAttendance.id,
        drill_type_id: drillTypeAttendance.id,
      },
      'attendence_stat',
    );
  }, [drillTypeAttendance]);

  useEffect(() => {
    if (clientsIds.length) {
      setShowClients(true);
    }
  }, [clientsIds]);

  useEffect(() => {
    if (!showClients) {
      setClientsIds([]);
    }
  }, [showClients]);

  return (
    <Box>
      {spinner
        ? <Box
            sx={{
              width: 'fit-content',
              marginLeft: 'auto',
              marginRight: 'auto',
              paddingTop: '5px',
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        : <Box>
            <Box
              sx={{
                width: 'fit-content',
                padding: '5px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <TextField
                id="bid-date-from-id"
                label={t('date_from', { ns: 'filters' })}
                sx={{ paddingLeft: '5px', paddingRight: '5px' }}
                variant="standard"
                type="date"
                onChange={(e) => {setStatDateStart(e.target.value)}}
                defaultValue={statDateStart}
              />
              <TextField
                id="bid-date-to-id"
                label={t('date_to', { ns: 'filters' })}
                sx={{ paddingLeft: '5px', paddingRight: '5px' }}
                variant="standard"
                type="date"
                onChange={(e) => {setStatDateStop(e.target.value)}}
                defaultValue={statDateStop}
              />
            </Box>
            <Box sx={{ marginBottom: '5px' }}>
              <Card sx={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                <CardContent>
                  <Autocomplete
                    disablePortal
                    id="stat-promo-filter-id"
                    options={
                      (
                        promos.state === "hasValue"
                          ? promos.contents.items.map(
                            (p) => {
                              return {
                                id: p.id,
                                label: `${p.name} (${p.club_unit.name})`
                              }
                            }
                          )
                          : []
                    )}
                    onChange={
                      (
                        e: React.SyntheticEvent,
                        value: AutocompleteProps | null,
                      ) => {
                        if (value && value.id) {
                          setStatPromo({id: value.id, name: value.label});
                        } else {
                          setStatPromo({});
                        }
                      }
                    }
                    sx={{
                      width: (props.device === 'mobile') ? '100%' : '30%',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                    renderInput={
                      (params: any) => <TextField
                        {...params}
                        label={t("promo", { ns: 'filters' })}
                      />
                    }
                  />
                  <Bar
                    ref={promoChartRef}
                    data={
                      {
                        labels: promoBarData.labels,
                        datasets: promoBarData.datasets,
                      }
                    }
                    options={
                      {
                        plugins: {
                          title: {
                            display: true,
                            text: plot_header,
                          },
                        },
                        responsive: true,
                        scales: {
                          x: {
                            stacked: true,
                          },
                          y: {
                            stacked: true,
                            min: 0,
                            ticks: {
                              stepSize: 1,
                            },
                          },
                        },
                      }
                    }
                    height={100}
                    onClick={
                      (e: any) => {
                      let elem: any = getElementAtEvent(promoChartRef.current, e);
                        if (elem.length === 0) {
                          return
                        }
                        elem = elem[0];
                        setClientsIds(
                          promoBarData.datasets[elem.datasetIndex].clients[elem.index].map((arr) => {return arr[0]})
                        );
                      }
                    }
                  />
                </CardContent>
              </Card>
            </Box>
            <Box
              sx={{
                display: (props.device === 'desktop') ? 'flex' : 'block',
                marginBottom: '5px',
              }}
            >
              <Card
                sx={{
                  width: '100%',
                  marginRight: '5px',
                }}
              >
                <CardContent>
                  {accountInfo.account_info.permissions.includes('get_statistic')
                    ? <Autocomplete
                        disablePortal
                        id="stat-coach-filter-id"
                        options={
                          (
                            coaches.state === "hasValue"
                              ? coaches.contents.items.map(
                                (c) => {
                                  return {
                                    id: c.id,
                                    label: `${c.first_name} ${c.last_name}`
                                  }
                                }
                              )
                            : []
                          )
                        }
                        onChange={
                          (
                            e: React.SyntheticEvent,
                            value: AutocompleteProps | null,
                          ) => {
                            if (value && value.id) {
                              setStatCoach({id: value.id, name: value.label});
                            } else {
                              setStatCoach({});
                            }
                          }
                        }
                        sx={{
                          width: (props.device === 'mobile') ? '100%' : '30%',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        }}
                        renderInput={
                          (params: any) => <TextField
                            {...params}
                            label={t("coach", { ns: 'filters' })}
                          />
                        }
                      />
                    : ''
                  }
                  <Bar
                    ref={coachChartRef}
                    data={
                      {
                        labels: coachBarData.labels,
                        datasets: coachBarData.datasets,
                      }
                    }
                    options={
                      {
                        plugins: {
                          title: {
                            display: true,
                            text: plot_header,
                          },
                        },
                        responsive: true,
                        scales: {
                          x: {
                            stacked: true,
                          },
                          y: {
                            stacked: true,
                            min: 0,
                            ticks: {
                              stepSize: 1,
                            },
                          },
                        },
                      }
                    }
                    onClick={
                      (e: any) => {
                      let elem: any = getElementAtEvent(coachChartRef.current, e);
                        if (elem.length === 0) {
                          return
                        }
                        elem = elem[0];
                        setClientsIds(
                          coachBarData.datasets[elem.datasetIndex].clients[elem.index].map((arr) => {return arr[0]})
                        );
                      }
                    }
                  />
                </CardContent>
              </Card>
              <Card
                sx={{
                  width: '100%',
                  marginLeft: '5px',
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: (props.device === 'desktop') ? 'flex' : 'block',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <Autocomplete
                      disablePortal
                      id="stat-ticket-filter-id"
                      options={
                        (
                          tickets.state === "hasValue"
                            ? tickets.contents.items.map(
                              (t) => {
                                return {
                                  id: t.id,
                                  label: `${t.ticket} (${t.club_unit.map(cu => {return cu.name}).join(',')})`
                                }
                              }
                            )
                          : []
                        )
                      }
                      onChange={
                        (
                          e: React.SyntheticEvent,
                          value: AutocompleteProps | null,
                        ) => {
                          if (value && value.id) {
                            setStatTicket({id: value.id, name: value.label});
                          } else {
                            setStatTicket({});
                          }
                        }
                      }
                      sx={{
                        width: (props.device === 'mobile') ? '100%' : '30%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                      renderInput={
                        (params: any) => <TextField
                          {...params}
                          label={t("ticket", { ns: 'filters' })}
                        />
                      }
                    />
                    {statTicket.id && unlimitTicketClients.length
                      ? <Autocomplete
                          disablePortal
                          id="clients-club_unit-filter-id"
                          options={
                            unlimitTicketClients.map(
                              (cl) => {
                                return {
                                  id: cl.id,
                                  label: `${cl.first_name} ${cl.last_name}`
                                }
                              }
                            )
                          }
                          onChange={
                            (
                              e: React.SyntheticEvent,
                              value: {id: string | number | boolean, label: string} | null,
                            ) => {
                              if (value && value.id) {
                                setUnlimitTicketAttendanceClient(Number(value.id));
                              } else {
                                setUnlimitTicketAttendanceClient(0);
                              }
                            }
                          }
                          sx={{
                            width: (props.device === 'mobile') ? '100%' : '30%',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                          }}
                          renderInput={
                            (params: any) => <TextField
                              {...params}
                              label={t("client", { ns: 'filters' })}
                            />
                          }
                        />
                      : ''
                    }
                  </Box>
                  <Box
                    sx={{
                      height: '300px',
                      width: '100%',
                      display: 'block',
                    }}
                  >
                    <ResponsivePie
                      data={unlimitTicketStatData}
                      margin={{ top: 60, right: 20, bottom: 60, left: 20 }}
                      innerRadius={0.5}
                      padAngle={3}
                      cornerRadius={3}
                      activeOuterRadiusOffset={8}
                      borderWidth={1}
                      colors={{"scheme": "category10"}}
                      borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                      arcLinkLabelsSkipAngle={10}
                      arcLinkLabelsTextColor="#333333"
                      arcLinkLabelsThickness={2}
                      arcLinkLabelsColor={{ from: 'color' }}
                      arcLabelsSkipAngle={10}
                      arcLabelsTextColor={{ from: 'color', modifiers: [ [ 'darker', 2 ] ] }}
                      defs={[]}
                      fill={[]}
                      legends={
                        [
                          {
                            anchor: 'top-left',
                            direction: 'column',
                            justify: false,
                            translateX: 0,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemWidth: 100,
                            itemHeight: 18,
                            itemTextColor: '#000',
                            itemDirection: 'left-to-right',
                            itemOpacity: 1,
                            symbolSize: 20,
                            symbolShape: 'circle',
                            effects: [
                              {
                                on: 'hover',
                                style: {
                                  itemTextColor: '#000'
                                }
                              }
                            ]
                          }
                        ]
                      }
                    />
                    </Box>
                </CardContent>
              </Card>
            </Box>
            <Box
              sx={{
                display: (props.device === 'desktop') ? 'flex' : 'block',
                marginBottom: '5px',
              }}
            >
              <Card sx={{ width: '100%', marginLeft: 'auto', marginRight: '5px' }}>
                <CardContent>
                  {accountInfo.account_info.permissions.includes('get_statistic')
                    ? <Autocomplete
                        disablePortal
                        id="density-coach-filter-id"
                        options={
                          (
                            coaches.state === "hasValue"
                              ? coaches.contents.items.map(
                                (c) => {
                                  return {
                                    id: c.id,
                                    label: `${c.first_name} ${c.last_name}`
                                  }
                                }
                              )
                            : []
                          )
                        }
                        onChange={
                          (
                            e: React.SyntheticEvent,
                            value: AutocompleteProps | null,
                          ) => {
                            if (value && value.id) {
                              setCoachDrillDensity({id: value.id, name: value.label});
                            } else {
                              setCoachDrillDensity({});
                            }
                          }
                        }
                        sx={{
                          width: (props.device === 'mobile') ? '100%' : '30%',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        }}
                        renderInput={
                          (params: any) => <TextField
                            {...params}
                            label={t("coach", { ns: 'filters' })}
                          />
                        }
                      />
                    : ''
                  }
                  <Autocomplete
                    disablePortal
                    id="density-drill-type-filter-id"
                    options={
                      (
                        drillTypes.state === "hasValue"
                          ? drillTypes.contents.items.map(
                            (dt) => {
                              return {
                                id: dt.id,
                                label: dt.name
                              }
                            }
                          )
                        : []
                      )
                    }
                    onChange={
                      (
                        e: React.SyntheticEvent,
                        value: AutocompleteProps | null,
                      ) => {
                        if (value && value.id) {
                          setDrillTypeDensity({id: value.id, name: value.label});
                        } else {
                          setDrillTypeDensity({});
                        }
                      }
                    }
                    sx={{
                      width: (props.device === 'mobile') ? '100%' : '30%',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      marginTop: '5px',
                    }}
                    renderInput={
                      (params: any) => <TextField
                        {...params}
                        label={t("drill_type", { ns: 'filters' })}
                      />
                    }
                  />
                  <Box sx={{ width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', marginTop: '15px', marginBottom: '5px' }}>
                    <Typography>
                      {t('total_drills')}: {totalCoachDrillDensity}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: '300px',
                      width: '100%',
                      display: 'block',
                    }}
                  >
                    <ResponsiveTimeRange
                      data={coachDrillDensityData}
                      from={statDateStart}
                      to={statDateStop}
                      emptyColor="#eeeeee"
                      colors={[ '#61cdbb', '#97e3d5', '#e8c1a0', '#f47560' ]}
                      margin={{ top: 40, right: 40, bottom: 100, left: 40 }}
                      dayBorderWidth={2}
                      dayBorderColor="#ffffff"
                      legends={[
                        {
                          anchor: 'bottom-right',
                          direction: 'row',
                          justify: false,
                          itemCount: 4,
                          itemWidth: 42,
                          itemHeight: 36,
                          itemsSpacing: 14,
                          itemDirection: 'right-to-left',
                          translateX: -60,
                          translateY: -60,
                          symbolSize: 20
                        }
                      ]}
                    />
                  </Box>
                </CardContent>
              </Card>
              <Card sx={{ width: '100%', marginLeft: '5px', marginRight: 'auto' }}>
                <CardContent>
                  {accountInfo.account_info.permissions.includes('get_statistic')
                    ? <Autocomplete
                        disablePortal
                        id="attendance-coach-filter-id"
                        options={
                          (
                            coaches.state === "hasValue"
                              ? coaches.contents.items.map(
                                (c) => {
                                  return {
                                    id: c.id,
                                    label: `${c.first_name} ${c.last_name}`
                                  }
                                }
                              )
                            : []
                          )
                        }
                        onChange={
                          (
                            e: React.SyntheticEvent,
                            value: AutocompleteProps | null,
                          ) => {
                            if (value && value.id) {
                              setCoachDrillAttendance({id: value.id, name: value.label});
                            } else {
                              setCoachDrillAttendance({});
                            }
                          }
                        }
                        sx={{
                          width: (props.device === 'mobile') ? '100%' : '30%',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        }}
                        renderInput={
                          (params: any) => <TextField
                            {...params}
                            label={t("coach", { ns: 'filters' })}
                          />
                        }
                      />
                    : ''
                  }
                  <Autocomplete
                    disablePortal
                    id="attendance-drill-type-filter-id"
                    options={
                      (
                        drillTypes.state === "hasValue"
                          ? drillTypes.contents.items.map(
                            (dt) => {
                              return {
                                id: dt.id,
                                label: dt.name
                              }
                            }
                          )
                        : []
                      )
                    }
                    onChange={
                      (
                        e: React.SyntheticEvent,
                        value: AutocompleteProps | null,
                      ) => {
                        if (value && value.id) {
                          setDrillTypeAttendance({id: value.id, name: value.label});
                        } else {
                          setDrillTypeAttendance({});
                        }
                      }
                    }
                    sx={{
                      width: (props.device === 'mobile') ? '100%' : '30%',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      marginTop: '5px',
                    }}
                    renderInput={
                      (params: any) => <TextField
                        {...params}
                        label={t("drill_type", { ns: 'filters' })}
                      />
                    }
                  />
                  <Box sx={{ width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', marginTop: '15px', marginBottom: '5px' }}>
                    <Typography>
                      {t('total_attendance')}: {totalCoachDrillAttendance}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: '300px',
                      width: '100%',
                      display: 'block',
                    }}
                  >
                    <ResponsiveTimeRange
                      data={coachDrillAttendanceData}
                      from={statDateStart}
                      to={statDateStop}
                      emptyColor="#eeeeee"
                      colors={[ '#61cdbb', '#97e3d5', '#e8c1a0', '#f47560' ]}
                      margin={{ top: 40, right: 40, bottom: 100, left: 40 }}
                      dayBorderWidth={2}
                      dayBorderColor="#ffffff"
                      legends={[
                        {
                          anchor: 'bottom-right',
                          direction: 'row',
                          justify: false,
                          itemCount: 4,
                          itemWidth: 42,
                          itemHeight: 36,
                          itemsSpacing: 14,
                          itemDirection: 'right-to-left',
                          translateX: -60,
                          translateY: -60,
                          symbolSize: 20
                        }
                      ]}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Box>
      }
      <BootstrapDialog
        onClose={(e) => {setShowClients(false)}}
        open={showClients}
        fullScreen
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar sx={{ backgroundColor: 'var(--black)' }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => {setShowClients(false)}}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {t('clients')}
            </Typography>
          </Toolbar>
        </AppBar>
        <Clients
          handleCloseSpinner={props.handleCloseSpinner}
          handleOpenSpinner={props.handleOpenSpinner}
          openPopUp={props.openPopUp}
          departments={props.departments}
          device={props.device}
          appState={props.appState}
          appSetState={props.appSetState}
          clientsIds={clientsIds.toString()}
          sendSocketMessage={props.sendSocketMessage}
        />
      </BootstrapDialog>
    </Box>
  )
}
