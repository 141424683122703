import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
      light: '#000',
      dark: '#000',
    },
    secondary: {
      main: '#fff',
      light: '#fff',
      dark: '#fff',
    },
    info: {
      main: '#f7941e',
      light: '#1ea51e',
      dark: '#e94141',
    },
    background: {
      default: "#c5c6c8",
    },
    action: {
      disabledBackground: 'transparent',
      disabled: '#ffffff4d',
    }
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#f7941e",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "#f7941e",
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          backgroundColor: "#c5c6c8",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: "#000",
          color: "#f7941e",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: "#f7941e",
        }
      }
    }
  },
});
