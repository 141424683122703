import { atom, selectorFamily } from 'recoil';

export const languageAtom = atom<string>({
  key: 'language',
  default: 'en',
})

export const languageAtomSelect = selectorFamily({
  key: 'languageSelect',
  get: () => ({get}) => {
    const language = get(languageAtom);
    return language
  },
  set: (n) => ({set}, newValue) => {
    set(languageAtom, newValue);
  },
})
