import { atom, selector } from 'recoil';

import { AccountInfo } from '../interfaces/AccountInfo';

export interface IAccountInfoState {
  account_info: AccountInfo;
  logged_in: boolean;
}

export const accountInfoAtom = atom<IAccountInfoState>({
  key: 'accountInfo',
  default: {
    account_info: {
        id: 0,
        first_name: '',
        last_name: '',
        patronymic: '',
        clubs: [],
        type: 0,
        permissions: [],
      } as AccountInfo,
    logged_in: false,
  },
})

export const accountInfoSelector = selector<IAccountInfoState>({
  key: 'accountInfoSelector',
  get: ({get}) => { return accountInfoAtom },
  set: ({set}, newValue) => {set(accountInfoAtom, newValue)},
})
