import React, { useEffect } from "react";

import { useRecoilValue } from 'recoil';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';

import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CloseIcon from '@mui/icons-material/Close';

import { useTranslation } from 'react-i18next';

import { builtISODate } from "../../helpers";

import {
  UserTicketFull,
  NonActiveUserTicket,
} from "../../interfaces/ClientTicket";
import {
  UserTicketCommonProps,
  NonActiveUserTicketsInfoProps,
  NonActiveTickectsArrProps,
} from "../../interfaces/Props";
import { UserTicketManage } from "../common/ClientTicketManage";
import { ApproveAction } from "../common/ActionApprove";
import { UserTicketManageRequestBody } from "../../interfaces/ApiBody";

import { theme as root_theme } from "../../consts";

import { BaseApi } from "../../api/base";

import { accountInfoAtom } from '../../atoms/AccountInfo';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    backgroundColor: root_theme.palette.background.default,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: root_theme.palette.info.main,
  }
}));

const WidthBootstrapDialog = styled(BootstrapDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '80%',
    maxWidth: '80%',
  },
  '& .MuiTableContainer-root': {
    width: '100%',
    maxWidth: '100%',
  }
}));

interface ITicketInfoDialog {
  ticketInfo: UserTicketFull
}

export function UserTicketCommonInfoDialog(props: ITicketInfoDialog) {
  const { t } = useTranslation('tickets');
  
  return (
    <Box>
      <Typography gutterBottom>
        {t('club_units')}: {props.ticketInfo.club_units.map((cu) => { return cu.name }).toString()}
      </Typography>
      <Divider />
      <Typography gutterBottom>
        {props.ticketInfo.start_date} - {props.ticketInfo.expire_date}
      </Typography>
      <Divider />
      <Box>
        <Typography gutterBottom>
          {t('completed_drills')}: {props.ticketInfo.completed_drills.count}
        </Typography>
        <List>
          {props.ticketInfo.completed_drills.schedule.map((drill, index) => {
            let txt = `${drill.drill_date} ${drill.drill_time}`;
            return (
              <ListItem
                dense={true}
                key={`attendance-${index}`}
              >
                <HorizontalRuleIcon />
                <ListItemText
                  primary={txt}
                />
              </ListItem>
            )
          })}
        </List>
      </Box>
      <Divider />
      <Typography gutterBottom>
        {t('lost_drills')}: {props.ticketInfo.lost_drills}
      </Typography>
      <Divider />
      <Typography gutterBottom>
        {props.ticketInfo.ticket.ticket}
      </Typography>
      <Divider />
      <Box>
        {props.ticketInfo.discipline.map((discip) => {
          return (
            <Typography key={`ticket-discipline-${discip.id}`}>
              {discip.name}
            </Typography>
          )
        })}
      </Box>
      <Divider />
      <Typography gutterBottom>
        {t('sail_date')}: {builtISODate(new Date(props.ticketInfo.created_at))}
      </Typography>
    </Box>
  )
}

export function UserTicketCommonInfo(props: UserTicketCommonProps) {
  const { t } = useTranslation('tickets');
  const [editTicket, setEditTicket] = React.useState<boolean>(false);
  const [ticketInfo, setTicketInfo] = React.useState<UserTicketFull | null>(null);
  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorMenu);
  const [openApply, setOpenApply] = React.useState<number>(0);
  const [approveAction, setApproveAction] = React.useState<string>('');
  const [spinner, setSpinner] = React.useState<boolean>(false);
  const accountInfo = useRecoilValue(accountInfoAtom);

  const getTicketInfo = async () => {
    setSpinner(true);
    const api = new BaseApi(1, 'user/user_tickets/');
    let res = await api.get({id: props.ticket.id}, props.appSetState, props.appState);
    if (!res.body.length) { return }
    setTicketInfo(res.body[0] as UserTicketFull);
    setSpinner(false);
  }

  useEffect(() => {
    if (!props.show) { return }
    getTicketInfo();
  }, [props.show]);
  
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorMenu(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorMenu(null);
  };

  const closeEdit = () => {
    setEditTicket(false);
  };

  const applyEdit = async (
    client_id: number,
    ticket_state: UserTicketFull,
    ticket: UserTicketManageRequestBody,
  ) => {
    setTicketInfo(ticket_state);
    const api = new BaseApi(1, `user/user_tickets/${ticket.id}/`);
    let res = await api.post(ticket, 'application/json', props.appSetState, props.appState);
    setEditTicket(false);
  };

  const closeTicket = async () => {
    setSpinner(true);
    const api = new BaseApi(1, `user/user_tickets/${props.ticket.id}/`);
    let res = await api.post(
      {expired: true},
      'application/json',
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      props.excludeTicket();
    }
    setOpenApply(0);
    setApproveAction('');
    setSpinner(false);
  }
  const removeTicket = async () => {
    setSpinner(true);
    const api = new BaseApi(1, `user/user_tickets/${props.ticket.id}/`);
    let res = await api.delete({}, props.appSetState, props.appState);
    if (res.status === 200) {
      props.excludeTicket();
    }
    setOpenApply(0);
    setApproveAction('');
    setSpinner(false);
    props.handleClose();
  }
  const declineTicketCloseRemove = () => {
    setOpenApply(0);
    setApproveAction('');
    props.handleClose();
  }

  return (
    <BootstrapDialog
      onClose={(e) => {
        if (!editTicket) { props.handleClose() }
      }}
      open={props.show}
    >
      <DialogTitle sx={{ paddingLeft: '5px' }}>
        <Box sx={{ display: 'flex' }}>
          {!editTicket && (
            accountInfo.account_info.permissions.includes('change_ticket') ||
            accountInfo.account_info.permissions.includes('add_userticketchange')
          )
            ? <IconButton aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
            : ''
          }
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontWeight: '600', fontSize: '1.25em' }}>
              {(ticketInfo) ? ticketInfo.ticket.ticket : props.ticket.ticket}
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      {spinner
        ? <DialogContent dividers className="box-content-center">
            <CircularProgress color="inherit" />
          </DialogContent>
        : <Box>
            {!editTicket
              ? <DialogContent dividers className="box-content-center">
                  {!ticketInfo
                    ? <Box sx={{ display: 'flex' }}>
                        <CircularProgress color="info" />
                      </Box>
                    : <UserTicketCommonInfoDialog ticketInfo={ticketInfo} />
                  }
                </DialogContent>
              : <DialogContent dividers className="box-content-center">
                {ticketInfo
                  ? <UserTicketManage
                      ticket={ticketInfo}
                      closeEdit={closeEdit}
                      applyEdit={applyEdit}
                      handleOpenSpinner={props.handleOpenSpinner}
                      handleCloseSpinner={props.handleCloseSpinner}
                      appState={props.appState}
                      appSetState={props.appSetState}
                      openPopUp={props.openPopUp}
                      client_id={props.client_id}
                      device={props.device}
                    />
                  : ''
                }
                </DialogContent>
            }
        </Box>
      }
      <Menu
        id={`ticket-menu-id-${props.ticket.id}`}
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorMenu}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          key={`ticket_menu_item-remove-${props.ticket.id}`}
          onClick={(e) => {
            if (ticketInfo) {
              setEditTicket(true);
            }
            setAnchorMenu(null);
          }}
          className="control-menu-item-wrapper"
        >
          {t("edit")}
        </MenuItem>
        <MenuItem
          key={`ticket_menu_item-notify-${props.ticket.id}`}
          onClick={
            (e) => {
              setOpenApply(1);
              setApproveAction('close_ticket');
              handleClose();
            }
          }
          className="control-menu-item-wrapper"
        >
          {t("close")}
        </MenuItem>
        <MenuItem
          key={`ticket_menu_item-loan-${props.ticket.id}`}
          onClick={
            (e) => {
              setOpenApply(2);
              setApproveAction('remove_ticket');
              handleClose();
            }
          }
          className="control-menu-item-wrapper"
        >
          {t("remove")}
        </MenuItem>
      </Menu>
      <ApproveAction
        handleApprove={(e: React.MouseEvent<HTMLElement>) => closeTicket()}
        handleDecline={declineTicketCloseRemove}
        action={approveAction}
        show={openApply}
        obj_pk={1}
      />
      <ApproveAction
        handleApprove={(e: React.MouseEvent<HTMLElement>) => removeTicket()}
        handleDecline={declineTicketCloseRemove}
        action={approveAction}
        show={openApply}
        obj_pk={2}
      />
    </BootstrapDialog>
  )
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

type TicketQueryType = { [key: number]: any }

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function NonActiveTicketsTable(props: NonActiveTickectsArrProps) {
  const { t } = useTranslation(['tickets', 'common']);
  const [saveTicketEnable, setSaveTicketEnable] = React.useState<number>(0);
  const [ticketAttendenceShow, setTicketAttendenceShow] = React.useState<TicketQueryType>({});
  const [ticketDetailsShow, setTicketDetailsShow] = React.useState<TicketQueryType>({});

  useEffect(() => {
    let _obj_show: TicketQueryType = {};
    for (let t of props.non_active_tickets) {
      _obj_show[t.id] = false;
    }
    setTicketAttendenceShow(_obj_show);
    setTicketDetailsShow(_obj_show);
  }, [props.non_active_tickets]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t("manage.ticket")}</TableCell>
            <TableCell>{t("manage.date_start")} - {t("manage.expire_date")}</TableCell>
            <TableCell>{t("completed_drills")}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.non_active_tickets.map((ticket) => (
            <TableRow
              key={`non_active_ticket_${ticket.id}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell
                sx={{ "cursor": "pointer" }}
                onClick={(e) => {
                  console.log("Cell")
                  let att: TicketQueryType = {};
                  for (let k in ticketDetailsShow) {
                    if (parseInt(k) === ticket.id) {
                      att[parseInt(k)] = !ticketDetailsShow[parseInt(k)];
                    } else {
                      att[parseInt(k)] = false;
                    }
                  }
                  setTicketDetailsShow(att);
                }}
              >
                <Typography>
                  {ticket.ticket.ticket}
                </Typography>
                {ticketDetailsShow[ticket.id]
                  ? <UserTicketCommonInfoDialog ticketInfo={ticket} />
                  : <></>
                }
              </TableCell>
              <TableCell>
                <TextField
                  label={t("manage.date_start")}
                  defaultValue={ticket.start_date}
                  sx={{ marginTop: '10px' }}
                  type='date'
                  onChange={(e) => {
                    setSaveTicketEnable(ticket.id);
                    ticket.start_date = e.target.value;
                  }}
                />
                <TextField
                  label={t("manage.expire_date")}
                  defaultValue={ticket.expire_date}
                  sx={{ marginTop: '10px' }}
                  type='date'
                  onChange={(e) => {
                    setSaveTicketEnable(ticket.id);
                    ticket.expire_date = e.target.value
                  }}
                />
              </TableCell>
              <TableCell align="center">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography>{ticket.completed_drills.count}</Typography>
                  <IconButton
                    aria-label="show"
                    onClick={(e) => {
                      let att: TicketQueryType = {};
                      for (let k in ticketAttendenceShow) {
                        if (parseInt(k) === ticket.id) {
                          att[parseInt(k)] = !ticketAttendenceShow[parseInt(k)];
                        } else {
                          att[parseInt(k)] = false;
                        }
                      }
                      setTicketAttendenceShow(att);
                    }}
                  >
                    {!ticketAttendenceShow[ticket.id]
                      ? <FactCheckIcon />
                      : <CloseIcon />
                    }
                    
                  </IconButton>
                </Box>
                {ticketAttendenceShow[ticket.id]
                  ? <List>
                    {ticket.completed_drills.schedule.map((drill) => {
                      return (
                        <ListItem key={`attend-drill-${drill.id}`}>
                          {drill.drill_date} {drill.drill_time}
                        </ListItem>
                      )
                    })}
                    </List>
                  : ''
                }
              </TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Switch
                    onClick={
                      (e) => {
                        switch(props.key_name) {
                          case 'expired': {
                            ticket.expired = false;
                            break;
                          }
                          case 'active': {
                            ticket.active = true;
                            break;
                          }
                          case 'frozen': {
                            ticket.frozen = false;
                            break;
                          }
                        }
                        setSaveTicketEnable(ticket.id);
                      }
                    }
                  />
                  <Button
                    variant="outlined"
                    disabled={ticket.id !== saveTicketEnable}
                    onClick={(e) => {props.manage_ticket(ticket)}}
                  >
                    {t('save', { ns: 'common' })}
                  </Button>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export function NonActiveUserTicketsInfo(props: NonActiveUserTicketsInfoProps) {
  const { t } = useTranslation('tickets');
  const [value, setValue] = React.useState<number>(0);
  const ticketQuery: TicketQueryType = {
    0: {expired: 1, user: props.user_id},
    1: {active: 'False', user: props.user_id},
    2: {frozen: 'True', user: props.user_id},
  }
  const [nonActiveTickets, setNonActiveTickets] = React.useState<UserTicketFull[]>([]);
  const [spinner, setSpinner] = React.useState<boolean>(false);

  const getNonActiveTickets = async () => {
    const api = new BaseApi(1, 'user/user_tickets/');
    setSpinner(true);
    let res = await api.get(ticketQuery[value], props.appSetState, props.appState)
    if (res.status === 200) {
      let non_active_tickets_arr = [];
      for (let t of res.body) {
        non_active_tickets_arr.push(t as UserTicketFull);
      };
      setNonActiveTickets(non_active_tickets_arr);
    };
    setSpinner(false);
  }

  useEffect(() => {
    if (!props.show) { return }
    if (props.user_id === 0 || props.user_id !== props.show) { return }
    getNonActiveTickets();
  }, [props.show]);

  useEffect(() => {
    if (!props.show) { return }
    if (props.user_id === 0 || props.user_id !== props.show) { return }
    getNonActiveTickets();
  }, [value]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const updateTicket = async (ticket: NonActiveUserTicket) => {
    setSpinner(true);
    if (ticket.active && !ticket.expired && !ticket.frozen) {
      ticket.expired = false;
      await props.manage_user_tickets(
        props.user_id,
        ticket,
        {
          id: ticket.id,
          ticket: ticket.ticket.id,
          payed: ticket.payed,
          start_date: ticket.start_date,
          expire_date: ticket.expire_date,
          active: ticket.active,
          frozen: ticket.frozen,
          expired: ticket.expired,
          user: props.user_id,
          ticket_name: ticket.ticket.ticket,
        } as UserTicketManageRequestBody,
        true,
      );
      setNonActiveTickets(nonActiveTickets.filter(t => t.id != ticket.id));
    };
    setSpinner(false);
  };
  
  return (
    <WidthBootstrapDialog
      onClose={(e) => {props.handleClose(props.user_id)}}
      open={props.show === props.user_id}
    >
      <DialogTitle sx={{ paddingLeft: '5px' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label={t("expired")} {...a11yProps(0)} />
            <Tab label={t("non_active")} {...a11yProps(1)} />
            <Tab label={t("frozen")} {...a11yProps(2)} />
          </Tabs>
        </Box>
      </DialogTitle>
      {spinner
        ? <DialogContent dividers className="box-content-center">
            <CircularProgress color="inherit" />
          </DialogContent>
        : <DialogContent dividers className="box-content-center">
            <CustomTabPanel value={value} index={0}>
              <NonActiveTicketsTable
                non_active_tickets={nonActiveTickets}
                manage_ticket={updateTicket}
                key_name={'expired'}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <NonActiveTicketsTable
                non_active_tickets={nonActiveTickets}
                manage_ticket={updateTicket}
                key_name={'active'}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <NonActiveTicketsTable
                non_active_tickets={nonActiveTickets}
                manage_ticket={updateTicket}
                key_name={'frozen'}
              />
            </CustomTabPanel>
          </DialogContent>
      }
    </WidthBootstrapDialog>
  )
}
