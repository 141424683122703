import { atom } from 'recoil';

import { UserInfoCommon } from '../interfaces/AccountInfo';

export interface IEmployeesStaffState {
  items: UserInfoCommon[]
}

const employeesStaffAtom = atom<IEmployeesStaffState>({
  key: 'employeesStaff',
  default: {
    items: [],
  },
})

export { employeesStaffAtom }
