export function builtISODate(date: Date): string {
  let year = String(date.getFullYear());
  let month = String(date.getMonth() + 1);
  if (month.length === 1) {
    month = `0${month}`;
  }
  let day = String(date.getDate());
  if (day.length === 1) {
    day = `0${day}`;
  }

  return `${year}-${month}-${day}`
}

export function buildISODateTime(date: Date): string {
  let _date: string = builtISODate(date);
  let _hours: string = date.getHours().toString();
  let _minutes: string = date.getMinutes().toString();
  let _seconds: string = date.getSeconds().toString();
  if (_hours.length === 1) { _hours = `0${_hours}` }
  if (_minutes.length === 1) { _minutes = `0${_minutes}` }
  if (_seconds.length === 1) { _seconds = `0${_seconds}` }

  return `${_date}T${_hours}:${_minutes}:${_seconds}`
}
