import React, { useEffect } from 'react';

import Markdown from 'react-markdown'

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import { useTranslation } from 'react-i18next';

import { builtISODate } from '../../helpers';

import { IComment, ICommentUser } from '../../interfaces/Comments';
import { BaseProps } from '../../interfaces/Props';

interface ICommentProps extends BaseProps {
  message: IComment;
  users: ICommentUser[];
}

export function ChatMessage(props: ICommentProps) {
  const { t } = useTranslation('chat');

  const author = props.users.filter(u => u.outside_id === props.message.author_id)[0]

  const today = new Date();
  
  return (
    <Box
      key={`message-key-${props.message.pk}`}
      sx={{
        display: "flex",
        justifyContent: (props.message.author_id === props.appState.account_info.id) ? "flex-end" : "flex-start",
        mb: 2,
        marginLeft: (props.message.author_id === props.appState.account_info.id) ? "auto" : "20px",
        marginRight: (props.message.author_id === props.appState.account_info.id) ? "20px" : "auto",
        width: (props.device === "mobile") ? "75%" : "50%",
      }}
    >
      <Paper
        variant="outlined"
        sx={{
          p: 2,
          backgroundColor: (props.message.author_id !== props.appState.account_info.id) ? "primary.light" : "secondary.light",
          borderRadius: (props.message.author_id !== props.appState.account_info.id) ? "20px 20px 20px 5px" : "20px 20px 5px 20px",
          width: '100%',
        }}
      >
        <Typography
          sx={{
            fontSize: '12px',
          }}
        >
          {author?.first_name} {author?.last_name}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            textAlign: (props.message.author_id === props.appState.account_info.id) ? "end" : "start",
            width: '100%',
            wordWrap: 'break-word',
            pointerEvents: 'none',
          }}
        >
          <Markdown>{props.message.message}</Markdown>
        </Typography>
        <Typography>
          {props.message.edited
            ? t('edited')
            : ''
          }
        </Typography>
        <Typography
          sx={{
            fontSize: '10px',
          }}
        >
          {today.setHours(0, 0, 0, 0) === new Date(props.message.created_at).setHours(0, 0, 0, 0)
            ? t('today')
            : builtISODate(new Date(props.message.created_at))
          }
        </Typography>
      </Paper>
    </Box>
  )
}
