import { atom, selectorFamily, useRecoilState } from 'recoil'

import { BaseApi } from '../api/base';

import { ICoachSalary } from '../interfaces/SalariesStat';
import { BodyObj } from '../interfaces/Types';

import { spinnerAtom } from './Spinner'

export const salaryStatArgsAtom = atom({
  key: 'salaryStatArgs',
  default: {
    date_start: '',
    date_stop: '',
    club_unit: 0,
  },
})

export interface ISalaryState {
  items: ICoachSalary[]
}

export const salaryStatSelector = selectorFamily({
    key: 'salaryStatSelector',
    get: (body: BodyObj) => async ({ get }): Promise<ISalaryState> => {
      // let body = get(salaryStatArgsAtom)
      if (
        body.date_start === '' || body.date_stop === '' || body.club_unit === 0
      ) {
        return { items: [] };
      }
      const api = new BaseApi(2, 'management/coaches/salary/count/');
      let res = await api.post(
        {
          club_unit: body.club_unit,
          date_start: body.date_start,
          date_stop: body.date_stop,
          except_tickets: body.except_tickets,
        },
        'application/json',
        () => {},
        {},
      );
      if (res.status === 200) {
        return { items: res.body.salaries };
      };
      return { items: [] };
    },
});

// export const salaryStatAtom = atom<ISalaryState>({
//   key: 'salaryStat',
//   default: salaryStatSelector,
// })
