import React, { useEffect } from "react";

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

import { useTranslation } from 'react-i18next';

import { ClientDebtsProps } from "../../interfaces/Props";
import { ClientDebts } from "../../interfaces/ClientDebts";

import { theme as root_theme } from "../../consts";

import { BaseApi } from "../../api/base";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    backgroundColor: root_theme.palette.background.default,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: root_theme.palette.info.main,
    width: '80%',
    maxWidth: '80%',
  },
  '& .MuiTableContainer-root': {
    width: '100%',
    maxWidth: '100%',
  }
}));

export function ClientDebtsRender(props: ClientDebtsProps) {
  const { t } = useTranslation('tables');
  const [debts, setDebts] = React.useState<ClientDebts[]>([]);
  const [spinner, setSpinner] = React.useState<boolean>(false);

  const getDebts = async () => {
    setSpinner(true);
    const api = new BaseApi(1, 'management/client/debts/');
    let res = await api.get({client_id: props.client_id}, props.appSetState, props.appState);
    if (!res.body.length) {
      setSpinner(false);
      return
    }
    setDebts(res.body);
    setSpinner(false);
  }

  useEffect(() => {
    if (props.show !== props.client_id) { return }
    getDebts();
  }, [props.show]);

  return (
    <BootstrapDialog
      onClose={(e) => {props.handleClose(props.client_id)}}
      open={props.show === props.client_id}
    >
      <DialogTitle sx={{ paddingLeft: '5px' }}>
        {t("debts.debts")}
      </DialogTitle>
      {spinner
        ? <DialogContent dividers className="box-content-center">
            <CircularProgress color="inherit" />
          </DialogContent>
        : <DialogContent dividers className="box-content-center">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("debts.name")}</TableCell>
                    <TableCell>{t("debts.date")}</TableCell>
                    <TableCell>{t("debts.price")}({t("debts.payed")})</TableCell>
                    <TableCell>{t("debts.categories")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {debts.map((debt) => (
                    <TableRow
                      key={`client_debt_${debt.id}`}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{debt.item}</TableCell>
                      <TableCell>{debt.start_date} - {debt.expire_date}</TableCell>
                      <TableCell>{debt.price}({debt.payed_amount})</TableCell>
                      <TableCell>{debt.categories?.join(',')}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
      }
    </BootstrapDialog>
  )
}
