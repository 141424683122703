import { atom, selectorFamily } from 'recoil';

export interface IPopupState {
  title: string;
  message: any;
  non_translatable: boolean;
  show: boolean;
}

export const popUpAtom = atom<IPopupState>({
  key: 'popUp',
  default: {
    title: '',
    message: '',
    non_translatable: false,
    show: false,
  },
})

export const popUpAtomSelect = selectorFamily({
  key: 'popUpSelect',
  get: () => ({get}) => {
    const popUp = get(popUpAtom);
    return popUp
  },
  set: (n) => ({set}, newValue) => {
    setTimeout(
      () => {
        set(popUpAtom, newValue);
        if (newValue.show) {
          set(
            popUpAtom,
            {title: '', message: '', non_translatable: false, show: false},
          )
        }
      },
      3000,
    )
  },
})
