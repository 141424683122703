import React from "react";
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";

import { useRecoilValue } from 'recoil';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import Popper from '@mui/material/Popper';

import MenuIcon from '@mui/icons-material/Menu';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SportsIcon from '@mui/icons-material/Sports';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DraftsIcon from '@mui/icons-material/Drafts';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EditNoteIcon from '@mui/icons-material/EditNote';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import SmsIcon from '@mui/icons-material/Sms';
import LogoutIcon from '@mui/icons-material/Logout';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { useTranslation } from 'react-i18next';

import { MenuItem, ToolboxProps } from '../../interfaces/Props';

import { accountInfoAtom } from '../../atoms/AccountInfo';

export function Toolbox(props: ToolboxProps) {
  let navigate = useNavigate();
  const { t } = useTranslation('menu');
  const [state, setState] = useState({menu: false});
  const menu: MenuItem[] = [
    {text: 'profile', icon: <PersonIcon />, neededPermissions: []},
    {text: 'club', icon: <OtherHousesIcon />, neededPermissions: ['view_club']},
    {
      text: 'statistic',
      icon: <QueryStatsIcon />,
      neededPermissions: [
        'get_statistic',
        'get_coach_statistic',
        'get_tickets_statistic',
        'get_bids_statistic',
        'get_purchase_statistic',
        'get_box_office_statistic',
        'get_managers_statistic',
        'get_new_ticket_type_statistic',
      ]
    },
    {text: 'clients', icon: <GroupIcon />, neededPermissions: ['get_clients_list']},
    {text: 'coaches', icon: <SportsIcon />, neededPermissions: ['get_coaches_list']},
    {text: 'calendar', icon: <CalendarMonthIcon />, neededPermissions: ['get_calendar_list']},
    {text: 'bids', icon: <DraftsIcon />, neededPermissions: ['view_bid']},
    {text: 'time_shifting', icon: <HourglassEmptyIcon />, neededPermissions: ['change_managershift']},
    {text: 'service_items', icon: <ShoppingCartCheckoutIcon />, neededPermissions: ['get_service_and_items']},
    {text: 'notifications', icon: <NotificationsIcon />, neededPermissions: ['view_notification']},
    {text: 'ticket_edit_request', icon: <EditNoteIcon />, neededPermissions: ['view_userticketchange']},
    {text: 'market', icon: <StorefrontIcon />, domain: process.env.REACT_APP_OLD_URI, neededPermissions: ['view_market']},
    {text: 'rent', icon: <VpnKeyIcon />, domain: process.env.REACT_APP_OLD_URI, neededPermissions: ['get_rent_info']},
  ];
  const [anchorShiftingTime, setAnchorShiftingTime] = React.useState<HTMLButtonElement | null>(null);
  const showShiftingTime: boolean = Boolean(anchorShiftingTime);
  const accountInfo = useRecoilValue(accountInfoAtom);

  const toggleDrawer =
    (open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, menu: open });
  };

  const menuArr = () => (
    <Box
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {
          menu.filter(
            m => (
              !m.neededPermissions.length || m.neededPermissions.filter(p => accountInfo.account_info.permissions.includes(p)).length
            ) && !process.env.REACT_APP_EXCEPT_PAGES?.split(",").includes(m.text)
          ).map((menu_item, index) => (
            <ListItem key={menu_item.text} disablePadding>
              <ListItemButton>
                <Button href={`${(menu_item.domain) ? menu_item.domain : ''}/${menu_item.text}${(menu_item.domain) ? `?access_token=${localStorage.getItem('access_token')}` : ''}`} startIcon={menu_item.icon}>
                  {t(menu_item.text)}
                </Button>
              </ListItemButton>
            </ListItem>
          ))
        }
      </List>
    </Box>
  );

  return (
    <div>
      <Box sx={{ flexGrow: 1 }} className='toolbox-wrapper'>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <div className='toolbox-logo-container'>
              <Link href="/">
                <img src="/logo_2.png" alt="ARES" className="toolbox-logo" />
              </Link>
            </div>
            <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{ display: 'flex' }}
            >
              {accountInfo.account_info.permissions.includes('start_stop_managers_shifts')
                ? <IconButton
                    size="large"
                    aria-label="control timer"
                    color="inherit"
                    aria-describedby='time-shifting-icon-id'
                    onClick={() => {props.handleShiftControl()}}
                    onMouseEnter={
                      (event: React.MouseEvent<HTMLElement>) => {
                        if (!props.currentShift) { return }
                        setAnchorShiftingTime(event.currentTarget);
                      }
                    }
                    onMouseLeave={() => {
                      setAnchorShiftingTime(null);
                    }}
                  >
                    <AccessTimeIcon
                      color={(props.currentShift) ? 'success' : 'error'}
                    />
                  </IconButton>
                : ''
              }
              {accountInfo.account_info.permissions.includes('start_stop_managers_shifts')
                ? <Popper
                    id='time-shifting-icon-id'
                    open={showShiftingTime}
                    anchorEl={anchorShiftingTime}
                  >
                    <Typography
                      sx={{
                        p: 2,
                        backgroundColor: 'var(--orange)',
                        color: 'var(--black)',
                      }}
                    >
                      {props.currentWorkingTime}
                    </Typography>
                  </Popper>
                : ''
              }
              <IconButton
                size="large"
                aria-label="show box office"
                color="inherit"
                onClick={(e) => {props.showBoxOffice()}}
              >
                <Badge badgeContent={0} color="error">
                  <PointOfSaleIcon />
                </Badge>
              </IconButton>
              <IconButton
                size="large"
                aria-label="show chat messages"
                color="inherit"
                onClick={(e) => {props.openChat()}}
                disabled={props.unreadChatsAmount === 0}
              >
                <Badge badgeContent={props.unreadChatsAmount} color="error">
                  <SmsIcon />
                </Badge>
              </IconButton>
              {accountInfo.account_info.permissions.includes('get_notifications_list')
                ? <IconButton
                    size="large"
                    aria-label="show notifications"
                    color="inherit"
                    onClick={() => {
                      navigate("/notifications")
                    }}
                  >
                    <Badge badgeContent={props.notifications.length} color="error">
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                : ''
              }
              <Button
                href="/profile"
                sx={{ color: "#fff", display: { xs: 'none', md: 'flex' } }}
              >
                {props.account_info.first_name}
              </Button>
              <IconButton
                size="large"
                edge="end"
                aria-label="logout"
                color="inherit"
                onClick={(e) => {props.signOut(e)}}
              >
                <LogoutIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
      <Drawer
        open={state.menu}
        onClose={toggleDrawer(false)}
      >
        {menuArr()}
      </Drawer>
    </div>
  )
}
