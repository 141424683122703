import React from "react";
import { useState } from 'react';

import { useRecoilValue } from 'recoil';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { StatisticPageProps } from "../../interfaces/Props";
import { CoachStatisticTab } from "./CoachStatistic";
import { TicketsStatisticTab } from "./CommonTikcetsStat";
import { PurchaseStatTab } from "./PurchaseStatTab";
import { BoxOfficeReport } from "./BoxOfficeReport";
import { BidsStatTab } from "./BidStatisticTab";
import { ManagersStatistic } from "./ManagerStatistic";
import { NewTicketStatisticTab } from "./NewTicketsStatistic";
import { SalaryStatistic } from './SalaryStat';
import { ExportStatistic } from "./ExportStat";

import { useTranslation } from 'react-i18next';

import { accountInfoAtom } from '../../atoms/AccountInfo';

interface AccordionInfo {
  header: 'ticket_stat' | 'coach_stat' | 'bid_stat' | 'sales_stat' | 'box_office_stat' | 'managers_stat' | 'new_tickets' | 'salary_stat' | 'export_stat',
  approveTypes: number[],
  neededPermission: string[],
}

const accordionsArr: readonly AccordionInfo[] = [
  { header: 'ticket_stat', approveTypes: [2, 3, 4], neededPermission: ['get_tickets_statistic', 'get_statistic'] },
  { header: 'coach_stat', approveTypes: [1, 2, 3, 4], neededPermission: ['get_coach_statistic', 'get_statistic'] },
  { header: 'bid_stat', approveTypes: [2, 3, 4], neededPermission: ['get_bids_statistic', 'get_statistic'] },
  { header: 'sales_stat', approveTypes: [2, 3, 4], neededPermission: ['get_purchase_statistic', 'get_statistic'] },
  { header: 'box_office_stat', approveTypes: [2, 3, 4], neededPermission: ['get_box_office_statistic', 'get_statistic'] },
  { header: 'managers_stat', approveTypes: [2, 3, 4], neededPermission: ['get_managers_statistic', 'get_statistic'] },
  { header: 'new_tickets', approveTypes: [2, 3, 4], neededPermission: ['get_new_ticket_type_statistic', 'get_statistic'] },
  { header: 'salary_stat', approveTypes: [3, 4], neededPermission: ['get_statistic'] },
  { header: 'export_stat', approveTypes: [3, 4], neededPermission: ['get_statistic'] },
]

export function StatisticPage(props: StatisticPageProps) {
  const { t } = useTranslation('statistic');
  const [expandedStat, setExpandedStat] = useState<string>('');
  const accountInfo = useRecoilValue(accountInfoAtom);

  return (
    <Box
      sx={{ padding: '5px' }}
    >
      {accordionsArr.map((accord_panel, index) => {
        if (accord_panel.neededPermission.filter(p => accountInfo.account_info.permissions.includes(p)).length) {
          return (
            <Accordion
              key={`stat-accordion-${index}`}
              sx={{
                backgroundColor: 'transparent',
                border: '1px var(--orange) solid',
              }}
              onChange={(event: React.SyntheticEvent, expanded: boolean) => {
                if (expandedStat === accord_panel.header) {
                  setExpandedStat('');
                } else {
                  setExpandedStat(accord_panel.header);
                }
              }}
              expanded={expandedStat === accord_panel.header}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id={`stat-accordion-id-${index}`}
              >
                <Typography>{t(accord_panel.header)}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {(() => {
                  switch (accord_panel.header) {
                    case 'coach_stat':
                      return (
                        <CoachStatisticTab
                          handleCloseSpinner={props.handleCloseSpinner}
                          handleOpenSpinner={props.handleOpenSpinner}
                          openPopUp={props.openPopUp}
                          departments={props.departments}
                          appState={props.appState}
                          appSetState={props.appSetState}
                          device={props.device}
                          statType={expandedStat}
                          sendSocketMessage={props.sendSocketMessage}
                        />
                      )
                    case 'ticket_stat':
                      return (
                        <TicketsStatisticTab
                          handleCloseSpinner={props.handleCloseSpinner}
                          handleOpenSpinner={props.handleOpenSpinner}
                          openPopUp={props.openPopUp}
                          departments={props.departments}
                          appState={props.appState}
                          appSetState={props.appSetState}
                          device={props.device}
                          statType={expandedStat}
                          sendSocketMessage={props.sendSocketMessage}
                        />
                      )
                    case 'sales_stat':
                      return (
                        <PurchaseStatTab
                          handleCloseSpinner={props.handleCloseSpinner}
                          handleOpenSpinner={props.handleOpenSpinner}
                          openPopUp={props.openPopUp}
                          departments={props.departments}
                          appState={props.appState}
                          appSetState={props.appSetState}
                          device={props.device}
                          statType={expandedStat}
                          sendSocketMessage={props.sendSocketMessage}
                        />
                      )
                    case 'box_office_stat':
                      return (
                        <BoxOfficeReport
                          handleCloseSpinner={props.handleCloseSpinner}
                          handleOpenSpinner={props.handleOpenSpinner}
                          openPopUp={props.openPopUp}
                          departments={props.departments}
                          appState={props.appState}
                          appSetState={props.appSetState}
                          device={props.device}
                          statType={expandedStat}
                          sendSocketMessage={props.sendSocketMessage}
                        />
                      )
                    case 'bid_stat':
                      return (
                        <BidsStatTab
                          handleCloseSpinner={props.handleCloseSpinner}
                          handleOpenSpinner={props.handleOpenSpinner}
                          openPopUp={props.openPopUp}
                          departments={props.departments}
                          appState={props.appState}
                          appSetState={props.appSetState}
                          device={props.device}
                          statType={expandedStat}
                          sendSocketMessage={props.sendSocketMessage}
                        />
                      )
                    case 'managers_stat':
                      return (
                        <ManagersStatistic
                          statType={expandedStat}
                        />
                      )
                    case 'new_tickets':
                      return (
                        <NewTicketStatisticTab
                          handleCloseSpinner={props.handleCloseSpinner}
                          handleOpenSpinner={props.handleOpenSpinner}
                          openPopUp={props.openPopUp}
                          departments={props.departments}
                          appState={props.appState}
                          appSetState={props.appSetState}
                          device={props.device}
                          statType={expandedStat}
                          sendSocketMessage={props.sendSocketMessage}
                        />
                      )
                    case 'salary_stat':
                      return (
                        <SalaryStatistic
                          statType={expandedStat}
                        />
                      )
                    case 'export_stat':
                      return (
                        <ExportStatistic
                          statType={expandedStat}
                        />
                      )
                    default:
                      return <Typography>
                        There will be statistic bars
                      </Typography>
                  }
                })()
                }
                
              </AccordionDetails>
            </Accordion>
          )
        }
      })}
    </Box>
  );
}
