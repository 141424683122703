import React, { useEffect } from "react";

import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import { useTranslation } from 'react-i18next';

import { NotificationCreateProps } from '../../interfaces/Props';
import { NotificationType } from '../../interfaces/Notification';
import { AutocompleteProps } from '../../interfaces/Props';
import { QueryObj } from "../../interfaces/Types";

import { BaseApi } from "../../api/base";

export function NotificationCreate(props: NotificationCreateProps) {
  const { t } = useTranslation('common');
  const [notifyTypes, setNotifyTypes] = React.useState<NotificationType[]>([]);
  const [choosenNotifyType, setChoosenNotifyType] = React.useState<number>(0);
  const [choosenNotifyDate, setChoosenNotifyDate] = React.useState<string>(new Date().toISOString().split('T')[0]);
  const [spinner, setSpinner] = React.useState<boolean>(false);

  const handleCreateNotification = async () => {
    if (choosenNotifyType === 0 || choosenNotifyDate === '') {
      props.openPopUp('error', 'notify_date_type');
      return
    }
    setSpinner(true);
    const api = new BaseApi(1, 'management/notifications/');
    let body: QueryObj = {
      message: '',
      notify_date: choosenNotifyDate,
      noty_type: choosenNotifyType,
    }
    if (props.client_id) {
      body.client = props.client_id;
    }
    if (props.bid_id) {
      body.bid = props.bid_id;
    }
    let res = await api.post(
      body,
      'application/json',
      props.appSetState,
      props.appState,
    );
    if (![200, 201].includes(res.status)) {
      props.openPopUp('error', 'failed_notify_create');
    }
    setSpinner(false);
    props.closeDialog();
  }

  const getNotifyTypes = async () => {
    setSpinner(true);
    const api = new BaseApi(1, 'management/notify_types/');
    let res = await api.get({}, props.appSetState, props.appState);
    if (res.status === 200) {
      setNotifyTypes(res.body)
    }
    setSpinner(false);
  }

  useEffect(() => {
    getNotifyTypes();
  }, [])
  
  return (
    <Box>
      {spinner
        ? <CircularProgress color="inherit" />
        : <Box>
            <Autocomplete
              id="notify-type-choice-id"
              options={
                notifyTypes.map((nt) => {
                  return {id: nt.id, label: nt.type}
                })
              }
              sx={{ width: "100%", marginBottom: "15px", zIndex: '100' }}
              renderInput={(params) => <TextField {...params} label={t("notify_type")} />}
              onChange={(
                e: React.SyntheticEvent,
                value: AutocompleteProps | null,
                reason: string,
              ) => {
                if (value && value.id) {
                  setChoosenNotifyType(parseInt(value.id.toString()));
                } else {
                  setChoosenNotifyType(0);
                }
              }}
            />
            <TextField
              required
              label={t('notify_date')}
              type='date'
              defaultValue={choosenNotifyDate}
              onChange={(e) => {setChoosenNotifyDate(e.target.value)}}
              sx={{width: '100%'}}
            />
            <Box
              sx={{
                width: 'fit-content',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '15px',
              }}
            >
              <Button
                variant="outlined"
                color="error"
                onClick={(e) => {props.closeDialog()}}
              >
                <CloseIcon />
              </Button>
              <Button
                variant="contained"
                color="info"
                onClick={(e) => {handleCreateNotification()}}
              >
                <CheckIcon />
              </Button>
            </Box>
          </Box>
      }
    </Box>
  )
}
