import React, { useEffect, FormEvent } from "react";

import { useRecoilValue } from 'recoil';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import { SxProps } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import InputBase from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Popover from '@mui/material/Popover';
import ListItemIcon from '@mui/material/ListItemIcon';
import DialogActions from '@mui/material/DialogActions';

import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import CheckIcon from '@mui/icons-material/Check';

import { useTranslation } from 'react-i18next';

import { CoachInfo } from "../../interfaces/Coach";
import { UserManage } from "./UserManage";
import { Register } from "../auth/Registration";
import { ClientsArrProps, AutocompleteProps } from "../../interfaces/Props";
import { CoachSkillInterface, UserTypeInterface } from "../../interfaces/Common";
import { ApproveAction } from "../common/ActionApprove";
import { QueryObj, BodyObj } from '../../interfaces/Types';
import { BaseFilters } from "../../interfaces/Filters";
import { ClubUnit } from "../../interfaces/Club";
import { AccountInfo } from "../../interfaces/AccountInfo";

import { theme as root_theme } from "../../consts";

import { BaseApi } from "../../api/base";

import { accountInfoAtom } from '../../atoms/AccountInfo';

interface Column {
  id: 'full_name' | 'coach_type' |  'contacts' | 'disciplines' | 'club_unit';
  minWidth?: number;
  align?: 'right';
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    backgroundColor: root_theme.palette.background.default,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: root_theme.palette.info.main,
  },
}));

const BootstrapDialogWidth = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    width: '90%',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    backgroundColor: root_theme.palette.background.default,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: root_theme.palette.info.main,
  },
  '& .MuiDialog-paper': {
    width: '70%',
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const columns: readonly Column[] = [
  { id: 'full_name', minWidth: 250 },
  { id: 'coach_type', minWidth: 150 },
  { id: 'contacts', minWidth: 150 },
  { id: 'disciplines', minWidth: 150 },
  { id: 'club_unit', minWidth: 220 },
];

export function Coaches(props: ClientsArrProps) {
  const { t } = useTranslation(['tables', 'forms', 'common', 'filters']);
  const accountInfo = useRecoilValue(accountInfoAtom);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [coachesCount, setCoachesCount] = React.useState<number>(0);
  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null);
  const [openMenu, setOpenMenu] = React.useState<number>();
  const [coaches, setCoaches] = React.useState<CoachInfo[]>([]);
  const [anchorPopover, setAnchorPopover] = React.useState<HTMLElement | null>(null);
  const [newCoach, setNewCoach] = React.useState<boolean>(false);
  const [editCoach, setEditCoach] = React.useState<number>(0);
  const [showNonActive, setShowNonActive] = React.useState<number>(0);
  const [removeUser, setRemoveUser] = React.useState<number>(0);
  const [coachesQuery, setCoachesQuery] = React.useState<QueryObj>(
    {
      limit: rowsPerPage,
      offset: rowsPerPage * page,
      club_unit: (props.clubUnitId) ? props.clubUnitId : '',
    }
  );
  const [filters, setFilters] = React.useState<BaseFilters>(
    {
      clubs: [],
      club_units: [],
      disciplines: [],
    }
  );
  const [filtersSpinner, setFiltersSpinner] = React.useState<boolean>(false);
  const [coachesSearch, setCoachesSearch] = React.useState<string>('');
  const [showFilters, setShowFilters] = React.useState<boolean>(props.device === 'desktop');
  const [firstRender, setFirstRender] = React.useState<boolean>(true);
  const [coachSkills, setCoachSkills] = React.useState<CoachSkillInterface[]>([]);
  const [userTypes, setUserTypes] = React.useState<UserTypeInterface[]>([]);
  
  const [newCoachSelect, setNewCoachSelect] = React.useState<string>('exist_user');
  const [newCoachFromUser, setNewCoachFromUser] = React.useState<BodyObj>({});
  const [searchString, setSearchString] = React.useState<string>('');
  const [users, setUsers] = React.useState<AccountInfo[]>([]);
  const [anchorUsersList, setAnchorUsersList] = React.useState<null | HTMLElement>(null);
  const showUsers: boolean = Boolean(anchorUsersList);
  const [selectedUser, setSelectedUser] = React.useState<AccountInfo | null>(null);

  const getCoachSkills = async () => {
    const api = new BaseApi(1, 'management/coach_skill_choices/');
    let res = await api.get({}, props.appSetState, props.appState);
    if (res.status === 200) {
      setCoachSkills(res.body);
    }
  }

  const getUserTypes = async () => {
    const api = new BaseApi(1, 'user/types/');
    let res = await api.get({}, props.appSetState, props.appState);
    if (res.status === 200) {
      setUserTypes(res.body.map((ut: any) => {return ut as UserTypeInterface}));
    }
  }

  const getCoaches = async () => {
    const api = new BaseApi(1, 'management/get_coaches/');
    props.handleOpenSpinner();
    await getCoachSkills();
    let res = await api.get(coachesQuery, props.appSetState, props.appState);
    if (res.status === 200) {
      let _coaches: CoachInfo[] = [];
      let _coach_club_units: ClubUnit[] = [];
      for (let ch of res.body.results) {
        _coach_club_units = [];
        for (let c of ch.clubs) {
          _coach_club_units = _coach_club_units.concat(c.units);
        }
        _coaches.push(
          {
            id: ch.id,
            first_name: ch.first_name,
            last_name: ch.last_name,
            patronymic: ch.patronymic,
            phone: ch.phone,
            email: ch.email,
            disciplines: ch.disciplines,
            text_skill: ch.text_skill,
            facebook: ch.facebook,
            instagram: ch.instagram,
            vk: ch.vk,
            whatsapp: ch.whatsapp,
            sketches: ch.sketches,
            club_units: _coach_club_units,
            type: ch.type,
          } as CoachInfo
        )
      }
      setCoaches(_coaches);
      setCoachesCount(res.body.count);
      setFirstRender(false);
    };
    props.handleCloseSpinner();
  };

  const getFilters = async () => {
    const api = new BaseApi(1, 'management/filters_info/');
    setFiltersSpinner(true);
    let res = await api.get({}, props.appSetState, props.appState);
    if (res.status === 200) {
      let _cus = [];
      for (let c of res.body.clubs) {
        _cus.push(...c.units.map((unit: ClubUnit) => {return {id: unit.id, name: unit.name}}));
      };
      res.body.club_units = _cus;
      setFilters(res.body);
    }
    setFiltersSpinner(false);
  };

  const createUser = async (data: FormData) => {
    props.handleOpenSpinner();
    const api = new BaseApi(1, `user/account/`);
    let res = await api.put(
      data, 'multipart/form-data', props.appSetState, props.appState
    );
    if (res.status === 201) {
      setNewCoachFromUser({ ...newCoachFromUser, coach: res.body.id });
      // res.body.club_units = filters.club_units.filter(cu => newCoachFromUser.units.includes(cu.id));
      // setCoaches([res.body].concat(coaches));
      // setCoachesCount(coachesCount + 1);
    } else {
      setNewCoachFromUser({});
    }
    setNewCoach(false);
    props.handleCloseSpinner();
  };

  useEffect(() => {
    getCoaches();
  }, [coachesQuery]);

  useEffect(() => {
    if (firstRender) { return }
    setCoachesQuery(
      {...coachesQuery, offset: rowsPerPage * page}
    );
  }, [page]);

  useEffect(() => {
    if (firstRender) { return }
    setCoachesQuery(
      {...coachesQuery, limit: rowsPerPage, offset: rowsPerPage * page}
    );
  }, [rowsPerPage]);

  useEffect(() => {
    getFilters();
    getUserTypes();
  }, []);

  useEffect(() => {
    if (!newCoachFromUser.units || !newCoachFromUser.coach) { return }
    if (newCoachSelect === 'exist_user') { return }
    createNewCoach();
  }, [newCoachFromUser]);

  useEffect(() => {
    setShowFilters(props.device === 'desktop');
  }, [props.device]);

  useEffect(() => {
    if (!coachesSearch) { return }
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        setCoachesQuery(
          {...coachesQuery, search: encodeURIComponent(coachesSearch)}
        );
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [coachesSearch]);

  useEffect(() => {
    if (!searchString) { return }
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        searchUsers();
        let elem = document.getElementById('users-videoconf-search');
        setAnchorUsersList(anchorUsersList ? null : elem);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [searchString]);
  
  const handleClick = (event: React.MouseEvent<HTMLElement>, coach_id: number) => {
    setOpenMenu(coach_id);
    setAnchorMenu(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorMenu(null);
    setOpenMenu(0);
  };
  
  const handleClosePopover = () => {
    setAnchorPopover(null);
  };
  
  const handleCloseNewCoach = () => {
    setNewCoach(false);
  };

  const handleCloseCoachEdit = () => {
    setEditCoach(0);
  };

  const handleCloseNonActive = () => {
    setShowNonActive(0);
  };

  const searchUsers = async () => {
    props.handleOpenSpinner();
    const api = new BaseApi(1, 'management/get_clients/');
    let res = await api.get(
      {limit: 5, offset: 0, search: encodeURIComponent(searchString)},
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      setUsers(res.body.results);
    };
    props.handleCloseSpinner();
  };

  const createNewCoach = async () => {
    props.handleOpenSpinner();
    let new_coach = await updateCoachByUserId(newCoachFromUser);
    let _choosen_units: number[] = [];
    if (newCoachFromUser.units) {
      _choosen_units = newCoachFromUser.units as number[];
    }
    let _coaches = coaches;
    _coaches.push(
      {
        id: new_coach.body.coach.id,
        first_name: new_coach.body.coach.first_name,
        last_name: new_coach.body.coach.last_name,
        patronymic: new_coach.body.coach.patronymic,
        phone: new_coach.body.coach.phone,
        email: new_coach.body.coach.email,
        disciplines: new_coach.body.coach.disciplines,
        text_skill: new_coach.body.coach.text_skill,
        facebook: new_coach.body.coach.facebook,
        instagram: new_coach.body.coach.instagram,
        vk: new_coach.body.coach.vk,
        whatsapp: new_coach.body.coach.whatsapp,
        sketches: new_coach.body.coach.sketches,
        club_units: filters.club_units.filter(cu => _choosen_units.includes(cu.id)),
        type: new_coach.body.coach.type,
      } as CoachInfo
    );
    setNewCoach(false);
    setSelectedUser(null);
    setNewCoachFromUser({});
    props.handleCloseSpinner();
  }

  const updateCoachByUserId = async (data: BodyObj) => {
    const api = new BaseApi(1, 'user/coach/slug/');
    let res = await api.post(data, 'application/json', props.appSetState, props.appState);
    return res
  };

  const manageCoach = async (coach: CoachInfo) => {
    props.handleOpenSpinner();
    if (coach.choosen_club_units_ids !== undefined && coach.choosen_club_units_ids !== null) {
      await updateCoachByUserId({coach: coach.id, units: coach.choosen_club_units_ids});
    }
    const api = new BaseApi(1, `user/account/${coach.id}/`);
    let res = await api.post(
      coach,
      'multipart/form-data',
      props.appSetState,
      props.appState,
    );
    if (res.status === 200) {
      let _coach_club_units: ClubUnit[] = [];
      for (let c of res.body.clubs) {
        _coach_club_units = _coach_club_units.concat(c.units);
      }
      let _coaches: CoachInfo[] = [];
      if (coach.type < 1) {
        _coaches = coaches.filter((ch) => {return ch.id !== coach.id});
      } else {
        _coaches = coaches.map((ch) => {
          if (ch.id === coach.id) {
            return {
              id: coach.id,
              first_name: coach.first_name,
              last_name: coach.last_name,
              patronymic: coach.patronymic,
              phone: coach.phone,
              email: coach.email,
              disciplines: coach.disciplines,
              text_skill: coach.text_skill,
              facebook: coach.facebook,
              instagram: coach.instagram,
              vk: coach.vk,
              whatsapp: coach.whatsapp,
              sketches: coach.sketches,
              club_units: _coach_club_units,
              type: coach.type,
            } as CoachInfo
          } else {
            return ch
          }
        });
      }
      setCoaches(_coaches);
    }
    setEditCoach(0);
    props.handleCloseSpinner();
  };
  const closeManageClient = () => {
    setEditCoach(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleListItemClick = (e: React.MouseEvent<HTMLElement>, pk: number) => {
    setAnchorPopover(e.currentTarget);
  };

  const handleRemoveUser = async (user_id: number) => {
    const api = new BaseApi(1, `user/account/${user_id}/`);
    props.handleOpenSpinner();
    let res = await api.delete({}, props.appSetState, props.appState);
    if (res.status === 200) {
      setCoaches(coaches.filter(ch => ch.id !== user_id));
      setCoachesCount(coachesCount - 1);
    };
    setRemoveUser(0);
    props.handleCloseSpinner();
  };
  const handleDeclineRemoveUser = () => {
    setRemoveUser(0);
  };

  const handleRegisterUser = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    data.append("type", '1');
    createUser(data);
  };
  
  return (
    <Box className="table-wrapper">
      {!props.clubUnitId
        ? <Box>
            <Paper
              sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: (props.device === 'desktop') ? '50%' : '95%',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: '5px',
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={t('search_placeholder', { ns: 'common' })}
                inputProps={{ 'aria-label': 'search google maps' }}
                onChange={(e) => {setCoachesSearch(e.target.value)}}
              />
              <IconButton
                type="button"
                sx={{ p: '10px' }}
                aria-label="search"
                onClick={
                  (e) => {
                    setCoachesQuery(
                      {...coachesQuery, search: encodeURIComponent(coachesSearch)}
                    );
                  }
                }
              >
                <SearchIcon />
              </IconButton>
            </Paper>
            <Box sx={{ marginBottom: '5px' }}>
              {props.device === 'mobile'
                ? <Box
                    sx={{
                      width: 'fit-content',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => setShowFilters(!showFilters)}
                    >
                      {t('filters', { ns: 'filters' })}
                    </Button>
                  </Box>
                : ''
              }
              {filtersSpinner
                ? <Box
                    sx={{
                      width: 'fit-content',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    <CircularProgress color="inherit" />
                  </Box>
                : <Box>
                    {showFilters
                      ? <Box
                          sx={{
                            display: (props.device === 'desktop') ? 'flex' : 'block',
                            justifyContent: 'space-between',
                            width: (props.device === 'desktop') ? '95%' : '70%',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                          }}
                        >
                          <Autocomplete
                            disablePortal
                            id="coaches-club-filter-id"
                            options={
                              filters.clubs.map(
                                (club) => {return {id: club.id, label: club.name}}
                              )
                            }
                            onChange={
                              (
                                e: React.SyntheticEvent,
                                value: AutocompleteProps | null,
                              ) => {
                                if (value && value.id) {
                                  setCoachesQuery({...coachesQuery, club: value.id});
                                } else {
                                  delete coachesQuery.club;
                                  setCoachesQuery(coachesQuery);
                                }
                              }
                            }
                            sx={{ width: (props.device === 'mobile') ? '100%' : '13%' }}
                            renderInput={
                              (params: any) => <TextField
                                {...params}
                                label={t("club", { ns: 'filters' })}
                              />
                            }
                          />
                          <Autocomplete
                            disablePortal
                            id="coaches-club_unit-filter-id"
                            options={
                              filters.club_units.map(
                                (cu) => {return {id: cu.id, label: cu.name}}
                              )
                            }
                            onChange={
                              (
                                e: React.SyntheticEvent,
                                value: AutocompleteProps | null,
                              ) => {
                                if (value && value.id) {
                                  setCoachesQuery({...coachesQuery, club_unit: value.id});
                                } else {
                                  delete coachesQuery.club_unit;
                                  setCoachesQuery(coachesQuery);
                                }
                              }
                            }
                            sx={{ width: (props.device === 'mobile') ? '100%' : '13%' }}
                            renderInput={
                              (params: any) => <TextField
                                {...params}
                                label={t("club_unit", { ns: 'filters' })}
                              />
                            }
                          />
                          <Autocomplete
                            disablePortal
                            id="coaches-disciplines-filter-id"
                            options={
                              filters.disciplines.map(
                                (discipline) => {return {id: discipline.id, label: discipline.name}}
                              )
                            }
                            onChange={
                              (
                                e: React.SyntheticEvent,
                                value: AutocompleteProps | null,
                              ) => {
                                if (value && value.id) {
                                  setCoachesQuery({...coachesQuery, discipline: value.id});
                                } else {
                                  delete coachesQuery.discipline;
                                  setCoachesQuery(coachesQuery);
                                }
                              }
                            }
                            sx={{ width: (props.device === 'mobile') ? '100%' : '13%' }}
                            renderInput={
                              (params: any) => <TextField
                                {...params}
                                label={t("discipline", { ns: 'filters' })}
                              />
                            }
                          />
                        </Box>
                      : ''
                    }
                  </Box>
              }
            </Box>
          </Box>
        : ''
      }
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: '74vh' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={`cell-${column.id}`}
                    align={column.align}
                    style={{ minWidth: column.minWidth, backgroundColor: 'var(--orange)' }}
                  >
                    {t(`heads.${column.id}`)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {coaches.map((coach, index) => {
                return (
                  <TableRow tabIndex={-1} key={`row-key-${index}`}>
                    <TableCell key={`key-full_name-${index}`}>
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={openMenu === coach.id ? 'long-menu' : undefined}
                        aria-expanded={openMenu === coach.id ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={(e) => {handleClick(e, coach.id)}}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      {coach.first_name} {coach.last_name}
                    </TableCell>
                    <TableCell key={`key-ticket-${index}`}>
                      <Typography>{coach.text_skill}</Typography>
                    </TableCell>
                    <TableCell key={`key-contacts-${index}`}>
                      <Typography>{coach.phone}</Typography>
                      <Typography>{coach.email}</Typography>
                    </TableCell>
                    <TableCell key={`key-disciplines-${index}`}>
                      <Typography variant='h6'>{t('body.coach_disciplines')}:</Typography>
                      <List component="nav" aria-label="secondary mailbox folder">
                        {coach.disciplines.map((discipline, index) => {
                          return (
                            <ListItemText
                              key={`coach-discipline-${coach.id}-${index}`}
                              primary={discipline}
                            />
                          )
                        })}
                      </List>
                    </TableCell>
                    <TableCell key={`key-club-units-${index}`}>
                      <Typography variant='h6'>{t('body.coach_club_units')}:</Typography>
                      <List component="nav" aria-label="secondary mailbox folder">
                        {coach.club_units.map((cu) => {
                          return (
                            <ListItemText
                              key={`coach-club-unit-${coach.id}-${cu.id}`}
                              primary={`- ${cu.name}`}
                            />
                          )
                        })}
                      </List>
                    </TableCell>
                    <Menu
                      id={`coach-menu-id-${index}`}
                      MenuListProps={{
                        'aria-labelledby': 'long-button',
                      }}
                      anchorEl={anchorMenu}
                      open={openMenu === coach.id}
                      onClose={handleClose}
                    >
                      <MenuItem
                        key={`menu_item-edit-${index}`}
                        onClick={(e) => {
                          setEditCoach(coach.id);
                          handleClose();
                        }}
                        className="control-menu-item-wrapper"
                      >
                        {t("actions.edit")}
                      </MenuItem>
                      <MenuItem
                        key={`menu_item-remove-${index}`}
                        onClick={
                          (e) => {
                            setRemoveUser(coach.id);
                            handleClose()
                          }
                        }
                        className="control-menu-item-wrapper"
                      >
                        {t("actions.remove")}
                      </MenuItem>
                    </Menu>
                    <BootstrapDialogWidth
                      key={`edit-client-${coach.id}`}
                      onClose={handleCloseCoachEdit}
                      open={editCoach === coach.id}
                    >
                      <DialogTitle sx={{ paddingLeft: '5px' }}>
                        {coach.first_name} {coach.last_name}
                      </DialogTitle>
                      <DialogContent dividers className="box-content-center">
                        <UserManage
                          client={coach}
                          departments={props.departments}
                          applyEdit={manageCoach}
                          closeEdit={closeManageClient}
                          device={props.device}
                          appState={props.appState}
                          appSetState={props.appSetState}
                          userTypes={userTypes}
                          isClient={false}
                          clubUnits={filters.club_units}
                          coachSkills={coachSkills}
                        />
                      </DialogContent>
                    </BootstrapDialogWidth>
                    <ApproveAction
                      handleApprove={handleRemoveUser}
                      handleDecline={handleDeclineRemoveUser}
                      action={'remove_coach'}
                      show={removeUser}
                      obj_pk={coach.id}
                    />
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={coachesCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {!props.clubUnitId
        ? <Zoom
            key="new-client-key"
            in={true}
          >
            <Fab sx={
                {
                  position: 'absolute',
                  bottom: 36,
                  left: 36,
                  borderColor: root_theme.palette.info.main,
                  borderWidth: '2px',
                  borderStyle: 'solid',
                  '&:hover': {
                    bgcolor: root_theme.palette.background.default,
                    borderColor: root_theme.palette.info.main,
                  },
                } as SxProps
              }
              aria-label="Add client"
              color='info'
              onClick={(e) => {setNewCoach(true)}}
            >
              <AddIcon />
            </Fab>
          </Zoom>
        : ''
      }
      <BootstrapDialog
        onClose={handleCloseNewCoach}
        open={newCoach}
      >
        <DialogTitle sx={{ paddingLeft: '5px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontWeight: '600', fontSize: '1.25em' }}>
              {t('register.coach_header', { ns: 'forms' })}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent
          dividers
          className="box-content-center"
        >
          <TabContext value={newCoachSelect}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={(e: React.SyntheticEvent, value: string) => {setNewCoachSelect(value)}}
                aria-label="choose new coach source"
              >
                <Tab label={t('choose.exist_user', { ns: 'forms' })} value="exist_user" />
                <Tab label={t('choose.new_user', { ns: 'forms' })} value="new_user" />
              </TabList>
            </Box>
            <TabPanel value="exist_user">
              <Box>
                <Autocomplete
                  disablePortal
                  disabled={!accountInfo.account_info.permissions.includes('manage_coaches')}
                  id="user-ticket-discipline-managing"
                  options={
                    filters.club_units.map((cu) => {
                      return {id: cu.id, label: cu.name}
                    })
                  }
                  sx={{ width: "100%", marginBottom: "15px" }}
                  renderInput={(params) => (<TextField {...params} label={t("club_unit", { ns: 'common' })} />)}
                  multiple={true}
                  onChange={(
                    e: React.SyntheticEvent,
                    value: Array<AutocompleteProps> | null,
                    reason: string,
                  ) => {
                    if (value && value.length) {
                      setNewCoachFromUser({ ...newCoachFromUser, units: value.map((v) => {return Number(v.id)}) });
                    } else {
                      setNewCoachFromUser({ ...newCoachFromUser, units: [] });
                    }
                  }}
                />
              </Box>
              <Paper
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  width: '95%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginBottom: '5px',
                  marginTop: '5px',
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  id="users-videoconf-search"
                  placeholder={t('search_placeholder', { ns: 'common' })}
                  inputProps={{ 'aria-label': 'search google maps' }}
                  onChange={(e) => {setSearchString(e.target.value)}}
                  value={searchString}
                />
                <IconButton
                  type="button"
                  sx={{ p: '10px' }}
                  aria-label="search"
                  onClick={
                    (e) => {
                      searchUsers();
                      let elem = document.getElementById('users-videoconf-search');
                      setAnchorUsersList(anchorUsersList ? null : elem);
                    }
                  }
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
              <Popover
                open={showUsers}
                anchorEl={anchorUsersList}
                onClose={(e) => {setAnchorUsersList(null)}}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
                  <List dense={false}>
                    {users.map((user) => {
                      return (
                        <ListItem
                          onClick={(e) => {
                            setNewCoachFromUser({ ...newCoachFromUser, coach: user.id })
                            setSelectedUser(user);
                            setUsers([]);
                            setAnchorUsersList(null);
                            setSearchString('');
                          }}
                          sx={{ cursor: 'pointer' }}
                          key={`conf-user-list-item-${user.id}`}
                        >
                          <ListItemIcon>
                            <PersonAddAltIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={`${user.first_name} ${user.last_name}`}
                          />
                        </ListItem>
                      )
                    })}
                  </List>
                </Box>
              </Popover>
              {selectedUser
                ? <Typography>{`${selectedUser.first_name} ${selectedUser.last_name}`}</Typography>
                : ''
              }
            </TabPanel>
            <TabPanel value="new_user">
              <Box>
                <Autocomplete
                  disablePortal
                  disabled={!accountInfo.account_info.permissions.includes('manage_clients')}
                  id="user-ticket-discipline-managing"
                  options={
                    filters.club_units.map((cu) => {
                      return {id: cu.id, label: cu.name}
                    })
                  }
                  sx={{ width: "100%", marginBottom: "15px" }}
                  renderInput={(params) => (<TextField {...params} label={t("club_unit", { ns: 'common' })} />)}
                  multiple={true}
                  onChange={(
                    e: React.SyntheticEvent,
                    value: Array<AutocompleteProps> | null,
                    reason: string,
                  ) => {
                    if (value && value.length) {
                      setNewCoachFromUser({ ...newCoachFromUser, units: value.map((v) => {return Number(v.id)}) });
                    } else {
                      setNewCoachFromUser({ ...newCoachFromUser, units: [] });
                    }
                  }}
                />
              </Box>
              {newCoachFromUser.units
                ? <Register
                    close_form={handleCloseNewCoach}
                    openPopUp={props.openPopUp}
                    register_user={handleRegisterUser}
                    appState={props.appState}
                    appSetState={props.appSetState}
                    device={props.device}
                    userTypes={userTypes}
                  />
                : ''
              }
            </TabPanel>
          </TabContext>
        </DialogContent>
        {newCoachSelect === 'exist_user'
          ? <DialogActions>
              <Button
                variant="contained"
                color="info"
                disabled={!newCoachFromUser.units || !newCoachFromUser.coach}
                onClick={
                  (e) => {
                    if (!newCoachFromUser.units || !newCoachFromUser.coach) {
                      props.openPopUp('error', 'fill_all_fields');
                      return
                    }
                    createNewCoach();
                  }
                }
              >
                <CheckIcon />
              </Button>
            </DialogActions>
          : ''
        }
      </BootstrapDialog>
    </Box>
  )
}
