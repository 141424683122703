import React from "react";
import { useEffect } from 'react';

import { useRecoilState, useRecoilValueLoadable, useRecoilValue } from 'recoil';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Autocomplete from '@mui/material/Autocomplete';

import { useTranslation } from 'react-i18next';

import { salaryStatSelector } from "../../atoms/SalariesCountStat";
import { accountInfoAtom } from "../../atoms/AccountInfo";
import { deviceAtom } from '../../atoms/Device';
import { spinnerAtom } from "../../atoms/Spinner";
import { allTicketsAtom } from "../../atoms/Tickets";

import { builtISODate } from "../../helpers";

import { AutocompleteProps } from "../../interfaces/Props";

import { SalaryTableCalendar } from "./components/SalaryTable";


interface ISalaryStatProps {
  statType: string
}

export function SalaryStatistic(props: ISalaryStatProps) {
  let today = new Date();
  let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  
  const device = useRecoilValue(deviceAtom);

  const { t, i18n } = useTranslation(['statistic', 'filters', 'common']);

  const [statDateStart, setStatDateStart] = React.useState<string>(builtISODate(firstDayOfMonth));
  const [statDateStop, setStatDateStop] = React.useState<string>(builtISODate(lastDayOfMonth));
  const [clubUnit, setClubUnit] = React.useState<number>(0);
  const [exceptTickets, setExceptTickets] = React.useState<number[]>([]);

  const salaryStat = useRecoilValueLoadable(
    salaryStatSelector(
      {club_unit: clubUnit, date_start: statDateStart, date_stop: statDateStop, except_tickets: exceptTickets}
    )
  )
  const [state, setState] = useRecoilState(accountInfoAtom);
  const [spinner, setSpinner] = useRecoilState(spinnerAtom)
  const tickets = useRecoilValueLoadable(allTicketsAtom);

  useEffect(() => {
    if (props.statType !== 'sails_stat') { return }
  }, [props.statType]);

  useEffect(() => {
    if (salaryStat.state === 'loading') {
      setSpinner({open: true})
      return
    }
    setSpinner({open: false})
  }, [salaryStat])

  return (
    <Box>
      <Box>
        <Box
          sx={{
            width: 'fit-content',
            padding: '5px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <TextField
            id="bid-date-from-id"
            label={t('date_from', { ns: 'filters' })}
            sx={{ paddingLeft: '5px', paddingRight: '5px' }}
            variant="standard"
            type="date"
            onChange={(e) => {setStatDateStart(e.target.value)}}
            defaultValue={statDateStart}
          />
          <TextField
            id="bid-date-to-id"
            label={t('date_to', { ns: 'filters' })}
            sx={{ paddingLeft: '5px', paddingRight: '5px' }}
            variant="standard"
            type="date"
            onChange={(e) => {setStatDateStop(e.target.value)}}
            defaultValue={statDateStop}
          />
        </Box>
      </Box>
      <Box sx={{ marginBottom: '5px' }}>
        <Card sx={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
          <CardContent>
            <Box
              sx={{ display: 'flex' }}
            >
              <Autocomplete
                disablePortal
                id="salary-stat-club-unit-filter-id"
                options={
                  state.account_info.clubs.map(club => club.units).flat().map(unit => {return {id: unit.id, label: unit.name}})
                }
                onChange={
                  (
                    e: React.SyntheticEvent,
                    value: AutocompleteProps | null,
                  ) => {
                    if (value && value.id) {
                      setClubUnit(Number(value.id));
                    } else {
                      setClubUnit(0);
                    }
                  }
                }
                sx={{
                  width: (device.item === 'mobile') ? '100%' : '30%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
                renderInput={
                  (params: any) => <TextField
                    {...params}
                    label={t("club_unit", { ns: 'filters' })}
                  />
                }
              />
              <Autocomplete
                disablePortal
                id="stat-ticket-filter-id"
                options={
                  (
                    tickets.state === "hasValue"
                      ? tickets.contents.items.map(
                        (t) => {
                          return {
                            id: t.id,
                            label: `${t.ticket} (${t.club_unit.map(cu => {return cu.name}).join(',')})`
                          }
                        }
                      )
                    : []
                  )
                }
                multiple={true}
                onChange={
                  (
                    e: React.SyntheticEvent,
                    value: Array<AutocompleteProps> | null,
                  ) => {
                    if (value && value.length) {
                      setExceptTickets(value.map((v) => { return Number(v.id) }));
                    } else {
                      setExceptTickets([]);
                    }
                  }
                }
                sx={{
                  width: (device.item === 'mobile') ? '100%' : '30%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
                renderInput={
                  (params: any) => <TextField
                    {...params}
                    label={t("ticket", { ns: 'filters' })}
                  />
                }
              />
            </Box>
            <SalaryTableCalendar
              dateStart={statDateStart}
              dateStop={statDateStop}
              clubUnit={clubUnit}
            />
          </CardContent>
        </Card>
      </Box>
    </Box>
  )
}
